/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import CartLine from './CartLine';
import CouponObj from './CouponObj';

/**
 * The RequestCart model module.
 * @module model/RequestCart
 * @version v1
 */
class RequestCart {
    /**
     * Constructs a new <code>RequestCart</code>.
     * @alias module:model/RequestCart
     * @param transactionRef {String} MaxLength:35
     * @param detail {Array.<module:model/CartLine>} 
     */
    constructor(transactionRef, detail) { 
        
        RequestCart.initialize(this, transactionRef, detail);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, transactionRef, detail) { 
        obj['TransactionRef'] = transactionRef;
        obj['Detail'] = detail;
    }

    /**
     * Constructs a <code>RequestCart</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/RequestCart} obj Optional instance to populate.
     * @return {module:model/RequestCart} The populated <code>RequestCart</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new RequestCart();

            if (data.hasOwnProperty('MemberUID')) {
                obj['MemberUID'] = ApiClient.convertToType(data['MemberUID'], 'String');
            }
            if (data.hasOwnProperty('LoyaltyCardNum')) {
                obj['LoyaltyCardNum'] = ApiClient.convertToType(data['LoyaltyCardNum'], 'String');
            }
            if (data.hasOwnProperty('TransactionRef')) {
                obj['TransactionRef'] = ApiClient.convertToType(data['TransactionRef'], 'String');
            }
            if (data.hasOwnProperty('Detail')) {
                obj['Detail'] = ApiClient.convertToType(data['Detail'], [CartLine]);
            }
            if (data.hasOwnProperty('Coupons')) {
                obj['Coupons'] = ApiClient.convertToType(data['Coupons'], [CouponObj]);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>RequestCart</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>RequestCart</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of RequestCart.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['MemberUID'] && !(typeof data['MemberUID'] === 'string' || data['MemberUID'] instanceof String)) {
            throw new Error("Expected the field `MemberUID` to be a primitive type in the JSON string but got " + data['MemberUID']);
        }
        // ensure the json data is a string
        if (data['LoyaltyCardNum'] && !(typeof data['LoyaltyCardNum'] === 'string' || data['LoyaltyCardNum'] instanceof String)) {
            throw new Error("Expected the field `LoyaltyCardNum` to be a primitive type in the JSON string but got " + data['LoyaltyCardNum']);
        }
        // ensure the json data is a string
        if (data['TransactionRef'] && !(typeof data['TransactionRef'] === 'string' || data['TransactionRef'] instanceof String)) {
            throw new Error("Expected the field `TransactionRef` to be a primitive type in the JSON string but got " + data['TransactionRef']);
        }
        if (data['Detail']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['Detail'])) {
                throw new Error("Expected the field `Detail` to be an array in the JSON data but got " + data['Detail']);
            }
            // validate the optional field `Detail` (array)
            for (const item of data['Detail']) {
                CartLine.validateJSON(item);
            };
        }
        if (data['Coupons']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['Coupons'])) {
                throw new Error("Expected the field `Coupons` to be an array in the JSON data but got " + data['Coupons']);
            }
            // validate the optional field `Coupons` (array)
            for (const item of data['Coupons']) {
                CouponObj.validateJSON(item);
            };
        }

        return true;
    }


}

RequestCart.RequiredProperties = ["TransactionRef", "Detail"];

/**
 * MaxLength:100
 * @member {String} MemberUID
 */
RequestCart.prototype['MemberUID'] = undefined;

/**
 * MaxLength:25
 * @member {String} LoyaltyCardNum
 */
RequestCart.prototype['LoyaltyCardNum'] = undefined;

/**
 * MaxLength:35
 * @member {String} TransactionRef
 */
RequestCart.prototype['TransactionRef'] = undefined;

/**
 * @member {Array.<module:model/CartLine>} Detail
 */
RequestCart.prototype['Detail'] = undefined;

/**
 * @member {Array.<module:model/CouponObj>} Coupons
 */
RequestCart.prototype['Coupons'] = undefined;






export default RequestCart;

