import CKNavigator from "../lib/CKNavigator";
import Styles, { CKColors, CKPadding, CKSizes } from "../lib/Styles";
import CommsLayer from "../lib/api/CommsLayer";
import ErrorCodes from "../lib/api/ErrorCodes";
import Alert from "../lib/components/Alert";
import CKButton from "../lib/components/CKButton";
import CKIcon, { CKIconType } from "../lib/components/CKIcon";
import CKInput from "../lib/components/CKInput";
import CKMenuItem from "../lib/components/CKMenuItem";
import CKMenuPanel from "../lib/components/CKMenuPanel";
import CKSpacer from "../lib/components/CKSpacer";
import CKText from "../lib/components/CKText";
import View from "../lib/components/View";
import LoggedInBase from "./LoggedInBase";

export default class DeleteAccount extends LoggedInBase {
    screenName = 'more'
    title = 'Delete Account'
    subLevelPage = true

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,

            txtPassword: '',
            txtPasswordError: ''
        }
    }

    loadData() {
        this.hideLoading();
    }

    componentDidMount() {
        super.componentDidMount();
    }

    onBack() {
        this.navigateTo(CKNavigator.moreProfile);
    }
    
    deleteAccount = () => {
        Alert.alert("Delete account", "You’ll lose all the progress on your account, if you decide to come back, you’ll start at Extra level 1.", [
            {
                text: "Cancel",
                style: 'primary'
            },
            {
                text: "Delete",
                style: 'secondary',
                onPress: () => {
                    Alert.dismiss();
                    
                    this.setState({
                        waitingForDeleteAccount: true,
                        txtPasswordError: ''
                    }, () => {
                        CommsLayer.members().membersV1MemberAccountDeletion(this.state.txtPassword).then(() => {
                            CommsLayer.logout();
                            this.navigateTo(CKNavigator.login)
                        }).catch(() => {
                            this.setState({
                                waitingForDeleteAccount: false,
                                txtPasswordError: 'Incorrect password'
                            });
                        })
                    })
                }
            }
        ])
    }

    render() {
        return super.render(
            <View className="main-container" style={{
                display: 'flex',
                flexDirection: 'column'
            }}>
                <CKText className="change-pass-title">We’re sorry to see you go</CKText>

                <CKText className="change-pass-subtitle" style={{
                    maxWidth: 260,
                    alignSelf: 'center'
                }}>
                    Are you sure you want to delete your account? You’re welcome back anytime!
                </CKText>
                
                <View className="delete-pass-container">
                    <View>
                        <CKInput
                            autoCapitalize='none'
                            label='Password'
                            placeholder={'Please enter password'}
                            error={this.state.txtPasswordError}
                            type="password"
                            value={this.state.txtPassword}
                            onChange={(evt) => {
                                this.setState({
                                    txtPassword: evt.target.value,
                                    txtPasswordError: ''
                                }, () => {
                                    
                                });
                            }}
                        />
                    </View>

                    <CKButton style={{
                        marginTop: CKPadding.default * 3
                    }} disabled={!this.state.txtPassword} onPress={() => {
                        this.deleteAccount();
                    }} loading={this.state.waitingForDeleteAccount}
                    >Delete Account</CKButton>

                    <CKButton style={{
                        marginTop: CKPadding.default
                    }} secondary onPress={() => {
                        this.onBack();
                    }}
                    >Back to Profile</CKButton>
                </View>
                

                <CKSpacer />
                <CKSpacer />
            </View>
        )
    }
}