/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import APIResponse from '../model/APIResponse';
import MemberPoints from '../model/MemberPoints';
import MemberPointsResponse from '../model/MemberPointsResponse';

/**
* Points service.
* @module api/PointsApi
* @version v1
*/
export default class PointsApi {

    /**
    * Constructs a new PointsApi. 
    * @alias module:api/PointsApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the pointsV1AddMemberPoints operation.
     * @callback module:api/PointsApi~pointsV1AddMemberPointsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MemberPointsResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Add Member Points
     * Add points for a member   
     * @param {String} memberUId Member Unique Identifier
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:model/MemberPoints} memberPoints MemberPoints Object
     * @param {module:api/PointsApi~pointsV1AddMemberPointsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/MemberPointsResponse}
     */
    pointsV1AddMemberPoints(memberUId, clientId, authorization, memberPoints, callback) {
      let postBody = memberPoints;
      // verify the required parameter 'memberUId' is set
      if (memberUId === undefined || memberUId === null) {
        throw new Error("Missing the required parameter 'memberUId' when calling pointsV1AddMemberPoints");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling pointsV1AddMemberPoints");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling pointsV1AddMemberPoints");
      }
      // verify the required parameter 'memberPoints' is set
      if (memberPoints === undefined || memberPoints === null) {
        throw new Error("Missing the required parameter 'memberPoints' when calling pointsV1AddMemberPoints");
      }

      let pathParams = {
        'memberUId': memberUId
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json', 'text/json', 'application/xml', 'text/xml', 'application/x-www-form-urlencoded'];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = MemberPointsResponse;
      return this.apiClient.callApi(
        '/v1/points/Members/{memberUId}/Add', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the pointsV1RedeemMemberPoints operation.
     * @callback module:api/PointsApi~pointsV1RedeemMemberPointsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MemberPointsResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Redeem Member Points
     * Redeem points for a member   
     * @param {String} memberUId Member Unique Identifier
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:model/MemberPoints} memberPoints MemberPoints Object
     * @param {module:api/PointsApi~pointsV1RedeemMemberPointsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/MemberPointsResponse}
     */
    pointsV1RedeemMemberPoints(memberUId, clientId, authorization, memberPoints, callback) {
      let postBody = memberPoints;
      // verify the required parameter 'memberUId' is set
      if (memberUId === undefined || memberUId === null) {
        throw new Error("Missing the required parameter 'memberUId' when calling pointsV1RedeemMemberPoints");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling pointsV1RedeemMemberPoints");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling pointsV1RedeemMemberPoints");
      }
      // verify the required parameter 'memberPoints' is set
      if (memberPoints === undefined || memberPoints === null) {
        throw new Error("Missing the required parameter 'memberPoints' when calling pointsV1RedeemMemberPoints");
      }

      let pathParams = {
        'memberUId': memberUId
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json', 'text/json', 'application/xml', 'text/xml', 'application/x-www-form-urlencoded'];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = MemberPointsResponse;
      return this.apiClient.callApi(
        '/v1/points/Members/{memberUId}/Redeem', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
