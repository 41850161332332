const Alert = {
    alerts: [],

    showTerms: () => {
        // TODO: notification to be raised from within the App.js - it is bound there
    },
    dismissTerms: () => {
        // TODO: notification to be raised from within the App.js - it is bound there
    },

    notify: () => {
        // TODO: notification to be raised from within the App.js - it is bound there
    },

    alert: (title, message, buttons = [{
        text: 'Continue',
        onPress: false,
        style: 'primary'
    }]) => {
        Alert.alerts.push({
            title,
            message,
            buttons
        });

        Alert.notify();
    },

    dismiss: () => {
        Alert.alerts.shift();

        Alert.notify();
    },

    get: () => {
        return Alert.alerts.length > 0 ? Alert.alerts[0] : false;
    }
}

export default Alert;