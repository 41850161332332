import axios, { AxiosError } from "axios";
import ErrorCodes from "./ErrorCodes";
import { XMLParser } from 'fast-xml-parser';
import {
    ApiClient,
    AccessIdentifierApi,
    CardsApi,
    ClubsApi,
    CommunicationsApi,
    CompetitionsApi,
    CustomFieldsApi,
    DonationsApi,
    GiftsApi,
    MembersApi,
    OffersApi,
    OrdersApi,
    PartnersApi,
    PointsApi,
    RewardsApi,
    SocialProfilesApi,
    SocietiesApi,
    SurveysApi
} from './generated/src';
import Identity, { TokenResponse } from "./Identity";
import FuelCard from "./FuelCard";
import Cookies from "js-cookie";
import CKCache, { CKCacheItem } from "../CKCache";
var apiClient = new ApiClient();

export const REFRESH_TOKEN_ERROR_EVENT = 'REFRESH_TOKEN_ERROR_EVENT';

const IS_MOCK = false;

class CommsLayer__c {
    token = new TokenResponse();
    appToken = new TokenResponse();
    hostProxyApi = process.env.REACT_APP_HOST_PROXY_API !== undefined ? process.env.REACT_APP_HOST_PROXY_API : 'https://frontend-test.ecd-test.alpaque.net';
    xmlEnpoint = this.hostProxyApi + '/appdata/xml-extra/new';
    playorParkOffers = this.hostProxyApi + '/appdata/loyalty/loyalty.xml';
    playorParkWowOffers = this.hostProxyApi + '/appdata/xml-extra/wowoffers-upgrade-new.xml';
    playorParkPreviousWinners = this.hostProxyApi + '/appdata/xml-extra/homescreen/news_new_app.xml';
    REFRESH_TOKEN_ERROR = 4001
    HOME_SCREEN = ""

    clientId = ""
    clientKey = ""

    memberData = {"AddressLine1": null, "AddressLine2": null, "Country": null, "County": null, "DateOfBirth": null, "Email": null, "EmailOptIn": false, "FirstName": null, "Gender": null, "HomeHeatOil": false, "HomeNum": null, "HouseName": null, "InAppOptIn": true, "IsEmployee": false, "IsMemberLoginCreated": false, "IsRegistered": false, "LastName": null, "LinkedPointsBalance": 0, "LoyaltyBalance": 0, "MobileNum": null, "MobileNumCountryId": null, "PartnersOptIn": false, "PostCode": null, "PostOptIn": false, "ResearchOptIn": false, "SMSOptIn": false, "SpendBalance": 0, "StoreId": 0, "TandCAgreed": false, "Title": null, "Town": null, "WorkNum": null};

    pendingCalls = []

    accessIdentifierApi = new AccessIdentifierApi();
    cardsApi = new CardsApi();
    clubsApi = new ClubsApi();
    communicationsApi = new CommunicationsApi();
    competitionsApi = new CompetitionsApi();
    customFieldsApi = new CustomFieldsApi();
    donationsApi = new DonationsApi();
    giftsApi = new GiftsApi();
    membersApi = new MembersApi();
    offersApi = new OffersApi();
    ordersApi = new OrdersApi();
    partnersApi = new PartnersApi();
    pointsApi = new PointsApi();
    rewardsApi = new RewardsApi();
    socialProfilesApi = new SocialProfilesApi();
    societiesApi = new SocietiesApi();
    surveysApi = new SurveysApi();

    constructor() {}

    initialise = (endpoint, clientId, clientKey) => {
        this._baseUrl = endpoint;
        this.clientKey = clientKey;
        this.clientId = clientId;

        apiClient = new ApiClient(this._baseUrl);

        // For proxy API:
        let loginEndpoint = this._baseUrl + '/v1/user/login';
        this.loginAPI = new Identity(loginEndpoint, this.clientKey);
        this.loginAPI.isProxy = true;
        let fuelCardEndpoint = this._baseUrl + '/v1/fuelcard'
        this.fuelCardAPI = new FuelCard(fuelCardEndpoint, this.clientKey);
        this.fuelCardAPI.isProxy = true;

        this._initAllApis();
    }

    ALL_APIS = {
        live: {
            accessIdentifierApi : AccessIdentifierApi,
            cardsApi : CardsApi,
            clubsApi : ClubsApi,
            communicationsApi : CommunicationsApi,
            competitionsApi : CompetitionsApi,
            customFieldsApi : CustomFieldsApi,
            donationsApi : DonationsApi,
            giftsApi : GiftsApi,
            membersApi : MembersApi,
            offersApi : OffersApi,
            ordersApi : OrdersApi,
            partnersApi : PartnersApi,
            pointsApi : PointsApi,
            rewardsApi : RewardsApi,
            socialProfilesApi : SocialProfilesApi,
            societiesApi : SocietiesApi,
            surveysApi : SurveysApi
        }
    };

    _initApis = (apis = {}, headers = {}) => {
        apiClient.defaultHeaders = headers;

        Object.keys(apis).forEach(k => {
            this[k] = new apis[k](apiClient);
        });
    }

    _initAllApis = (headers = {}) => {
        let apisToInit = this.ALL_APIS.live;

        if(IS_MOCK) {
            apisToInit = this.ALL_APIS.mock;
        }

        if(!headers) {
            headers = {};
        }

        headers = {
            ...headers
        }

        this._initApis(apisToInit, headers);
    }

    isSandbox = () => {        
        return this._baseUrl.indexOf("sandbox") >= 0 || this._baseUrl.indexOf('192.168') >= 0 || this._baseUrl.indexOf('localhost') >= 0 || this._baseUrl.indexOf('test') >= 0 || this._baseUrl.indexOf('qa') >= 0;
    }

    prepareResponse = (r) => {
        if(r) {
            if(r.data) {
                r = r.data;
            }
        }
        
        return r;
    }

    uiLogout = () => {
        // this method is bound on each active logged in screen to manage refresh token failures and auto signout
        // See - LoggedInBase.js
    }

    logout = () => {
        return new Promise((resolve) => {
            Cookies.remove('refresh_token');

            resolve();
        });
    }

    setToken = (token) => {
        this.token = token;
        // save refresh_token into the keychain
        Cookies.set('refresh_token', this.getToken().refresh_token, { sameSite: 'none', secure: true});
    }

    setAppToken = (token) => {
        this.appToken = token;
        // save refresh_token into the keychain
        Cookies.set('app_token', this.getAppToken().refresh_token, { sameSite: 'none', secure: true});
    }

    getToken = () => {
        return this.token;
    }

    getAppToken = () => {
        return this.appToken;
    }

    login = (username, password) => {
        return this.loginAPI.memberLogin(username, password).then(async token => {
            this.setToken(token);

            // lets try to load app settings
            try {
                let xmlSettings = await this.xmls().settings();
                if(xmlSettings && xmlSettings.home) {
                    this.HOME_SCREEN = xmlSettings.home;
                }
            } catch {}
            // - - - - - - - -

            return token;
        });
    }

    refreshToken = (refresh_token, memberUID = '') => {
        return new Promise((resolve, reject) => {
            this.loginAPI.refreshToken(refresh_token).then(async token => {
                if(memberUID) token.memberUID = memberUID;

                this.setToken(token);

                // lets try to load app settings
                try {
                    let xmlSettings = await this.xmls().settings();
                    if(xmlSettings && xmlSettings.home) {
                        this.HOME_SCREEN = xmlSettings.home;
                    }
                } catch {}
                // - - - - - - - -
    
                resolve(token);
            }).catch(async (err) => {
                if(err && err.code !==AxiosError.ERR_NETWORK) {
                    try {
                        let pass = Cookies.get('refresh_token');
                        
                        if(pass) {
                            // lets try to refresh token based on memberUUID
                            let token = await this.loginAPI.appLogin();
                            token.memberUID = pass;

                            this.setToken(token);

                            // lets try to load app settings
                            try {
                                let xmlSettings = await this.xmls().settings();
                                if(xmlSettings && xmlSettings.home) {
                                    this.HOME_SCREEN = xmlSettings.home;
                                }
                            } catch {}
                            // - - - - - - - -

                            resolve(token);
                        } else {
                            reject({
                                response: {
                                    status: this.REFRESH_TOKEN_ERROR
                                }
                            });
                        }
                    } catch(err) {
                        reject({
                            response: {
                                status: this.REFRESH_TOKEN_ERROR
                            }
                        });
                    }
                } else {
                    reject(err);
                    
                }
            });
        });
    }

    refreshAppToken = (refresh_token) => {
        return this.loginAPI.refreshToken(refresh_token).then(token => {
            this.setAppToken(token);

            return token;
        });
    }

    callAppApiPublic = (apiCall, apiMethod = '') => {
        // lets first check if apiMethod currently pending
        if(!this.pendingCalls) this.pendingCalls = [];
        if(apiMethod && this.pendingCalls[apiMethod]) return this.pendingCalls[apiMethod];

        let promise = new Promise(async (resolve, reject) => {
            try {
                let response = await apiCall();
                if(apiMethod) this.pendingCalls[apiMethod] = false;
                resolve(response);
            } catch(err) {
                let foundResponse = false;
                if(err.response && err.response.body && err.response.body.message && err.response.body.message.code) {
                    foundResponse = err.response.body.message;
                } else if(err.response && err.response.body && err.response.body.data && err.response.body.data.message && err.response.body.data.code) {
                    foundResponse = err.response.body.data;
                }
                
                if(foundResponse && foundResponse.message && foundResponse.code) {
                    let errorMessage = ErrorCodes.find(foundResponse.code);
                    if(errorMessage) {
                        err = {
                            code: foundResponse.code,
                            message: errorMessage
                        }
                    }
                }

                if(apiMethod) this.pendingCalls[apiMethod] = false;
                reject(err);
            }
        });

        if(apiMethod) this.pendingCalls[apiMethod] = promise;

        return promise;
    }

    callApiAutoRefreshToken = (apiCall, apiMethod = '') => {
        // lets first check if apiMethod currently pending
        if(!this.pendingCalls) this.pendingCalls = [];
        if(apiMethod && this.pendingCalls[apiMethod]) return this.pendingCalls[apiMethod];

        let promise = new Promise(async (resolve, reject) => {
            try {
                // lets check if token needs to be refreshed
                if(this.getToken() && this.getToken().isExpired() && this.getToken().refresh_token) {
                    // lets renew token
                    this.setToken(await this.refreshToken(this.getToken().refresh_token, this.getMemberUID()));
                }

                let response = await apiCall();
                if(apiMethod) this.pendingCalls[apiMethod] = false;
                resolve(response);
            } catch(err) {
                if(err.response && err.response.status ===401) {
                    // lets try to refresh token and then retry the call
                    try {
                        this.setToken(await this.refreshToken(this.getToken().refresh_token, this.getMemberUID()));
                        
                        // lets try to call API again
                        let response = await apiCall();
                        if(apiMethod) this.pendingCalls[apiMethod] = false;
                        resolve(response);
                    } catch(err) {
                        if(apiMethod) this.pendingCalls[apiMethod] = false;

                        // lets check if refresh token failed, ignore normal reject, but force logout
                        if(err.response.status ===this.REFRESH_TOKEN_ERROR) {
                            console.log('REFRESH TOKEN ERROR');
                            this.uiLogout();
                        } else {
                            reject(err);
                        }
                    }
                } else {
                    if(err && err.response && err.response.text) {
                        try {
                            err.response = JSON.parse(err.response.text);
                        } catch(e) {}
                    }

                    if(err.response && err.response.data && err.response.data.ErrorCode) {
                        let errorMessage = ErrorCodes.find(err.response.data.ErrorCode);
                        if(errorMessage) {
                            err = {
                                code: err.response.data.ErrorCode,
                                message: errorMessage
                            }
                        }
                    }

                    if(apiMethod) this.pendingCalls[apiMethod] = false;

                    // lets check if refresh token failed, ignore normal reject, but force logout
                    if(err && err.response && err.response.status ===this.REFRESH_TOKEN_ERROR) {
                        console.log('REFRESH TOKEN ERROR 2');
                        this.uiLogout();
                    } else {
                        reject(err);
                    }
                }
            }
        });

        if(apiMethod) this.pendingCalls[apiMethod] = promise;

        return promise;
    }

    getMemberUID = () => {
        return this.getToken() ? this.getToken().memberUID : '';
    }

    getClientId = () => {
        return this.clientId;
    }

    getBearerToken = () => {
        return this.getToken() ? this.getToken().getBearer() : '';
    }

    getAppBearerToken = () => {
        return this.getAppToken() ? this.getAppToken().getBearer() : '';
    }

    standardRequestCallback = (resolve, reject) => {
        return (error, data) => {
            if(error) reject(error);
            else resolve(data);
        }
    }

    // membersAPI
    members = () => {
        return {
            setLastSavedProfile: (fields) => {
                this.lastSavedProfile = fields;  
            },
            getLastSavedProfile: () => {
                let lastSavedProfile = this.lastSavedProfile
                
                this.lastSavedProfile = false;

                return lastSavedProfile;
            },
            // logged in calls
            membersV1GetMemberInfo: () => {
                return this.callApiAutoRefreshToken(() => {
                    return new Promise((resolve, reject) => {
                        this.membersApi.membersV1GetMemberInfo(
                            this.getMemberUID(),
                            this.getClientId(),
                            this.getBearerToken(),
                            this.standardRequestCallback(resolve, reject)
                        );
                    })
                }).then(this.prepareResponse).then((data) => {
                    this.memberData = data;

                    return data;
                });
            },
            membersV1GetMemberSummary: () => {
                return this.callApiAutoRefreshToken(() => {
                    return new Promise((resolve, reject) => {
                        this.membersApi.membersV1GetMemberSummary(
                            this.getMemberUID(),
                            this.getClientId(),
                            this.getBearerToken(),
                            {},
                            this.standardRequestCallback(resolve, reject)
                        );
                    });
                }).then(this.prepareResponse).then(async data => {
                    // caching for 1 year, it would be removed upon signout
                    //await CKCache.setItem(CKCacheItem.membersV1GetMemberSummary, data, 60 * 60 * 24 * 365);
                    return data;
                });
            },
            membersV1MemberAccountDeletion: (password) => {
                return this.callApiAutoRefreshToken(() => {
                    return new Promise((resolve, reject) => {
                        this.membersApi.membersV1MemberAccountDeletion(
                            this.getMemberUID(),
                            this.getClientId(),
                            this.getBearerToken(),
                            {
                                Password: password
                            },
                            this.standardRequestCallback(resolve, reject)
                        );
                    });
                }).then(this.prepareResponse);
            },
            membersV1UpdateMemberPatch: (memberData) => {
                return this.callApiAutoRefreshToken(() => {
                    return new Promise((resolve, reject) => {
                        this.membersApi.membersV1UpdateMemberPatch(
                            this.getMemberUID(),
                            this.getClientId(),
                            this.getBearerToken(),
                            memberData,
                            this.standardRequestCallback(resolve, reject)
                        );
                    });
                }).then(this.prepareResponse).then(async (data) => {
                    try {
                        await this.members().membersV1GetMemberInfo();
                    } catch {}

                    return data;
                });
            },
            membersV1UpdatePassword: (oldPassword, newPassword, confirmNewPassword) => {
                return this.callApiAutoRefreshToken(() => {
                    return new Promise((resolve, reject) => {
                        this.membersApi.membersV1UpdatePassword(
                            this.getMemberUID(),
                            this.getClientId(),
                            this.getBearerToken(),
                            {
                                OldPassword: oldPassword,
                                NewPassword: newPassword,
                                ConfirmNewPassword: confirmNewPassword
                            },
                            this.standardRequestCallback(resolve, reject)
                        );
                    })
                }).then(this.prepareResponse);
            },
            

            // public calls
            membersV1ForgotPassword: (email) => {
                return this.callAppApiPublic(() => {
                    return new Promise((resolve, reject) => {
                        this.membersApi.membersV1ForgotPassword(
                            this.getClientId(),
                            this.getAppBearerToken(),
                            {username: email},
                            this.standardRequestCallback(resolve, reject)
                        )
                    });
                }).then(this.prepareResponse)
            },
            membersV1RegisterMember: (firstname, surname, email, password, dateOfBirth) => {
                return this.callAppApiPublic(() => {
                    return new Promise((resolve, reject) => {
                        this.membersApi.membersV1RegisterMember(
                            this.getClientId(),
                            this.getAppBearerToken(),
                            {
                                FirstName: firstname,
                                LastName: surname,
                                Email: email,
                                DateOfBirth: dateOfBirth,
                                RegistrationType: 3, // In App
                                TandCAgreed: true,
                                Password: password,
                                InAppOptIn: true
                            },
                            this.standardRequestCallback(resolve, reject)
                        )
                    });
                }).then(this.prepareResponse);
            }
        }
    }

    accessIdentifier = () => {
        // this.accessIdentifierApi
        return {
            accessIdentifierV1CreateAccessIdentifier: (url) => {
                return this.callApiAutoRefreshToken(() => {
                    return new Promise((resolve, reject) => {
                        this.accessIdentifierApi.accessIdentifierV1CreateAccessIdentifier(
                            this.getMemberUID(),
                            this.getClientId(),
                            this.getBearerToken(),
                            {
                                URL: url,
                                PermissionShared: true
                            },
                            this.standardRequestCallback(resolve, reject)
                        );
                    });
                }).then(this.prepareResponse);
            }
        }
    }

    cards = () => {
        // this.cardsApi
        return {
            
        }
    }

    clubs = () => {
        // this.clubsApi
        return {
            clubsV1AddMemberToClub: (clubId) => {
                return this.callApiAutoRefreshToken(() => {
                    return new Promise((resolve, reject) => {
                        this.clubsApi.clubsV1AddMemberToClub(
                            this.getMemberUID(),
                            clubId,
                            this.getClientId(),
                            this.getBearerToken(),
                            this.standardRequestCallback(resolve, reject)
                        );
                    });
                }).then(this.prepareResponse);
            },
            get: () => {
                return this.callApiAutoRefreshToken(() => {
                    return new Promise((resolve, reject) => {
                        this.clubsApi.clubsV1GetMemberClubs(
                            this.getMemberUID(),
                            this.getClientId(),
                            this.getBearerToken(),
                            {},
                            this.standardRequestCallback(resolve, reject)
                        );
                    });
                }).then(this.prepareResponse).then(async clubs => {                    
                    // lets retrieve XML files with structure
                    let my_club = false;
                    let default_club = false;
                    let pending_text = '';
                    let pending_title = '';
                    let membership_info = '';
                    let isSpecial = false;

                    try {
                        let club_structure = await this.xmls().levels();
                        pending_text = club_structure.pending_text;
                        pending_title = club_structure.pending_title;
                        membership_info = club_structure.membership_info;
                        
                        if(club_structure && club_structure.tiered) {
                            club_structure.tiered = club_structure.tiered.reverse();

                            // lets find default club
                            for(var i = 0; i < club_structure.tiered.length; i++) {
                                if(club_structure.tiered[i].is_default) {
                                    default_club = club_structure.tiered[i];
                                    break;
                                }
                            }

                            // lets see if we find any level from tiered
                            for(var i = 0; i < club_structure.tiered.length; i++) {
                                let my_c = clubs.find(c => {
                                    return c.ClubId ===club_structure.tiered[i].group_id;
                                })

                                if(my_c) {
                                    my_club = club_structure.tiered[i];
                                    break;
                                }
                            }
                        }

                        // lets check if I am in the special group
                        if(club_structure && club_structure.special) {
                            if(!Array.isArray(club_structure.special)) {
                                club_structure.special = [club_structure.special];
                            }

                            // lets see if we find any level from special then take first
                            for(var i = 0; i < club_structure.special.length; i++) {
                                let my_c = clubs.find(c => {
                                    return c.ClubId ===club_structure.special[i].group_id;
                                })

                                if(my_c) {
                                    my_club = club_structure.special[i];
                                    isSpecial = true;
                                    break;
                                }
                            }
                        }

                    } catch(err) {
                        console.log(err);
                    }

                    return {
                        my_club,
                        clubs,
                        pending_text,
                        pending_title,
                        default_club,
                        membership_info,
                        isSpecial
                    };
                });
            }
        }
    }

    communications = () => {
        // this.communicationsApi
        return {
            communicationsV1MarkAsRead: (communicationId) => {
                return this.callApiAutoRefreshToken(() => {
                    return new Promise((resolve, reject) => {
                        this.communicationsApi.communicationsV1MarkAsRead(
                            communicationId,
                            this.getClientId(),
                            this.getBearerToken(),
                            this.standardRequestCallback(resolve, reject)
                        );
                    });
                }).then(this.prepareResponse);
            },

            communicationsV1GetMemberMessages: (forceUpdate = false) => {
                // lets check if in cache
                if(!forceUpdate) {
                    return new Promise(async (resolve, reject) => {
                        let result = false;

                        try {
                            // lets check if cache exists
                            let result = await CKCache.getItem(CKCacheItem.communicationsV1GetMemberMessages);

                            if(result) {
                                return resolve(result);
                            }
                        } catch {
                            // error reading cache, override with force update
                            result = false;
                        }

                        if(!result) {
                            this.communications().communicationsV1GetMemberMessages(true).then(resolve).catch(reject);
                        }
                    });
                }

                return this.callApiAutoRefreshToken(() => {
                    return new Promise((resolve, reject) => {
                        this.communicationsApi.communicationsV1GetMemberMessages(
                            this.getMemberUID(),
                            this.getClientId(),
                            this.getBearerToken(),
                            this.standardRequestCallback(resolve, reject)
                        );
                    });
                }).then(this.prepareResponse).then((data) => {
                    CKCache.setItem(CKCacheItem.communicationsV1GetMemberMessages, data);

                    return data;
                });
            }
        }
    }

    competitions = () => {
        return {
            competitionsV1GetCompetitions: () => {
                return this.callApiAutoRefreshToken(() => {
                    return new Promise((resolve, reject) => {
                        this.competitionsApi.competitionsV1GetCompetitions(
                            this.getClientId(),
                            this.getBearerToken(),
                            {},
                            this.standardRequestCallback(resolve, reject)
                        );
                    });
                }).then(this.prepareResponse);
            },
            competitionsV1GetCompetition: (competitionId) => {
                return this.callApiAutoRefreshToken(() => {
                    return new Promise((resolve, reject) => {
                        this.competitionsApi.competitionsV1GetCompetition(
                            competitionId,
                            this.getClientId(),
                            this.getBearerToken(),
                            this.standardRequestCallback(resolve, reject)
                        );
                    });
                }).then(this.prepareResponse);
            },
            competitionsV1SaveCompetitionEntry: (competitionId, numberOfPlays = 1) => {
                return this.callApiAutoRefreshToken(() => {
                    return new Promise((resolve, reject) => {
                        this.competitionsApi.competitionsV1SaveCompetitionEntry(
                            this.getMemberUID(),
                            competitionId,
                            this.getClientId(),
                            this.getBearerToken(),
                            {
                                CommunicationTypeId: 1,
                                TotalPlays: numberOfPlays
                            },
                            this.standardRequestCallback(resolve, reject)
                        );
                    });
                }).then(this.prepareResponse);
            },
        }
    }

    customFields = () => {
        // this.customFieldsApi
        return {
            
        }
    }

    donations = () => {
        // this.donationsApi
        return {
            
        }
    }

    gifts = () => {
        // this.giftsApi
        return {
            
        }
    }

    offers = () => {
        // this.offersApi
        return {
            offersV1GetCurrentOffersWithDetails: (ignoreGrouping = false) => {
                return this.callApiAutoRefreshToken(() => {
                    return new Promise((resolve, reject) => {
                        this.offersApi.offersV1GetCurrentOffersWithDetails(
                            9999,
                            this.getMemberUID(),
                            this.getClientId(),
                            this.getBearerToken(),
                            {},
                            this.standardRequestCallback(resolve, reject)
                        )
                    })
                }).then(this.prepareResponse).then(async (result) => {
                    if(ignoreGrouping) return result;
                    // lets load rules for the offers screen
                    let rules = {};

                    try {
                        rules = await this.xmls().offers();
                    } catch {}

                    // lets group offers into three groups
                    // 1. "FUEL:" offers
                    // 2. "SURPRISE:" offers
                    // 3. "COUNT:" offers
                    // 4. "VIEW_MORE:" offers
                    // 5. "OTHER:" offers
                    let offers = {
                        exclude: [],
                        fuel: [],
                        surprise: [],
                        counting: [],
                        view_more: [],
                        other: [],
                        total_offers: 0
                    }

                    if(result && result.length > 0) {
                        let ruleKeys = Object.keys(rules);
                        result.forEach((offer) => {
                            for(var i = 0; i < ruleKeys.length; i++) {
                                let key = ruleKeys[i];

                                // skip if rule is unkown
                                if(!offers[key]) continue;

                                let ruleOffer = rules[key].offers && rules[key].offers.length > 0 ? rules[key].offers.find(o => o.id ===offer.OfferId) : false;

                                if(ruleOffer) {
                                    offer.rule = ruleOffer;
                                }

                                // lets check if FindOutMore exist and starts with particular key
                                if(rules[key].text_indicator && offer.FindOutMore && offer.FindOutMore.startsWith(rules[key].text_indicator)) {
                                    offer.FindOutMore = offer.FindOutMore.replace(rules[key].text_indicator, "").trim();

                                    if(rules[key].offer_text) {
                                        offer.custom_title = rules[key].offer_text;
                                    }

                                    offers[key].push(offer);
                                    offers.total_offers++;
                                    break;
                                }
                                // check if particular offer is found by ID
                                else if(ruleOffer) {
                                    if(rules[key].offer_text) {
                                        offer.custom_title = rules[key].offer_text;
                                    }

                                    offers[key].push(offer);
                                    offers.total_offers++;
                                    break;
                                }
                            }
                        })
                    }

                    return offers;
                });
            }
        }
    }

    orders = () => {
        // this.ordersApi
        return {
            ordersV1GetOrderSummary: () => {
                return this.callApiAutoRefreshToken(() => {
                    return new Promise((resolve, reject) => {
                        this.ordersApi.ordersV1GetOrderSummary(
                            this.getMemberUID(),
                            this.getClientId(),
                            this.getBearerToken(),
                            this.standardRequestCallback(resolve, reject)
                        );
                    });
                }).then(this.prepareResponse);
            },
            addLoyalty: (ref) => {
                return this.callApiAutoRefreshToken(() => {
                    return new Promise((resolve, reject) => {
                        this.ordersApi.ordersV1AddLoyalty(
                            this.getMemberUID(),
                            this.getClientId(),
                            this.getBearerToken(),
                            {
                                TransactionRef: ref
                            },
                            this.standardRequestCallback(resolve, reject)
                        );
                    });
                }).then(this.prepareResponse);
            }
        }
    }

    partners = () => {
        // this.partnersApi
        return {
            partnersV1SaveTransactionalEmail: (bodyMessage, emailAddressTo) => {
                return this.callAppApiAutoRefreshToken(() => {
                    return new Promise((resolve, reject) => {
                        this.partnersApi.partnersV1SaveTransactionalEmail(
                            this.getMemberUID(),
                            this.getClientId(),
                            this.getBearerToken(),
                            {
                                BodyMessage: bodyMessage,
                                EmailAddressTo: emailAddressTo,
                                TransTypeId: 4 // contact us
                            },
                            this.standardRequestCallback(resolve, reject)
                        )
                    });
                }).then(this.prepareResponse);
            }
        }
    }

    points = () => {
        // this.pointsApi
        return {
            
        }
    }

    rewards = () => {
        // this.rewardsApi
        return {
            
        }
    }

    socialProfiles = () => {
        // this.socialProfilesApi
        return {
            
        }
    }

    societies = () => {
        // this.societiesApi
        return {
            
        }
    }

    surveys = () => {
        // this.surveysApi
        return {
            
        }
    }

    xmls = () => {
        return {
            _getCachedXmlCall: (url) => {
                if(this.isSandbox() && url.indexOf('https://') >= 0 && url.indexOf('homescreen') < 0) {
                    url = url.replace('appdata/loyalty/', 'appdata/loyalty/sandbox/');
                    url = url.replace('appdata/xml/', 'appdata/xml/sandbox/');
                }
                
                url = (url.indexOf('https://') >= 0 ? 
                    '' 
                    : 
                    this.xmlEnpoint + (this.isSandbox() ? "/sandbox" : "")) + url;

                return new Promise(async (resolve, reject) => {
                    let data = false;
                    try {
                        data = await CKCache.getItem(url);
                    } catch {
                        data = false;
                    }

                    if(!data || this.isSandbox()) {
                        try {
                            data = await axios.get(url, {
                                headers: {
                                    'Cache-Control': 'no-cache',
                                    'Pragma': 'no-cache',
                                    'Expires': '0',
                                },
                            });
                            data = data.data;
                            
                            data = new XMLParser().parse(data);

                            await CKCache.setItem(url, data);
                        } catch(err) {
                            data = false;
                        }
                    }
                    
                    if(data) {
                        resolve(data);
                    } else resolve({});//reject('data not found');
                });
            },
            settings: () => {
                return this.xmls()
                    ._getCachedXmlCall('/settings.xml')
                    .then(data => {
                        if(data) {
                            data = data.settings;
                        }

                        return data;
                    });
            },
            importantInformation: () => {
                return this.xmls()
                    ._getCachedXmlCall('/important_information.xml')
                    .then(data => {
                        if(data) {
                            data = data.data;
                        }

                        return data;
                    });
            },
            howExtraWorks: () => {
                return this.xmls()
                    ._getCachedXmlCall('/how_extra_works.xml')
                    .then(data => {
                        if(data) {
                            data = data.extra;
                        }

                        return data;
                    });
            },
            howExtraWorks: () => {
                return this.xmls()
                    ._getCachedXmlCall('/how_extra_works.xml')
                    .then(data => {
                        if(data) {
                            data = data.extra;
                        }

                        return data;
                    });
            },
            infoCard: () => {
                return this.xmls()
                    ._getCachedXmlCall('/info_card.xml')
                    .then(data => {
                        console.log(data)
                        if(data) {
                            data = data.card;
                        }

                        return data;
                    });
            },            
            privacy: () => {
                return this.xmls()
                    ._getCachedXmlCall('/policies.xml')
                    .then(data => {
                        if(data) {
                            data = data.policies;
                            
                            if(data.policy) {
                                data = data.policy;

                                if(data.description) {
                                    data = data.description;
                                }
                            }
                        }

                        return data;
                    });
            },
            terms: () => {
                return this.xmls()
                    ._getCachedXmlCall('/terms.xml')
                    .then(data => {
                        if(data) {
                            data = data.terms;
                            
                            if(data.term) {
                                data = data.term;

                                if(data.description) {
                                    data = data.description;
                                }
                            }
                        }

                        return data;
                    });
            },
            faqs: () => {
                return this.xmls()
                    ._getCachedXmlCall('/faqs.xml')
                    .then(data => {
                        if(data) {
                            data = data.faqs;

                            if(data.items && data.items.item) {
                                data.items = data.items.item;
                            }
                        }

                        return data;
                    });
            },
            contact: () => {
                return this.xmls()
                    ._getCachedXmlCall('/contacts.xml')
                    .then(data => {
                        if(data) {
                            data = data.contacts;

                            if(data.departments && data.departments.department) {
                                data.departments = data.departments.department;
                            }
                        }

                        return data;
                    });
            },
            dashboard: () => {
                return this.xmls()
                    ._getCachedXmlCall('/dashboard.xml')
                    .then(data => {
                        if(data) {
                            data = data.dashboard;

                            if(data.main_links && data.main_links.main_link) {
                                data.main_links = data.main_links.main_link;
                            }
                        }

                        return data;
                    });
            },
            levels: () => {
                return this.xmls()
                    ._getCachedXmlCall('/levels.xml')
                    .then(data => {
                        if(data) {
                            data = data.levels;

                            if(data.tiered && data.tiered.tier) {
                                data.tiered = data.tiered.tier;
                                if(!Array.isArray(data.tiered)) {
                                    data.tiered = [data.tiered];
                                }
                            }

                            if(data.special && data.special.group) {
                                data.special = data.special.group;
                                if(!Array.isArray(data.special)) {
                                    data.special = [data.special];
                                }
                            }
                        }

                        return data;
                    });
            },
            offers: () => {
                return this.xmls()._getCachedXmlCall('/offers.xml').then(data => {
                    let d = data && data.offers ? data.offers : false;

                    if(d) {
                        let ruleKeys = Object.keys(d);
                        for(var i = 0; i < ruleKeys.length; i++) {
                            let key = ruleKeys[i];
                            if(d[key].offers && !Array.isArray(d[key].offers)) {
                                d[key].offers = [d[key].offers];
                            }
                        }
                    }
                    
                    return d ? d : {}
                });
            },
            playorpark: () => {
                return {
                    offers: () => {
                        return this.xmls()._getCachedXmlCall(this.playorParkOffers).then(data => {
                            if(data && data.vouchers && data.vouchers.cards) {
                                if(!Array.isArray(data.vouchers.cards)) data.vouchers.cards = [data.vouchers.cards];

                                return data.vouchers.cards;
                            }

                            return [];
                        });
                    },
                    wow: () => {
                        return this.xmls()._getCachedXmlCall(this.playorParkWowOffers).then(data => {
                            if(data && data.offers && data.offers.offer) {
                                if(!Array.isArray(data.offers.offer)) data.offers.offer = [data.offers.offer];

                                return data.offers.offer;
                            }

                            return [];
                        });
                    },
                    winners: () => {
                        return this.xmls()._getCachedXmlCall(this.playorParkPreviousWinners).then(data => {
                            if(data && data.news && data.news.item) {
                                if(!Array.isArray(data.news.item)) data.news.item = [data.news.item];

                                return data.news.item;
                            }

                            return [];
                        });
                    }
                }
            }
        }
    }

    fuelCardCheck = (fuelCard) => {
        return this.fuelCardAPI.checkFuelCard(fuelCard).then(async response => {            
            return response;
        });
    }

    fuelCardAssign = (fuelCard, username, password) => {
        return this.fuelCardAPI.assignFuelCard(fuelCard, username, password).then(async response => {            
            return response;
        });
    }
}

const CommsLayer = new CommsLayer__c();

export default CommsLayer;
