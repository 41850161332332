/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import APIOKResponse from '../model/APIOKResponse';
import APIResponse from '../model/APIResponse';
import MemberSociety from '../model/MemberSociety';
import Society from '../model/Society';
import SocietyInfo from '../model/SocietyInfo';
import SocietyResponse from '../model/SocietyResponse';
import SocietySaved from '../model/SocietySaved';

/**
* Societies service.
* @module api/SocietiesApi
* @version v1
*/
export default class SocietiesApi {

    /**
    * Constructs a new SocietiesApi. 
    * @alias module:api/SocietiesApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the societiesV1AddMemberSociety operation.
     * @callback module:api/SocietiesApi~societiesV1AddMemberSocietyCallback
     * @param {String} error Error message, if any.
     * @param {module:model/APIOKResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Add Member Society
     * Add a society for a member.   
     * @param {String} memberUId Member Unique Identifier
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:model/Society} society Society Object    Provide the store Id  Provide the society Id  Provide the Offer Id
     * @param {module:api/SocietiesApi~societiesV1AddMemberSocietyCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/APIOKResponse}
     */
    societiesV1AddMemberSociety(memberUId, clientId, authorization, society, callback) {
      let postBody = society;
      // verify the required parameter 'memberUId' is set
      if (memberUId === undefined || memberUId === null) {
        throw new Error("Missing the required parameter 'memberUId' when calling societiesV1AddMemberSociety");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling societiesV1AddMemberSociety");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling societiesV1AddMemberSociety");
      }
      // verify the required parameter 'society' is set
      if (society === undefined || society === null) {
        throw new Error("Missing the required parameter 'society' when calling societiesV1AddMemberSociety");
      }

      let pathParams = {
        'memberUId': memberUId
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json', 'text/json', 'application/xml', 'text/xml', 'application/x-www-form-urlencoded'];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = APIOKResponse;
      return this.apiClient.callApi(
        '/v1/societies/Members/{memberUId}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the societiesV1AddSociety operation.
     * @callback module:api/SocietiesApi~societiesV1AddSocietyCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SocietySaved} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Add Society
     * Add a society              <br /><span class=\"green\">Lookup Tables for the following are listed here:</span><br /><a href=\"../../v1/Helpers/Lookups/County/\">County</a><br /><a href=\"../../v1/Helpers/Lookups/SocietyType/\">SocietyType</a>   
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:model/SocietyInfo} societyInfo See SocietyInfo Object and Lookup Tables above
     * @param {module:api/SocietiesApi~societiesV1AddSocietyCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SocietySaved}
     */
    societiesV1AddSociety(clientId, authorization, societyInfo, callback) {
      let postBody = societyInfo;
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling societiesV1AddSociety");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling societiesV1AddSociety");
      }
      // verify the required parameter 'societyInfo' is set
      if (societyInfo === undefined || societyInfo === null) {
        throw new Error("Missing the required parameter 'societyInfo' when calling societiesV1AddSociety");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json', 'text/json', 'application/xml', 'text/xml', 'application/x-www-form-urlencoded'];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = SocietySaved;
      return this.apiClient.callApi(
        '/v1/societies', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the societiesV1GetMemberSocieties operation.
     * @callback module:api/SocietiesApi~societiesV1GetMemberSocietiesCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/MemberSociety>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * List Member Societies
     * List societies for a member   
     * @param {String} memberUId Member Unique Identifier
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:api/SocietiesApi~societiesV1GetMemberSocietiesCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/MemberSociety>}
     */
    societiesV1GetMemberSocieties(memberUId, clientId, authorization, callback) {
      let postBody = null;
      // verify the required parameter 'memberUId' is set
      if (memberUId === undefined || memberUId === null) {
        throw new Error("Missing the required parameter 'memberUId' when calling societiesV1GetMemberSocieties");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling societiesV1GetMemberSocieties");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling societiesV1GetMemberSocieties");
      }

      let pathParams = {
        'memberUId': memberUId
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = [MemberSociety];
      return this.apiClient.callApi(
        '/v1/societies/Members/{memberUId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the societiesV1GetSociety operation.
     * @callback module:api/SocietiesApi~societiesV1GetSocietyCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SocietyResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get Society
     * Get a specific society   
     * @param {Number} societyId societyId
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:api/SocietiesApi~societiesV1GetSocietyCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SocietyResponse}
     */
    societiesV1GetSociety(societyId, clientId, authorization, callback) {
      let postBody = null;
      // verify the required parameter 'societyId' is set
      if (societyId === undefined || societyId === null) {
        throw new Error("Missing the required parameter 'societyId' when calling societiesV1GetSociety");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling societiesV1GetSociety");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling societiesV1GetSociety");
      }

      let pathParams = {
        'societyId': societyId
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = SocietyResponse;
      return this.apiClient.callApi(
        '/v1/societies/{societyId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the societiesV1GetSocietyList operation.
     * @callback module:api/SocietiesApi~societiesV1GetSocietyListCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/SocietyResponse>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * List Societies
     * List all enabled Societies or list based on provided filters    <br /><span class=\"blue\">Lookup Tables for the following are listed here:</span><br /><a href=\"../../v1/Helpers/Lookups/SocietyType/\">SocietyType</a>,  <a href=\"../../v1/Helpers/Lookups/County/\">County</a>,   <a href=\"../../v1/Helpers/Lookups/Country/\">Country</a>   
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {Object} opts Optional parameters
     * @param {Number} [societyTypeId] societyTypeId (optional)
     * @param {Number} [countryId] countryId (optional)
     * @param {Number} [countyId] countyId (optional)
     * @param {String} [societyName] societyName (optional)
     * @param {module:api/SocietiesApi~societiesV1GetSocietyListCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/SocietyResponse>}
     */
    societiesV1GetSocietyList(clientId, authorization, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling societiesV1GetSocietyList");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling societiesV1GetSocietyList");
      }

      let pathParams = {
      };
      let queryParams = {
        'societyTypeId': opts['societyTypeId'],
        'countryId': opts['countryId'],
        'countyId': opts['countyId'],
        'societyName': opts['societyName']
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = [SocietyResponse];
      return this.apiClient.callApi(
        '/v1/societies', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
