/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import APIOKResponse from '../model/APIOKResponse';
import APIResponse from '../model/APIResponse';
import SocialProfile from '../model/SocialProfile';
import SocialProfileIdentify from '../model/SocialProfileIdentify';
import SocialProfileIdentity from '../model/SocialProfileIdentity';
import SocialProfileUpdate from '../model/SocialProfileUpdate';

/**
* SocialProfiles service.
* @module api/SocialProfilesApi
* @version v1
*/
export default class SocialProfilesApi {

    /**
    * Constructs a new SocialProfilesApi. 
    * @alias module:api/SocialProfilesApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the socialProfilesV1AddMemberSocialProfile operation.
     * @callback module:api/SocialProfilesApi~socialProfilesV1AddMemberSocialProfileCallback
     * @param {String} error Error message, if any.
     * @param {module:model/APIOKResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Add Social Access Information
     * Adds a member’s social media account information     <br /><span class=\"green\">Lookup Tables for the following are listed here:</span><br /><a href=\"../../v1/Helpers/Lookups/SocialProfileType/\">SocialProfileType</a>   
     * @param {String} memberUId Member Unique Identifier
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:model/SocialProfile} socialProfile 
     * @param {module:api/SocialProfilesApi~socialProfilesV1AddMemberSocialProfileCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/APIOKResponse}
     */
    socialProfilesV1AddMemberSocialProfile(memberUId, clientId, authorization, socialProfile, callback) {
      let postBody = socialProfile;
      // verify the required parameter 'memberUId' is set
      if (memberUId === undefined || memberUId === null) {
        throw new Error("Missing the required parameter 'memberUId' when calling socialProfilesV1AddMemberSocialProfile");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling socialProfilesV1AddMemberSocialProfile");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling socialProfilesV1AddMemberSocialProfile");
      }
      // verify the required parameter 'socialProfile' is set
      if (socialProfile === undefined || socialProfile === null) {
        throw new Error("Missing the required parameter 'socialProfile' when calling socialProfilesV1AddMemberSocialProfile");
      }

      let pathParams = {
        'memberUId': memberUId
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json', 'text/json', 'application/xml', 'text/xml', 'application/x-www-form-urlencoded'];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = APIOKResponse;
      return this.apiClient.callApi(
        '/v1/socialprofiles/Members/{memberUId}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the socialProfilesV1IdentifyMemberSocialProfile operation.
     * @callback module:api/SocialProfilesApi~socialProfilesV1IdentifyMemberSocialProfileCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SocialProfileIdentity} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get Social Access Identity
     * Identifies a member based on email address and a social media account identification e.g. Facebook, Google etc..     <br /><span class=\"green\">Lookup Tables for the following are listed here:</span><br /><a href=\"../../v1/Helpers/Lookups/SocialProfileType/\">SocialProfileType</a>   
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:model/SocialProfileIdentify} identify 
     * @param {module:api/SocialProfilesApi~socialProfilesV1IdentifyMemberSocialProfileCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SocialProfileIdentity}
     */
    socialProfilesV1IdentifyMemberSocialProfile(clientId, authorization, identify, callback) {
      let postBody = identify;
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling socialProfilesV1IdentifyMemberSocialProfile");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling socialProfilesV1IdentifyMemberSocialProfile");
      }
      // verify the required parameter 'identify' is set
      if (identify === undefined || identify === null) {
        throw new Error("Missing the required parameter 'identify' when calling socialProfilesV1IdentifyMemberSocialProfile");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json', 'text/json', 'application/xml', 'text/xml', 'application/x-www-form-urlencoded'];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = SocialProfileIdentity;
      return this.apiClient.callApi(
        '/v1/socialprofiles/Members/Identify', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the socialProfilesV1UpdateMemberSocialProfile operation.
     * @callback module:api/SocialProfilesApi~socialProfilesV1UpdateMemberSocialProfileCallback
     * @param {String} error Error message, if any.
     * @param {module:model/APIOKResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Update Social Access Information
     * Update some member Social Access information (One or more properties)      <br /><span class=\"green\">Lookup Tables for the following are listed here:</span><br /><a href=\"../../v1/Helpers/Lookups/SocialProfileType/\">SocialProfileType</a>   
     * @param {String} memberUId Member Unique Identifier
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:model/SocialProfileUpdate} socialProfile 
     * @param {module:api/SocialProfilesApi~socialProfilesV1UpdateMemberSocialProfileCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/APIOKResponse}
     */
    socialProfilesV1UpdateMemberSocialProfile(memberUId, clientId, authorization, socialProfile, callback) {
      let postBody = socialProfile;
      // verify the required parameter 'memberUId' is set
      if (memberUId === undefined || memberUId === null) {
        throw new Error("Missing the required parameter 'memberUId' when calling socialProfilesV1UpdateMemberSocialProfile");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling socialProfilesV1UpdateMemberSocialProfile");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling socialProfilesV1UpdateMemberSocialProfile");
      }
      // verify the required parameter 'socialProfile' is set
      if (socialProfile === undefined || socialProfile === null) {
        throw new Error("Missing the required parameter 'socialProfile' when calling socialProfilesV1UpdateMemberSocialProfile");
      }

      let pathParams = {
        'memberUId': memberUId
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json', 'text/json', 'application/xml', 'text/xml', 'application/x-www-form-urlencoded'];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = APIOKResponse;
      return this.apiClient.callApi(
        '/v1/socialprofiles/Members/{memberUId}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
