import CKNavigator from "../lib/CKNavigator";
import Styles, { CKColors, CKPadding, CKSizes } from "../lib/Styles";
import CKInput from "../lib/components/CKInput";
import CKMenuItem from "../lib/components/CKMenuItem";
import CKMenuPanel from "../lib/components/CKMenuPanel";
import View from "../lib/components/View";
import LoggedInBase from "./LoggedInBase";
import Genders from '../lib/Genders';
import Counties from '../lib/Counties';
import MobilePhonePrefixes from '../lib/MobilePhonePrefixes';
import Countries from '../lib/Countries';
import CommsLayer from "../lib/api/CommsLayer";
import Alert from "../lib/components/Alert";
import ErrorCodes from "../lib/api/ErrorCodes";
import CKCache from "../lib/CKCache";
import CKSpacer from "../lib/components/CKSpacer";
import CKButton from "../lib/components/CKButton";

export default class MoreProfile extends LoggedInBase {
    screenName = 'more'
    title = 'Profile'
    subLevelPage = true

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            fields: {
                firstName: '',
                surname: '',
                dob: '',
                gender: '',
                mobileNumCountryId: 0,
                mobileNumber: '',
                email: '',
                address_line_1: '',
                address_line_2: '',
                town: '',
                postcode: '',
                county: 0,
                country: 0
            },
            originalFields: {},
            editMode: false,
            focusField: false,
            updating: false,
            screenLoading: true
        }
    }

    rightNavBar () {
        return <View>
            <CKButton onPress={() => {
                this.navigateTo(CKNavigator.moreProfileEdit);
            }} className='ck-button-right-save' style={{
                color: CKColors.red
            }} link>Edit</CKButton>
        </View>
    }

    onBack() {
        this.navigateTo(CKNavigator.moreMyAccount);
    }

    async loadData() {
        let lastSavedProfile = CommsLayer.members().getLastSavedProfile();

        if(lastSavedProfile) {
            CommsLayer.members().setLastSavedProfile(false);

            return this.setState({
                fields: lastSavedProfile
            }, this.hideScreenLoading);
        }

        await CommsLayer.members().membersV1GetMemberInfo().then((data) => {
            this.setState({
                fields: {
                    firstName: data.FirstName ? data.FirstName : '',
                    surname: data.LastName ? data.LastName : '',
                    dob: data.DateOfBirth ? data.DateOfBirth : '',
                    gender: data.Gender ? this.findGender(data.Gender) : 0,
                    mobileNumber: data.MobileNum ? data.MobileNum : '',
                    mobileNumCountryId: data.MobileNumCountryId ? this.findMobileCountry(data.MobileNumCountryId) : 0,
                    email: data.Email ? data.Email : '',
                    address_line_1: data.AddressLine1 ? data.AddressLine1 : '',
                    address_line_2: data.AddressLine2 ? data.AddressLine2 : '',
                    town: data.Town ? data.Town : '',
                    postcode: data.PostCode ? data.PostCode : '',
                    county: data.County ? this.findCounty(data.County) : '',
                    country: data.Country ? this.findCountry(data.Country) : ''
                }
            }, () => {
                this.hideScreenLoading();
            })
        }).catch(() => {
            Alert.alert("Error", ErrorCodes.generalAPIError());
            this.onBack();
        })

        this.setState({
            isRefreshing: false
        })
    }

    componentDidMount() {
        super.componentDidMount();

        this.showScreenLoading();
    }

    findCounty = (county) => {
        return Counties.find(c => {
            return c.countyId ===county || c.name ===county;
        })
    }

    findMobileCountry = (country) => {
        return MobilePhonePrefixes.find(c => {
            return c.id ===country || c.name ===country;
        })
    }

    findCountry = (country) => {
        return Countries.find(c => {
            return c.id ===country || c.name ===country;
        })
    }

    findGender = (gender) => {
        return Genders.find(c => {
            return c.id ===gender || c.name ===gender;
        })
    }

    isFieldsModified = () => {
        var isModified = false;
        for(var f in this.state.fields) {
            isModified |= (this.state.originalFields[f] !==this.state.fields[f]);
        }

        return isModified;
    }

    addressFieldsToString = () => {
        let address = '';
        let fields = [
            this.state.fields.address_line_1,
            this.state.fields.address_line_2,
            this.state.fields.town,
            this.state.fields.county ? this.state.fields.county.name : '',
            this.state.fields.postcode,
            this.state.fields.country ? this.state.fields.country.name : ''
        ];

        fields.forEach(f => {
            if(address && f) address += '\r\n';
            if(f) address += f
        })

        return address;
    }

    render() {
        return super.render(
            <View style={{
                display: 'flex',
                justifyContent: 'center'
            }}>
                <View className="profile-container">
                    <CKInput style={{
                        marginBottom: CKPadding.default
                    }} readonly label="First Name" value={this.state.fields.firstName} on_empty={
                        <CKButton reverseUnderline={true} onPress={() => {
                            this.navigateTo(CKNavigator.moreProfileEdit);
                        }} style={{
                            fontSize: CKSizes.font.px16,
                            lineHeight: CKSizes.font.px16 * 1.5,
                            ...(Styles.gothamBook),
                            color: CKColors.orange
                        }} link>Complete profile</CKButton>
                    } />

                    <CKInput style={{
                        marginBottom: CKPadding.default
                    }} readonly label="Surname" value={this.state.fields.surname} on_empty={
                        <CKButton reverseUnderline={true} onPress={() => {
                            this.navigateTo(CKNavigator.moreProfileEdit);
                        }} style={{
                            fontSize: CKSizes.font.px16,
                            lineHeight: CKSizes.font.px16 * 1.5,
                            ...(Styles.gothamBook),
                            color: CKColors.orange
                        }} link color={CKColors.orange}>Complete profile</CKButton>
                    } />

                    <CKInput style={{
                        marginBottom: CKPadding.default
                    }} date readonly label="Date of Birth" value={this.state.fields.dob} on_empty={
                        <CKButton reverseUnderline={true} onPress={() => {
                            this.navigateTo(CKNavigator.moreProfileEdit);
                        }} style={{
                            fontSize: CKSizes.font.px16,
                            lineHeight: CKSizes.font.px16 * 1.5,
                            ...(Styles.gothamBook),
                            color: CKColors.orange
                        }} link color={CKColors.orange}>Complete profile</CKButton>
                    } />

                    <CKInput style={{
                        marginBottom: CKPadding.default
                    }} readonly label="Gender" value={this.state.fields.gender ? this.state.fields.gender.name : ''} on_empty={
                        <CKButton reverseUnderline={true} onPress={() => {
                            this.navigateTo(CKNavigator.moreProfileEdit);
                        }} style={{
                            fontSize: CKSizes.font.px16,
                            lineHeight: CKSizes.font.px16 * 1.5,
                            ...(Styles.gothamBook),
                            color: CKColors.orange
                        }} link color={CKColors.orange}>Complete profile</CKButton>
                    } />

                    <CKInput style={{
                        marginBottom: CKPadding.default
                    }} readonly label="Mobile Number" value={
                        (this.state.fields.mobileNumCountryId ? this.state.fields.mobileNumCountryId.name : '') + 
                        (this.state.fields.mobileNumCountryId && this.state.fields.mobileNumber ? ' ' : '') + 
                        (this.state.fields.mobileNumber ? this.state.fields.mobileNumber : '')
                    } on_empty={
                        <CKButton reverseUnderline={true} onPress={() => {
                            this.navigateTo(CKNavigator.moreProfileEdit);
                        }} style={{
                            fontSize: CKSizes.font.px16,
                            lineHeight: CKSizes.font.px16 * 1.5,
                            ...(Styles.gothamBook),
                            color: CKColors.orange
                        }} link color={CKColors.orange}>Complete profile</CKButton>
                    } />


                    <CKInput style={{
                        marginBottom: CKPadding.default
                    }} date readonly label="Email" value={this.state.fields.email} on_empty={
                        <CKButton reverseUnderline={true} onPress={() => {
                            this.navigateTo(CKNavigator.moreProfileEdit);
                        }} style={{
                            fontSize: CKSizes.font.px16,
                            lineHeight: CKSizes.font.px16 * 1.5,
                            ...(Styles.gothamBook),
                            color: CKColors.orange
                        }} link color={CKColors.orange}>Complete profile</CKButton>
                    } />

                    <CKInput field_style={{
                            whiteSpace: 'pre-wrap',
                            lineHeight: "20px"
                        }} style={{
                        marginBottom: CKPadding.default
                    }} date readonly label="Address" value={this.addressFieldsToString()} on_empty={
                        <CKButton reverseUnderline={true} onPress={() => {
                            this.navigateTo(CKNavigator.moreProfileEdit);
                        }} style={{
                            fontSize: CKSizes.font.px16,
                            lineHeight: CKSizes.font.px16 * 1.5,
                            ...(Styles.gothamBook),
                            color: CKColors.orange
                        }} link color={CKColors.orange}>Complete profile</CKButton>
                    } />

                    <CKMenuPanel style={{
                        marginTop: CKPadding.default * 2
                    }}>
                        <CKMenuItem className="hide-on-desktop special-button-treatment" full_border_off onPress={() => {
                            this.navigateTo(CKNavigator.moreProfileEdit);
                        }} title='Edit Profile' />
                        <CKMenuItem full_border_off medium onPress={() => {
                            this.navigateTo(CKNavigator.changePassword);
                        }} title='Change password' />
                    </CKMenuPanel>

                    <CKMenuPanel style={{
                        marginTop: CKPadding.default
                    }}>
                        <CKMenuItem full_border_off skipArrow onPress={() => {
                            Alert.alert('Log out', 'Are you sure you want to log out of your account?', [
                                {
                                    text: 'Cancel',
                                    style: 'primary'
                                },
                                {
                                    text: 'Continue',
                                    onPress: async (dismiss) => {
                                        try {
                                            //await Keychain.resetGenericPassword();
                                            await CommsLayer.logout();
                                            await CKCache.clearAll();
                                        } catch(e) {
                                            // TODO: unable to delete from keychain, still proceed
                                        }

                                        this.navigateTo('/login');

                                        Alert.dismiss();
                                    },
                                    style: 'secondary'
                                }
                            ]);
                        }} title='Logout' />
                        <CKMenuItem full_border_off border={1} onPress={() => {
                            this.navigateTo(CKNavigator.moreProfileDelete);
                        }} title='Delete account' />
                    </CKMenuPanel>

                    <CKSpacer />
                    <CKSpacer />
                </View>
            </View>
        )
    }
}