import { Component } from "react";

export default class PageNotFound extends Component {
    render() {
        return (
            <div>
                  <p>404 Page not found</p>
            </div>
        );
    }
}