/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import OrderDetailOffer from './OrderDetailOffer';

/**
 * The OrderDetails model module.
 * @module model/OrderDetails
 * @version v1
 */
class OrderDetails {
    /**
     * Constructs a new <code>OrderDetails</code>.
     * @alias module:model/OrderDetails
     * @param lineNumber {Number} 
     * @param productCode {String} MaxLength:20
     * @param productDesc {String} MaxLength:50
     * @param quantity {Number} 
     * @param offerDiscount {Number} 
     * @param lineValue {Number} 
     * @param points {Number} 
     */
    constructor(lineNumber, productCode, productDesc, quantity, offerDiscount, lineValue, points) { 
        
        OrderDetails.initialize(this, lineNumber, productCode, productDesc, quantity, offerDiscount, lineValue, points);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, lineNumber, productCode, productDesc, quantity, offerDiscount, lineValue, points) { 
        obj['LineNumber'] = lineNumber;
        obj['ProductCode'] = productCode;
        obj['ProductDesc'] = productDesc;
        obj['Quantity'] = quantity;
        obj['OfferDiscount'] = offerDiscount;
        obj['LineValue'] = lineValue;
        obj['Points'] = points;
    }

    /**
     * Constructs a <code>OrderDetails</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/OrderDetails} obj Optional instance to populate.
     * @return {module:model/OrderDetails} The populated <code>OrderDetails</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new OrderDetails();

            if (data.hasOwnProperty('LineNumber')) {
                obj['LineNumber'] = ApiClient.convertToType(data['LineNumber'], 'Number');
            }
            if (data.hasOwnProperty('ProductCode')) {
                obj['ProductCode'] = ApiClient.convertToType(data['ProductCode'], 'String');
            }
            if (data.hasOwnProperty('ProductDesc')) {
                obj['ProductDesc'] = ApiClient.convertToType(data['ProductDesc'], 'String');
            }
            if (data.hasOwnProperty('Department')) {
                obj['Department'] = ApiClient.convertToType(data['Department'], 'String');
            }
            if (data.hasOwnProperty('Category')) {
                obj['Category'] = ApiClient.convertToType(data['Category'], 'String');
            }
            if (data.hasOwnProperty('SubCategory')) {
                obj['SubCategory'] = ApiClient.convertToType(data['SubCategory'], 'String');
            }
            if (data.hasOwnProperty('Quantity')) {
                obj['Quantity'] = ApiClient.convertToType(data['Quantity'], 'Number');
            }
            if (data.hasOwnProperty('OfferDiscount')) {
                obj['OfferDiscount'] = ApiClient.convertToType(data['OfferDiscount'], 'Number');
            }
            if (data.hasOwnProperty('LineValue')) {
                obj['LineValue'] = ApiClient.convertToType(data['LineValue'], 'Number');
            }
            if (data.hasOwnProperty('Points')) {
                obj['Points'] = ApiClient.convertToType(data['Points'], 'Number');
            }
            if (data.hasOwnProperty('OrderDetailOffers')) {
                obj['OrderDetailOffers'] = ApiClient.convertToType(data['OrderDetailOffers'], [OrderDetailOffer]);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>OrderDetails</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>OrderDetails</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of OrderDetails.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['ProductCode'] && !(typeof data['ProductCode'] === 'string' || data['ProductCode'] instanceof String)) {
            throw new Error("Expected the field `ProductCode` to be a primitive type in the JSON string but got " + data['ProductCode']);
        }
        // ensure the json data is a string
        if (data['ProductDesc'] && !(typeof data['ProductDesc'] === 'string' || data['ProductDesc'] instanceof String)) {
            throw new Error("Expected the field `ProductDesc` to be a primitive type in the JSON string but got " + data['ProductDesc']);
        }
        // ensure the json data is a string
        if (data['Department'] && !(typeof data['Department'] === 'string' || data['Department'] instanceof String)) {
            throw new Error("Expected the field `Department` to be a primitive type in the JSON string but got " + data['Department']);
        }
        // ensure the json data is a string
        if (data['Category'] && !(typeof data['Category'] === 'string' || data['Category'] instanceof String)) {
            throw new Error("Expected the field `Category` to be a primitive type in the JSON string but got " + data['Category']);
        }
        // ensure the json data is a string
        if (data['SubCategory'] && !(typeof data['SubCategory'] === 'string' || data['SubCategory'] instanceof String)) {
            throw new Error("Expected the field `SubCategory` to be a primitive type in the JSON string but got " + data['SubCategory']);
        }
        if (data['OrderDetailOffers']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['OrderDetailOffers'])) {
                throw new Error("Expected the field `OrderDetailOffers` to be an array in the JSON data but got " + data['OrderDetailOffers']);
            }
            // validate the optional field `OrderDetailOffers` (array)
            for (const item of data['OrderDetailOffers']) {
                OrderDetailOffer.validateJSON(item);
            };
        }

        return true;
    }


}

OrderDetails.RequiredProperties = ["LineNumber", "ProductCode", "ProductDesc", "Quantity", "OfferDiscount", "LineValue", "Points"];

/**
 * @member {Number} LineNumber
 */
OrderDetails.prototype['LineNumber'] = undefined;

/**
 * MaxLength:20
 * @member {String} ProductCode
 */
OrderDetails.prototype['ProductCode'] = undefined;

/**
 * MaxLength:50
 * @member {String} ProductDesc
 */
OrderDetails.prototype['ProductDesc'] = undefined;

/**
 * MaxLength:10
 * @member {String} Department
 */
OrderDetails.prototype['Department'] = undefined;

/**
 * MaxLength:10
 * @member {String} Category
 */
OrderDetails.prototype['Category'] = undefined;

/**
 * MaxLength:10
 * @member {String} SubCategory
 */
OrderDetails.prototype['SubCategory'] = undefined;

/**
 * @member {Number} Quantity
 */
OrderDetails.prototype['Quantity'] = undefined;

/**
 * @member {Number} OfferDiscount
 */
OrderDetails.prototype['OfferDiscount'] = undefined;

/**
 * @member {Number} LineValue
 */
OrderDetails.prototype['LineValue'] = undefined;

/**
 * @member {Number} Points
 */
OrderDetails.prototype['Points'] = undefined;

/**
 * @member {Array.<module:model/OrderDetailOffer>} OrderDetailOffers
 */
OrderDetails.prototype['OrderDetailOffers'] = undefined;






export default OrderDetails;

