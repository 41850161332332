/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import APIOKResponse from '../model/APIOKResponse';
import APIResponse from '../model/APIResponse';
import PartnerStore from '../model/PartnerStore';
import TransactionalEmail from '../model/TransactionalEmail';
import VerificationEmail from '../model/VerificationEmail';

/**
* Partners service.
* @module api/PartnersApi
* @version v1
*/
export default class PartnersApi {

    /**
    * Constructs a new PartnersApi. 
    * @alias module:api/PartnersApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the partnersV1GetPartnerStore operation.
     * @callback module:api/PartnersApi~partnersV1GetPartnerStoreCallback
     * @param {String} error Error message, if any.
     * @param {module:model/PartnerStore} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get Store
     * Get store information for a specific store   
     * @param {Number} storeId ID of Store
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:api/PartnersApi~partnersV1GetPartnerStoreCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/PartnerStore}
     */
    partnersV1GetPartnerStore(storeId, clientId, authorization, callback) {
      let postBody = null;
      // verify the required parameter 'storeId' is set
      if (storeId === undefined || storeId === null) {
        throw new Error("Missing the required parameter 'storeId' when calling partnersV1GetPartnerStore");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling partnersV1GetPartnerStore");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling partnersV1GetPartnerStore");
      }

      let pathParams = {
        'storeId': storeId
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = PartnerStore;
      return this.apiClient.callApi(
        '/v1/partners/Stores/{storeId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the partnersV1GetPartnerStores operation.
     * @callback module:api/PartnersApi~partnersV1GetPartnerStoresCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/PartnerStore>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * List Stores
     * List store information for all stores    <br /><span class=\"blue\">Lookup Tables for the following are listed here:</span><br /><a href=\"../../v1/Helpers/Lookups/County/\">County</a>,   <a href=\"../../v1/Helpers/Lookups/Country/\">Country</a>   
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {Object} opts Optional parameters
     * @param {Number} [countyId] Set to 0 for ALL
     * @param {Number} [countryId] Set to 0 for ALL
     * @param {module:api/PartnersApi~partnersV1GetPartnerStoresCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/PartnerStore>}
     */
    partnersV1GetPartnerStores(clientId, authorization, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling partnersV1GetPartnerStores");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling partnersV1GetPartnerStores");
      }

      let pathParams = {
      };
      let queryParams = {
        'CountyId': opts['countyId'],
        'CountryId': opts['countryId']
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = [PartnerStore];
      return this.apiClient.callApi(
        '/v1/partners/Stores', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the partnersV1SaveTransactionalEmail operation.
     * @callback module:api/PartnersApi~partnersV1SaveTransactionalEmailCallback
     * @param {String} error Error message, if any.
     * @param {module:model/APIOKResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Send Email
     * Save and Send Transactional Email    <br /><span class=\"green\">Lookup Tables for the following are listed here:</span><br /><a href=\"../../v1/Helpers/Lookups/TransType/\">TransType</a>   
     * @param {String} memberUId Member Unique Identifier
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:model/TransactionalEmail} transactionalEmail TransactionalEmail Object    TransTypeId:    1 - Registration    2 - Rewards    3 - ForgottenPassword    4 - ContactUs
     * @param {module:api/PartnersApi~partnersV1SaveTransactionalEmailCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/APIOKResponse}
     */
    partnersV1SaveTransactionalEmail(memberUId, clientId, authorization, transactionalEmail, callback) {
      let postBody = transactionalEmail;
      // verify the required parameter 'memberUId' is set
      if (memberUId === undefined || memberUId === null) {
        throw new Error("Missing the required parameter 'memberUId' when calling partnersV1SaveTransactionalEmail");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling partnersV1SaveTransactionalEmail");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling partnersV1SaveTransactionalEmail");
      }
      // verify the required parameter 'transactionalEmail' is set
      if (transactionalEmail === undefined || transactionalEmail === null) {
        throw new Error("Missing the required parameter 'transactionalEmail' when calling partnersV1SaveTransactionalEmail");
      }

      let pathParams = {
        'memberUId': memberUId
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json', 'text/json', 'application/xml', 'text/xml', 'application/x-www-form-urlencoded'];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = APIOKResponse;
      return this.apiClient.callApi(
        '/v1/partners/Members/{memberUId}/SendEmail', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the partnersV1VerificationCode operation.
     * @callback module:api/PartnersApi~partnersV1VerificationCodeCallback
     * @param {String} error Error message, if any.
     * @param {module:model/APIOKResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Add Verification Code
     * Sends a verification code by email to the specified email address   
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:model/VerificationEmail} verificationEmail Specify email address
     * @param {module:api/PartnersApi~partnersV1VerificationCodeCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/APIOKResponse}
     */
    partnersV1VerificationCode(clientId, authorization, verificationEmail, callback) {
      let postBody = verificationEmail;
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling partnersV1VerificationCode");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling partnersV1VerificationCode");
      }
      // verify the required parameter 'verificationEmail' is set
      if (verificationEmail === undefined || verificationEmail === null) {
        throw new Error("Missing the required parameter 'verificationEmail' when calling partnersV1VerificationCode");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json', 'text/json', 'application/xml', 'text/xml', 'application/x-www-form-urlencoded'];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = APIOKResponse;
      return this.apiClient.callApi(
        '/v1/partners/VerificationCode', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
