import moment from "moment";
import CKNavigator from "../lib/CKNavigator";
import Styles, { CKColors, CKPadding, CKSizes } from "../lib/Styles";
import CKButton from "../lib/components/CKButton";
import CKMenuPanel from "../lib/components/CKMenuPanel";
import View from "../lib/components/View";
import LoggedInBase from "./LoggedInBase";
import CommsLayer from "../lib/api/CommsLayer";
import CKText from "../lib/components/CKText";
import CKIcon, { CKIconType } from "../lib/components/CKIcon";
import CKSpacer from "../lib/components/CKSpacer";

export default class MoreTransactions extends LoggedInBase {
    screenName = 'more'
    title = 'Transactions'
    subLevelPage = true

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            tabSelected: 0,
            tabDays: [7, 14, 30],
            data: [],
            apiData: [],
            screenLoading: true
        }
    }

    onBack() {
        this.navigateTo(CKNavigator.moreMyAccount);
    }

    loadData() {
        CommsLayer.orders().ordersV1GetOrderSummary().then(response => {
            this.setState({
                apiData: response
            }, () => {
                this.setState({
                    data: this.refreshData(this.state.tabDays[this.state.tabSelected])
                }, this.hideScreenLoading);
            });
        }).catch(() => {
            this.hideScreenLoading();
        });
    }

    componentDidMount() {
        super.componentDidMount();

        this.showScreenLoading();
    }

    refreshData = (daysToPull = 7) => {
        let data = [];

        this.state.apiData.forEach(d => {
            if(!d.TransDate) return;
            let transactionDate = moment(d.TransDate);
            if(!transactionDate) return;
            if(!d.NumBasketItems) return;
            
            if(moment().add('-' + daysToPull, 'days') < transactionDate) {
                data.push(d);
            }
        });

        return data;
    }

    formatCurrency = (amount = 0) => {
        return "€" + amount.toFixed(2);
    }

    componentDidMount() {
        super.componentDidMount();
    }

    render() {
        return super.render(
            <View className="main-container">
                <View style={{
                    display: "flex",
                    flexDirection: 'row'
                }}>
                    {this.state.tabDays.map((d, index) => {
                        return <CKButton custom key={'day_' + d} onPress={() => {
                            this.setState({
                                tabSelected: index,
                                data: this.refreshData(this.state.tabDays[index])
                            })
                        }} default_style={{
                            flex: 1,
                            padding: 0
                        }}>
                            <View className="transaction-tab-button-container" style={{
                                backgroundColor: index ===this.state.tabSelected ? CKColors.red : CKColors.borderColor,
                                marginRight: index !==(this.state.tabDays.length - 1) ? 1 : 0,
                                borderTopLeftRadius: index ===0 ? CKSizes.radius : 0,
                                borderBottomLeftRadius: index ===0 ? CKSizes.radius : 0,
                                borderTopRightRadius: index ===this.state.tabDays.length - 1 ? CKSizes.radius : 0,
                                borderBottomRightRadius: index ===this.state.tabDays.length - 1 ? CKSizes.radius : 0
                            }}>
                                <CKText className='text' style={{
                                    color: index ===this.state.tabSelected ? CKColors.white : CKColors.offBlack,
                                    ...(index ===this.state.tabSelected ? {...Styles.gothamBlack} : {...Styles.gothamBook, marginTop: 0})
                                }}>{'Last ' + d + ' days'}</CKText>
                            </View>
                        </CKButton>
                    })}
                </View>

                {this.state.data.length ===0 ? <CKMenuPanel round style={{
                    padding: CKPadding.default,
                    marginTop: CKPadding.default
                }}>
                    <CKText className="transactions-empty-text">You have no transactions</CKText>
                </CKMenuPanel> : <>
                    {this.state.data.map(d => {
                        return <CKMenuPanel round key={d.OrderId} style={{
                            marginTop: CKPadding.default,
                            paddingTop: CKPadding.default
                        }}>
                            <CKText className="transaction-date">{moment(d.TransDate).format('DD MMM YYYY')}</CKText>
                            <View className="transaction-details">
                                <CKText className="left">{d.TransactionRef}</CKText>
                                <CKText className="right">{this.formatCurrency(d.TotalValue)}</CKText>
                            </View>
                            <View className="transaction-location">
                                <CKIcon icon={CKIconType.location_gray} width={24} height={24} />
                                <CKText className="text">
                                    {d.StoreName}
                                </CKText>
                            </View>
                        </CKMenuPanel>
                    })}
                </>}

                <CKSpacer />
            </View>
        )
    }
}