import CKNavigator from "../lib/CKNavigator";
import CommsLayer from "../lib/api/CommsLayer";
import ErrorCodes from "../lib/api/ErrorCodes";
import Alert from "../lib/components/Alert";
import CKButton from "../lib/components/CKButton";
import CKSpacer from "../lib/components/CKSpacer";
import CKText from "../lib/components/CKText";
import View from "../lib/components/View";
import LoggedInBase from "./LoggedInBase";

import {ReactComponent as IconPlay} from '../images/icon_play.svg';

export default class MoreHowItWorks extends LoggedInBase {
    screenName = 'more'
    title = 'How Extra Works'
    subLevelPage = true

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,

            playingVideo: false,
            screenLoading: true
        }
    }

    onBack() {
        this.navigateTo(CKNavigator.helpAndSupport);
    }

    loadData() {
        CommsLayer.xmls().howExtraWorks().then(result => {
            this.setState({
                ...result
            });
        }).catch(() => {
            Alert.alert("Error", ErrorCodes.generalAPIError());
            this.onBack();
        }).finally(() => {
            this.hideScreenLoading();
        });
    }

    componentDidMount() {
        super.componentDidMount();

        this.showScreenLoading();
    }

    render() {
        return super.render(
            <View>
                {this.state.image ?
                    <View style={{
                        position: 'relative'
                    }}>
                        <img style={{
                            width: '100%'
                        }} src={this.state.image} />

                        {this.state.video ? 
                            (
                                this.state.playingVideo ? <View style={{
                                    position: 'absolute',
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    top: 0,
                                    backgroundColor: '#000000'
                                }}>
                                    <video autoPlay onEnded={() => {
                                        this.setState({
                                            playingVideo: false
                                        })
                                    }} controls style={{
                                        width: '100%',
                                        height: '100%'
                                    }}>
                                        <source src={this.state.video} />
                                    </video>
                                </View>
                                :
                                <CKButton custom className="play-button" default_style={{
                                    padding: 0
                                }} onPress={() => {
                                    this.setState({
                                        playingVideo: true
                                    })
                                }}>
                                    <IconPlay />
                                </CKButton>
                            )
                            :
                            null
                        }
                    </View>
                : null }

                <View className="main-container-how-extra">
                    <CKText className="single-page-subject">{this.state.headline}</CKText>

                    <CKText className="single-page-body">
                        {
                            this.state.description ? 
                                this.state.description.replace(/\&ldquo;/g, '“')
                                    .replace(/\&rdquo;/g, '”')
                                    .replace(/\&nbsp;/g, ' ')
                                    .replace(/\t/g, '') 
                            :
                            ''
                        }
                    </CKText>
                </View>

                <CKSpacer />
            </View>
        )
    }
}