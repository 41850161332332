/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import OrderDetail from './OrderDetail';

/**
 * The OrderDetailPagination model module.
 * @module model/OrderDetailPagination
 * @version v1
 */
class OrderDetailPagination {
    /**
     * Constructs a new <code>OrderDetailPagination</code>.
     * @alias module:model/OrderDetailPagination
     * @param offset {Number} int64
     * @param hasMore {Boolean} 
     * @param data {Array.<module:model/OrderDetail>} 
     */
    constructor(offset, hasMore, data) { 
        
        OrderDetailPagination.initialize(this, offset, hasMore, data);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, offset, hasMore, data) { 
        obj['Offset'] = offset;
        obj['HasMore'] = hasMore;
        obj['Data'] = data;
    }

    /**
     * Constructs a <code>OrderDetailPagination</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/OrderDetailPagination} obj Optional instance to populate.
     * @return {module:model/OrderDetailPagination} The populated <code>OrderDetailPagination</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new OrderDetailPagination();

            if (data.hasOwnProperty('Offset')) {
                obj['Offset'] = ApiClient.convertToType(data['Offset'], 'Number');
            }
            if (data.hasOwnProperty('HasMore')) {
                obj['HasMore'] = ApiClient.convertToType(data['HasMore'], 'Boolean');
            }
            if (data.hasOwnProperty('Data')) {
                obj['Data'] = ApiClient.convertToType(data['Data'], [OrderDetail]);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>OrderDetailPagination</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>OrderDetailPagination</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of OrderDetailPagination.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        if (data['Data']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['Data'])) {
                throw new Error("Expected the field `Data` to be an array in the JSON data but got " + data['Data']);
            }
            // validate the optional field `Data` (array)
            for (const item of data['Data']) {
                OrderDetail.validateJSON(item);
            };
        }

        return true;
    }


}

OrderDetailPagination.RequiredProperties = ["Offset", "HasMore", "Data"];

/**
 * int64
 * @member {Number} Offset
 */
OrderDetailPagination.prototype['Offset'] = undefined;

/**
 * @member {Boolean} HasMore
 */
OrderDetailPagination.prototype['HasMore'] = undefined;

/**
 * @member {Array.<module:model/OrderDetail>} Data
 */
OrderDetailPagination.prototype['Data'] = undefined;






export default OrderDetailPagination;

