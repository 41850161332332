/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The Identity model module.
 * @module model/Identity
 * @version v1
 */
class Identity {
    /**
     * Constructs a new <code>Identity</code>.
     * @alias module:model/Identity
     * @param loyaltyCardNum {String} MaxLength:25
     * @param email {String} MaxLength:64
     */
    constructor(loyaltyCardNum, email) { 
        
        Identity.initialize(this, loyaltyCardNum, email);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, loyaltyCardNum, email) { 
        obj['LoyaltyCardNum'] = loyaltyCardNum;
        obj['Email'] = email;
    }

    /**
     * Constructs a <code>Identity</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Identity} obj Optional instance to populate.
     * @return {module:model/Identity} The populated <code>Identity</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Identity();

            if (data.hasOwnProperty('LoyaltyCardNum')) {
                obj['LoyaltyCardNum'] = ApiClient.convertToType(data['LoyaltyCardNum'], 'String');
            }
            if (data.hasOwnProperty('Email')) {
                obj['Email'] = ApiClient.convertToType(data['Email'], 'String');
            }
            if (data.hasOwnProperty('MobileNum')) {
                obj['MobileNum'] = ApiClient.convertToType(data['MobileNum'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Identity</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Identity</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of Identity.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['LoyaltyCardNum'] && !(typeof data['LoyaltyCardNum'] === 'string' || data['LoyaltyCardNum'] instanceof String)) {
            throw new Error("Expected the field `LoyaltyCardNum` to be a primitive type in the JSON string but got " + data['LoyaltyCardNum']);
        }
        // ensure the json data is a string
        if (data['Email'] && !(typeof data['Email'] === 'string' || data['Email'] instanceof String)) {
            throw new Error("Expected the field `Email` to be a primitive type in the JSON string but got " + data['Email']);
        }
        // ensure the json data is a string
        if (data['MobileNum'] && !(typeof data['MobileNum'] === 'string' || data['MobileNum'] instanceof String)) {
            throw new Error("Expected the field `MobileNum` to be a primitive type in the JSON string but got " + data['MobileNum']);
        }

        return true;
    }


}

Identity.RequiredProperties = ["LoyaltyCardNum", "Email"];

/**
 * MaxLength:25
 * @member {String} LoyaltyCardNum
 */
Identity.prototype['LoyaltyCardNum'] = undefined;

/**
 * MaxLength:64
 * @member {String} Email
 */
Identity.prototype['Email'] = undefined;

/**
 * MaxLength:20
 * @member {String} MobileNum
 */
Identity.prototype['MobileNum'] = undefined;






export default Identity;

