/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The SocietyResponse model module.
 * @module model/SocietyResponse
 * @version v1
 */
class SocietyResponse {
    /**
     * Constructs a new <code>SocietyResponse</code>.
     * @alias module:model/SocietyResponse
     * @param societyId {Number} 
     */
    constructor(societyId) { 
        
        SocietyResponse.initialize(this, societyId);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, societyId) { 
        obj['SocietyId'] = societyId;
    }

    /**
     * Constructs a <code>SocietyResponse</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/SocietyResponse} obj Optional instance to populate.
     * @return {module:model/SocietyResponse} The populated <code>SocietyResponse</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new SocietyResponse();

            if (data.hasOwnProperty('SocietyId')) {
                obj['SocietyId'] = ApiClient.convertToType(data['SocietyId'], 'Number');
            }
            if (data.hasOwnProperty('SocietyName')) {
                obj['SocietyName'] = ApiClient.convertToType(data['SocietyName'], 'String');
            }
            if (data.hasOwnProperty('Town')) {
                obj['Town'] = ApiClient.convertToType(data['Town'], 'String');
            }
            if (data.hasOwnProperty('County')) {
                obj['County'] = ApiClient.convertToType(data['County'], 'String');
            }
            if (data.hasOwnProperty('WebAddress')) {
                obj['WebAddress'] = ApiClient.convertToType(data['WebAddress'], 'String');
            }
            if (data.hasOwnProperty('SocietyType')) {
                obj['SocietyType'] = ApiClient.convertToType(data['SocietyType'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>SocietyResponse</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>SocietyResponse</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of SocietyResponse.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['SocietyName'] && !(typeof data['SocietyName'] === 'string' || data['SocietyName'] instanceof String)) {
            throw new Error("Expected the field `SocietyName` to be a primitive type in the JSON string but got " + data['SocietyName']);
        }
        // ensure the json data is a string
        if (data['Town'] && !(typeof data['Town'] === 'string' || data['Town'] instanceof String)) {
            throw new Error("Expected the field `Town` to be a primitive type in the JSON string but got " + data['Town']);
        }
        // ensure the json data is a string
        if (data['County'] && !(typeof data['County'] === 'string' || data['County'] instanceof String)) {
            throw new Error("Expected the field `County` to be a primitive type in the JSON string but got " + data['County']);
        }
        // ensure the json data is a string
        if (data['WebAddress'] && !(typeof data['WebAddress'] === 'string' || data['WebAddress'] instanceof String)) {
            throw new Error("Expected the field `WebAddress` to be a primitive type in the JSON string but got " + data['WebAddress']);
        }
        // ensure the json data is a string
        if (data['SocietyType'] && !(typeof data['SocietyType'] === 'string' || data['SocietyType'] instanceof String)) {
            throw new Error("Expected the field `SocietyType` to be a primitive type in the JSON string but got " + data['SocietyType']);
        }

        return true;
    }


}

SocietyResponse.RequiredProperties = ["SocietyId"];

/**
 * @member {Number} SocietyId
 */
SocietyResponse.prototype['SocietyId'] = undefined;

/**
 * MaxLength:100
 * @member {String} SocietyName
 */
SocietyResponse.prototype['SocietyName'] = undefined;

/**
 * MaxLength:100
 * @member {String} Town
 */
SocietyResponse.prototype['Town'] = undefined;

/**
 * MaxLength:64
 * @member {String} County
 */
SocietyResponse.prototype['County'] = undefined;

/**
 * MaxLength:100
 * @member {String} WebAddress
 */
SocietyResponse.prototype['WebAddress'] = undefined;

/**
 * MaxLength:20
 * @member {String} SocietyType
 */
SocietyResponse.prototype['SocietyType'] = undefined;






export default SocietyResponse;

