/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import CompetitionQuestion from './CompetitionQuestion';

/**
 * The CompetitionInfo model module.
 * @module model/CompetitionInfo
 * @version v1
 */
class CompetitionInfo {
    /**
     * Constructs a new <code>CompetitionInfo</code>.
     * @alias module:model/CompetitionInfo
     * @param competitionId {Number} 
     * @param winnerId {Number} 
     * @param pointsCost {Number} 
     * @param pointsEarn {String} MaxLength:10
     * @param competitionTypeId {Number} 
     * @param competitionQuestionId {Number} 
     * @param competitionName {String} MaxLength:40
     * @param competitionHeading {String} MaxLength:20
     * @param startDate {String} Format [yyyy-MM-dd HH:mm:ss] UTC
     * @param endDate {String} Format [yyyy-MM-dd HH:mm:ss] UTC
     * @param competitionContent {String} MaxLength:8000
     * @param competitionFindOutMore {String} MaxLength:8000
     * @param competitionTermsConditions {String} MaxLength:8000
     * @param claimed {String} MaxLength:5
     * @param enabled {String} MaxLength:5
     * @param thumbnailFilePath {String} MaxLength:1000
     * @param imageFilePath {String} MaxLength:1000
     * @param flashFilePath {String} MaxLength:75
     * @param appImageFilePath {String} MaxLength:1000
     * @param comments {String} MaxLength:500
     * @param competitionType {String} MaxLength:50
     * @param competitionQuestion {module:model/CompetitionQuestion} 
     */
    constructor(competitionId, winnerId, pointsCost, pointsEarn, competitionTypeId, competitionQuestionId, competitionName, competitionHeading, startDate, endDate, competitionContent, competitionFindOutMore, competitionTermsConditions, claimed, enabled, thumbnailFilePath, imageFilePath, flashFilePath, appImageFilePath, comments, competitionType, competitionQuestion) { 
        
        CompetitionInfo.initialize(this, competitionId, winnerId, pointsCost, pointsEarn, competitionTypeId, competitionQuestionId, competitionName, competitionHeading, startDate, endDate, competitionContent, competitionFindOutMore, competitionTermsConditions, claimed, enabled, thumbnailFilePath, imageFilePath, flashFilePath, appImageFilePath, comments, competitionType, competitionQuestion);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, competitionId, winnerId, pointsCost, pointsEarn, competitionTypeId, competitionQuestionId, competitionName, competitionHeading, startDate, endDate, competitionContent, competitionFindOutMore, competitionTermsConditions, claimed, enabled, thumbnailFilePath, imageFilePath, flashFilePath, appImageFilePath, comments, competitionType, competitionQuestion) { 
        obj['CompetitionId'] = competitionId;
        obj['WinnerId'] = winnerId;
        obj['PointsCost'] = pointsCost;
        obj['PointsEarn'] = pointsEarn;
        obj['CompetitionTypeId'] = competitionTypeId;
        obj['CompetitionQuestionId'] = competitionQuestionId;
        obj['CompetitionName'] = competitionName;
        obj['CompetitionHeading'] = competitionHeading;
        obj['StartDate'] = startDate;
        obj['EndDate'] = endDate;
        obj['CompetitionContent'] = competitionContent;
        obj['CompetitionFindOutMore'] = competitionFindOutMore;
        obj['CompetitionTermsConditions'] = competitionTermsConditions;
        obj['Claimed'] = claimed;
        obj['Enabled'] = enabled;
        obj['ThumbnailFilePath'] = thumbnailFilePath;
        obj['ImageFilePath'] = imageFilePath;
        obj['FlashFilePath'] = flashFilePath;
        obj['AppImageFilePath'] = appImageFilePath;
        obj['Comments'] = comments;
        obj['CompetitionType'] = competitionType;
        obj['CompetitionQuestion'] = competitionQuestion;
    }

    /**
     * Constructs a <code>CompetitionInfo</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CompetitionInfo} obj Optional instance to populate.
     * @return {module:model/CompetitionInfo} The populated <code>CompetitionInfo</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CompetitionInfo();

            if (data.hasOwnProperty('CompetitionId')) {
                obj['CompetitionId'] = ApiClient.convertToType(data['CompetitionId'], 'Number');
            }
            if (data.hasOwnProperty('WinnerId')) {
                obj['WinnerId'] = ApiClient.convertToType(data['WinnerId'], 'Number');
            }
            if (data.hasOwnProperty('PointsCost')) {
                obj['PointsCost'] = ApiClient.convertToType(data['PointsCost'], 'Number');
            }
            if (data.hasOwnProperty('PointsEarn')) {
                obj['PointsEarn'] = ApiClient.convertToType(data['PointsEarn'], 'String');
            }
            if (data.hasOwnProperty('CompetitionTypeId')) {
                obj['CompetitionTypeId'] = ApiClient.convertToType(data['CompetitionTypeId'], 'Number');
            }
            if (data.hasOwnProperty('CompetitionQuestionId')) {
                obj['CompetitionQuestionId'] = ApiClient.convertToType(data['CompetitionQuestionId'], 'Number');
            }
            if (data.hasOwnProperty('CompetitionName')) {
                obj['CompetitionName'] = ApiClient.convertToType(data['CompetitionName'], 'String');
            }
            if (data.hasOwnProperty('CompetitionHeading')) {
                obj['CompetitionHeading'] = ApiClient.convertToType(data['CompetitionHeading'], 'String');
            }
            if (data.hasOwnProperty('StartDate')) {
                obj['StartDate'] = ApiClient.convertToType(data['StartDate'], 'String');
            }
            if (data.hasOwnProperty('EndDate')) {
                obj['EndDate'] = ApiClient.convertToType(data['EndDate'], 'String');
            }
            if (data.hasOwnProperty('CompetitionContent')) {
                obj['CompetitionContent'] = ApiClient.convertToType(data['CompetitionContent'], 'String');
            }
            if (data.hasOwnProperty('CompetitionFindOutMore')) {
                obj['CompetitionFindOutMore'] = ApiClient.convertToType(data['CompetitionFindOutMore'], 'String');
            }
            if (data.hasOwnProperty('CompetitionTermsConditions')) {
                obj['CompetitionTermsConditions'] = ApiClient.convertToType(data['CompetitionTermsConditions'], 'String');
            }
            if (data.hasOwnProperty('Claimed')) {
                obj['Claimed'] = ApiClient.convertToType(data['Claimed'], 'String');
            }
            if (data.hasOwnProperty('Enabled')) {
                obj['Enabled'] = ApiClient.convertToType(data['Enabled'], 'String');
            }
            if (data.hasOwnProperty('ThumbnailFilePath')) {
                obj['ThumbnailFilePath'] = ApiClient.convertToType(data['ThumbnailFilePath'], 'String');
            }
            if (data.hasOwnProperty('ImageFilePath')) {
                obj['ImageFilePath'] = ApiClient.convertToType(data['ImageFilePath'], 'String');
            }
            if (data.hasOwnProperty('FlashFilePath')) {
                obj['FlashFilePath'] = ApiClient.convertToType(data['FlashFilePath'], 'String');
            }
            if (data.hasOwnProperty('AppImageFilePath')) {
                obj['AppImageFilePath'] = ApiClient.convertToType(data['AppImageFilePath'], 'String');
            }
            if (data.hasOwnProperty('Comments')) {
                obj['Comments'] = ApiClient.convertToType(data['Comments'], 'String');
            }
            if (data.hasOwnProperty('CompetitionType')) {
                obj['CompetitionType'] = ApiClient.convertToType(data['CompetitionType'], 'String');
            }
            if (data.hasOwnProperty('CompetitionQuestion')) {
                obj['CompetitionQuestion'] = CompetitionQuestion.constructFromObject(data['CompetitionQuestion']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>CompetitionInfo</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CompetitionInfo</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of CompetitionInfo.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['PointsEarn'] && !(typeof data['PointsEarn'] === 'string' || data['PointsEarn'] instanceof String)) {
            throw new Error("Expected the field `PointsEarn` to be a primitive type in the JSON string but got " + data['PointsEarn']);
        }
        // ensure the json data is a string
        if (data['CompetitionName'] && !(typeof data['CompetitionName'] === 'string' || data['CompetitionName'] instanceof String)) {
            throw new Error("Expected the field `CompetitionName` to be a primitive type in the JSON string but got " + data['CompetitionName']);
        }
        // ensure the json data is a string
        if (data['CompetitionHeading'] && !(typeof data['CompetitionHeading'] === 'string' || data['CompetitionHeading'] instanceof String)) {
            throw new Error("Expected the field `CompetitionHeading` to be a primitive type in the JSON string but got " + data['CompetitionHeading']);
        }
        // ensure the json data is a string
        if (data['StartDate'] && !(typeof data['StartDate'] === 'string' || data['StartDate'] instanceof String)) {
            throw new Error("Expected the field `StartDate` to be a primitive type in the JSON string but got " + data['StartDate']);
        }
        // ensure the json data is a string
        if (data['EndDate'] && !(typeof data['EndDate'] === 'string' || data['EndDate'] instanceof String)) {
            throw new Error("Expected the field `EndDate` to be a primitive type in the JSON string but got " + data['EndDate']);
        }
        // ensure the json data is a string
        if (data['CompetitionContent'] && !(typeof data['CompetitionContent'] === 'string' || data['CompetitionContent'] instanceof String)) {
            throw new Error("Expected the field `CompetitionContent` to be a primitive type in the JSON string but got " + data['CompetitionContent']);
        }
        // ensure the json data is a string
        if (data['CompetitionFindOutMore'] && !(typeof data['CompetitionFindOutMore'] === 'string' || data['CompetitionFindOutMore'] instanceof String)) {
            throw new Error("Expected the field `CompetitionFindOutMore` to be a primitive type in the JSON string but got " + data['CompetitionFindOutMore']);
        }
        // ensure the json data is a string
        if (data['CompetitionTermsConditions'] && !(typeof data['CompetitionTermsConditions'] === 'string' || data['CompetitionTermsConditions'] instanceof String)) {
            throw new Error("Expected the field `CompetitionTermsConditions` to be a primitive type in the JSON string but got " + data['CompetitionTermsConditions']);
        }
        // ensure the json data is a string
        if (data['Claimed'] && !(typeof data['Claimed'] === 'string' || data['Claimed'] instanceof String)) {
            throw new Error("Expected the field `Claimed` to be a primitive type in the JSON string but got " + data['Claimed']);
        }
        // ensure the json data is a string
        if (data['Enabled'] && !(typeof data['Enabled'] === 'string' || data['Enabled'] instanceof String)) {
            throw new Error("Expected the field `Enabled` to be a primitive type in the JSON string but got " + data['Enabled']);
        }
        // ensure the json data is a string
        if (data['ThumbnailFilePath'] && !(typeof data['ThumbnailFilePath'] === 'string' || data['ThumbnailFilePath'] instanceof String)) {
            throw new Error("Expected the field `ThumbnailFilePath` to be a primitive type in the JSON string but got " + data['ThumbnailFilePath']);
        }
        // ensure the json data is a string
        if (data['ImageFilePath'] && !(typeof data['ImageFilePath'] === 'string' || data['ImageFilePath'] instanceof String)) {
            throw new Error("Expected the field `ImageFilePath` to be a primitive type in the JSON string but got " + data['ImageFilePath']);
        }
        // ensure the json data is a string
        if (data['FlashFilePath'] && !(typeof data['FlashFilePath'] === 'string' || data['FlashFilePath'] instanceof String)) {
            throw new Error("Expected the field `FlashFilePath` to be a primitive type in the JSON string but got " + data['FlashFilePath']);
        }
        // ensure the json data is a string
        if (data['AppImageFilePath'] && !(typeof data['AppImageFilePath'] === 'string' || data['AppImageFilePath'] instanceof String)) {
            throw new Error("Expected the field `AppImageFilePath` to be a primitive type in the JSON string but got " + data['AppImageFilePath']);
        }
        // ensure the json data is a string
        if (data['Comments'] && !(typeof data['Comments'] === 'string' || data['Comments'] instanceof String)) {
            throw new Error("Expected the field `Comments` to be a primitive type in the JSON string but got " + data['Comments']);
        }
        // ensure the json data is a string
        if (data['CompetitionType'] && !(typeof data['CompetitionType'] === 'string' || data['CompetitionType'] instanceof String)) {
            throw new Error("Expected the field `CompetitionType` to be a primitive type in the JSON string but got " + data['CompetitionType']);
        }
        // validate the optional field `CompetitionQuestion`
        if (data['CompetitionQuestion']) { // data not null
          CompetitionQuestion.validateJSON(data['CompetitionQuestion']);
        }

        return true;
    }


}

CompetitionInfo.RequiredProperties = ["CompetitionId", "WinnerId", "PointsCost", "PointsEarn", "CompetitionTypeId", "CompetitionQuestionId", "CompetitionName", "CompetitionHeading", "StartDate", "EndDate", "CompetitionContent", "CompetitionFindOutMore", "CompetitionTermsConditions", "Claimed", "Enabled", "ThumbnailFilePath", "ImageFilePath", "FlashFilePath", "AppImageFilePath", "Comments", "CompetitionType", "CompetitionQuestion"];

/**
 * @member {Number} CompetitionId
 */
CompetitionInfo.prototype['CompetitionId'] = undefined;

/**
 * @member {Number} WinnerId
 */
CompetitionInfo.prototype['WinnerId'] = undefined;

/**
 * @member {Number} PointsCost
 */
CompetitionInfo.prototype['PointsCost'] = undefined;

/**
 * MaxLength:10
 * @member {String} PointsEarn
 */
CompetitionInfo.prototype['PointsEarn'] = undefined;

/**
 * @member {Number} CompetitionTypeId
 */
CompetitionInfo.prototype['CompetitionTypeId'] = undefined;

/**
 * @member {Number} CompetitionQuestionId
 */
CompetitionInfo.prototype['CompetitionQuestionId'] = undefined;

/**
 * MaxLength:40
 * @member {String} CompetitionName
 */
CompetitionInfo.prototype['CompetitionName'] = undefined;

/**
 * MaxLength:20
 * @member {String} CompetitionHeading
 */
CompetitionInfo.prototype['CompetitionHeading'] = undefined;

/**
 * Format [yyyy-MM-dd HH:mm:ss] UTC
 * @member {String} StartDate
 */
CompetitionInfo.prototype['StartDate'] = undefined;

/**
 * Format [yyyy-MM-dd HH:mm:ss] UTC
 * @member {String} EndDate
 */
CompetitionInfo.prototype['EndDate'] = undefined;

/**
 * MaxLength:8000
 * @member {String} CompetitionContent
 */
CompetitionInfo.prototype['CompetitionContent'] = undefined;

/**
 * MaxLength:8000
 * @member {String} CompetitionFindOutMore
 */
CompetitionInfo.prototype['CompetitionFindOutMore'] = undefined;

/**
 * MaxLength:8000
 * @member {String} CompetitionTermsConditions
 */
CompetitionInfo.prototype['CompetitionTermsConditions'] = undefined;

/**
 * MaxLength:5
 * @member {String} Claimed
 */
CompetitionInfo.prototype['Claimed'] = undefined;

/**
 * MaxLength:5
 * @member {String} Enabled
 */
CompetitionInfo.prototype['Enabled'] = undefined;

/**
 * MaxLength:1000
 * @member {String} ThumbnailFilePath
 */
CompetitionInfo.prototype['ThumbnailFilePath'] = undefined;

/**
 * MaxLength:1000
 * @member {String} ImageFilePath
 */
CompetitionInfo.prototype['ImageFilePath'] = undefined;

/**
 * MaxLength:75
 * @member {String} FlashFilePath
 */
CompetitionInfo.prototype['FlashFilePath'] = undefined;

/**
 * MaxLength:1000
 * @member {String} AppImageFilePath
 */
CompetitionInfo.prototype['AppImageFilePath'] = undefined;

/**
 * MaxLength:500
 * @member {String} Comments
 */
CompetitionInfo.prototype['Comments'] = undefined;

/**
 * MaxLength:50
 * @member {String} CompetitionType
 */
CompetitionInfo.prototype['CompetitionType'] = undefined;

/**
 * @member {module:model/CompetitionQuestion} CompetitionQuestion
 */
CompetitionInfo.prototype['CompetitionQuestion'] = undefined;






export default CompetitionInfo;

