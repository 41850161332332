/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The SurveyQuestion model module.
 * @module model/SurveyQuestion
 * @version v1
 */
class SurveyQuestion {
    /**
     * Constructs a new <code>SurveyQuestion</code>.
     * @alias module:model/SurveyQuestion
     * @param questionNo {Number} 
     * @param question {String} MaxLength:400
     */
    constructor(questionNo, question) { 
        
        SurveyQuestion.initialize(this, questionNo, question);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, questionNo, question) { 
        obj['QuestionNo'] = questionNo;
        obj['Question'] = question;
    }

    /**
     * Constructs a <code>SurveyQuestion</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/SurveyQuestion} obj Optional instance to populate.
     * @return {module:model/SurveyQuestion} The populated <code>SurveyQuestion</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new SurveyQuestion();

            if (data.hasOwnProperty('QuestionNo')) {
                obj['QuestionNo'] = ApiClient.convertToType(data['QuestionNo'], 'Number');
            }
            if (data.hasOwnProperty('Question')) {
                obj['Question'] = ApiClient.convertToType(data['Question'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>SurveyQuestion</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>SurveyQuestion</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of SurveyQuestion.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['Question'] && !(typeof data['Question'] === 'string' || data['Question'] instanceof String)) {
            throw new Error("Expected the field `Question` to be a primitive type in the JSON string but got " + data['Question']);
        }

        return true;
    }


}

SurveyQuestion.RequiredProperties = ["QuestionNo", "Question"];

/**
 * @member {Number} QuestionNo
 */
SurveyQuestion.prototype['QuestionNo'] = undefined;

/**
 * MaxLength:400
 * @member {String} Question
 */
SurveyQuestion.prototype['Question'] = undefined;






export default SurveyQuestion;

