/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The ValidateAccessIdentifier model module.
 * @module model/ValidateAccessIdentifier
 * @version v1
 */
class ValidateAccessIdentifier {
    /**
     * Constructs a new <code>ValidateAccessIdentifier</code>.
     * @alias module:model/ValidateAccessIdentifier
     * @param accessIdentifier {String} MaxLength:200
     */
    constructor(accessIdentifier) { 
        
        ValidateAccessIdentifier.initialize(this, accessIdentifier);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, accessIdentifier) { 
        obj['AccessIdentifier'] = accessIdentifier;
    }

    /**
     * Constructs a <code>ValidateAccessIdentifier</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ValidateAccessIdentifier} obj Optional instance to populate.
     * @return {module:model/ValidateAccessIdentifier} The populated <code>ValidateAccessIdentifier</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ValidateAccessIdentifier();

            if (data.hasOwnProperty('AccessIdentifier')) {
                obj['AccessIdentifier'] = ApiClient.convertToType(data['AccessIdentifier'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ValidateAccessIdentifier</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ValidateAccessIdentifier</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of ValidateAccessIdentifier.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['AccessIdentifier'] && !(typeof data['AccessIdentifier'] === 'string' || data['AccessIdentifier'] instanceof String)) {
            throw new Error("Expected the field `AccessIdentifier` to be a primitive type in the JSON string but got " + data['AccessIdentifier']);
        }

        return true;
    }


}

ValidateAccessIdentifier.RequiredProperties = ["AccessIdentifier"];

/**
 * MaxLength:200
 * @member {String} AccessIdentifier
 */
ValidateAccessIdentifier.prototype['AccessIdentifier'] = undefined;






export default ValidateAccessIdentifier;

