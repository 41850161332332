import CKNavigator from "../lib/CKNavigator";
import { CKColors, CKPadding } from "../lib/Styles";
import CKInput from "../lib/components/CKInput";
import View from "../lib/components/View";
import LoggedInBase from "./LoggedInBase";
import Genders from '../lib/Genders';
import Counties from '../lib/Counties';
import MobilePhonePrefixes from '../lib/MobilePhonePrefixes';
import Countries from '../lib/Countries';
import CommsLayer from "../lib/api/CommsLayer";
import Alert from "../lib/components/Alert";
import ErrorCodes from "../lib/api/ErrorCodes";
import CKSpacer from "../lib/components/CKSpacer";
import CKButton from "../lib/components/CKButton";

export default class MoreProfileEdit extends LoggedInBase {
    screenName = 'more'
    title = 'Edit Profile'
    subLevelPage = true

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            fields: {
                firstName: '',
                surname: '',
                dob: '',
                gender: '',
                mobileNumCountryId: 0,
                mobileNumber: '',
                email: '',
                address_line_1: '',
                address_line_2: '',
                town: '',
                postcode: '',
                county: 0,
                country: 0
            },
            originalFields: {},
            editMode: false,
            focusField: false,
            updating: false,
            screenLoading: true
        }
    }

    rightNavBar (state = {}) {
        return <View>
            <CKButton className='ck-button-right-save' loading={this.state.updating} disabled={!this.isFieldsModified()} onPress={() => {
                this.performSave();
            }} style={{
                color: this.isFieldsModified() ? CKColors.red : CKColors.primaryDarkGray
            }} link>Save</CKButton>
        </View>
    }

    performSave = () => {
        this.setState({
            updating: true
        }, () => {
            CommsLayer.members().membersV1UpdateMemberPatch({
                FirstName: this.state.fields.firstName,
                LastName: this.state.fields.surname,
                Gender: this.state.fields.gender ? this.state.fields.gender.id : 0,
                DateOfBirth: this.state.fields.dob,
                AddressLine1: this.state.fields.address_line_1 ? this.state.fields.address_line_1 : ' ',
                AddressLine2: this.state.fields.address_line_2 ? this.state.fields.address_line_2 : ' ',
                Town: this.state.fields.town ? this.state.fields.town : ' ',
                PostCode: this.state.fields.postcode ? this.state.fields.postcode : ' ',
                County: this.state.fields.county ? this.state.fields.county.countyId : 0,
                Country: this.state.fields.country ? this.state.fields.country.id : 0,
                MobileNum: this.state.fields.mobileNumber ? this.state.fields.mobileNumber : '',
                MobileNumCountryId: this.state.fields.mobileNumCountryId ? this.state.fields.mobileNumCountryId.id : 0
                //Email: this.state.fields.email
            }).then(() => {
                CommsLayer.members().setLastSavedProfile(this.state.fields);
                this.onBack();
            }).catch(err => {
                if(err.code && err.message) {
                    Alert.alert("Error", err.message);
                } else {
                    Alert.alert("Error", ErrorCodes.generalAPIError());
                }
                this.setState({
                    updating: false
                })
            });
        });
    }

    onBack() {
        this.navigateTo(CKNavigator.moreProfile);
    }

    async loadData() {
        let lastSavedProfile = CommsLayer.members().getLastSavedProfile();

        if(lastSavedProfile) {
            return this.setState({
                fields: lastSavedProfile
            })
        }

        await CommsLayer.members().membersV1GetMemberInfo().then((data) => {
            this.setState({
                fields: {
                    firstName: data.FirstName ? data.FirstName : '',
                    surname: data.LastName ? data.LastName : '',
                    dob: data.DateOfBirth ? data.DateOfBirth : '',
                    gender: data.Gender ? this.findGender(data.Gender) : 0,
                    mobileNumber: data.MobileNum ? data.MobileNum : '',
                    mobileNumCountryId: data.MobileNumCountryId ? this.findMobileCountry(data.MobileNumCountryId) : 0,
                    email: data.Email ? data.Email : '',
                    address_line_1: data.AddressLine1 ? data.AddressLine1 : '',
                    address_line_2: data.AddressLine2 ? data.AddressLine2 : '',
                    town: data.Town ? data.Town : '',
                    postcode: data.PostCode ? data.PostCode : '',
                    county: data.County ? this.findCounty(data.County) : '',
                    country: data.Country ? this.findCountry(data.Country) : ''
                }
            }, () => {
                for(var f in this.state.fields) {
                    this.state.originalFields[f] = this.state.fields[f];
                }

                this.setState({
                    ...this.state
                }, this.hideScreenLoading);
            })
        }).catch(() => {
            Alert.alert("Error", ErrorCodes.generalAPIError());
            this.onBack();
        })

        this.setState({
            isRefreshing: false
        })
    }

    componentDidMount() {
        super.componentDidMount();

        this.showScreenLoading();
    }

    findCounty = (county) => {
        return Counties.find(c => {
            return c.countyId == county || c.name == county;
        })
    }

    findMobileCountry = (country) => {
        return MobilePhonePrefixes.find(c => {
            return c.id ===country || c.name ===country;
        })
    }

    findCountry = (country) => {
        return Countries.find(c => {
            return c.id ===country || c.name ===country;
        })
    }

    findGender = (gender) => {
        return Genders.find(c => {
            return c.id ===gender || c.name ===gender;
        })
    }

    isFieldsModified = () => {
        var isModified = false;
        
        for(var f in this.state.fields) {
            isModified |= (this.state.originalFields[f] !==this.state.fields[f]);
        }

        return isModified;
    }

    addressFieldsToString = () => {
        let address = '';
        let fields = [
            this.state.fields.address_line_1,
            this.state.fields.address_line_2,
            this.state.fields.town,
            this.state.fields.county ? this.state.fields.county.name : '',
            this.state.fields.postcode,
            this.state.fields.country ? this.state.fields.country.name : ''
        ];

        fields.forEach(f => {
            if(address && f) address += '\r\n';
            if(f) address += f
        })

        return address;
    }

    render() {
        return super.render(
            <View style={{
                display: 'flex',
                justifyContent: 'center'
            }}>
                <View className="profile-container">
                    <CKInput placeholder={'Please enter your first name'} onChange={(evt) => {
                        this.setState({fields: {...this.state.fields, firstName: evt.target.value}});
                    }} style={{
                        marginBottom: CKPadding.default
                    }} label="First Name" value={this.state.fields.firstName} />

                    <CKInput onChange={(evt) => {
                        this.setState({fields: {...this.state.fields, surname: evt.target.value}});
                    }} style={{
                        marginBottom: CKPadding.default
                    }} label="Surname" placeholder={'Please enter your surname'} value={this.state.fields.surname} />

                    <CKInput onChange={(evt) => {
                        this.setState({
                            fields: {
                                ...this.state.fields,
                                dob: evt.target.value
                            }
                        })
                    }} style={{
                        marginBottom: CKPadding.default
                    }} date label="Date of Birth" value={this.state.fields.dob} />

                    <CKInput placeholder='Select gender' onChange={(evt) => {
                        this.setState({fields: {...this.state.fields, gender: evt.target.value}});
                    }} style={{
                        marginBottom: CKPadding.default
                    }} label="Gender" value={this.state.fields.gender ? this.state.fields.gender.name : ''} getValue={(d) => {
                        return d.name;
                    }} dropdownData={Genders} rowTextForSelection={(item) => {return item.name; }} />

                    <View style={{
                        display: "flex",
                        flex: 1
                    }}>
                        <View style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginRight: CKPadding.default
                        }}>
                            <CKInput style={{
                                width: 100,
                                paddingRight: CKPadding.default
                            }} label='Prefix' value={this.state.fields.mobileNumCountryId ? this.state.fields.mobileNumCountryId.name : ''} onChange={(event) => {
                                this.setState({fields: {...this.state.fields, mobileNumCountryId: event.target.value}});
                            }} placeholder='Prefix' dropdownData={MobilePhonePrefixes} getValue={(d) => {return d.name}} rowTextForSelection={(item) => {return item.name}} />
                        </View>

                        <View style={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'column'
                        }}>
                            <CKInput style={{
                                marginBottom: CKPadding.default
                            }} onChange={(evt) => {
                                this.setState({
                                    fields: {...this.state.fields, mobileNumber: evt.target.value}
                                })
                            }} placeholder="Please enter your mobile number" label="Mobile Number" value={this.state.fields.mobileNumber} />
                        </View>
                    </View>

                    <CKInput placeholder={"Address Line 1"} style={{
                        marginBottom: CKPadding.default
                    }} onChange={(evt) => {
                        this.setState({
                            fields: {...this.state.fields, address_line_1: evt.target.value}
                        })
                    }} label="Address" value={this.state.fields.address_line_1} />
                    <CKInput placeholder={"Address Line 2"} style={{
                        marginBottom: CKPadding.default
                    }} onChange={(evt) => {
                        this.setState({
                            fields: {...this.state.fields, address_line_2: evt.target.value}
                        })
                    }} value={this.state.fields.address_line_2} />
                    <CKInput placeholder={"Address Line 3"} style={{
                        marginBottom: CKPadding.default
                    }} onChange={(evt) => {
                        this.setState({
                            fields: {...this.state.fields, town: evt.target.value}
                        })
                    }} value={this.state.fields.town} />
                    <CKInput style={{
                        marginBottom: CKPadding.default
                    }} dropdownData={Counties} rowTextForSelection={(item) => {
                            return item.name;
                        }}  placeholder='Select county' value={this.state.fields.county.name} onChange={(evt) => {
                            this.setState({fields: {...this.state.fields, county: evt.target.value}});
                        }}
                    />
                    <CKInput style={{
                        marginBottom: CKPadding.default
                    }} placeholder={'Please enter your eircode'} value={this.state.fields.postcode} onChange={(evt) => {
                            this.setState({fields: {...this.state.fields, postcode: evt.target.value}});
                        }}
                    />
                    <CKInput dropdownData={Countries} rowTextForSelection={(item) => {
                            return item.name;
                        }} getValue={(item) => {
                            return item.name;
                        }} placeholder='Please select your country' value={this.state.fields.country.name} onChange={(evt) => {
                            this.setState({fields: {...this.state.fields, country: evt.target.value}});
                        }}
                    />

                    <CKSpacer />

                    <CKButton disabled={!this.isFieldsModified()} onPress={() => {
                        this.performSave();
                    }} loading={this.state.updating}
                    >Save</CKButton>

                    <CKButton secondary className="add-top-space hide-on-desktop" onPress={() => {
                        this.onBack();
                    }}
                    >Back to Profile</CKButton>


                    <CKSpacer />
                    <CKSpacer />
                </View>
            </View>
        )
    }
}