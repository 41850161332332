import CKNavigator, { CKNavigatorStorage } from "../lib/CKNavigator";
import CommsLayer from "../lib/api/CommsLayer";
import Alert from "../lib/components/Alert";
import CKButton from "../lib/components/CKButton";
import CKSpacer from "../lib/components/CKSpacer";
import CKText from "../lib/components/CKText";
import View from "../lib/components/View";
import LoggedInBase from "./LoggedInBase";

export default class ExclusiveMemberOffers extends LoggedInBase {
    screenName = 'home'
    title = ''
    subLevelPage = true

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,

            memberData: false
        }
    }

    onBack () {
        this.navigateTo(CKNavigator.home);
    }

    async loadData() {
        // lets check if parameters were passed
        let memberOffers = CKNavigatorStorage.getMemberOffers();

        if(!memberOffers) {
            // lets load params based on the ID of the offer
            await CommsLayer.offers().offersV1GetCurrentOffersWithDetails().then(offers => {
                if(offers.view_more) {
                    memberOffers = offers.view_more;
                }
            });
        }

        // lets check if were able to find params
        if(memberOffers && memberOffers.length > 0) {
            this.title = memberOffers[0].custom_title ? memberOffers[0].custom_title : 'Exclusive In-Store Member Offers';

            this.setState({
                offers: memberOffers
            }, this.hideScreenLoading);
        } else {
            Alert.alert('Error', 'No offers found')
            this.onBack();
        }
    }

    componentDidMount() {
        super.componentDidMount();

        this.showScreenLoading();
    }

    render() {
        return super.render(
            this.state.offers ? 
            <View style={{
                display: 'flex',
                justifyContent: 'center',
            }}>
                <View className="exclusive-members-container">
                    {this.state.offers.map((offer, index) => {
                        return <CKButton className="offer" custom onPress={() => {
                            CKNavigatorStorage.set(offer, 'view_more');
                            this.navigateTo(CKNavigator.offerDetails.replace(':id', offer.OfferId));
                        }} key={'offer' + offer.OfferId}>
                            <View className="container">
                                <View className="top">
                                    <img src={offer.ImageLocation.replace(/\n/g, '')} />
                                </View>

                                <View className="bottom">
                                    <CKText className="text">{offer.OfferText}</CKText>
                                </View>
                            </View>
                        </CKButton>
                    })}

                    <CKSpacer />
                    <CKSpacer />
                </View>
            </View>
            :
            null
        )
    }
}