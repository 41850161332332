/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import SurveyQuestion from './SurveyQuestion';

/**
 * The SurveyInfo model module.
 * @module model/SurveyInfo
 * @version v1
 */
class SurveyInfo {
    /**
     * Constructs a new <code>SurveyInfo</code>.
     * @alias module:model/SurveyInfo
     * @param surveyId {Number} 
     * @param surveyName {String} MaxLength:40
     * @param surveyContent {String} MaxLength:8000
     * @param startDate {String} Format [yyyy-MM-dd HH:mm:ss] UTC
     * @param endDate {String} Format [yyyy-MM-dd HH:mm:ss] UTC
     * @param pointsAwarded {Number} 
     * @param enabled {Boolean} 
     * @param questions {Array.<module:model/SurveyQuestion>} 
     */
    constructor(surveyId, surveyName, surveyContent, startDate, endDate, pointsAwarded, enabled, questions) { 
        
        SurveyInfo.initialize(this, surveyId, surveyName, surveyContent, startDate, endDate, pointsAwarded, enabled, questions);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, surveyId, surveyName, surveyContent, startDate, endDate, pointsAwarded, enabled, questions) { 
        obj['SurveyId'] = surveyId;
        obj['SurveyName'] = surveyName;
        obj['SurveyContent'] = surveyContent;
        obj['StartDate'] = startDate;
        obj['EndDate'] = endDate;
        obj['PointsAwarded'] = pointsAwarded;
        obj['Enabled'] = enabled;
        obj['Questions'] = questions;
    }

    /**
     * Constructs a <code>SurveyInfo</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/SurveyInfo} obj Optional instance to populate.
     * @return {module:model/SurveyInfo} The populated <code>SurveyInfo</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new SurveyInfo();

            if (data.hasOwnProperty('SurveyId')) {
                obj['SurveyId'] = ApiClient.convertToType(data['SurveyId'], 'Number');
            }
            if (data.hasOwnProperty('SurveyName')) {
                obj['SurveyName'] = ApiClient.convertToType(data['SurveyName'], 'String');
            }
            if (data.hasOwnProperty('SurveyContent')) {
                obj['SurveyContent'] = ApiClient.convertToType(data['SurveyContent'], 'String');
            }
            if (data.hasOwnProperty('StartDate')) {
                obj['StartDate'] = ApiClient.convertToType(data['StartDate'], 'String');
            }
            if (data.hasOwnProperty('EndDate')) {
                obj['EndDate'] = ApiClient.convertToType(data['EndDate'], 'String');
            }
            if (data.hasOwnProperty('PointsAwarded')) {
                obj['PointsAwarded'] = ApiClient.convertToType(data['PointsAwarded'], 'Number');
            }
            if (data.hasOwnProperty('Enabled')) {
                obj['Enabled'] = ApiClient.convertToType(data['Enabled'], 'Boolean');
            }
            if (data.hasOwnProperty('Questions')) {
                obj['Questions'] = ApiClient.convertToType(data['Questions'], [SurveyQuestion]);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>SurveyInfo</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>SurveyInfo</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of SurveyInfo.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['SurveyName'] && !(typeof data['SurveyName'] === 'string' || data['SurveyName'] instanceof String)) {
            throw new Error("Expected the field `SurveyName` to be a primitive type in the JSON string but got " + data['SurveyName']);
        }
        // ensure the json data is a string
        if (data['SurveyContent'] && !(typeof data['SurveyContent'] === 'string' || data['SurveyContent'] instanceof String)) {
            throw new Error("Expected the field `SurveyContent` to be a primitive type in the JSON string but got " + data['SurveyContent']);
        }
        // ensure the json data is a string
        if (data['StartDate'] && !(typeof data['StartDate'] === 'string' || data['StartDate'] instanceof String)) {
            throw new Error("Expected the field `StartDate` to be a primitive type in the JSON string but got " + data['StartDate']);
        }
        // ensure the json data is a string
        if (data['EndDate'] && !(typeof data['EndDate'] === 'string' || data['EndDate'] instanceof String)) {
            throw new Error("Expected the field `EndDate` to be a primitive type in the JSON string but got " + data['EndDate']);
        }
        if (data['Questions']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['Questions'])) {
                throw new Error("Expected the field `Questions` to be an array in the JSON data but got " + data['Questions']);
            }
            // validate the optional field `Questions` (array)
            for (const item of data['Questions']) {
                SurveyQuestion.validateJSON(item);
            };
        }

        return true;
    }


}

SurveyInfo.RequiredProperties = ["SurveyId", "SurveyName", "SurveyContent", "StartDate", "EndDate", "PointsAwarded", "Enabled", "Questions"];

/**
 * @member {Number} SurveyId
 */
SurveyInfo.prototype['SurveyId'] = undefined;

/**
 * MaxLength:40
 * @member {String} SurveyName
 */
SurveyInfo.prototype['SurveyName'] = undefined;

/**
 * MaxLength:8000
 * @member {String} SurveyContent
 */
SurveyInfo.prototype['SurveyContent'] = undefined;

/**
 * Format [yyyy-MM-dd HH:mm:ss] UTC
 * @member {String} StartDate
 */
SurveyInfo.prototype['StartDate'] = undefined;

/**
 * Format [yyyy-MM-dd HH:mm:ss] UTC
 * @member {String} EndDate
 */
SurveyInfo.prototype['EndDate'] = undefined;

/**
 * @member {Number} PointsAwarded
 */
SurveyInfo.prototype['PointsAwarded'] = undefined;

/**
 * @member {Boolean} Enabled
 */
SurveyInfo.prototype['Enabled'] = undefined;

/**
 * @member {Array.<module:model/SurveyQuestion>} Questions
 */
SurveyInfo.prototype['Questions'] = undefined;






export default SurveyInfo;

