import { Component } from "react";
import Styles, { CKPadding } from "../Styles";
import CKButton from "./CKButton";
import Alert from "./Alert";
import CKText from "./CKText";
import CKIcon, { CKIconType } from "./CKIcon";
import View from "./View";

export default class CKAlert extends Component {
    render() {
        return <div className="confirmation-holder">
            <CKButton custom default_style={{
                position: 'absolute',
                right: 0,
                left: 0,
                bottom: 0,
                top: 0,
                cursor: 'default',
                zIndex: 0
            }} onPress={() => {
                Alert.dismiss();
            }} />

            <div className="confirmation-holder-inner">
                <CKText style={{
                    ...Styles.gothamBlack,
                    fontSize: 16,
                    lineHeight: "33px",
                    marginBottom: 20
                }}>{this.props.title}</CKText>

                <CKText style={{
                    ...Styles.gotham,
                    fontSize: 16,
                    lineHeight: "24px"
                }}>{this.props.message}</CKText>

                <View style={{
                    display: 'flex',
                    gap: CKPadding.default,
                    justifyContent: 'center'
                }}>
                    {this.props.buttons.map((button, index) => {
                        return <CKButton {...(button.style ==='secondary' ? {secondary: true} : {})} key={'button_' + index} style={{
                            marginTop: 20,
                            maxWidth: 328,
                            display: 'flex',
                            alignSelf: 'center',
                            justifyContent: 'center',
                            alignContent: 'center',
                            alignItems: 'center'
                        }} onPress={() => {
                            if(button.onPress) button.onPress();
                            else Alert.dismiss();
                        }}>{button.text}</CKButton>
                    })}
                </View>

                <CKButton custom default_style={{
                    position: 'absolute',
                    right: 16,
                    top: 17
                }} hover_style={{
                    opacity: .75
                }} onPress={() => {
                    Alert.dismiss();
                }}>
                    <CKIcon width={24} height={24} icon={CKIconType.cancel_grey} />
                </CKButton>
            </div>
        </div>
    }
}