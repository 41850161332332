/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import APIOKMsgResponse from '../model/APIOKMsgResponse';
import APIResponse from '../model/APIResponse';
import CompetitionEntry from '../model/CompetitionEntry';
import CompetitionInfo from '../model/CompetitionInfo';
import MemberCompetitionPlays from '../model/MemberCompetitionPlays';

/**
* Competitions service.
* @module api/CompetitionsApi
* @version v1
*/
export default class CompetitionsApi {

    /**
    * Constructs a new CompetitionsApi. 
    * @alias module:api/CompetitionsApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the competitionsV1GetCompetition operation.
     * @callback module:api/CompetitionsApi~competitionsV1GetCompetitionCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CompetitionInfo} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get Competition
     * Get a specific competition   
     * @param {Number} competitionId ID of Competition
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:api/CompetitionsApi~competitionsV1GetCompetitionCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/CompetitionInfo}
     */
    competitionsV1GetCompetition(competitionId, clientId, authorization, callback) {
      let postBody = null;
      // verify the required parameter 'competitionId' is set
      if (competitionId === undefined || competitionId === null) {
        throw new Error("Missing the required parameter 'competitionId' when calling competitionsV1GetCompetition");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling competitionsV1GetCompetition");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling competitionsV1GetCompetition");
      }

      let pathParams = {
        'competitionId': competitionId
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = CompetitionInfo;
      return this.apiClient.callApi(
        '/v1/competitions/{competitionId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the competitionsV1GetCompetitions operation.
     * @callback module:api/CompetitionsApi~competitionsV1GetCompetitionsCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/CompetitionInfo>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * List Competitions
     * List all competitions   
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {Object} opts Optional parameters
     * @param {Number} [competitionType] 0 to return all
     * @param {Number} [maxNo] Set to 0 for ALL
     * @param {module:api/CompetitionsApi~competitionsV1GetCompetitionsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/CompetitionInfo>}
     */
    competitionsV1GetCompetitions(clientId, authorization, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling competitionsV1GetCompetitions");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling competitionsV1GetCompetitions");
      }

      let pathParams = {
      };
      let queryParams = {
        'competitionType': opts['competitionType'],
        'maxNo': opts['maxNo']
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = [CompetitionInfo];
      return this.apiClient.callApi(
        '/v1/competitions', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the competitionsV1GetMemberCompetitionPlays operation.
     * @callback module:api/CompetitionsApi~competitionsV1GetMemberCompetitionPlaysCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MemberCompetitionPlays} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get Member Competition Plays
     * Get a member's play(s) for a competition   
     * @param {String} memberUId Member Unique Identifier
     * @param {Number} competitionId ID of Competition
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:api/CompetitionsApi~competitionsV1GetMemberCompetitionPlaysCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/MemberCompetitionPlays}
     */
    competitionsV1GetMemberCompetitionPlays(memberUId, competitionId, clientId, authorization, callback) {
      let postBody = null;
      // verify the required parameter 'memberUId' is set
      if (memberUId === undefined || memberUId === null) {
        throw new Error("Missing the required parameter 'memberUId' when calling competitionsV1GetMemberCompetitionPlays");
      }
      // verify the required parameter 'competitionId' is set
      if (competitionId === undefined || competitionId === null) {
        throw new Error("Missing the required parameter 'competitionId' when calling competitionsV1GetMemberCompetitionPlays");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling competitionsV1GetMemberCompetitionPlays");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling competitionsV1GetMemberCompetitionPlays");
      }

      let pathParams = {
        'memberUId': memberUId,
        'competitionId': competitionId
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = MemberCompetitionPlays;
      return this.apiClient.callApi(
        '/v1/competitions/{competitionId}/Members/{memberUId}/Plays', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the competitionsV1GetMemberCompetitionsPlays operation.
     * @callback module:api/CompetitionsApi~competitionsV1GetMemberCompetitionsPlaysCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/MemberCompetitionPlays>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * List Member Competition Plays
     * List all competition play(s) for a member   
     * @param {String} memberUId Member Unique Identifier
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:api/CompetitionsApi~competitionsV1GetMemberCompetitionsPlaysCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/MemberCompetitionPlays>}
     */
    competitionsV1GetMemberCompetitionsPlays(memberUId, clientId, authorization, callback) {
      let postBody = null;
      // verify the required parameter 'memberUId' is set
      if (memberUId === undefined || memberUId === null) {
        throw new Error("Missing the required parameter 'memberUId' when calling competitionsV1GetMemberCompetitionsPlays");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling competitionsV1GetMemberCompetitionsPlays");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling competitionsV1GetMemberCompetitionsPlays");
      }

      let pathParams = {
        'memberUId': memberUId
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = [MemberCompetitionPlays];
      return this.apiClient.callApi(
        '/v1/competitions/Members/{memberUId}/Plays', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the competitionsV1SaveCompetitionEntry operation.
     * @callback module:api/CompetitionsApi~competitionsV1SaveCompetitionEntryCallback
     * @param {String} error Error message, if any.
     * @param {module:model/APIOKMsgResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Add Competition Entry
     * Add a competition entry for a member.                <br /><span class=\"green\">Request CompetitionEntry Object:</span>            {         \"CommunicationTypeId\": 1 = App, 2 = Web,         \"TotalPlays\": No of entries required. Note for a competition of skills test the total no of entries in a single request can only be 1,         \"AnswerId\": ID of the Answer (Test of skill only)      }   
     * @param {String} memberUId Member Unique Identifier
     * @param {Number} competitionId ID of Competition
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:model/CompetitionEntry} competitionEntry See CompetitionEntry Object above
     * @param {module:api/CompetitionsApi~competitionsV1SaveCompetitionEntryCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/APIOKMsgResponse}
     */
    competitionsV1SaveCompetitionEntry(memberUId, competitionId, clientId, authorization, competitionEntry, callback) {
      let postBody = competitionEntry;
      // verify the required parameter 'memberUId' is set
      if (memberUId === undefined || memberUId === null) {
        throw new Error("Missing the required parameter 'memberUId' when calling competitionsV1SaveCompetitionEntry");
      }
      // verify the required parameter 'competitionId' is set
      if (competitionId === undefined || competitionId === null) {
        throw new Error("Missing the required parameter 'competitionId' when calling competitionsV1SaveCompetitionEntry");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling competitionsV1SaveCompetitionEntry");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling competitionsV1SaveCompetitionEntry");
      }
      // verify the required parameter 'competitionEntry' is set
      if (competitionEntry === undefined || competitionEntry === null) {
        throw new Error("Missing the required parameter 'competitionEntry' when calling competitionsV1SaveCompetitionEntry");
      }

      let pathParams = {
        'memberUId': memberUId,
        'competitionId': competitionId
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json', 'text/json', 'application/xml', 'text/xml', 'application/x-www-form-urlencoded'];
      let accepts = ['application/json', 'text/json'];
      let returnType = APIOKMsgResponse;
      return this.apiClient.callApi(
        '/v1/competitions/{competitionId}/Members/{memberUId}/Entry', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
