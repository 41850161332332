import { Component } from "react";
import View from "../lib/components/View";
import CKButton from "../lib/components/CKButton";
import Alert from "../lib/components/Alert";
import CKIcon, { CKIconType } from "../lib/components/CKIcon";
import CKLoading from "../lib/components/CKLoading";
import CommsLayer from "../lib/api/CommsLayer";
import ErrorCodes from "../lib/api/ErrorCodes";

export default class Terms extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true
        }
    }

    componentDidMount() {
        CommsLayer.xmls().terms().then(data => {
            data = data.replace(/\n/g, '');
            data = data.replace(/<\/p> <p>/g, '</p><p>');
            data = data.replace(/<p>/g, '<div>');
            data = data.replace(/<\/p>/g, '</div>');
            data = data.replace(/\t\t\t\t\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;');
            data = data.replace(/\t\t\t\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
            data = data.replace(/\t\t\t/g, '&nbsp;&nbsp;&nbsp;');
            data = data.replace(/\t\t/g, '&nbsp;&nbsp;');
            data = data.replace(/\t/g, '&nbsp;');

            this.setState({
                terms: data
            }, this.hideLoading())
        }).catch(() => {
            Alert.alert('Error', ErrorCodes.generalAPIError());
            Alert.dismissTerms();
        })
    }

    hideLoading () {
        this.setState({
            loading: false
        })
    }

    render() {
        return (
            <View className="terms-popup-container">
                <CKButton custom default_style={{
                    position: 'absolute',
                    right: 0,
                    left: 0,
                    bottom: 0,
                    top: 0,
                    cursor: 'default',
                    zIndex: 0
                }} onPress={() => {
                    Alert.dismissTerms();
                }} />

                <View className="content">
                    {this.state.loading ? <CKLoading red width={67} text={'Loading...'} /> : <View className='terms'>
                        <div dangerouslySetInnerHTML={{__html: this.state.terms}} />
                    </View>}
                    <CKButton custom default_style={{
                        position: 'absolute',
                        right: 16,
                        top: 17
                    }} hover_style={{
                        opacity: .75
                    }} onPress={() => {
                        Alert.dismissTerms();
                    }}>
                        <CKIcon width={24} height={24} icon={CKIconType.cancel_grey} />
                    </CKButton>
                </View>
            </View>
        );
    }
}