/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The Card model module.
 * @module model/Card
 * @version v1
 */
class Card {
    /**
     * Constructs a new <code>Card</code>.
     * @alias module:model/Card
     * @param loyaltyCardNum {String} MaxLength:25
     */
    constructor(loyaltyCardNum) { 
        
        Card.initialize(this, loyaltyCardNum);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, loyaltyCardNum) { 
        obj['LoyaltyCardNum'] = loyaltyCardNum;
    }

    /**
     * Constructs a <code>Card</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Card} obj Optional instance to populate.
     * @return {module:model/Card} The populated <code>Card</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Card();

            if (data.hasOwnProperty('LoyaltyCardNum')) {
                obj['LoyaltyCardNum'] = ApiClient.convertToType(data['LoyaltyCardNum'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Card</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Card</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of Card.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['LoyaltyCardNum'] && !(typeof data['LoyaltyCardNum'] === 'string' || data['LoyaltyCardNum'] instanceof String)) {
            throw new Error("Expected the field `LoyaltyCardNum` to be a primitive type in the JSON string but got " + data['LoyaltyCardNum']);
        }

        return true;
    }


}

Card.RequiredProperties = ["LoyaltyCardNum"];

/**
 * MaxLength:25
 * @member {String} LoyaltyCardNum
 */
Card.prototype['LoyaltyCardNum'] = undefined;






export default Card;

