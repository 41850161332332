import { Component } from "react";
import {ReactComponent as LogoSide} from '../../images/ck_logo_side.svg';
import {ReactComponent as LogoSideMobile} from '../../images/logo_ck_extra.svg';
import CKSeparator from './CKSeparator'
import CKButtonSide from "./CKButtonSide";

export default class CKSidebar extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <div className="logged-in-sidebar">
            <LogoSide className="desktop-logo" />

            <LogoSideMobile className="mobile-logo" />

            <CKSeparator className="hide-on-mobile" style={{
                marginBottom: 22
            }} full nospace />

            <div className="button-holders">
                <CKButtonSide onPress={() => {
                    if(this.props.onPress) this.props.onPress('home');
                }} icon='home' label="Home" selected={this.props.selected ==='home'} />

                <CKButtonSide onPress={() => {
                    if(this.props.onPress) this.props.onPress('inbox');
                }} icon='inbox' label="Inbox" selected={this.props.selected ==='inbox'} />

                <CKButtonSide onPress={() => {
                    if(this.props.onPress) this.props.onPress('more');
                }} icon='more' label="More" selected={this.props.selected ==='more'} />
            </div>
        </div>
    }
}