/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The MemberUpdatePatch model module.
 * @module model/MemberUpdatePatch
 * @version v1
 */
class MemberUpdatePatch {
    /**
     * Constructs a new <code>MemberUpdatePatch</code>.
     * @alias module:model/MemberUpdatePatch
     */
    constructor() { 
        
        MemberUpdatePatch.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>MemberUpdatePatch</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/MemberUpdatePatch} obj Optional instance to populate.
     * @return {module:model/MemberUpdatePatch} The populated <code>MemberUpdatePatch</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MemberUpdatePatch();

            if (data.hasOwnProperty('FirstName')) {
                obj['FirstName'] = ApiClient.convertToType(data['FirstName'], 'String');
            }
            if (data.hasOwnProperty('LastName')) {
                obj['LastName'] = ApiClient.convertToType(data['LastName'], 'String');
            }
            if (data.hasOwnProperty('Language')) {
                obj['Language'] = ApiClient.convertToType(data['Language'], 'Number');
            }
            if (data.hasOwnProperty('Gender')) {
                obj['Gender'] = ApiClient.convertToType(data['Gender'], 'Number');
            }
            if (data.hasOwnProperty('Title')) {
                obj['Title'] = ApiClient.convertToType(data['Title'], 'Number');
            }
            if (data.hasOwnProperty('DateOfBirth')) {
                obj['DateOfBirth'] = ApiClient.convertToType(data['DateOfBirth'], 'String');
            }
            if (data.hasOwnProperty('Nationality')) {
                obj['Nationality'] = ApiClient.convertToType(data['Nationality'], 'Number');
            }
            if (data.hasOwnProperty('HouseName')) {
                obj['HouseName'] = ApiClient.convertToType(data['HouseName'], 'String');
            }
            if (data.hasOwnProperty('AddressLine1')) {
                obj['AddressLine1'] = ApiClient.convertToType(data['AddressLine1'], 'String');
            }
            if (data.hasOwnProperty('AddressLine2')) {
                obj['AddressLine2'] = ApiClient.convertToType(data['AddressLine2'], 'String');
            }
            if (data.hasOwnProperty('Town')) {
                obj['Town'] = ApiClient.convertToType(data['Town'], 'String');
            }
            if (data.hasOwnProperty('PostCode')) {
                obj['PostCode'] = ApiClient.convertToType(data['PostCode'], 'String');
            }
            if (data.hasOwnProperty('County')) {
                obj['County'] = ApiClient.convertToType(data['County'], 'Number');
            }
            if (data.hasOwnProperty('Country')) {
                obj['Country'] = ApiClient.convertToType(data['Country'], 'Number');
            }
            if (data.hasOwnProperty('FuelCardNum')) {
                obj['FuelCardNum'] = ApiClient.convertToType(data['FuelCardNum'], 'String');
            }
            if (data.hasOwnProperty('HomeHeatOil')) {
                obj['HomeHeatOil'] = ApiClient.convertToType(data['HomeHeatOil'], 'Boolean');
            }
            if (data.hasOwnProperty('MobileNum')) {
                obj['MobileNum'] = ApiClient.convertToType(data['MobileNum'], 'String');
            }
            if (data.hasOwnProperty('MobileNumCountryId')) {
                obj['MobileNumCountryId'] = ApiClient.convertToType(data['MobileNumCountryId'], 'Number');
            }
            if (data.hasOwnProperty('EmailOptIn')) {
                obj['EmailOptIn'] = ApiClient.convertToType(data['EmailOptIn'], 'Boolean');
            }
            if (data.hasOwnProperty('SMSOptIn')) {
                obj['SMSOptIn'] = ApiClient.convertToType(data['SMSOptIn'], 'Boolean');
            }
            if (data.hasOwnProperty('PostOptIn')) {
                obj['PostOptIn'] = ApiClient.convertToType(data['PostOptIn'], 'Boolean');
            }
            if (data.hasOwnProperty('InAppOptIn')) {
                obj['InAppOptIn'] = ApiClient.convertToType(data['InAppOptIn'], 'Boolean');
            }
            if (data.hasOwnProperty('Email')) {
                obj['Email'] = ApiClient.convertToType(data['Email'], 'String');
            }
            if (data.hasOwnProperty('Password')) {
                obj['Password'] = ApiClient.convertToType(data['Password'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>MemberUpdatePatch</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>MemberUpdatePatch</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['FirstName'] && !(typeof data['FirstName'] === 'string' || data['FirstName'] instanceof String)) {
            throw new Error("Expected the field `FirstName` to be a primitive type in the JSON string but got " + data['FirstName']);
        }
        // ensure the json data is a string
        if (data['LastName'] && !(typeof data['LastName'] === 'string' || data['LastName'] instanceof String)) {
            throw new Error("Expected the field `LastName` to be a primitive type in the JSON string but got " + data['LastName']);
        }
        // ensure the json data is a string
        if (data['DateOfBirth'] && !(typeof data['DateOfBirth'] === 'string' || data['DateOfBirth'] instanceof String)) {
            throw new Error("Expected the field `DateOfBirth` to be a primitive type in the JSON string but got " + data['DateOfBirth']);
        }
        // ensure the json data is a string
        if (data['HouseName'] && !(typeof data['HouseName'] === 'string' || data['HouseName'] instanceof String)) {
            throw new Error("Expected the field `HouseName` to be a primitive type in the JSON string but got " + data['HouseName']);
        }
        // ensure the json data is a string
        if (data['AddressLine1'] && !(typeof data['AddressLine1'] === 'string' || data['AddressLine1'] instanceof String)) {
            throw new Error("Expected the field `AddressLine1` to be a primitive type in the JSON string but got " + data['AddressLine1']);
        }
        // ensure the json data is a string
        if (data['AddressLine2'] && !(typeof data['AddressLine2'] === 'string' || data['AddressLine2'] instanceof String)) {
            throw new Error("Expected the field `AddressLine2` to be a primitive type in the JSON string but got " + data['AddressLine2']);
        }
        // ensure the json data is a string
        if (data['Town'] && !(typeof data['Town'] === 'string' || data['Town'] instanceof String)) {
            throw new Error("Expected the field `Town` to be a primitive type in the JSON string but got " + data['Town']);
        }
        // ensure the json data is a string
        if (data['PostCode'] && !(typeof data['PostCode'] === 'string' || data['PostCode'] instanceof String)) {
            throw new Error("Expected the field `PostCode` to be a primitive type in the JSON string but got " + data['PostCode']);
        }
        // ensure the json data is a string
        if (data['FuelCardNum'] && !(typeof data['FuelCardNum'] === 'string' || data['FuelCardNum'] instanceof String)) {
            throw new Error("Expected the field `FuelCardNum` to be a primitive type in the JSON string but got " + data['FuelCardNum']);
        }
        // ensure the json data is a string
        if (data['MobileNum'] && !(typeof data['MobileNum'] === 'string' || data['MobileNum'] instanceof String)) {
            throw new Error("Expected the field `MobileNum` to be a primitive type in the JSON string but got " + data['MobileNum']);
        }
        // ensure the json data is a string
        if (data['Email'] && !(typeof data['Email'] === 'string' || data['Email'] instanceof String)) {
            throw new Error("Expected the field `Email` to be a primitive type in the JSON string but got " + data['Email']);
        }
        // ensure the json data is a string
        if (data['Password'] && !(typeof data['Password'] === 'string' || data['Password'] instanceof String)) {
            throw new Error("Expected the field `Password` to be a primitive type in the JSON string but got " + data['Password']);
        }

        return true;
    }


}



/**
 * MaxLength:64
 * @member {String} FirstName
 */
MemberUpdatePatch.prototype['FirstName'] = undefined;

/**
 * MaxLength:64
 * @member {String} LastName
 */
MemberUpdatePatch.prototype['LastName'] = undefined;

/**
 * @member {Number} Language
 */
MemberUpdatePatch.prototype['Language'] = undefined;

/**
 * @member {Number} Gender
 */
MemberUpdatePatch.prototype['Gender'] = undefined;

/**
 * @member {Number} Title
 */
MemberUpdatePatch.prototype['Title'] = undefined;

/**
 * Format [yyyy-MM-dd]
 * @member {String} DateOfBirth
 */
MemberUpdatePatch.prototype['DateOfBirth'] = undefined;

/**
 * @member {Number} Nationality
 */
MemberUpdatePatch.prototype['Nationality'] = undefined;

/**
 * MaxLength:100
 * @member {String} HouseName
 */
MemberUpdatePatch.prototype['HouseName'] = undefined;

/**
 * MaxLength:100
 * @member {String} AddressLine1
 */
MemberUpdatePatch.prototype['AddressLine1'] = undefined;

/**
 * MaxLength:100
 * @member {String} AddressLine2
 */
MemberUpdatePatch.prototype['AddressLine2'] = undefined;

/**
 * MaxLength:100
 * @member {String} Town
 */
MemberUpdatePatch.prototype['Town'] = undefined;

/**
 * MaxLength:100
 * @member {String} PostCode
 */
MemberUpdatePatch.prototype['PostCode'] = undefined;

/**
 * @member {Number} County
 */
MemberUpdatePatch.prototype['County'] = undefined;

/**
 * @member {Number} Country
 */
MemberUpdatePatch.prototype['Country'] = undefined;

/**
 * MaxLength:25
 * @member {String} FuelCardNum
 */
MemberUpdatePatch.prototype['FuelCardNum'] = undefined;

/**
 * @member {Boolean} HomeHeatOil
 */
MemberUpdatePatch.prototype['HomeHeatOil'] = undefined;

/**
 * MaxLength:20
 * @member {String} MobileNum
 */
MemberUpdatePatch.prototype['MobileNum'] = undefined;

/**
 * @member {Number} MobileNumCountryId
 */
MemberUpdatePatch.prototype['MobileNumCountryId'] = undefined;

/**
 * @member {Boolean} EmailOptIn
 */
MemberUpdatePatch.prototype['EmailOptIn'] = undefined;

/**
 * @member {Boolean} SMSOptIn
 */
MemberUpdatePatch.prototype['SMSOptIn'] = undefined;

/**
 * @member {Boolean} PostOptIn
 */
MemberUpdatePatch.prototype['PostOptIn'] = undefined;

/**
 * @member {Boolean} InAppOptIn
 */
MemberUpdatePatch.prototype['InAppOptIn'] = undefined;

/**
 * MaxLength:64
 * @member {String} Email
 */
MemberUpdatePatch.prototype['Email'] = undefined;

/**
 * MaxLength:64
 * @member {String} Password
 */
MemberUpdatePatch.prototype['Password'] = undefined;






export default MemberUpdatePatch;

