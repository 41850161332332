/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The GiftCardRequest model module.
 * @module model/GiftCardRequest
 * @version v1
 */
class GiftCardRequest {
    /**
     * Constructs a new <code>GiftCardRequest</code>.
     * @alias module:model/GiftCardRequest
     * @param transactionRef {String} MaxLength:35
     * @param idempotencyKey {String} MaxLength:50
     * @param amount {Number} 
     */
    constructor(transactionRef, idempotencyKey, amount) { 
        
        GiftCardRequest.initialize(this, transactionRef, idempotencyKey, amount);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, transactionRef, idempotencyKey, amount) { 
        obj['TransactionRef'] = transactionRef;
        obj['IdempotencyKey'] = idempotencyKey;
        obj['Amount'] = amount;
    }

    /**
     * Constructs a <code>GiftCardRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/GiftCardRequest} obj Optional instance to populate.
     * @return {module:model/GiftCardRequest} The populated <code>GiftCardRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new GiftCardRequest();

            if (data.hasOwnProperty('TransactionRef')) {
                obj['TransactionRef'] = ApiClient.convertToType(data['TransactionRef'], 'String');
            }
            if (data.hasOwnProperty('IdempotencyKey')) {
                obj['IdempotencyKey'] = ApiClient.convertToType(data['IdempotencyKey'], 'String');
            }
            if (data.hasOwnProperty('Amount')) {
                obj['Amount'] = ApiClient.convertToType(data['Amount'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>GiftCardRequest</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>GiftCardRequest</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of GiftCardRequest.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['TransactionRef'] && !(typeof data['TransactionRef'] === 'string' || data['TransactionRef'] instanceof String)) {
            throw new Error("Expected the field `TransactionRef` to be a primitive type in the JSON string but got " + data['TransactionRef']);
        }
        // ensure the json data is a string
        if (data['IdempotencyKey'] && !(typeof data['IdempotencyKey'] === 'string' || data['IdempotencyKey'] instanceof String)) {
            throw new Error("Expected the field `IdempotencyKey` to be a primitive type in the JSON string but got " + data['IdempotencyKey']);
        }

        return true;
    }


}

GiftCardRequest.RequiredProperties = ["TransactionRef", "IdempotencyKey", "Amount"];

/**
 * MaxLength:35
 * @member {String} TransactionRef
 */
GiftCardRequest.prototype['TransactionRef'] = undefined;

/**
 * MaxLength:50
 * @member {String} IdempotencyKey
 */
GiftCardRequest.prototype['IdempotencyKey'] = undefined;

/**
 * @member {Number} Amount
 */
GiftCardRequest.prototype['Amount'] = undefined;






export default GiftCardRequest;

