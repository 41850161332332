/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The MemberIdentity model module.
 * @module model/MemberIdentity
 * @version v1
 */
class MemberIdentity {
    /**
     * Constructs a new <code>MemberIdentity</code>.
     * @alias module:model/MemberIdentity
     * @param identityText {String} MaxLength:64
     * @param identityTypeId {Number} 
     */
    constructor(identityText, identityTypeId) { 
        
        MemberIdentity.initialize(this, identityText, identityTypeId);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, identityText, identityTypeId) { 
        obj['IdentityText'] = identityText;
        obj['IdentityTypeId'] = identityTypeId;
    }

    /**
     * Constructs a <code>MemberIdentity</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/MemberIdentity} obj Optional instance to populate.
     * @return {module:model/MemberIdentity} The populated <code>MemberIdentity</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MemberIdentity();

            if (data.hasOwnProperty('IdentityText')) {
                obj['IdentityText'] = ApiClient.convertToType(data['IdentityText'], 'String');
            }
            if (data.hasOwnProperty('IdentityTypeId')) {
                obj['IdentityTypeId'] = ApiClient.convertToType(data['IdentityTypeId'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>MemberIdentity</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>MemberIdentity</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of MemberIdentity.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['IdentityText'] && !(typeof data['IdentityText'] === 'string' || data['IdentityText'] instanceof String)) {
            throw new Error("Expected the field `IdentityText` to be a primitive type in the JSON string but got " + data['IdentityText']);
        }

        return true;
    }


}

MemberIdentity.RequiredProperties = ["IdentityText", "IdentityTypeId"];

/**
 * MaxLength:64
 * @member {String} IdentityText
 */
MemberIdentity.prototype['IdentityText'] = undefined;

/**
 * @member {Number} IdentityTypeId
 */
MemberIdentity.prototype['IdentityTypeId'] = undefined;






export default MemberIdentity;

