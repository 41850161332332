/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import APIResponse from '../model/APIResponse';
import ApplyLoyalty from '../model/ApplyLoyalty';
import CartInfoCou from '../model/CartInfoCou';
import LoyaltyApplied from '../model/LoyaltyApplied';
import Order from '../model/Order';
import OrderDetailPagination from '../model/OrderDetailPagination';
import OrderInfoCou from '../model/OrderInfoCou';
import OrderSalesTotal from '../model/OrderSalesTotal';
import OrderStatus from '../model/OrderStatus';
import OrdersSummary from '../model/OrdersSummary';
import RedeemGiftRequest from '../model/RedeemGiftRequest';
import RedeemGiftResponse from '../model/RedeemGiftResponse';
import RequestCart from '../model/RequestCart';

/**
* Orders service.
* @module api/OrdersApi
* @version v1
*/
export default class OrdersApi {

    /**
    * Constructs a new OrdersApi. 
    * @alias module:api/OrdersApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the ordersV1AddLoyalty operation.
     * @callback module:api/OrdersApi~ordersV1AddLoyaltyCallback
     * @param {String} error Error message, if any.
     * @param {module:model/LoyaltyApplied} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Add Loyalty Card to Order
     * Add points for a customer after an eligible purchase where the original purchase had no loyalty card associated with it.   
     * @param {String} memberUId Member Unique Identifier
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:model/ApplyLoyalty} loyalty Apply Loyalty Object    Provide the transaction reference number
     * @param {module:api/OrdersApi~ordersV1AddLoyaltyCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/LoyaltyApplied}
     */
    ordersV1AddLoyalty(memberUId, clientId, authorization, loyalty, callback) {
      let postBody = loyalty;
      // verify the required parameter 'memberUId' is set
      if (memberUId === undefined || memberUId === null) {
        throw new Error("Missing the required parameter 'memberUId' when calling ordersV1AddLoyalty");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling ordersV1AddLoyalty");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling ordersV1AddLoyalty");
      }
      // verify the required parameter 'loyalty' is set
      if (loyalty === undefined || loyalty === null) {
        throw new Error("Missing the required parameter 'loyalty' when calling ordersV1AddLoyalty");
      }

      let pathParams = {
        'memberUId': memberUId
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json', 'text/json', 'application/xml', 'text/xml', 'application/x-www-form-urlencoded'];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = LoyaltyApplied;
      return this.apiClient.callApi(
        '/v1/orders/Members/{memberUId}/AddLoyalty', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the ordersV1AddOrder operation.
     * @callback module:api/OrdersApi~ordersV1AddOrderCallback
     * @param {String} error Error message, if any.
     * @param {module:model/OrderInfoCou} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Add Order
     * Add an Order. This represents an actual order placed by a customer.                <br /><span class=\"green\">Request Add Information:</span>            {         \"MemberUID\": Encrypted customer identification (Optional).         \"LoyaltyCardNum\": Customer device reference (Optional),           Either the \"MemberUID\" or \"LoyaltyCardNum\" properties, or both, must be populated.          \"TransactionRef\": Unique basket identifier of this order.         \"RedeemPoints\": The Number of points the customer wishes to redeem in this transaction.         \"Detail\": Customers Basket Items.         \"Coupons\": Coupons provided by the customer (Optional),           The \"Coupons\" json Property in the request is optional, when it is not provided, the \"Coupons\" Property is not            returned in the Response.       }  <br />   
     * @param {Number} storeId ID of Store
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:model/Order} order Order Object
     * @param {module:api/OrdersApi~ordersV1AddOrderCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/OrderInfoCou}
     */
    ordersV1AddOrder(storeId, clientId, authorization, order, callback) {
      let postBody = order;
      // verify the required parameter 'storeId' is set
      if (storeId === undefined || storeId === null) {
        throw new Error("Missing the required parameter 'storeId' when calling ordersV1AddOrder");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling ordersV1AddOrder");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling ordersV1AddOrder");
      }
      // verify the required parameter 'order' is set
      if (order === undefined || order === null) {
        throw new Error("Missing the required parameter 'order' when calling ordersV1AddOrder");
      }

      let pathParams = {
        'storeId': storeId
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json', 'text/json', 'application/xml', 'text/xml', 'application/x-www-form-urlencoded'];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = OrderInfoCou;
      return this.apiClient.callApi(
        '/v1/orders/stores/{storeId}/Add', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the ordersV1CancelOrder operation.
     * @callback module:api/OrdersApi~ordersV1CancelOrderCallback
     * @param {String} error Error message, if any.
     * @param {module:model/OrderStatus} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Cancel Order
     * Cancel an Order. This represents an order that has not been completed by the customer.   
     * @param {Number} orderId ID of Order
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:api/OrdersApi~ordersV1CancelOrderCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/OrderStatus}
     */
    ordersV1CancelOrder(orderId, clientId, authorization, callback) {
      let postBody = null;
      // verify the required parameter 'orderId' is set
      if (orderId === undefined || orderId === null) {
        throw new Error("Missing the required parameter 'orderId' when calling ordersV1CancelOrder");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling ordersV1CancelOrder");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling ordersV1CancelOrder");
      }

      let pathParams = {
        'orderId': orderId
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = OrderStatus;
      return this.apiClient.callApi(
        '/v1/orders/{orderId}/Cancel', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the ordersV1CompleteOrder operation.
     * @callback module:api/OrdersApi~ordersV1CompleteOrderCallback
     * @param {String} error Error message, if any.
     * @param {module:model/OrderStatus} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Complete Order
     * Complete an Order. This represents an order that has been paid for and completed by the customer.   
     * @param {Number} orderId ID of Order
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:api/OrdersApi~ordersV1CompleteOrderCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/OrderStatus}
     */
    ordersV1CompleteOrder(orderId, clientId, authorization, callback) {
      let postBody = null;
      // verify the required parameter 'orderId' is set
      if (orderId === undefined || orderId === null) {
        throw new Error("Missing the required parameter 'orderId' when calling ordersV1CompleteOrder");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling ordersV1CompleteOrder");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling ordersV1CompleteOrder");
      }

      let pathParams = {
        'orderId': orderId
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = OrderStatus;
      return this.apiClient.callApi(
        '/v1/orders/{orderId}/Complete', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the ordersV1GetOrderSalesTotal operation.
     * @callback module:api/OrdersApi~ordersV1GetOrderSalesTotalCallback
     * @param {String} error Error message, if any.
     * @param {module:model/OrderSalesTotal} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Member Sales totals
     * Member Sales totals for the period   
     * @param {String} memberUId Member Unique Identifier
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {Object} opts Optional parameters
     * @param {Number} [days] 
     * @param {module:api/OrdersApi~ordersV1GetOrderSalesTotalCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/OrderSalesTotal}
     */
    ordersV1GetOrderSalesTotal(memberUId, clientId, authorization, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'memberUId' is set
      if (memberUId === undefined || memberUId === null) {
        throw new Error("Missing the required parameter 'memberUId' when calling ordersV1GetOrderSalesTotal");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling ordersV1GetOrderSalesTotal");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling ordersV1GetOrderSalesTotal");
      }

      let pathParams = {
        'memberUId': memberUId
      };
      let queryParams = {
        'days': opts['days']
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = OrderSalesTotal;
      return this.apiClient.callApi(
        '/v1/orders/Members/{memberUId}/SalesTotal', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the ordersV1GetOrderSummary operation.
     * @callback module:api/OrdersApi~ordersV1GetOrderSummaryCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/OrdersSummary>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * List Orders
     * List of a member’s orders   
     * @param {String} memberUId Member Unique Identifier
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:api/OrdersApi~ordersV1GetOrderSummaryCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/OrdersSummary>}
     */
    ordersV1GetOrderSummary(memberUId, clientId, authorization, callback) {
      let postBody = null;
      // verify the required parameter 'memberUId' is set
      if (memberUId === undefined || memberUId === null) {
        throw new Error("Missing the required parameter 'memberUId' when calling ordersV1GetOrderSummary");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling ordersV1GetOrderSummary");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling ordersV1GetOrderSummary");
      }

      let pathParams = {
        'memberUId': memberUId
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = [OrdersSummary];
      return this.apiClient.callApi(
        '/v1/orders/Members/{memberUId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the ordersV1GetOrdersDetails operation.
     * @callback module:api/OrdersApi~ordersV1GetOrdersDetailsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/OrderDetailPagination} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * List Orders Details
     * List of a Member’s Orders Details   
     * @param {String} memberUId Member Unique Identifier
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {Object} opts Optional parameters
     * @param {Number} [days] Days (1-30) - Number of days to return basket orders
     * @param {Number} [pageSize] Max 10 Transactions
     * @param {Number} [offset] Next page identification
     * @param {module:api/OrdersApi~ordersV1GetOrdersDetailsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/OrderDetailPagination}
     */
    ordersV1GetOrdersDetails(memberUId, clientId, authorization, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'memberUId' is set
      if (memberUId === undefined || memberUId === null) {
        throw new Error("Missing the required parameter 'memberUId' when calling ordersV1GetOrdersDetails");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling ordersV1GetOrdersDetails");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling ordersV1GetOrdersDetails");
      }

      let pathParams = {
        'memberUId': memberUId
      };
      let queryParams = {
        'days': opts['days'],
        'pageSize': opts['pageSize'],
        'offset': opts['offset']
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = OrderDetailPagination;
      return this.apiClient.callApi(
        '/v1/orders/Members/{memberUId}/Details', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the ordersV1RedeemGiftCard operation.
     * @callback module:api/OrdersApi~ordersV1RedeemGiftCardCallback
     * @param {String} error Error message, if any.
     * @param {module:model/RedeemGiftResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Redeem Gift Card
     * Redeem a specified value from a gift card   
     * @param {Number} orderId ID of Order
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:model/RedeemGiftRequest} redeemGiftRequest RedeemGiftRequest Object
     * @param {module:api/OrdersApi~ordersV1RedeemGiftCardCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/RedeemGiftResponse}
     */
    ordersV1RedeemGiftCard(orderId, clientId, authorization, redeemGiftRequest, callback) {
      let postBody = redeemGiftRequest;
      // verify the required parameter 'orderId' is set
      if (orderId === undefined || orderId === null) {
        throw new Error("Missing the required parameter 'orderId' when calling ordersV1RedeemGiftCard");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling ordersV1RedeemGiftCard");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling ordersV1RedeemGiftCard");
      }
      // verify the required parameter 'redeemGiftRequest' is set
      if (redeemGiftRequest === undefined || redeemGiftRequest === null) {
        throw new Error("Missing the required parameter 'redeemGiftRequest' when calling ordersV1RedeemGiftCard");
      }

      let pathParams = {
        'orderId': orderId
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json', 'text/json', 'application/xml', 'text/xml', 'application/x-www-form-urlencoded'];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = RedeemGiftResponse;
      return this.apiClient.callApi(
        '/v1/orders/{orderId}/RedeemGift', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the ordersV1RequestCartInfo operation.
     * @callback module:api/OrdersApi~ordersV1RequestCartInfoCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CartInfoCou} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get Cart Information
     * Loyalty and offers information on a customer’s shopping cart. Cart is temporary, in that it is discarded.                <br /><span class=\"green\">Request Cart Information:</span>            {         \"MemberUID\": Encrypted customer identification  (Optional).         \"LoyaltyCardNum\": Customer device reference (Optional).           If the \"MemberUID\" and \"LoyaltyCardNum\" properties are not populated, the offer discounts will not be calculated.         \"TransactionRef\": Unique basket identifier of this order.         \"Detail\": Customers Basket Items.         \"Coupons\": Coupons provided by the customer (Optional),           The \"Coupons\" json Property in the request is optional, when it is not provided, the \"Coupons\" Property is not            returned in the Response.       }  <br />   
     * @param {Number} storeId ID of Store
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:model/RequestCart} requestCart RequestCart Object
     * @param {module:api/OrdersApi~ordersV1RequestCartInfoCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/CartInfoCou}
     */
    ordersV1RequestCartInfo(storeId, clientId, authorization, requestCart, callback) {
      let postBody = requestCart;
      // verify the required parameter 'storeId' is set
      if (storeId === undefined || storeId === null) {
        throw new Error("Missing the required parameter 'storeId' when calling ordersV1RequestCartInfo");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling ordersV1RequestCartInfo");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling ordersV1RequestCartInfo");
      }
      // verify the required parameter 'requestCart' is set
      if (requestCart === undefined || requestCart === null) {
        throw new Error("Missing the required parameter 'requestCart' when calling ordersV1RequestCartInfo");
      }

      let pathParams = {
        'storeId': storeId
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json', 'text/json', 'application/xml', 'text/xml', 'application/x-www-form-urlencoded'];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = CartInfoCou;
      return this.apiClient.callApi(
        '/v1/orders/stores/{storeId}/Cart', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
