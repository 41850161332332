const Counties = [
    {
      "countyId": 5,
      "name": "Carlow",
      "countryId": 105
    },
    {
      "countyId": 6,
      "name": "Cavan",
      "countryId": 105
    },
    {
      "countyId":7,
      "name":"Clare",
      "countryId":105
    },
    {
      "countyId":8,
      "name":"Cork",
      "countryId":105
    },
    {
      "countyId":9,
      "name":"Donegal",
      "countryId":105
    },
    {
      "countyId":10,
      "name":"Dublin",
      "countryId":105
    },
    {
      "countyId":11,
      "name":"Galway",
      "countryId":105
    },
    {
      "countyId":12,
      "name":"Kerry",
      "countryId":105
    },
    {
      "countyId":13,
      "name":"Kildare",
      "countryId":105
    },
    {
      "countyId":14,
      "name":"Kilkenny",
      "countryId":105
    },
    {
      "countyId":15,
      "name":"Laois",
      "countryId":105
    },
    {
      "countyId":16,
      "name":"Leitrim",
      "countryId":105
    },
    {
      "countyId":17,
      "name":"Limerick",
      "countryId":105
    },
    {
      "countyId":18,
      "name":"Longford",
      "countryId":105
    },
    {
      "countyId":19,
      "name":"Louth",
      "countryId":105
    },
    {
      "countyId":20,
      "name":"Mayo",
      "countryId":105
    },
    {
      "countyId":21,
      "name":"Meath",
      "countryId":105
    },
    {
      "countyId":22,
      "name":"Monaghan",
      "countryId":105
    },
    {
      "countyId":23,
      "name":"Offaly",
      "countryId":105
    },
    {
      "countyId":24,
      "name":"Roscommon",
      "countryId":105
    },
    {
      "countyId":25,
      "name":"Sligo",
      "countryId":105
    },
    {
      "countyId":26,
      "name":"Tipperary",
      "countryId":105
    },
    {
      "countyId":27,
      "name":"Waterford",
      "countryId":105
    },
    {
      "countyId":28,
      "name":"Westmeath",
      "countryId":105
    },
    {
      "countyId":29,
      "name":"Wexford",
      "countryId":105
    },
    {
      "countyId":30,
      "name":"Wicklow",
      "countryId":105
    },
    {
      "countyId":31,
      "name":"Fermanagh",
      "countryId":229
    },
    {
      "countyId":32,
      "name":"Antrim",
      "countryId":229
    },
    {
      "countyId":33,
      "name":"Down",
      "countryId":229
    },
    {
      "countyId":34,
      "name":"Armagh",
      "countryId":229
    },
    {
      "countyId":35,
      "name":"Tyrone",
      "countryId":229
    },
    {
      "countyId":36,
      "name":"Derry",
      "countryId":229
    },
    {
      "countyId":37,
      "name":"Cork City",
      "countryId":105
    },
    {
      "countyId":38,
      "name":"Dublin 1",
      "countryId":105
    },
    {
      "countyId":39,
      "name":"Dublin 2",
      "countryId":105
    },
    {
      "countyId":40,
      "name":"Dublin 3",
      "countryId":105
    },
    {
      "countyId":41,
      "name":"Dublin 4",
      "countryId":105
    },
    {
      "countyId":42,
      "name":"Dublin 5",
      "countryId":105
    },
    {
      "countyId":43,
      "name":"Dublin 6",
      "countryId":105
    },
    {
      "countyId":44,
      "name":"Dublin 6W",
      "countryId":105
    },
    {
      "countyId":45,
      "name":"Dublin 7",
      "countryId":105
    },
    {
      "countyId":46,
      "name":"Dublin 8",
      "countryId":105
    },
    {
      "countyId":47,
      "name":"Dublin 9",
      "countryId":105
    },
    {
      "countyId":48,
      "name":"Dublin 10",
      "countryId":105
    },
    {
      "countyId":49,
      "name":"Dublin 11",
      "countryId":105
    },
    {
      "countyId":50,
      "name":"Dublin 12",
      "countryId":105
    },
    {
      "countyId":51,
      "name":"Dublin 13",
      "countryId":105
    },
    {
      "countyId":52,
      "name":"Dublin 14",
      "countryId":105
    },
    {
      "countyId":53,
      "name":"Dublin 15",
      "countryId":105
    },
    {
      "countyId":54,
      "name":"Dublin 16",
      "countryId":105
    },
    {
      "countyId":55,
      "name":"Dublin 17",
      "countryId":105
    },
    {
      "countyId":56,
      "name":"Dublin 18",
      "countryId":105
    },
    {
      "countyId":57,
      "name":"Dublin 19",
      "countryId":105
    },
    {
      "countyId":58,
      "name":"Dublin 20",
      "countryId":105
    },
    {
      "countyId":59,
      "name":"Dublin 21",
      "countryId":105
    },
    {
      "countyId":60,
      "name":"Dublin 22",
      "countryId":105
    },
    {
      "countyId":61,
      "name":"Dublin 24",
      "countryId":105
    }
]

export default Counties;