import React from 'react';
import CKText from './CKText';
import View from './View';

const CKMenuPanel = (props) => {

    const className = "ck-menu-panel" + " " + (props.round ? 'round' : '') + " " + (props.className ?? "")

    return <View className={className} style={{
        ...props.style,
        ...(props.borderBottom !==null && props.borderBottom >= 0 ? {
            borderBottomWidth: props.borderBottom
        } : {})
    }}>
        {props.title ?
            <CKText className="title">{props.title}</CKText>
            : null 
        }
        {props.description ?
            <CKText className="description">{props.description}</CKText>
            :
            null
        }

        {props.children}
    </View>
}

export default CKMenuPanel;