/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The OfferReserveResponse model module.
 * @module model/OfferReserveResponse
 * @version v1
 */
class OfferReserveResponse {
    /**
     * Constructs a new <code>OfferReserveResponse</code>.
     * @alias module:model/OfferReserveResponse
     */
    constructor() { 
        
        OfferReserveResponse.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>OfferReserveResponse</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/OfferReserveResponse} obj Optional instance to populate.
     * @return {module:model/OfferReserveResponse} The populated <code>OfferReserveResponse</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new OfferReserveResponse();

            if (data.hasOwnProperty('MessageHeader')) {
                obj['MessageHeader'] = ApiClient.convertToType(data['MessageHeader'], 'String');
            }
            if (data.hasOwnProperty('MessageDetail')) {
                obj['MessageDetail'] = ApiClient.convertToType(data['MessageDetail'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>OfferReserveResponse</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>OfferReserveResponse</code>.
     */
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['MessageHeader'] && !(typeof data['MessageHeader'] === 'string' || data['MessageHeader'] instanceof String)) {
            throw new Error("Expected the field `MessageHeader` to be a primitive type in the JSON string but got " + data['MessageHeader']);
        }
        // ensure the json data is a string
        if (data['MessageDetail'] && !(typeof data['MessageDetail'] === 'string' || data['MessageDetail'] instanceof String)) {
            throw new Error("Expected the field `MessageDetail` to be a primitive type in the JSON string but got " + data['MessageDetail']);
        }

        return true;
    }


}



/**
 * MaxLength:500
 * @member {String} MessageHeader
 */
OfferReserveResponse.prototype['MessageHeader'] = undefined;

/**
 * MaxLength:8000
 * @member {String} MessageDetail
 */
OfferReserveResponse.prototype['MessageDetail'] = undefined;






export default OfferReserveResponse;

