import CommsLayer from "../lib/api/CommsLayer";
import AuthBase from "../lib/AuthBase";
import CKNavigator from "../lib/CKNavigator";
import Alert from "../lib/components/Alert";
import CKButton from "../lib/components/CKButton";
import CKIcon, { CKIconType } from "../lib/components/CKIcon";
import CKLoading from "../lib/components/CKLoading";
import CKSidebar from "../lib/components/CKSidebar";
import CKText from "../lib/components/CKText";
import View from "../lib/components/View";
import { CKPadding } from '../lib/Styles';

export default class LoggedInBase extends AuthBase {
    screenName = ''
    title = ''
    subLevelPage = false

    constructor(props) {
        super(props);

        this.state = {
            ...this.state
        }
    }

    loadData() {

    }

    showScreenLoading() {
        this.setState({
            screenLoading: true
        })
    }

    hideScreenLoading() {
        this.setState({
            screenLoading: false
        }, this.hideLoading);
    }

    componentDidMount() {
        super.componentDidMount();

        let refresh_token = this.getRefreshToken();

        CommsLayer.uiLogout = () => {
            Alert.alert("Notification!", "Your session has timed out. Please login again!");
            this.navigateTo('/login');
        }

        if(refresh_token) {
            // lets check if access token acquired or not
            if(CommsLayer.getToken() && !CommsLayer.getToken().access_token) {
                this.showLoading();

                // lets refresh token
                CommsLayer.refreshToken(refresh_token).then(() => {
                    this.loadData();
                }).catch(() => {
                    this.navigateTo(this.redirectTo ? this.redirectTo : '/login');
                })
            } else {
                this.loadData();
            }
        } else {
            this.navigateTo(this.redirectTo ? this.redirectTo : '/login');
        }
    }

    refreshInbox = (forceUpdate = false) => {
        return CommsLayer.communications().communicationsV1GetMemberMessages(forceUpdate).then((data) => {
            //EventRegister.emit(CKLoggedInHeaderOptionsEvents.GET_COMMUNICATIONS, data);

            return data;
        });
    }

    render(children) {
        return super.render(
            <div style={{
                textAlign: 'center'
            }}>
                <div className='main'>
                    <div className="main-app-holder">
                        <CKSidebar onPress={(screen) => {
                            if(this.screenName !==screen || this.subLevelPage) {
                                this.navigateTo(CKNavigator[screen]);
                            }
                        }} selected={this.screenName} />

                        <div style={{
                            flex: 1
                        }} className='content'>
                            {this.title ? <div className="hide-on-mobile logged-in-header">
                                {this.subLevelPage ? <CKButton hover_style={{
                                    opacity: .75
                                }} default_style={{
                                    position: 'absolute',
                                    left: CKPadding.default,
                                    top: 8
                                }} custom onPress={() => {
                                    if(this.onBack) this.onBack();
                                }} customChildren={() => {
                                    return <CKIcon icon={CKIconType.back} width={24} height={24} />
                                }} /> : null}
                                <CKText className='header-title'>{this.title}</CKText>
                                {this.rightNavBar ? <View className="right-bar">{this.rightNavBar(this.state)}</View> : null}
                            </div> : null}
                            {this.state.screenLoading ? <div className="screen-loading-holder">
                                    <div className="loading-holder">
                                        <CKLoading red width={67} text={'Loading...'} />
                                    </div>
                                </div>
                                :
                                children
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}