import CKNavigator from "../lib/CKNavigator";
import CKMenuItem from "../lib/components/CKMenuItem";
import CKMenuPanel from "../lib/components/CKMenuPanel";
import CKSpacer from "../lib/components/CKSpacer";
import View from "../lib/components/View";
import LoggedInBase from "./LoggedInBase";

export default class MoreMyAccount extends LoggedInBase {
    screenName = 'more'
    title = 'My Account'
    subLevelPage = true

    constructor(props) {
        super(props);

        this.state = {
            ...this.state
        }
    }

    onBack() {
        this.navigateTo(CKNavigator.more);
    }

    loadData() {
        this.hideLoading();
    }

    componentDidMount() {
        super.componentDidMount();
    }

    render() {
        return super.render(
            <View className="main-container">
                <CKMenuPanel>
                    <CKMenuItem titleStyle={{
                        
                     }} full_border_off onPress={() => {
                        this.navigateTo(CKNavigator.moreTransactions)
                    }} title='Transactions' />
                    <CKMenuItem titleStyle={{
                        
                     }} full_border_off onPress={() => {
                        this.navigateTo(CKNavigator.moreProfile)
                    }} border={1} title='Profile' />
                </CKMenuPanel>

                <CKSpacer />
            </View>
        )
    }
}