import { Component } from "react";
import { ReactComponent as LoadingW } from '../../images/loading_w.svg';
import { ReactComponent as Loading } from '../../images/loading.svg';


export default class CKLoading extends Component {
    render() {
        //65.106x67.457
        let w = 65.106;
        let h = 67.457;

        let width = this.props.width ?? 30;
        let height = width / w * h;

        let style = {
            width: width,
            height: height,
            animation: `spin 3s linear infinite`,
            ...this.props.style
        }

        return <div style={{
            textAlign: 'center'
        }}>
            {this.props.red ? <Loading style={style} /> : <LoadingW style={style} />}
            {this.props.text ? <div style={{
                textAlign: 'center',
                marginTop: 20,
                fontSize: 12
            }}>{this.props.text}</div> : null}
        </div>
    }
}