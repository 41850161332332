const CKNavigator = {
    home: '/',
    compareLevels: '/compare-levels',
    offerDetails: '/offer/:id',
    exclusiveMemberOffers: '/exclusive-member',
    inbox: '/inbox',
    more: '/more',
    moreMyAccount: '/more/account',
    moreTransactions: '/more/account/transactions',
    moreProfile: '/more/account/profile',
    changePassword: '/more/account/profile/change-password',
    moreProfileEdit: '/more/account/profile/edit',
    moreProfileDelete: '/more/account/profile/delete',
    helpAndSupport: '/more/help',
    howItWorks: '/more/help/how-it-works',
    faq: '/more/help/faq',
    login: '/login',
    forgot: '/login/forgot',
    signup: '/login/signup',
    fuelCard: '/login/fuelcard',
    fuelCardTransfer: '/login/fuelcard/transfer',
    fuelCardSuccess: '/login/fuelcard/success',
    fuelCardSignup: '/login/fuelcard/signup',
    fuelCardSignupForgot: '/login/fuelcard/signup/forgot',
}

export const CKNavigatorStorage = {
    _offer: false,
    _type: '',
    _memberOffers: false,

    setMemberOffers: (offers) => {
        CKNavigatorStorage._memberOffers = offers;
    },
    
    getMemberOffers: () => {
        return CKNavigatorStorage._memberOffers;
    },

    set: (offer, type) => { 
        CKNavigatorStorage._offer = offer;
        CKNavigatorStorage._type = type;
    },

    get: () => {
        return CKNavigatorStorage._offer ? {
            offer: CKNavigatorStorage._offer,
            type: CKNavigatorStorage._type
        } : false
    }
}

export default CKNavigator;