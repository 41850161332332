import React, { useState } from 'react';
import Styles, { CKColors, CKPadding, CKSizes } from '../../lib/Styles';
import {ReactComponent as OfferHot} from '../../images/offer_hot.svg';
import {ReactComponent as OfferCold} from '../../images/offer_cold.svg';
import {ReactComponent as OfferWash} from '../../images/offer_wash.svg';
import {ReactComponent as OfferCrisps} from '../../images/offer_crisps.svg';
import {ReactComponent as OfferFood} from '../../images/offer_food.svg';
import {ReactComponent as OfferFuel} from '../../images/offer_fuel.svg';
import {ReactComponent as OfferSurprise} from '../../images/offer_suprise.svg';
import {ReactComponent as OfferExclusive} from '../../images/offer_exclusive.svg';
import CKButton from './CKButton';
import CKIcon, { CKIconType } from './CKIcon';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const CKOffer = (props) => {
    let percentageComplete = props.max_value >= 1 ? props.current_value * 100 / props.max_value : 100;

    let leftPercentageComplete = percentageComplete * 2;
    if(leftPercentageComplete > 100) leftPercentageComplete = 100;

    // lets calculate number of separators
    let separators = [];
    for(var i = 1; i <= props.max_value && props.max_value > 1; i++) {
        let step = 360 / props.max_value;
        let s = 180 + (i - 1) * step;
        if(s > 360) s -= 360;

        separators.push(s);
    }

    const [pressedIn, setPressedIn] = useState(false);

    const renderIcon = (type, scale = false) => {
        if(type ==='hot') return <OfferHot className={scale ? 'scale-down' : ''} />;
        if(type ==='cold') return <OfferCold className={scale ? 'scale-down' : ''}  />;
        if(type ==='crisps') return <OfferCrisps className={scale ? 'scale-down' : ''} />;
        if(type ==='food') return <OfferFood className={scale ? 'scale-down' : ''} />;
        if(type ==='wash') return <OfferWash className={scale ? 'scale-down' : ''} />;
        if(type ==='fuel') return <OfferFuel className={scale ? 'scale-down' : ''} />;
        if(type ==='surprise') return <OfferSurprise className={scale ? 'scale-down' : ''} />;
        if(type ==='offers') return <OfferExclusive className={scale ? 'scale-down' : ''} />;

        return null;
    }

    const renderProgress = (renderContainer = true) => {
        return <div className={'offer-container-progress ' + (renderContainer ? '' : 'offer-containerless')}>
            <div className='inner-holder'>
                <div className='inner-bg' />
                <div style={{
                    transform: 'rotate(180deg)'
                }}>
                    <CircularProgressbar styles={buildStyles({
                        pathColor: CKColors.red,
                        strokeLinecap: 'butt'
                    })} value={percentageComplete / 100.0} maxValue={1} />
                </div>
                {separators.map((s, i) => {
                    return <div className='progress-separator' key={'separator_' + i} style={{
                        transform: 'rotate(' + s + 'deg)'
                    }}>
                        <div className='progress-separator2'></div>
                    </div>
                })}
                <div className='offer-icon-bg' />
                <div className='offer-icon'>
                    {renderIcon(props.type)}
                </div>
            </div>
        </div>
    }

    if(props.iconOnly) {
        return renderIcon(props.type, props.scaleIcon);
    }

    if(props.onlyProgress) {
        return renderProgress(false);
    }

    return <div className='offer-container' style={{
        marginTop: props.marginTop ?? 0
    }}>
        <CKButton custom disabled={props.onPress ? false : true} onPress={props.onPress}
            onPressIn={() => setPressedIn(true)}
            onPressOut={() => setPressedIn(false)}
            default_style={{
                width: '100%',
                padding: 0,
                display: 'flex'
            }}
        >
            <div style={{
                display: 'flex',
                flex: 1
            }}>
                {renderProgress()}

                <div className='inner' style={{
                    backgroundColor: !pressedIn && props.redeem ? CKColors.red : (pressedIn ? (props.redeem ? CKColors.red2 : CKColors.offWhite) : CKColors.white)
                }}>
                    {props.title ? <div className='title' style={{
                        color: props.redeem ? CKColors.white : CKColors.offBlack
                    }}>
                        {props.title}
                    </div> : null}
                    {props.description ? <div className='description' style={{
                        color: props.redeem ? CKColors.white : CKColors.anotherGrayColor,
                        marginTop: props.title ? CKPadding.default / 4 : 0
                    }}>
                        {props.description}
                    </div> : null }

                    {props.onPress ? <CKButton 
                        no_button
                        custom
                        onPressIn={() => setPressedIn(true)}
                        onPressOut={() => setPressedIn(false)} 
                        default_style={{
                            marginTop: CKPadding.default / 4,
                            padding: 0
                        }}>
                        <div style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            display: 'flex'
                        }}>
                            <CKButton no_button reverseUnderline style={{
                                textTransform: 'uppercase',
                                fontSize: CKSizes.font.tiny,
                                color: props.redeem ? CKColors.white : CKColors.red,
                                ...Styles.gothamMedium
                            }} link>
                                {props.customViewText ?? 'View Reward'}
                            </CKButton>
                            
                            <CKIcon style={{
                                marginBottom: 0
                            }} width={24} height={24} icon={props.redeem ? CKIconType.chevron_white : CKIconType.chevron_red} />
                        </div>
                    </CKButton> : null}
                </div>
            </div>
        </CKButton>
    </div>;
}

export default CKOffer;