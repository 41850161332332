import { Component } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Forgot from "./pages/Forgot";
import CKNavigator from "./lib/CKNavigator";
import PageNotFound from "./pages/PageNotFound";
import Signup from "./pages/Signup";
import Inbox from "./pages/Inbox";
import More from "./pages/More";
import InboxSingle from "./pages/InboxSingle";
import MoreMyAccount from "./pages/MoreMyAccount";
import MoreTransactions from "./pages/MoreTransactions";
import MoreProfile from "./pages/MoreProfile";
import HelpAndSupport from "./pages/HelpAndSupport";
import MoreHowItWorks from "./pages/MoreHowItWorks";
import FAQs from "./pages/FAQs";
import ChangePassword from "./pages/ChangePassword";
import MoreProfileEdit from "./pages/MoreProfileEdit";
import DeleteAccount from "./pages/DeleteAccount";
import ViewMembershipLevels from "./pages/ViewMembershipLevels";
import OfferDetails from "./pages/OfferDetails";
import ExclusiveMemberOffers from "./pages/ExclusiveMemberOffers";
import FuelCard from "./pages/FuelCard";
import FuelCardTransfer from "./pages/FuelCardTransfer";
import FuelCardSuccess from "./pages/FuelCardSuccess";

export default class CKRouter extends Component {
    constructor(props){
        super(props);

        this.state = {
            
        }
    }

    render() {
        return (
            <Routes>
                <Route exact path={CKNavigator.home} element={<Home />} />
                <Route exact path={CKNavigator.compareLevels} element={<ViewMembershipLevels />} />
                <Route exact path={CKNavigator.offerDetails} element={<OfferDetails />} />
                <Route exact path={CKNavigator.exclusiveMemberOffers} element={<ExclusiveMemberOffers />} />
                
                <Route exact path={CKNavigator.inbox} element={<Inbox />} />
                <Route exact path={CKNavigator.inbox + "/:id"} element={<InboxSingle />} />

                <Route exact path={CKNavigator.more} element={<More />} />
                <Route exact path={CKNavigator.moreMyAccount} element={<MoreMyAccount />} />
                <Route exact path={CKNavigator.moreTransactions} element={<MoreTransactions />} />
                <Route exact path={CKNavigator.moreProfile} element={<MoreProfile />} />
                <Route exact path={CKNavigator.moreProfileEdit} element={<MoreProfileEdit />} />
                <Route exact path={CKNavigator.changePassword} element={<ChangePassword />} />
                <Route exact path={CKNavigator.moreProfileDelete} element={<DeleteAccount />} />

                <Route exact path={CKNavigator.helpAndSupport} element={<HelpAndSupport />} />
                <Route exact path={CKNavigator.howItWorks} element={<MoreHowItWorks />} />
                <Route exact path={CKNavigator.faq} element={<FAQs />} />

                <Route exact path={CKNavigator.login} element={<Login />} />
                <Route exact path={CKNavigator.forgot} element={<Forgot type="normal"/>} />
                <Route exact path={CKNavigator.signup} element={<Signup type="normal" />} />
                <Route exact path={CKNavigator.fuelCard} element={<FuelCard />} />
                <Route exact path={CKNavigator.fuelCardTransfer} element={<FuelCardTransfer />} />
                <Route exact path={CKNavigator.fuelCardSignup} element={<Signup type="fuelcard" />} />
                <Route exact path={CKNavigator.fuelCardSignupForgot} element={<Forgot type="fuelcard"/>} />
                <Route exact path={CKNavigator.fuelCardSuccess} element={<FuelCardSuccess />} />
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        )
    }
}