/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The FreeItemsAvailable model module.
 * @module model/FreeItemsAvailable
 * @version v1
 */
class FreeItemsAvailable {
    /**
     * Constructs a new <code>FreeItemsAvailable</code>.
     * @alias module:model/FreeItemsAvailable
     * @param freeItems {Number} 
     */
    constructor(freeItems) { 
        
        FreeItemsAvailable.initialize(this, freeItems);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, freeItems) { 
        obj['FreeItems'] = freeItems;
    }

    /**
     * Constructs a <code>FreeItemsAvailable</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/FreeItemsAvailable} obj Optional instance to populate.
     * @return {module:model/FreeItemsAvailable} The populated <code>FreeItemsAvailable</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new FreeItemsAvailable();

            if (data.hasOwnProperty('FreeItems')) {
                obj['FreeItems'] = ApiClient.convertToType(data['FreeItems'], 'Number');
            }
            if (data.hasOwnProperty('ExpiryDates')) {
                obj['ExpiryDates'] = ApiClient.convertToType(data['ExpiryDates'], ['String']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>FreeItemsAvailable</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>FreeItemsAvailable</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of FreeItemsAvailable.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is an array
        if (!Array.isArray(data['ExpiryDates'])) {
            throw new Error("Expected the field `ExpiryDates` to be an array in the JSON data but got " + data['ExpiryDates']);
        }

        return true;
    }


}

FreeItemsAvailable.RequiredProperties = ["FreeItems"];

/**
 * @member {Number} FreeItems
 */
FreeItemsAvailable.prototype['FreeItems'] = undefined;

/**
 * Format [yyyy-MM-dd HH:mm:ss] UTC
 * @member {Array.<String>} ExpiryDates
 */
FreeItemsAvailable.prototype['ExpiryDates'] = undefined;






export default FreeItemsAvailable;

