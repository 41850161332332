/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import APIOKResponse from '../model/APIOKResponse';
import APIResponse from '../model/APIResponse';
import IdentifyResponse from '../model/IdentifyResponse';
import Identity from '../model/Identity';
import MemberIdentity from '../model/MemberIdentity';
import MemberInfo from '../model/MemberInfo';
import MemberPassword from '../model/MemberPassword';
import MemberRegister from '../model/MemberRegister';
import MemberRegisterResponse from '../model/MemberRegisterResponse';
import MemberSummary from '../model/MemberSummary';
import MemberUpdatePatch from '../model/MemberUpdatePatch';
import MemberUsername from '../model/MemberUsername';
import ResetPassword from '../model/ResetPassword';
import UpdatePassword from '../model/UpdatePassword';

/**
* Members service.
* @module api/MembersApi
* @version v1
*/
export default class MembersApi {

    /**
    * Constructs a new MembersApi. 
    * @alias module:api/MembersApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the membersV1ForgotPassword operation.
     * @callback module:api/MembersApi~membersV1ForgotPasswordCallback
     * @param {String} error Error message, if any.
     * @param {module:model/APIOKResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Add Forgot Password
     * Add a forgotten password email to be sent to a specific email address   
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:model/MemberUsername} username Username
     * @param {module:api/MembersApi~membersV1ForgotPasswordCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/APIOKResponse}
     */
    membersV1ForgotPassword(clientId, authorization, username, callback) {
      let postBody = username;
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling membersV1ForgotPassword");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling membersV1ForgotPassword");
      }
      // verify the required parameter 'username' is set
      if (username === undefined || username === null) {
        throw new Error("Missing the required parameter 'username' when calling membersV1ForgotPassword");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json', 'text/json', 'application/xml', 'text/xml', 'application/x-www-form-urlencoded'];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = APIOKResponse;
      return this.apiClient.callApi(
        '/v1/members/ForgotPassword', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the membersV1GetMemberInfo operation.
     * @callback module:api/MembersApi~membersV1GetMemberInfoCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MemberInfo} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get Member Information
     * Get information for a member   
     * @param {String} memberUId Member Unique Identifier
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:api/MembersApi~membersV1GetMemberInfoCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/MemberInfo}
     */
    membersV1GetMemberInfo(memberUId, clientId, authorization, callback) {
      let postBody = null;
      // verify the required parameter 'memberUId' is set
      if (memberUId === undefined || memberUId === null) {
        throw new Error("Missing the required parameter 'memberUId' when calling membersV1GetMemberInfo");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling membersV1GetMemberInfo");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling membersV1GetMemberInfo");
      }

      let pathParams = {
        'memberUId': memberUId
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = MemberInfo;
      return this.apiClient.callApi(
        '/v1/members/{memberUId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the membersV1GetMemberSummary operation.
     * @callback module:api/MembersApi~membersV1GetMemberSummaryCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MemberSummary} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get Member Summary
     * Get summary information for a member  <br />  Set the parameter ‘VirtualOn’ to false unless otherwise advised by your PDI Technologies Integrator.   
     * @param {String} memberUId Member Unique Identifier
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {Object} opts Optional parameters
     * @param {String} [deviceTypeIds] Leave blank for all.    DeviceTypeIds:    1 - Loyalty Device (Spend Home Store)    2 - Loyalty Device(Spend Linked Stores)    5 - Loyalty Device (No Spend)
     * @param {String} [virtualOn] Set to ‘False’ as default value
     * @param {module:api/MembersApi~membersV1GetMemberSummaryCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/MemberSummary}
     */
    membersV1GetMemberSummary(memberUId, clientId, authorization, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'memberUId' is set
      if (memberUId === undefined || memberUId === null) {
        throw new Error("Missing the required parameter 'memberUId' when calling membersV1GetMemberSummary");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling membersV1GetMemberSummary");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling membersV1GetMemberSummary");
      }

      let pathParams = {
        'memberUId': memberUId
      };
      let queryParams = {
        'deviceTypeIds': opts['deviceTypeIds'],
        'virtualOn': opts['virtualOn']
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = MemberSummary;
      return this.apiClient.callApi(
        '/v1/members/{memberUId}/Summary', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the membersV1IdentifyMember operation.
     * @callback module:api/MembersApi~membersV1IdentifyMemberCallback
     * @param {String} error Error message, if any.
     * @param {module:model/IdentifyResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get Member Identify
     * Identifies a member based on the card number and email address and/or mobile phone number. <br />  MobileNum must be of the E.164 numbers format [+] [country code] [subscriber number including area code] and can have a maximum of fifteen digits.   
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:model/Identity} identity Identity Object
     * @param {module:api/MembersApi~membersV1IdentifyMemberCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/IdentifyResponse}
     */
    membersV1IdentifyMember(clientId, authorization, identity, callback) {
      let postBody = identity;
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling membersV1IdentifyMember");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling membersV1IdentifyMember");
      }
      // verify the required parameter 'identity' is set
      if (identity === undefined || identity === null) {
        throw new Error("Missing the required parameter 'identity' when calling membersV1IdentifyMember");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json', 'text/json', 'application/xml', 'text/xml', 'application/x-www-form-urlencoded'];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = IdentifyResponse;
      return this.apiClient.callApi(
        '/v1/members/Identify', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the membersV1MemberAccountDeletion operation.
     * @callback module:api/MembersApi~membersV1MemberAccountDeletionCallback
     * @param {String} error Error message, if any.
     * @param {module:model/APIOKResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Delete Account
     * Delete an account from the system   
     * @param {String} memberUId Member Unique Identifier
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:model/MemberPassword} memberPassword Member Password
     * @param {module:api/MembersApi~membersV1MemberAccountDeletionCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/APIOKResponse}
     */
    membersV1MemberAccountDeletion(memberUId, clientId, authorization, memberPassword, callback) {
      let postBody = memberPassword;
      // verify the required parameter 'memberUId' is set
      if (memberUId === undefined || memberUId === null) {
        throw new Error("Missing the required parameter 'memberUId' when calling membersV1MemberAccountDeletion");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling membersV1MemberAccountDeletion");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling membersV1MemberAccountDeletion");
      }
      // verify the required parameter 'memberPassword' is set
      if (memberPassword === undefined || memberPassword === null) {
        throw new Error("Missing the required parameter 'memberPassword' when calling membersV1MemberAccountDeletion");
      }

      let pathParams = {
        'memberUId': memberUId
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json', 'text/json', 'application/xml', 'text/xml', 'application/x-www-form-urlencoded'];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = APIOKResponse;
      return this.apiClient.callApi(
        '/v1/members/{memberUId}/DeleteAccount', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the membersV1RegisterMember operation.
     * @callback module:api/MembersApi~membersV1RegisterMemberCallback
     * @param {String} error Error message, if any.
     * @param {module:model/MemberRegisterResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Add Member
     * Add a member.  <br /> MobileNum must be of the E.164 numbers format [+] [country code] [subscriber number including area code] and can have a maximum of fifteen digits. MobileNumCountryId is not required if this format is followed.                <br /><span class=\"green\">Request Member Object:</span>            {         \"StoreId\": ID of store if specified, 0 for homestore registration,         \"VerificationCode\": if required is sent as part of POST /v1/partners/VerificationCode,         \"IsPaperRegistration\": set to true to complete an online login         \"LoyaltyCard and Password\": are required values when IsPaperRegistration is true          \"Firsname, LastName and RegistrationType\": are required values when IsPaperRegistration is false      }  <br /><span class=\"green\">Lookup Tables for the following are listed here:</span><br /><a href=\"../../v1/Helpers/Lookups/County/\">County</a>,   <a href=\"../../v1/Helpers/Lookups/Country/\">Country</a>,   <a href=\"../../v1/Helpers/Lookups/Gender/\">Gender</a>,   <a href=\"../../v1/Helpers/Lookups/Language/\">Language</a>,   <a href=\"../../v1/Helpers/Lookups/Title/\">Title</a>,   <a href=\"../../v1/Helpers/Lookups/Nationality/\">Nationality</a>,   <a href=\"../../v1/Helpers/Lookups/RegistrationType/\">RegistrationType</a>   
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:model/MemberRegister} member See Member Object and Lookup Tables above
     * @param {module:api/MembersApi~membersV1RegisterMemberCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/MemberRegisterResponse}
     */
    membersV1RegisterMember(clientId, authorization, member, callback) {
      let postBody = member;
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling membersV1RegisterMember");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling membersV1RegisterMember");
      }
      // verify the required parameter 'member' is set
      if (member === undefined || member === null) {
        throw new Error("Missing the required parameter 'member' when calling membersV1RegisterMember");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json', 'text/json', 'application/xml', 'text/xml', 'application/x-www-form-urlencoded'];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = MemberRegisterResponse;
      return this.apiClient.callApi(
        '/v1/members', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the membersV1ResetPassword operation.
     * @callback module:api/MembersApi~membersV1ResetPasswordCallback
     * @param {String} error Error message, if any.
     * @param {module:model/APIOKResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Add Reset Password
     * Reset the password for the specified username   
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:model/ResetPassword} resetPassword Reset Password Object    Include the ‘uniqueidentifier’ sent in the forgotten password email.
     * @param {module:api/MembersApi~membersV1ResetPasswordCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/APIOKResponse}
     */
    membersV1ResetPassword(clientId, authorization, resetPassword, callback) {
      let postBody = resetPassword;
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling membersV1ResetPassword");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling membersV1ResetPassword");
      }
      // verify the required parameter 'resetPassword' is set
      if (resetPassword === undefined || resetPassword === null) {
        throw new Error("Missing the required parameter 'resetPassword' when calling membersV1ResetPassword");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json', 'text/json', 'application/xml', 'text/xml', 'application/x-www-form-urlencoded'];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = APIOKResponse;
      return this.apiClient.callApi(
        '/v1/members/ResetPassword', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the membersV1UpdateMemberPatch operation.
     * @callback module:api/MembersApi~membersV1UpdateMemberPatchCallback
     * @param {String} error Error message, if any.
     * @param {module:model/APIOKResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Update Member Information
     * Update some member information (One or more properties).  <br />Updating the email field requires the existing password to be sent.  <br />Mobile phone number must be of the E.164 numbers format [+] [country code] [subscriber number including area code] and can have a maximum of fifteen digits.    <br /><span class=\"orange\">Lookup Tables for the following are listed here:</span><br /><a href=\"../../v1/Helpers/Lookups/County/\">County</a>,   <a href=\"../../v1/Helpers/Lookups/Country/\">Country</a>,   <a href=\"../../v1/Helpers/Lookups/Gender/\">Gender</a>,   <a href=\"../../v1/Helpers/Lookups/Language/\">Language</a>,   <a href=\"../../v1/Helpers/Lookups/Title/\">Title</a>,   <a href=\"../../v1/Helpers/Lookups/Nationality/\">Nationality</a>   
     * @param {String} memberUId Member Unique Identifier
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:model/MemberUpdatePatch} member Member Object
     * @param {module:api/MembersApi~membersV1UpdateMemberPatchCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/APIOKResponse}
     */
    membersV1UpdateMemberPatch(memberUId, clientId, authorization, member, callback) {
      let postBody = member;
      // verify the required parameter 'memberUId' is set
      if (memberUId === undefined || memberUId === null) {
        throw new Error("Missing the required parameter 'memberUId' when calling membersV1UpdateMemberPatch");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling membersV1UpdateMemberPatch");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling membersV1UpdateMemberPatch");
      }
      // verify the required parameter 'member' is set
      if (member === undefined || member === null) {
        throw new Error("Missing the required parameter 'member' when calling membersV1UpdateMemberPatch");
      }

      let pathParams = {
        'memberUId': memberUId
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json', 'text/json', 'application/xml', 'text/xml', 'application/x-www-form-urlencoded'];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = APIOKResponse;
      return this.apiClient.callApi(
        '/v1/members/{memberUId}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the membersV1UpdatePassword operation.
     * @callback module:api/MembersApi~membersV1UpdatePasswordCallback
     * @param {String} error Error message, if any.
     * @param {module:model/APIOKResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Update Password
     * Update password for a member   
     * @param {String} memberUId Member Unique Identifier
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:model/UpdatePassword} updatePassword Update Password Object
     * @param {module:api/MembersApi~membersV1UpdatePasswordCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/APIOKResponse}
     */
    membersV1UpdatePassword(memberUId, clientId, authorization, updatePassword, callback) {
      let postBody = updatePassword;
      // verify the required parameter 'memberUId' is set
      if (memberUId === undefined || memberUId === null) {
        throw new Error("Missing the required parameter 'memberUId' when calling membersV1UpdatePassword");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling membersV1UpdatePassword");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling membersV1UpdatePassword");
      }
      // verify the required parameter 'updatePassword' is set
      if (updatePassword === undefined || updatePassword === null) {
        throw new Error("Missing the required parameter 'updatePassword' when calling membersV1UpdatePassword");
      }

      let pathParams = {
        'memberUId': memberUId
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json', 'text/json', 'application/xml', 'text/xml', 'application/x-www-form-urlencoded'];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = APIOKResponse;
      return this.apiClient.callApi(
        '/v1/members/{memberUId}/UpdatePassword', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the membersV1ValidateMemberIdentity operation.
     * @callback module:api/MembersApi~membersV1ValidateMemberIdentityCallback
     * @param {String} error Error message, if any.
     * @param {module:model/APIOKResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Validate Member Identity
     *  Validate identity columns for registration purposes. <br />   Mobile phone number must be of the E.164 numbers format [+] [country code] [subscriber number including area code] and can have a maximum of fifteen digits.     <br /><span class=\"green\">Request Identity Object:</span>            {         \"IdentityText\": Email address, Card number or Mobile phone number,         \"IdentityTypeId\": 1 - Email address, 2 - Card Number, 3 - Mobile phone number      }   
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:model/MemberIdentity} identity See Identity Object above
     * @param {module:api/MembersApi~membersV1ValidateMemberIdentityCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/APIOKResponse}
     */
    membersV1ValidateMemberIdentity(clientId, authorization, identity, callback) {
      let postBody = identity;
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling membersV1ValidateMemberIdentity");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling membersV1ValidateMemberIdentity");
      }
      // verify the required parameter 'identity' is set
      if (identity === undefined || identity === null) {
        throw new Error("Missing the required parameter 'identity' when calling membersV1ValidateMemberIdentity");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json', 'text/json', 'application/xml', 'text/xml', 'application/x-www-form-urlencoded'];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = APIOKResponse;
      return this.apiClient.callApi(
        '/v1/members/Validate', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
