/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The SocialProfileIdentity model module.
 * @module model/SocialProfileIdentity
 * @version v1
 */
class SocialProfileIdentity {
    /**
     * Constructs a new <code>SocialProfileIdentity</code>.
     * @alias module:model/SocialProfileIdentity
     * @param memberUId {String} MaxLength:100
     * @param accessToken {String} MaxLength:500
     * @param modifiedDate {String} Format [yyyy-MM-dd HH:mm:ss] UTC
     */
    constructor(memberUId, accessToken, modifiedDate) { 
        
        SocialProfileIdentity.initialize(this, memberUId, accessToken, modifiedDate);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, memberUId, accessToken, modifiedDate) { 
        obj['MemberUId'] = memberUId;
        obj['AccessToken'] = accessToken;
        obj['ModifiedDate'] = modifiedDate;
    }

    /**
     * Constructs a <code>SocialProfileIdentity</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/SocialProfileIdentity} obj Optional instance to populate.
     * @return {module:model/SocialProfileIdentity} The populated <code>SocialProfileIdentity</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new SocialProfileIdentity();

            if (data.hasOwnProperty('MemberUId')) {
                obj['MemberUId'] = ApiClient.convertToType(data['MemberUId'], 'String');
            }
            if (data.hasOwnProperty('AccessToken')) {
                obj['AccessToken'] = ApiClient.convertToType(data['AccessToken'], 'String');
            }
            if (data.hasOwnProperty('ModifiedDate')) {
                obj['ModifiedDate'] = ApiClient.convertToType(data['ModifiedDate'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>SocialProfileIdentity</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>SocialProfileIdentity</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of SocialProfileIdentity.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['MemberUId'] && !(typeof data['MemberUId'] === 'string' || data['MemberUId'] instanceof String)) {
            throw new Error("Expected the field `MemberUId` to be a primitive type in the JSON string but got " + data['MemberUId']);
        }
        // ensure the json data is a string
        if (data['AccessToken'] && !(typeof data['AccessToken'] === 'string' || data['AccessToken'] instanceof String)) {
            throw new Error("Expected the field `AccessToken` to be a primitive type in the JSON string but got " + data['AccessToken']);
        }
        // ensure the json data is a string
        if (data['ModifiedDate'] && !(typeof data['ModifiedDate'] === 'string' || data['ModifiedDate'] instanceof String)) {
            throw new Error("Expected the field `ModifiedDate` to be a primitive type in the JSON string but got " + data['ModifiedDate']);
        }

        return true;
    }


}

SocialProfileIdentity.RequiredProperties = ["MemberUId", "AccessToken", "ModifiedDate"];

/**
 * MaxLength:100
 * @member {String} MemberUId
 */
SocialProfileIdentity.prototype['MemberUId'] = undefined;

/**
 * MaxLength:500
 * @member {String} AccessToken
 */
SocialProfileIdentity.prototype['AccessToken'] = undefined;

/**
 * Format [yyyy-MM-dd HH:mm:ss] UTC
 * @member {String} ModifiedDate
 */
SocialProfileIdentity.prototype['ModifiedDate'] = undefined;






export default SocialProfileIdentity;

