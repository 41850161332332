/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import DetailLine from './DetailLine';

/**
 * The OrderInfo model module.
 * @module model/OrderInfo
 * @version v1
 */
class OrderInfo {
    /**
     * Constructs a new <code>OrderInfo</code>.
     * @alias module:model/OrderInfo
     * @param orderId {Number} int64
     * @param membersName {String} MaxLength:130
     * @param pointsBalance {Number} 
     * @param pointsValue {Number} 
     * @param orderPoints {Number} 
     * @param pointsRedeemed {Number} 
     * @param detail {Array.<module:model/DetailLine>} 
     */
    constructor(orderId, membersName, pointsBalance, pointsValue, orderPoints, pointsRedeemed, detail) { 
        
        OrderInfo.initialize(this, orderId, membersName, pointsBalance, pointsValue, orderPoints, pointsRedeemed, detail);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, orderId, membersName, pointsBalance, pointsValue, orderPoints, pointsRedeemed, detail) { 
        obj['OrderId'] = orderId;
        obj['MembersName'] = membersName;
        obj['PointsBalance'] = pointsBalance;
        obj['PointsValue'] = pointsValue;
        obj['OrderPoints'] = orderPoints;
        obj['PointsRedeemed'] = pointsRedeemed;
        obj['Detail'] = detail;
    }

    /**
     * Constructs a <code>OrderInfo</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/OrderInfo} obj Optional instance to populate.
     * @return {module:model/OrderInfo} The populated <code>OrderInfo</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new OrderInfo();

            if (data.hasOwnProperty('OrderId')) {
                obj['OrderId'] = ApiClient.convertToType(data['OrderId'], 'Number');
            }
            if (data.hasOwnProperty('MembersName')) {
                obj['MembersName'] = ApiClient.convertToType(data['MembersName'], 'String');
            }
            if (data.hasOwnProperty('PointsBalance')) {
                obj['PointsBalance'] = ApiClient.convertToType(data['PointsBalance'], 'Number');
            }
            if (data.hasOwnProperty('PointsValue')) {
                obj['PointsValue'] = ApiClient.convertToType(data['PointsValue'], 'Number');
            }
            if (data.hasOwnProperty('OrderPoints')) {
                obj['OrderPoints'] = ApiClient.convertToType(data['OrderPoints'], 'Number');
            }
            if (data.hasOwnProperty('PointsRedeemed')) {
                obj['PointsRedeemed'] = ApiClient.convertToType(data['PointsRedeemed'], 'Number');
            }
            if (data.hasOwnProperty('Detail')) {
                obj['Detail'] = ApiClient.convertToType(data['Detail'], [DetailLine]);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>OrderInfo</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>OrderInfo</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of OrderInfo.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['MembersName'] && !(typeof data['MembersName'] === 'string' || data['MembersName'] instanceof String)) {
            throw new Error("Expected the field `MembersName` to be a primitive type in the JSON string but got " + data['MembersName']);
        }
        if (data['Detail']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['Detail'])) {
                throw new Error("Expected the field `Detail` to be an array in the JSON data but got " + data['Detail']);
            }
            // validate the optional field `Detail` (array)
            for (const item of data['Detail']) {
                DetailLine.validateJSON(item);
            };
        }

        return true;
    }


}

OrderInfo.RequiredProperties = ["OrderId", "MembersName", "PointsBalance", "PointsValue", "OrderPoints", "PointsRedeemed", "Detail"];

/**
 * int64
 * @member {Number} OrderId
 */
OrderInfo.prototype['OrderId'] = undefined;

/**
 * MaxLength:130
 * @member {String} MembersName
 */
OrderInfo.prototype['MembersName'] = undefined;

/**
 * @member {Number} PointsBalance
 */
OrderInfo.prototype['PointsBalance'] = undefined;

/**
 * @member {Number} PointsValue
 */
OrderInfo.prototype['PointsValue'] = undefined;

/**
 * @member {Number} OrderPoints
 */
OrderInfo.prototype['OrderPoints'] = undefined;

/**
 * @member {Number} PointsRedeemed
 */
OrderInfo.prototype['PointsRedeemed'] = undefined;

/**
 * @member {Array.<module:model/DetailLine>} Detail
 */
OrderInfo.prototype['Detail'] = undefined;






export default OrderInfo;

