/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The MemberSociety model module.
 * @module model/MemberSociety
 * @version v1
 */
class MemberSociety {
    /**
     * Constructs a new <code>MemberSociety</code>.
     * @alias module:model/MemberSociety
     * @param societyId {Number} 
     * @param societyName {String} MaxLength:100
     * @param offerId {Number} 
     * @param offerText {String} MaxLength:250
     * @param unitsEarned {Number} 
     */
    constructor(societyId, societyName, offerId, offerText, unitsEarned) { 
        
        MemberSociety.initialize(this, societyId, societyName, offerId, offerText, unitsEarned);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, societyId, societyName, offerId, offerText, unitsEarned) { 
        obj['SocietyId'] = societyId;
        obj['SocietyName'] = societyName;
        obj['OfferId'] = offerId;
        obj['OfferText'] = offerText;
        obj['UnitsEarned'] = unitsEarned;
    }

    /**
     * Constructs a <code>MemberSociety</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/MemberSociety} obj Optional instance to populate.
     * @return {module:model/MemberSociety} The populated <code>MemberSociety</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MemberSociety();

            if (data.hasOwnProperty('SocietyId')) {
                obj['SocietyId'] = ApiClient.convertToType(data['SocietyId'], 'Number');
            }
            if (data.hasOwnProperty('SocietyName')) {
                obj['SocietyName'] = ApiClient.convertToType(data['SocietyName'], 'String');
            }
            if (data.hasOwnProperty('OfferId')) {
                obj['OfferId'] = ApiClient.convertToType(data['OfferId'], 'Number');
            }
            if (data.hasOwnProperty('OfferText')) {
                obj['OfferText'] = ApiClient.convertToType(data['OfferText'], 'String');
            }
            if (data.hasOwnProperty('UnitsEarned')) {
                obj['UnitsEarned'] = ApiClient.convertToType(data['UnitsEarned'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>MemberSociety</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>MemberSociety</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of MemberSociety.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['SocietyName'] && !(typeof data['SocietyName'] === 'string' || data['SocietyName'] instanceof String)) {
            throw new Error("Expected the field `SocietyName` to be a primitive type in the JSON string but got " + data['SocietyName']);
        }
        // ensure the json data is a string
        if (data['OfferText'] && !(typeof data['OfferText'] === 'string' || data['OfferText'] instanceof String)) {
            throw new Error("Expected the field `OfferText` to be a primitive type in the JSON string but got " + data['OfferText']);
        }

        return true;
    }


}

MemberSociety.RequiredProperties = ["SocietyId", "SocietyName", "OfferId", "OfferText", "UnitsEarned"];

/**
 * @member {Number} SocietyId
 */
MemberSociety.prototype['SocietyId'] = undefined;

/**
 * MaxLength:100
 * @member {String} SocietyName
 */
MemberSociety.prototype['SocietyName'] = undefined;

/**
 * @member {Number} OfferId
 */
MemberSociety.prototype['OfferId'] = undefined;

/**
 * MaxLength:250
 * @member {String} OfferText
 */
MemberSociety.prototype['OfferText'] = undefined;

/**
 * @member {Number} UnitsEarned
 */
MemberSociety.prototype['UnitsEarned'] = undefined;






export default MemberSociety;

