/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The Donation model module.
 * @module model/Donation
 * @version v1
 */
class Donation {
    /**
     * Constructs a new <code>Donation</code>.
     * @alias module:model/Donation
     * @param donationId {Number} 
     * @param startDate {String} Format [yyyy-MM-dd HH:mm:ss] UTC
     * @param endDate {String} Format [yyyy-MM-dd HH:mm:ss] UTC
     * @param donationCategory {String} MaxLength:100
     * @param categoryAppImageLocation {String} MaxLength:1000
     * @param categoryWebImageLocation {String} MaxLength:1000
     * @param categoryThumbnailLocation {String} MaxLength:1000
     * @param donationName {String} MaxLength:500
     * @param donationDetail {String} MaxLength:8000
     * @param providerName {String} MaxLength:500
     * @param pointsCost {Number} 
     * @param tandC {String} MaxLength:8000
     * @param appImageLocation {String} MaxLength:1000
     * @param webImageLocation {String} MaxLength:1000
     * @param thumbnailImageLocation {String} MaxLength:1000
     * @param donationValue {Number} 
     * @param maxUnits {Number} 
     */
    constructor(donationId, startDate, endDate, donationCategory, categoryAppImageLocation, categoryWebImageLocation, categoryThumbnailLocation, donationName, donationDetail, providerName, pointsCost, tandC, appImageLocation, webImageLocation, thumbnailImageLocation, donationValue, maxUnits) { 
        
        Donation.initialize(this, donationId, startDate, endDate, donationCategory, categoryAppImageLocation, categoryWebImageLocation, categoryThumbnailLocation, donationName, donationDetail, providerName, pointsCost, tandC, appImageLocation, webImageLocation, thumbnailImageLocation, donationValue, maxUnits);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, donationId, startDate, endDate, donationCategory, categoryAppImageLocation, categoryWebImageLocation, categoryThumbnailLocation, donationName, donationDetail, providerName, pointsCost, tandC, appImageLocation, webImageLocation, thumbnailImageLocation, donationValue, maxUnits) { 
        obj['DonationId'] = donationId;
        obj['StartDate'] = startDate;
        obj['EndDate'] = endDate;
        obj['DonationCategory'] = donationCategory;
        obj['CategoryAppImageLocation'] = categoryAppImageLocation;
        obj['CategoryWebImageLocation'] = categoryWebImageLocation;
        obj['CategoryThumbnailLocation'] = categoryThumbnailLocation;
        obj['DonationName'] = donationName;
        obj['DonationDetail'] = donationDetail;
        obj['ProviderName'] = providerName;
        obj['PointsCost'] = pointsCost;
        obj['TandC'] = tandC;
        obj['AppImageLocation'] = appImageLocation;
        obj['WebImageLocation'] = webImageLocation;
        obj['ThumbnailImageLocation'] = thumbnailImageLocation;
        obj['DonationValue'] = donationValue;
        obj['MaxUnits'] = maxUnits;
    }

    /**
     * Constructs a <code>Donation</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Donation} obj Optional instance to populate.
     * @return {module:model/Donation} The populated <code>Donation</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Donation();

            if (data.hasOwnProperty('DonationId')) {
                obj['DonationId'] = ApiClient.convertToType(data['DonationId'], 'Number');
            }
            if (data.hasOwnProperty('StartDate')) {
                obj['StartDate'] = ApiClient.convertToType(data['StartDate'], 'String');
            }
            if (data.hasOwnProperty('EndDate')) {
                obj['EndDate'] = ApiClient.convertToType(data['EndDate'], 'String');
            }
            if (data.hasOwnProperty('DonationCategory')) {
                obj['DonationCategory'] = ApiClient.convertToType(data['DonationCategory'], 'String');
            }
            if (data.hasOwnProperty('CategoryAppImageLocation')) {
                obj['CategoryAppImageLocation'] = ApiClient.convertToType(data['CategoryAppImageLocation'], 'String');
            }
            if (data.hasOwnProperty('CategoryWebImageLocation')) {
                obj['CategoryWebImageLocation'] = ApiClient.convertToType(data['CategoryWebImageLocation'], 'String');
            }
            if (data.hasOwnProperty('CategoryThumbnailLocation')) {
                obj['CategoryThumbnailLocation'] = ApiClient.convertToType(data['CategoryThumbnailLocation'], 'String');
            }
            if (data.hasOwnProperty('DonationName')) {
                obj['DonationName'] = ApiClient.convertToType(data['DonationName'], 'String');
            }
            if (data.hasOwnProperty('DonationDetail')) {
                obj['DonationDetail'] = ApiClient.convertToType(data['DonationDetail'], 'String');
            }
            if (data.hasOwnProperty('ProviderName')) {
                obj['ProviderName'] = ApiClient.convertToType(data['ProviderName'], 'String');
            }
            if (data.hasOwnProperty('PointsCost')) {
                obj['PointsCost'] = ApiClient.convertToType(data['PointsCost'], 'Number');
            }
            if (data.hasOwnProperty('TandC')) {
                obj['TandC'] = ApiClient.convertToType(data['TandC'], 'String');
            }
            if (data.hasOwnProperty('AppImageLocation')) {
                obj['AppImageLocation'] = ApiClient.convertToType(data['AppImageLocation'], 'String');
            }
            if (data.hasOwnProperty('WebImageLocation')) {
                obj['WebImageLocation'] = ApiClient.convertToType(data['WebImageLocation'], 'String');
            }
            if (data.hasOwnProperty('ThumbnailImageLocation')) {
                obj['ThumbnailImageLocation'] = ApiClient.convertToType(data['ThumbnailImageLocation'], 'String');
            }
            if (data.hasOwnProperty('DonationValue')) {
                obj['DonationValue'] = ApiClient.convertToType(data['DonationValue'], 'Number');
            }
            if (data.hasOwnProperty('MaxUnits')) {
                obj['MaxUnits'] = ApiClient.convertToType(data['MaxUnits'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Donation</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Donation</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of Donation.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['StartDate'] && !(typeof data['StartDate'] === 'string' || data['StartDate'] instanceof String)) {
            throw new Error("Expected the field `StartDate` to be a primitive type in the JSON string but got " + data['StartDate']);
        }
        // ensure the json data is a string
        if (data['EndDate'] && !(typeof data['EndDate'] === 'string' || data['EndDate'] instanceof String)) {
            throw new Error("Expected the field `EndDate` to be a primitive type in the JSON string but got " + data['EndDate']);
        }
        // ensure the json data is a string
        if (data['DonationCategory'] && !(typeof data['DonationCategory'] === 'string' || data['DonationCategory'] instanceof String)) {
            throw new Error("Expected the field `DonationCategory` to be a primitive type in the JSON string but got " + data['DonationCategory']);
        }
        // ensure the json data is a string
        if (data['CategoryAppImageLocation'] && !(typeof data['CategoryAppImageLocation'] === 'string' || data['CategoryAppImageLocation'] instanceof String)) {
            throw new Error("Expected the field `CategoryAppImageLocation` to be a primitive type in the JSON string but got " + data['CategoryAppImageLocation']);
        }
        // ensure the json data is a string
        if (data['CategoryWebImageLocation'] && !(typeof data['CategoryWebImageLocation'] === 'string' || data['CategoryWebImageLocation'] instanceof String)) {
            throw new Error("Expected the field `CategoryWebImageLocation` to be a primitive type in the JSON string but got " + data['CategoryWebImageLocation']);
        }
        // ensure the json data is a string
        if (data['CategoryThumbnailLocation'] && !(typeof data['CategoryThumbnailLocation'] === 'string' || data['CategoryThumbnailLocation'] instanceof String)) {
            throw new Error("Expected the field `CategoryThumbnailLocation` to be a primitive type in the JSON string but got " + data['CategoryThumbnailLocation']);
        }
        // ensure the json data is a string
        if (data['DonationName'] && !(typeof data['DonationName'] === 'string' || data['DonationName'] instanceof String)) {
            throw new Error("Expected the field `DonationName` to be a primitive type in the JSON string but got " + data['DonationName']);
        }
        // ensure the json data is a string
        if (data['DonationDetail'] && !(typeof data['DonationDetail'] === 'string' || data['DonationDetail'] instanceof String)) {
            throw new Error("Expected the field `DonationDetail` to be a primitive type in the JSON string but got " + data['DonationDetail']);
        }
        // ensure the json data is a string
        if (data['ProviderName'] && !(typeof data['ProviderName'] === 'string' || data['ProviderName'] instanceof String)) {
            throw new Error("Expected the field `ProviderName` to be a primitive type in the JSON string but got " + data['ProviderName']);
        }
        // ensure the json data is a string
        if (data['TandC'] && !(typeof data['TandC'] === 'string' || data['TandC'] instanceof String)) {
            throw new Error("Expected the field `TandC` to be a primitive type in the JSON string but got " + data['TandC']);
        }
        // ensure the json data is a string
        if (data['AppImageLocation'] && !(typeof data['AppImageLocation'] === 'string' || data['AppImageLocation'] instanceof String)) {
            throw new Error("Expected the field `AppImageLocation` to be a primitive type in the JSON string but got " + data['AppImageLocation']);
        }
        // ensure the json data is a string
        if (data['WebImageLocation'] && !(typeof data['WebImageLocation'] === 'string' || data['WebImageLocation'] instanceof String)) {
            throw new Error("Expected the field `WebImageLocation` to be a primitive type in the JSON string but got " + data['WebImageLocation']);
        }
        // ensure the json data is a string
        if (data['ThumbnailImageLocation'] && !(typeof data['ThumbnailImageLocation'] === 'string' || data['ThumbnailImageLocation'] instanceof String)) {
            throw new Error("Expected the field `ThumbnailImageLocation` to be a primitive type in the JSON string but got " + data['ThumbnailImageLocation']);
        }

        return true;
    }


}

Donation.RequiredProperties = ["DonationId", "StartDate", "EndDate", "DonationCategory", "CategoryAppImageLocation", "CategoryWebImageLocation", "CategoryThumbnailLocation", "DonationName", "DonationDetail", "ProviderName", "PointsCost", "TandC", "AppImageLocation", "WebImageLocation", "ThumbnailImageLocation", "DonationValue", "MaxUnits"];

/**
 * @member {Number} DonationId
 */
Donation.prototype['DonationId'] = undefined;

/**
 * Format [yyyy-MM-dd HH:mm:ss] UTC
 * @member {String} StartDate
 */
Donation.prototype['StartDate'] = undefined;

/**
 * Format [yyyy-MM-dd HH:mm:ss] UTC
 * @member {String} EndDate
 */
Donation.prototype['EndDate'] = undefined;

/**
 * MaxLength:100
 * @member {String} DonationCategory
 */
Donation.prototype['DonationCategory'] = undefined;

/**
 * MaxLength:1000
 * @member {String} CategoryAppImageLocation
 */
Donation.prototype['CategoryAppImageLocation'] = undefined;

/**
 * MaxLength:1000
 * @member {String} CategoryWebImageLocation
 */
Donation.prototype['CategoryWebImageLocation'] = undefined;

/**
 * MaxLength:1000
 * @member {String} CategoryThumbnailLocation
 */
Donation.prototype['CategoryThumbnailLocation'] = undefined;

/**
 * MaxLength:500
 * @member {String} DonationName
 */
Donation.prototype['DonationName'] = undefined;

/**
 * MaxLength:8000
 * @member {String} DonationDetail
 */
Donation.prototype['DonationDetail'] = undefined;

/**
 * MaxLength:500
 * @member {String} ProviderName
 */
Donation.prototype['ProviderName'] = undefined;

/**
 * @member {Number} PointsCost
 */
Donation.prototype['PointsCost'] = undefined;

/**
 * MaxLength:8000
 * @member {String} TandC
 */
Donation.prototype['TandC'] = undefined;

/**
 * MaxLength:1000
 * @member {String} AppImageLocation
 */
Donation.prototype['AppImageLocation'] = undefined;

/**
 * MaxLength:1000
 * @member {String} WebImageLocation
 */
Donation.prototype['WebImageLocation'] = undefined;

/**
 * MaxLength:1000
 * @member {String} ThumbnailImageLocation
 */
Donation.prototype['ThumbnailImageLocation'] = undefined;

/**
 * @member {Number} DonationValue
 */
Donation.prototype['DonationValue'] = undefined;

/**
 * @member {Number} MaxUnits
 */
Donation.prototype['MaxUnits'] = undefined;






export default Donation;

