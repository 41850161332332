import PublicBase from "../lib/PublicBase";
import Styles, { Colors } from "../lib/Styles";
import CKButton from "../lib/components/CKButton";
import CKInput from "../lib/components/CKInput";
import { ReactComponent as EmailSuccessLogo } from '../images/forgot_pass_confirm.svg';
import Header from "../lib/components/Header";
import ShadowPanel from "../lib/components/ShadowPanel";
import CKNavigator from "../lib/CKNavigator";
import CKSeparator from "../lib/components/CKSeparator";
import CommsLayer from "../lib/api/CommsLayer";
import ErrorCodes from "../lib/api/ErrorCodes";

export default class Forgot extends PublicBase {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            txtEmail: '',
            showSuccess: false,
            userNotFound: false,
            waitingForgotPassword: false,
            type: props.type,
        }
    }

    sendPassword = () => {
        this.setState({
            waitingForgotPassword: true,
            txtEmailError: ''
        });

        CommsLayer.members().membersV1ForgotPassword(this.state.txtEmail).then(() => {
            this.setState({
                showSuccess: true
            })
        }).catch(err => {
            // lets process the error
            if(err && err.code && err.message && err.code ==='WS110') {
                this.setState({
                    userNotFound: true
                });
            } else {
                this.setState({
                    txtEmailError: ErrorCodes.generalAPIError()
                })
            }
        }).finally(() => {
            this.setState({waitingForgotPassword: false});
        });
    }

    render() {
        return super.render(
            
            <ShadowPanel>

                {this.state.showSuccess ? <div style={{
                    textAlign: 'center',
                    marginBottom: 22
                }}><EmailSuccessLogo /></div> : null}

                <Header style={{
                    marginBottom: 16,
                    fontSize: 20,
                    lineHeight: 22.5,
                    ...Styles.gothamBlack
                }}>{this.state.showSuccess ? 'Check Your Email' : (
                    this.state.userNotFound ? 'We could\'t find you!' : 'Forgot Password?'
                )}</Header>

                {!this.state.showSuccess ? <>
                    <p style={{textAlign: 'center'}}>
                    {this.state.userNotFound ? 
                        "That email may not be registered with Circle K."
                        :
                        "Don’t worry, it happens to the best of us. Enter the email address associated with your account and we’ll send you an email with instruction to reset your password."
                    }
                    </p>

                    {this.state.userNotFound ? <>
                            <CKButton onClick={() => {
                                this.setState({
                                    userNotFound: false,
                                    txtEmail: ''
                                })
                            }}>Try a different Email?</CKButton>

                            <CKSeparator />

                            <p style={{
                                fontSize: 16,
                                ...Styles.gothamMedium,
                                padding: 0,
                                margin: 0,
                                textAlign: 'center'
                            }}>
                                New to Circle K?
                            </p>

                            <CKButton style={{
                                marginTop: 15
                            }} secondary onClick={() => {
                                this.navigateTo(CKNavigator.signup)
                            }}>Sign Up</CKButton>
                        </>
                        :
                        <>
                            <CKInput onEnter={() => {
                                if(this.state.txtEmail && !this.state.waitingForgotPassword) this.sendPassword();
                            }} error={this.state.txtEmailError} placeholder="Please enter email address" label="Email address" onChange={(evt) => {
                                this.setState({
                                    txtEmail: evt.target.value,
                                    txtEmailError: ''
                                })
                            }} value={this.state.txtEmail} type="email" />

                            <div style={{marginBottom: 24}}></div>

                            <CKButton disabled={!this.state.txtEmail} loading={this.state.waitingForgotPassword} onClick={this.sendPassword}>Send</CKButton>

                            <CKButton disabled={this.state.isLoginInProgress}  style={{
                                marginTop: 30
                            }} secondary onClick={() => {
                                if ( this.state.type && this.state.type === "fuelcard" ) {this.navigateTo(CKNavigator.fuelCardTransfer) } else {this.navigateTo(CKNavigator.login)};
                            }}>Cancel</CKButton>
                        </>
                    }
                </>
                :
                <>
                    <p style={{textAlign: 'center'}}>
                        We have sent you an email to reset your password.
                    </p>
                    { this.state.type && this.state.type === "normal" && 
                        <CKButton loading={this.state.isLoginInProgress} onClick={() => {
                            this.navigateTo(CKNavigator.login);
                        }}>Login</CKButton>
                    }
                    { this.state.type && this.state.type === "fuelcard" && 
                        <CKButton loading={this.state.isLoginInProgress} onClick={() => {
                            this.navigateTo(CKNavigator.fuelCardTransfer);
                        }}>Transfer Account</CKButton>
                    }
                    <p style={{textAlign: 'center', marginBottom: 0, marginTop: 40}}>
                        Did not receive the email? Check your spam folder or <CKButton onClick={() => {
                            this.setState({
                                showSuccess: false,
                                txtEmail: ''
                            })
                        }} underline link style={{
                            color: Colors.orange
                        }}>try sending again</CKButton>
                    </p>
                </>}
            </ShadowPanel>
        )
    }
}