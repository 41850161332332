/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import APIResponse from '../model/APIResponse';
import AccessIdentifierRequest from '../model/AccessIdentifierRequest';
import AccessIdentifierResponse from '../model/AccessIdentifierResponse';
import IdentifyResponse from '../model/IdentifyResponse';
import ValidateAccessIdentifier from '../model/ValidateAccessIdentifier';

/**
* AccessIdentifier service.
* @module api/AccessIdentifierApi
* @version v1
*/
export default class AccessIdentifierApi {

    /**
    * Constructs a new AccessIdentifierApi. 
    * @alias module:api/AccessIdentifierApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the accessIdentifierV1CreateAccessIdentifier operation.
     * @callback module:api/AccessIdentifierApi~accessIdentifierV1CreateAccessIdentifierCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AccessIdentifierResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Generate Access Identifier
     * Generate an Access Identifier for an external application   
     * @param {String} memberUId Member Unique Identifier
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:model/AccessIdentifierRequest} accessIdentifierRequest AccessIdentifierRequest Object
     * @param {module:api/AccessIdentifierApi~accessIdentifierV1CreateAccessIdentifierCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AccessIdentifierResponse}
     */
    accessIdentifierV1CreateAccessIdentifier(memberUId, clientId, authorization, accessIdentifierRequest, callback) {
      let postBody = accessIdentifierRequest;
      // verify the required parameter 'memberUId' is set
      if (memberUId === undefined || memberUId === null) {
        throw new Error("Missing the required parameter 'memberUId' when calling accessIdentifierV1CreateAccessIdentifier");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling accessIdentifierV1CreateAccessIdentifier");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling accessIdentifierV1CreateAccessIdentifier");
      }
      // verify the required parameter 'accessIdentifierRequest' is set
      if (accessIdentifierRequest === undefined || accessIdentifierRequest === null) {
        throw new Error("Missing the required parameter 'accessIdentifierRequest' when calling accessIdentifierV1CreateAccessIdentifier");
      }

      let pathParams = {
        'memberUId': memberUId
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json', 'text/json', 'application/xml', 'text/xml', 'application/x-www-form-urlencoded'];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = AccessIdentifierResponse;
      return this.apiClient.callApi(
        '/v1/AccessIdentifier/Members/{memberUId}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the accessIdentifierV1ValidateAccessIdentifier operation.
     * @callback module:api/AccessIdentifierApi~accessIdentifierV1ValidateAccessIdentifierCallback
     * @param {String} error Error message, if any.
     * @param {module:model/IdentifyResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Validate  Access Identifier
     * Validate an Access Identifier by an external application   
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:model/ValidateAccessIdentifier} validateAccessId ValidateAccessIdentifierResponse object
     * @param {module:api/AccessIdentifierApi~accessIdentifierV1ValidateAccessIdentifierCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/IdentifyResponse}
     */
    accessIdentifierV1ValidateAccessIdentifier(clientId, authorization, validateAccessId, callback) {
      let postBody = validateAccessId;
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling accessIdentifierV1ValidateAccessIdentifier");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling accessIdentifierV1ValidateAccessIdentifier");
      }
      // verify the required parameter 'validateAccessId' is set
      if (validateAccessId === undefined || validateAccessId === null) {
        throw new Error("Missing the required parameter 'validateAccessId' when calling accessIdentifierV1ValidateAccessIdentifier");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json', 'text/json', 'application/xml', 'text/xml', 'application/x-www-form-urlencoded'];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = IdentifyResponse;
      return this.apiClient.callApi(
        '/v1/AccessIdentifier/Validate', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
