/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import APIOKResponse from '../model/APIOKResponse';
import APIResponse from '../model/APIResponse';
import CommunicationInfo from '../model/CommunicationInfo';

/**
* Communications service.
* @module api/CommunicationsApi
* @version v1
*/
export default class CommunicationsApi {

    /**
    * Constructs a new CommunicationsApi. 
    * @alias module:api/CommunicationsApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the communicationsV1GetMemberMessages operation.
     * @callback module:api/CommunicationsApi~communicationsV1GetMemberMessagesCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/CommunicationInfo>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get Messages
     * Get Messages for a Member   
     * @param {String} memberUId Member Unique Identifier
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:api/CommunicationsApi~communicationsV1GetMemberMessagesCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/CommunicationInfo>}
     */
    communicationsV1GetMemberMessages(memberUId, clientId, authorization, callback) {
      let postBody = null;
      // verify the required parameter 'memberUId' is set
      if (memberUId === undefined || memberUId === null) {
        throw new Error("Missing the required parameter 'memberUId' when calling communicationsV1GetMemberMessages");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling communicationsV1GetMemberMessages");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling communicationsV1GetMemberMessages");
      }

      let pathParams = {
        'memberUId': memberUId
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = [CommunicationInfo];
      return this.apiClient.callApi(
        '/v1/communications/Members/{memberUId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the communicationsV1MarkAsRead operation.
     * @callback module:api/CommunicationsApi~communicationsV1MarkAsReadCallback
     * @param {String} error Error message, if any.
     * @param {module:model/APIOKResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Update Message
     * Update Message as read   
     * @param {Number} communicationId ID of Communication
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:api/CommunicationsApi~communicationsV1MarkAsReadCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/APIOKResponse}
     */
    communicationsV1MarkAsRead(communicationId, clientId, authorization, callback) {
      let postBody = null;
      // verify the required parameter 'communicationId' is set
      if (communicationId === undefined || communicationId === null) {
        throw new Error("Missing the required parameter 'communicationId' when calling communicationsV1MarkAsRead");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling communicationsV1MarkAsRead");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling communicationsV1MarkAsRead");
      }

      let pathParams = {
        'communicationId': communicationId
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = APIOKResponse;
      return this.apiClient.callApi(
        '/v1/communications/{communicationId}/Read', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
