import { Component } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import './App.css';
import CKRouter from './CKRouter';
import CommsLayer from './lib/api/CommsLayer';
import Alert from './lib/components/Alert';
import CKAlert from './lib/components/CKAlert';
import Terms from './pages/Terms';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alert: false
    }    
    this.hostProxyApi = process.env.REACT_APP_HOST_PROXY_API !== undefined ? process.env.REACT_APP_HOST_PROXY_API : 'https://frontend-test.ecd-test.alpaque.net';
    CommsLayer.initialise(this.hostProxyApi + '/api', '', '');
  }

  componentDidMount() {
    Alert.notify = () => {
      let a = Alert.get();

      this.setState({
        alert: a
      })
    }

    Alert.showTerms = () => {
      this.setState({
        showTerms: true
      })
    }

    Alert.dismissTerms = () => {
      this.setState({
        showTerms: false
      });
    }
  }

  render() {
    return (
      <Router>
        <CKRouter />
        {this.state.showTerms ? <Terms /> : null}
        {this.state.alert ? <CKAlert title={this.state.alert.title} message={this.state.alert.message} buttons={this.state.alert.buttons} /> : null}
      </Router>
    );
  }
}

export default App;
