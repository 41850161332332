import CKNavigator from "../lib/CKNavigator";
import CommsLayer from "../lib/api/CommsLayer";
import ErrorCodes from "../lib/api/ErrorCodes";
import Alert from "../lib/components/Alert";
import CKIcon, { CKIconType } from "../lib/components/CKIcon";
import CKMenuItem from "../lib/components/CKMenuItem";
import CKMenuPanel from "../lib/components/CKMenuPanel";
import CKSpacer from "../lib/components/CKSpacer";
import View from "../lib/components/View";
import LoggedInBase from "./LoggedInBase";

export default class More extends LoggedInBase {
    screenName = 'more'
    title = 'More'

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,

            memberData: false,
            screenLoading: true
        }
    }

    loadData() {
        if(CommsLayer.memberData && CommsLayer.memberData.FirstName) {
            this.setState({
                memberData: CommsLayer.memberData
            }, this.hideScreenLoading)
        } else {
            CommsLayer.members().membersV1GetMemberInfo().then((memberData) => {
                this.setState({
                    memberData
                }, this.hideScreenLoading)
            }).catch(err => {
                Alert.alert("Error", ErrorCodes.generalAPIError);
            })
        }
    }

    componentDidMount() {
        super.componentDidMount();

        this.showScreenLoading();
    }

    render() {
        return super.render(
            <View className="main-container">
                <CKMenuPanel
                    title={this.state.memberData.FirstName + ' ' + this.state.memberData.LastName} 
                    description={this.state.memberData.Email}
                >
                    <CKMenuItem onPress={() => {
                        this.navigateTo(CKNavigator.moreMyAccount);
                    }} medium icon={<CKIcon icon={CKIconType.my_account} width={27} height={27} />} full_border_off border={1} title='My Account' />

                    <CKMenuItem onPress={() => {
                        this.navigateTo(CKNavigator.helpAndSupport);
                    }} medium icon={<CKIcon icon={CKIconType.help} width={27} height={27} />} full_border_off border={1} title='Help & Support' />
                </CKMenuPanel>

                {/* <CKMenuPanel>
                    
                    <CKMenuItem onPress={() => {
                        //this.navigate(Navigator.screens.SettingsPrivacy);
                    }} medium icon={<CKIcon icon={CKIconType.settings} width={27} height={27} />} full_border_off title='Settings & Privacy' border={1} />
                </CKMenuPanel> */}

                <CKSpacer />
            </View>
        )
    }
}