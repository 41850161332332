/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The CommunicationInfo model module.
 * @module model/CommunicationInfo
 * @version v1
 */
class CommunicationInfo {
    /**
     * Constructs a new <code>CommunicationInfo</code>.
     * @alias module:model/CommunicationInfo
     * @param communicationId {Number} 
     * @param message {String} MaxLength:1000
     * @param subject {String} MaxLength:500
     * @param dateCreated {String} Format [yyyy-MM-dd HH:mm:ss] UTC
     * @param read {Boolean} 
     */
    constructor(communicationId, message, subject, dateCreated, read) { 
        
        CommunicationInfo.initialize(this, communicationId, message, subject, dateCreated, read);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, communicationId, message, subject, dateCreated, read) { 
        obj['CommunicationId'] = communicationId;
        obj['Message'] = message;
        obj['Subject'] = subject;
        obj['DateCreated'] = dateCreated;
        obj['Read'] = read;
    }

    /**
     * Constructs a <code>CommunicationInfo</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CommunicationInfo} obj Optional instance to populate.
     * @return {module:model/CommunicationInfo} The populated <code>CommunicationInfo</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CommunicationInfo();

            if (data.hasOwnProperty('CommunicationId')) {
                obj['CommunicationId'] = ApiClient.convertToType(data['CommunicationId'], 'Number');
            }
            if (data.hasOwnProperty('Message')) {
                obj['Message'] = ApiClient.convertToType(data['Message'], 'String');
            }
            if (data.hasOwnProperty('Subject')) {
                obj['Subject'] = ApiClient.convertToType(data['Subject'], 'String');
            }
            if (data.hasOwnProperty('DateCreated')) {
                obj['DateCreated'] = ApiClient.convertToType(data['DateCreated'], 'String');
            }
            if (data.hasOwnProperty('Read')) {
                obj['Read'] = ApiClient.convertToType(data['Read'], 'Boolean');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>CommunicationInfo</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CommunicationInfo</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of CommunicationInfo.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['Message'] && !(typeof data['Message'] === 'string' || data['Message'] instanceof String)) {
            throw new Error("Expected the field `Message` to be a primitive type in the JSON string but got " + data['Message']);
        }
        // ensure the json data is a string
        if (data['Subject'] && !(typeof data['Subject'] === 'string' || data['Subject'] instanceof String)) {
            throw new Error("Expected the field `Subject` to be a primitive type in the JSON string but got " + data['Subject']);
        }
        // ensure the json data is a string
        if (data['DateCreated'] && !(typeof data['DateCreated'] === 'string' || data['DateCreated'] instanceof String)) {
            throw new Error("Expected the field `DateCreated` to be a primitive type in the JSON string but got " + data['DateCreated']);
        }

        return true;
    }


}

CommunicationInfo.RequiredProperties = ["CommunicationId", "Message", "Subject", "DateCreated", "Read"];

/**
 * @member {Number} CommunicationId
 */
CommunicationInfo.prototype['CommunicationId'] = undefined;

/**
 * MaxLength:1000
 * @member {String} Message
 */
CommunicationInfo.prototype['Message'] = undefined;

/**
 * MaxLength:500
 * @member {String} Subject
 */
CommunicationInfo.prototype['Subject'] = undefined;

/**
 * Format [yyyy-MM-dd HH:mm:ss] UTC
 * @member {String} DateCreated
 */
CommunicationInfo.prototype['DateCreated'] = undefined;

/**
 * @member {Boolean} Read
 */
CommunicationInfo.prototype['Read'] = undefined;






export default CommunicationInfo;

