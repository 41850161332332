import { useParams } from "react-router-dom";
import CKNavigator, { CKNavigatorStorage } from "../lib/CKNavigator";
import { CKColors, CKPadding } from "../lib/Styles";
import CommsLayer from "../lib/api/CommsLayer";
import Alert from "../lib/components/Alert";
import CKSpacer from "../lib/components/CKSpacer";
import View from "../lib/components/View";
import LoggedInBase from "./LoggedInBase";
import CKOffer from "../lib/components/CKOffer";
import CKText from "../lib/components/CKText";
import CKButton from "../lib/components/CKButton";
import { CKStrings } from "../lib/components/CKStrings";

import { ReactComponent as AppleBadge } from '../images/apple-badge.svg';
import GoogleBadge from '../images/google-play-badge.png';

class OfferDetails extends LoggedInBase {
    screenName = 'home'
    title = ''
    subLevelPage = true

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,

            memberData: false
        }
    }

    onBack() {
        if(this.state.isExclusive) this.navigateTo(CKNavigator.exclusiveMemberOffers);
        else this.navigateTo(CKNavigator.home);
    }

    async loadData() {
        // lets check if parameters were passed
        let params = CKNavigatorStorage.get();

        // lets check if param is same as the offer id in the url
        if(params && "" + params.offer.OfferId !== "" + this.props.params.id) params = false;

        if(!params) {
            // lets load params based on the ID of the offer
            await CommsLayer.offers().offersV1GetCurrentOffersWithDetails().then(offers => {
                // we need to find offer by ID and the correct category
                let cats = ['surprise', 'counting', 'view_more', 'other'];

                for(var i = 0; !params && i < cats.length; i++) {
                    var cat = cats[i];
                    if(offers[cat]) {
                        for(var j = 0; j < offers[cat].length; j++) {
                            var offer = offers[cat][j];
                            if(offer.OfferId ===this.props.params.id) {
                                params = {
                                    offer,
                                    type: cat
                                }
                                break;
                            }
                        }
                    }
                }
            });
        }
        // lets check if were able to find params
        if(params) {
            let state = {};

            state.offer = params.offer;
            state.type = params.type;
            state.isExclusive = params.type ==='view_more';

            if(state.offer.ImageLocation) {
                state.offer.ImageLocation = state.offer.ImageLocation.replace(/\n/g, '');
            }

            this.offerTitle = state.offer.OfferText;

            if(state.type ==='counting') {
                this.title = 'Every' + ' ' + (state.offer.OfferDetails.Multiple + 1) + (
                    (state.offer.OfferDetails.Multiple + 1) ===1 ? 'st' : (
                        (state.offer.OfferDetails.Multiple + 1) ===2 ? 'nd' : (
                            (state.offer.OfferDetails.Multiple + 1) ===3 ? 'rd' : 'th'
                        )
                    )
                ) + ' ' + this.offerTitle + ' ' + 'Free';
            } else this.title = state.offer.OfferText;

            this.setState({
                ...state
            }, this.hideScreenLoading);
        } else {
            Alert.alert('Error', 'Offer expired or not found.')
            this.onBack();
        }
    }

    componentDidMount() {
        super.componentDidMount();

        this.showScreenLoading();
    }

    render() {
        return super.render(
            this.state.offer ? 
            <View className='offer-details'>
                <img alt={this.title} className="offer-image" src={this.state.offer.ImageLocation} />

                {this.state.isExclusive ? <View style={{marginBottom: CKPadding.default * 1.25}} /> : <View className="offer-progress">
                        <CKOffer
                            onlyProgress
                            max_value={this.state.type !=='counting' ? 1 : this.state.offer.OfferDetails.Multiple}
                            current_value={this.state.type !=='counting' ? 1 : (this.state.offer.OfferDetails.FreeItemsAvailable && this.state.offer.OfferDetails.FreeItemsAvailable.FreeItems >= 1 ? this.state.offer.OfferDetails.Multiple : this.state.offer.OfferDetails.UnitsEarned)}
                            type={this.state.offer.rule && this.state.offer.rule.icon ? this.state.offer.rule.icon : (this.state.type !=='counting' ? (this.state.type ==='other' ? 'surprise' : this.state.type) : '')}
                            offer={this.state.offer}
                        />
                    </View>
                }

                <View className='inner-page-container'>
                    {!this.state.isExclusive && this.offerTitle && this.state.type ==='counting' ? <CKText className="offer-subtitle">{CKStrings.convertToUserCurrency(this.offerTitle)}</CKText> : null}

                    <CKText className="offer-title">
                        {
                            this.state.type !=='counting' ? this.state.offer.OfferText : (
                                this.state.offer.OfferDetails.FreeItemsAvailable && this.state.offer.OfferDetails.FreeItemsAvailable.FreeItems >= 1 ? 'Get Your Free' + ' ' + this.offerTitle : CKStrings.convertToUserCurrency(this.title)
                            )
                        }
                    </CKText>
                    <CKText className="offer-description">
                        {CKStrings.convertToUserCurrency(this.state.offer.FindOutMore)}
                    </CKText>

                    {this.state.isExclusive ? <CKButton style={{
                        display: 'flex',
                        alignSelf: 'flex-start',
                        marginTop: CKPadding.default / 2,
                        color: CKColors.orange
                    }} onPress={() => {
                        Alert.showTerms();
                    }} link>View Terms & Conditions</CKButton> : null }

                    <View style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: CKPadding.default * 2
                    }}>
                        <CKButton onPress={() => {
                             window.open('https://apps.apple.com/ie/app/circle-k-ireland/id527612136', '_blank');
                        }} custom default_style={{
                            padding: 0
                        }}>
                            <AppleBadge width={120} />
                        </CKButton>

                        <CKButton onPress={() => {
                            window.open('https://play.google.com/store/apps/details?id=clients.topaz', '_blank');
                        }} custom default_style={{
                            padding: 0
                        }}>
                            <img alt="google badge" style={{
                                width: 120 * 1.26
                            }} src={GoogleBadge} />
                        </CKButton>
                    </View>
                </View>

                <CKSpacer />
                <CKSpacer />
            </View>
            :
            null
        )
    }
}

export default (props) => (
    <OfferDetails
        {...props}
        params={useParams()}
    />
);