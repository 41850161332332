/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The SurveyAnswer model module.
 * @module model/SurveyAnswer
 * @version v1
 */
class SurveyAnswer {
    /**
     * Constructs a new <code>SurveyAnswer</code>.
     * @alias module:model/SurveyAnswer
     * @param questionNo {Number} 
     * @param answer {String} MaxLength:800
     */
    constructor(questionNo, answer) { 
        
        SurveyAnswer.initialize(this, questionNo, answer);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, questionNo, answer) { 
        obj['QuestionNo'] = questionNo;
        obj['Answer'] = answer;
    }

    /**
     * Constructs a <code>SurveyAnswer</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/SurveyAnswer} obj Optional instance to populate.
     * @return {module:model/SurveyAnswer} The populated <code>SurveyAnswer</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new SurveyAnswer();

            if (data.hasOwnProperty('QuestionNo')) {
                obj['QuestionNo'] = ApiClient.convertToType(data['QuestionNo'], 'Number');
            }
            if (data.hasOwnProperty('Answer')) {
                obj['Answer'] = ApiClient.convertToType(data['Answer'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>SurveyAnswer</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>SurveyAnswer</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of SurveyAnswer.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['Answer'] && !(typeof data['Answer'] === 'string' || data['Answer'] instanceof String)) {
            throw new Error("Expected the field `Answer` to be a primitive type in the JSON string but got " + data['Answer']);
        }

        return true;
    }


}

SurveyAnswer.RequiredProperties = ["QuestionNo", "Answer"];

/**
 * @member {Number} QuestionNo
 */
SurveyAnswer.prototype['QuestionNo'] = undefined;

/**
 * MaxLength:800
 * @member {String} Answer
 */
SurveyAnswer.prototype['Answer'] = undefined;






export default SurveyAnswer;

