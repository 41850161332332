/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The QuestionAnswer model module.
 * @module model/QuestionAnswer
 * @version v1
 */
class QuestionAnswer {
    /**
     * Constructs a new <code>QuestionAnswer</code>.
     * @alias module:model/QuestionAnswer
     * @param answerDescription {String} MaxLength:8000
     * @param answerId {Number} 
     */
    constructor(answerDescription, answerId) { 
        
        QuestionAnswer.initialize(this, answerDescription, answerId);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, answerDescription, answerId) { 
        obj['AnswerDescription'] = answerDescription;
        obj['AnswerId'] = answerId;
    }

    /**
     * Constructs a <code>QuestionAnswer</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/QuestionAnswer} obj Optional instance to populate.
     * @return {module:model/QuestionAnswer} The populated <code>QuestionAnswer</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new QuestionAnswer();

            if (data.hasOwnProperty('AnswerDescription')) {
                obj['AnswerDescription'] = ApiClient.convertToType(data['AnswerDescription'], 'String');
            }
            if (data.hasOwnProperty('AnswerId')) {
                obj['AnswerId'] = ApiClient.convertToType(data['AnswerId'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>QuestionAnswer</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>QuestionAnswer</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of QuestionAnswer.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['AnswerDescription'] && !(typeof data['AnswerDescription'] === 'string' || data['AnswerDescription'] instanceof String)) {
            throw new Error("Expected the field `AnswerDescription` to be a primitive type in the JSON string but got " + data['AnswerDescription']);
        }

        return true;
    }


}

QuestionAnswer.RequiredProperties = ["AnswerDescription", "AnswerId"];

/**
 * MaxLength:8000
 * @member {String} AnswerDescription
 */
QuestionAnswer.prototype['AnswerDescription'] = undefined;

/**
 * @member {Number} AnswerId
 */
QuestionAnswer.prototype['AnswerId'] = undefined;






export default QuestionAnswer;

