/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The SocietyInfo model module.
 * @module model/SocietyInfo
 * @version v1
 */
class SocietyInfo {
    /**
     * Constructs a new <code>SocietyInfo</code>.
     * @alias module:model/SocietyInfo
     * @param societyName {String} MaxLength:100
     * @param countyId {Number} 
     * @param societyEmail {String} MaxLength:64
     * @param societyMobile {String} MaxLength:20
     * @param contactName {String} MaxLength:50
     * @param localStoreId {Number} 
     */
    constructor(societyName, countyId, societyEmail, societyMobile, contactName, localStoreId) { 
        
        SocietyInfo.initialize(this, societyName, countyId, societyEmail, societyMobile, contactName, localStoreId);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, societyName, countyId, societyEmail, societyMobile, contactName, localStoreId) { 
        obj['SocietyName'] = societyName;
        obj['CountyId'] = countyId;
        obj['SocietyEmail'] = societyEmail;
        obj['SocietyMobile'] = societyMobile;
        obj['ContactName'] = contactName;
        obj['LocalStoreId'] = localStoreId;
    }

    /**
     * Constructs a <code>SocietyInfo</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/SocietyInfo} obj Optional instance to populate.
     * @return {module:model/SocietyInfo} The populated <code>SocietyInfo</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new SocietyInfo();

            if (data.hasOwnProperty('SocietyName')) {
                obj['SocietyName'] = ApiClient.convertToType(data['SocietyName'], 'String');
            }
            if (data.hasOwnProperty('SocietyTypeId')) {
                obj['SocietyTypeId'] = ApiClient.convertToType(data['SocietyTypeId'], 'Number');
            }
            if (data.hasOwnProperty('AddressLine1')) {
                obj['AddressLine1'] = ApiClient.convertToType(data['AddressLine1'], 'String');
            }
            if (data.hasOwnProperty('AddressLine2')) {
                obj['AddressLine2'] = ApiClient.convertToType(data['AddressLine2'], 'String');
            }
            if (data.hasOwnProperty('Town')) {
                obj['Town'] = ApiClient.convertToType(data['Town'], 'String');
            }
            if (data.hasOwnProperty('CountyId')) {
                obj['CountyId'] = ApiClient.convertToType(data['CountyId'], 'Number');
            }
            if (data.hasOwnProperty('SocietyEmail')) {
                obj['SocietyEmail'] = ApiClient.convertToType(data['SocietyEmail'], 'String');
            }
            if (data.hasOwnProperty('SocietyMobile')) {
                obj['SocietyMobile'] = ApiClient.convertToType(data['SocietyMobile'], 'String');
            }
            if (data.hasOwnProperty('SocietyWebsite')) {
                obj['SocietyWebsite'] = ApiClient.convertToType(data['SocietyWebsite'], 'String');
            }
            if (data.hasOwnProperty('SocietyVatNo')) {
                obj['SocietyVatNo'] = ApiClient.convertToType(data['SocietyVatNo'], 'String');
            }
            if (data.hasOwnProperty('ContactName')) {
                obj['ContactName'] = ApiClient.convertToType(data['ContactName'], 'String');
            }
            if (data.hasOwnProperty('ContactPosition')) {
                obj['ContactPosition'] = ApiClient.convertToType(data['ContactPosition'], 'String');
            }
            if (data.hasOwnProperty('LocalStoreId')) {
                obj['LocalStoreId'] = ApiClient.convertToType(data['LocalStoreId'], 'Number');
            }
            if (data.hasOwnProperty('ContactBySMS')) {
                obj['ContactBySMS'] = ApiClient.convertToType(data['ContactBySMS'], 'Boolean');
            }
            if (data.hasOwnProperty('ContactByEmail')) {
                obj['ContactByEmail'] = ApiClient.convertToType(data['ContactByEmail'], 'Boolean');
            }
            if (data.hasOwnProperty('ContactByPost')) {
                obj['ContactByPost'] = ApiClient.convertToType(data['ContactByPost'], 'Boolean');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>SocietyInfo</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>SocietyInfo</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of SocietyInfo.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['SocietyName'] && !(typeof data['SocietyName'] === 'string' || data['SocietyName'] instanceof String)) {
            throw new Error("Expected the field `SocietyName` to be a primitive type in the JSON string but got " + data['SocietyName']);
        }
        // ensure the json data is a string
        if (data['AddressLine1'] && !(typeof data['AddressLine1'] === 'string' || data['AddressLine1'] instanceof String)) {
            throw new Error("Expected the field `AddressLine1` to be a primitive type in the JSON string but got " + data['AddressLine1']);
        }
        // ensure the json data is a string
        if (data['AddressLine2'] && !(typeof data['AddressLine2'] === 'string' || data['AddressLine2'] instanceof String)) {
            throw new Error("Expected the field `AddressLine2` to be a primitive type in the JSON string but got " + data['AddressLine2']);
        }
        // ensure the json data is a string
        if (data['Town'] && !(typeof data['Town'] === 'string' || data['Town'] instanceof String)) {
            throw new Error("Expected the field `Town` to be a primitive type in the JSON string but got " + data['Town']);
        }
        // ensure the json data is a string
        if (data['SocietyEmail'] && !(typeof data['SocietyEmail'] === 'string' || data['SocietyEmail'] instanceof String)) {
            throw new Error("Expected the field `SocietyEmail` to be a primitive type in the JSON string but got " + data['SocietyEmail']);
        }
        // ensure the json data is a string
        if (data['SocietyMobile'] && !(typeof data['SocietyMobile'] === 'string' || data['SocietyMobile'] instanceof String)) {
            throw new Error("Expected the field `SocietyMobile` to be a primitive type in the JSON string but got " + data['SocietyMobile']);
        }
        // ensure the json data is a string
        if (data['SocietyWebsite'] && !(typeof data['SocietyWebsite'] === 'string' || data['SocietyWebsite'] instanceof String)) {
            throw new Error("Expected the field `SocietyWebsite` to be a primitive type in the JSON string but got " + data['SocietyWebsite']);
        }
        // ensure the json data is a string
        if (data['SocietyVatNo'] && !(typeof data['SocietyVatNo'] === 'string' || data['SocietyVatNo'] instanceof String)) {
            throw new Error("Expected the field `SocietyVatNo` to be a primitive type in the JSON string but got " + data['SocietyVatNo']);
        }
        // ensure the json data is a string
        if (data['ContactName'] && !(typeof data['ContactName'] === 'string' || data['ContactName'] instanceof String)) {
            throw new Error("Expected the field `ContactName` to be a primitive type in the JSON string but got " + data['ContactName']);
        }
        // ensure the json data is a string
        if (data['ContactPosition'] && !(typeof data['ContactPosition'] === 'string' || data['ContactPosition'] instanceof String)) {
            throw new Error("Expected the field `ContactPosition` to be a primitive type in the JSON string but got " + data['ContactPosition']);
        }

        return true;
    }


}

SocietyInfo.RequiredProperties = ["SocietyName", "CountyId", "SocietyEmail", "SocietyMobile", "ContactName", "LocalStoreId"];

/**
 * MaxLength:100
 * @member {String} SocietyName
 */
SocietyInfo.prototype['SocietyName'] = undefined;

/**
 * @member {Number} SocietyTypeId
 */
SocietyInfo.prototype['SocietyTypeId'] = undefined;

/**
 * MaxLength:100
 * @member {String} AddressLine1
 */
SocietyInfo.prototype['AddressLine1'] = undefined;

/**
 * MaxLength:100
 * @member {String} AddressLine2
 */
SocietyInfo.prototype['AddressLine2'] = undefined;

/**
 * MaxLength:100
 * @member {String} Town
 */
SocietyInfo.prototype['Town'] = undefined;

/**
 * @member {Number} CountyId
 */
SocietyInfo.prototype['CountyId'] = undefined;

/**
 * MaxLength:64
 * @member {String} SocietyEmail
 */
SocietyInfo.prototype['SocietyEmail'] = undefined;

/**
 * MaxLength:20
 * @member {String} SocietyMobile
 */
SocietyInfo.prototype['SocietyMobile'] = undefined;

/**
 * MaxLength:100
 * @member {String} SocietyWebsite
 */
SocietyInfo.prototype['SocietyWebsite'] = undefined;

/**
 * MaxLength:20
 * @member {String} SocietyVatNo
 */
SocietyInfo.prototype['SocietyVatNo'] = undefined;

/**
 * MaxLength:50
 * @member {String} ContactName
 */
SocietyInfo.prototype['ContactName'] = undefined;

/**
 * MaxLength:40
 * @member {String} ContactPosition
 */
SocietyInfo.prototype['ContactPosition'] = undefined;

/**
 * @member {Number} LocalStoreId
 */
SocietyInfo.prototype['LocalStoreId'] = undefined;

/**
 * @member {Boolean} ContactBySMS
 */
SocietyInfo.prototype['ContactBySMS'] = undefined;

/**
 * @member {Boolean} ContactByEmail
 */
SocietyInfo.prototype['ContactByEmail'] = undefined;

/**
 * @member {Boolean} ContactByPost
 */
SocietyInfo.prototype['ContactByPost'] = undefined;






export default SocietyInfo;

