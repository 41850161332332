import { Component } from "react";

export default class CKText extends Component {
    render() {
        return <span className={this.props.className} {...({
            style: {
                ...this.props.style, 
                ...(
                    this.props.style && this.props.style.paddingHorizontal ? {
                        paddingLeft: this.props.style.paddingHorizontal,
                        paddingRight: this.props.style.paddingHorizontal
                    } : {}
                    ),
                ...(
                    this.props.style && this.props.style.paddingVertical ? {
                        paddingTop: this.props.style.paddingVertical,
                        paddingBottom: this.props.style.paddingVertical
                    } : {}
                    )
                },
                ...(
                    this.props.style && this.props.style.marginHorizontal ? {
                        marginLeft: this.props.style.marginHorizontal,
                        marginRight: this.props.style.marginHorizontal
                    } : {}
                    ),
                ...(
                    this.props.style && this.props.style.marginVertical ? {
                        marginTop: this.props.style.marginVertical,
                        marginBottom: this.props.style.marginVertical
                    } : {}
                )
        })}>{this.props.children}</span>
    }
}