import moment from "moment";
import { CKIconType } from "../lib/components/CKIcon";
import View from "../lib/components/View";
import LoggedInBase from "./LoggedInBase";
import CKMenuPanel from "../lib/components/CKMenuPanel";
import { CKColors, CKPadding, CKSizes } from "../lib/Styles";
import CKText from "../lib/components/CKText";
import CKMenuItem from "../lib/components/CKMenuItem";
import CKSpacer from '../lib/components/CKSpacer';
import CKNavigator from "../lib/CKNavigator";

export default class Inbox extends LoggedInBase {
    screenName = 'inbox'
    title = 'Inbox'

    constructor(props) {
        super(props);
    
        this.state = {
            ...this.state,
            data: [],
            screenLoading: true
        }
    }

    componentDidMount() {
        super.componentDidMount()

        this.showScreenLoading();
    }

    async loadData() {
        try {
            let data = await this.refreshInbox(false);

            this.setState({
                data
            }, this.hideScreenLoading);
        } catch {}

        this.setState({
            isRefreshing: false
        })
    }

    shortenMessage = (message) => {
        let limit = 99;
        // lets shorten to 50 characters by words
        let count = 0;
        let parts = message.split(' ');
        let msg = '';
        for(var i = 0; i < parts.length; i++) {
            let p = parts[i];

            if(p.length + count > limit) {
                msg += '...';
                break;
            } else {
                if(msg) msg += ' ';
                msg += p;
                count += p.length;
            }
        }

        return msg;
    }

    render() {
        return super.render(
            <View className="inbox-container">
                {!this.state.data || this.state.data.length ===0 ? <CKMenuPanel className="inner-container">
                        <CKText className="empty">
                            No messages yet! Check again later.
                        </CKText>
                    </CKMenuPanel>
                    :
                    <View className="inner-container">
                        {this.state.data.map((item, index) => {
                            return <CKMenuItem {...(
                                    item.Read ? 
                                    {
                                        backgroundColor: CKColors.offWhite
                                    } 
                                    : 
                                    {
                                        descriptionMedium: true,
                                        bold: true
                                    }
                                )} titleStyle={{
                                fontSize: CKSizes.font.tiny,
                                color: item.Read ? CKColors.offBlack : CKColors.red,
                                marginTop: CKPadding.default
                            }} descriptionStyle={{
                                color: item.Read ? CKColors.offBlack : CKColors.offBlack,
                                marginTop: CKPadding.default / 2,
                                marginBottom: CKPadding.default
                            }} onPress={() => {
                                this.navigateTo(CKNavigator.inbox + '/' + item.CommunicationId)
                            }} arrowIconType={item.Read ? CKIconType.arrow_right_gray : undefined} description={this.shortenMessage(item.Message)} border={index ===0 ? 1 : 0} key={item.CommunicationId} title={moment(item.DateCreated).format('DD/MM/YYYY')} />
                        })}
                    </View>
                }

                <CKSpacer />
            </View>
        )
    }
}