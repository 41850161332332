/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import APIResponse from '../model/APIResponse';
import RedeemReward from '../model/RedeemReward';
import Reward from '../model/Reward';

/**
* Rewards service.
* @module api/RewardsApi
* @version v1
*/
export default class RewardsApi {

    /**
    * Constructs a new RewardsApi. 
    * @alias module:api/RewardsApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the rewardsV1GetMemberReward operation.
     * @callback module:api/RewardsApi~rewardsV1GetMemberRewardCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Reward} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get Member Reward
     * Get details of a specific reward for a member   
     * @param {String} memberUId Member Unique Identifier
     * @param {Number} rewardId ID of Reward
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:api/RewardsApi~rewardsV1GetMemberRewardCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Reward}
     */
    rewardsV1GetMemberReward(memberUId, rewardId, clientId, authorization, callback) {
      let postBody = null;
      // verify the required parameter 'memberUId' is set
      if (memberUId === undefined || memberUId === null) {
        throw new Error("Missing the required parameter 'memberUId' when calling rewardsV1GetMemberReward");
      }
      // verify the required parameter 'rewardId' is set
      if (rewardId === undefined || rewardId === null) {
        throw new Error("Missing the required parameter 'rewardId' when calling rewardsV1GetMemberReward");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling rewardsV1GetMemberReward");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling rewardsV1GetMemberReward");
      }

      let pathParams = {
        'memberUId': memberUId,
        'rewardId': rewardId
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = Reward;
      return this.apiClient.callApi(
        '/v1/rewards/{rewardId}/Members/{memberUId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the rewardsV1GetMemberRewards operation.
     * @callback module:api/RewardsApi~rewardsV1GetMemberRewardsCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/Reward>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * List Member Rewards
     * List details of all rewards for a member   
     * @param {String} memberUId Member Unique Identifier
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {Object} opts Optional parameters
     * @param {String} [sortOrder] 1 - Points Cost descending    2 - Points Cost ascending    3 - Reward Name descending    4 - Reward Name ascending
     * @param {Number} [maxNo] Set to 0 for ALL
     * @param {module:api/RewardsApi~rewardsV1GetMemberRewardsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/Reward>}
     */
    rewardsV1GetMemberRewards(memberUId, clientId, authorization, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'memberUId' is set
      if (memberUId === undefined || memberUId === null) {
        throw new Error("Missing the required parameter 'memberUId' when calling rewardsV1GetMemberRewards");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling rewardsV1GetMemberRewards");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling rewardsV1GetMemberRewards");
      }

      let pathParams = {
        'memberUId': memberUId
      };
      let queryParams = {
        'sortOrder': opts['sortOrder'],
        'maxNo': opts['maxNo']
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = [Reward];
      return this.apiClient.callApi(
        '/v1/rewards/Members/{memberUId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the rewardsV1GetReward operation.
     * @callback module:api/RewardsApi~rewardsV1GetRewardCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Reward} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get Reward
     * Get details of a specific reward   
     * @param {Number} rewardId ID of Reward
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:api/RewardsApi~rewardsV1GetRewardCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Reward}
     */
    rewardsV1GetReward(rewardId, clientId, authorization, callback) {
      let postBody = null;
      // verify the required parameter 'rewardId' is set
      if (rewardId === undefined || rewardId === null) {
        throw new Error("Missing the required parameter 'rewardId' when calling rewardsV1GetReward");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling rewardsV1GetReward");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling rewardsV1GetReward");
      }

      let pathParams = {
        'rewardId': rewardId
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = Reward;
      return this.apiClient.callApi(
        '/v1/rewards/{rewardId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the rewardsV1GetRewardsV1 operation.
     * @callback module:api/RewardsApi~rewardsV1GetRewardsV1Callback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/Reward>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * List Rewards
     * List details of all rewards   
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {Object} opts Optional parameters
     * @param {String} [sortOrder] 1 - Points Cost descending    2 - Points Cost ascending    3 - Reward Name descending    4 - Reward Name ascending
     * @param {Number} [maxNo] Set to 0 for ALL
     * @param {module:api/RewardsApi~rewardsV1GetRewardsV1Callback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/Reward>}
     */
    rewardsV1GetRewardsV1(clientId, authorization, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling rewardsV1GetRewardsV1");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling rewardsV1GetRewardsV1");
      }

      let pathParams = {
      };
      let queryParams = {
        'sortOrder': opts['sortOrder'],
        'maxNo': opts['maxNo']
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = [Reward];
      return this.apiClient.callApi(
        '/v1/rewards', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the rewardsV1RedeemReward operation.
     * @callback module:api/RewardsApi~rewardsV1RedeemRewardCallback
     * @param {String} error Error message, if any.
     * @param {module:model/RedeemReward} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Add Reward Redemption
     * Add a reward redemption for a member   
     * @param {String} memberUId Member Unique Identifier
     * @param {Number} rewardId ID of Reward
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:api/RewardsApi~rewardsV1RedeemRewardCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/RedeemReward}
     */
    rewardsV1RedeemReward(memberUId, rewardId, clientId, authorization, callback) {
      let postBody = null;
      // verify the required parameter 'memberUId' is set
      if (memberUId === undefined || memberUId === null) {
        throw new Error("Missing the required parameter 'memberUId' when calling rewardsV1RedeemReward");
      }
      // verify the required parameter 'rewardId' is set
      if (rewardId === undefined || rewardId === null) {
        throw new Error("Missing the required parameter 'rewardId' when calling rewardsV1RedeemReward");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling rewardsV1RedeemReward");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling rewardsV1RedeemReward");
      }

      let pathParams = {
        'memberUId': memberUId,
        'rewardId': rewardId
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = RedeemReward;
      return this.apiClient.callApi(
        '/v1/rewards/{rewardId}/Members/{memberUId}/Redeem', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
