import { Component } from "react";
import Cookies from 'js-cookie';
import { Navigate } from 'react-router-dom';
import CKLoading from "./components/CKLoading";

class Base extends Component {
    constructor(props) {
        super(props);

        this.state = {
            
        }
    }

    showLoading(loadingText = 'Loading...') {
        this.setState({
            loading: true,
            loadingText
        })
    }

    hideLoading() {
        this.setState({
            loading: false
        });
    }

    navigateTo(path){
        this.setState({
            redirectTo: path
        }, () => {
            if(window.parentIFrame) {
                window.parentIFrame.sendMessage("scroll-to-top");
            } else {
                window.scrollTo(0, 0);
            }
            
        })
    }

    getRefreshToken = () => {
        return Cookies.get('refresh_token');
    }

    render(children) {
        if(this.state.redirectTo) {
            return <Navigate to={this.state.redirectTo} replace={false} />
        }

        if(this.state.loading) {
            return <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: 240
            }}>
                <div style={{
                    position: "absolute",
                    top: "50%",
                    transform: "translate(0px, -50%)",
                }}>
                    <CKLoading red width={67} text={this.state.loadingText} />
                </div>
            </div>
        }

        return children;
    }
}

export default Base;