/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The CardDetail model module.
 * @module model/CardDetail
 * @version v1
 */
class CardDetail {
    /**
     * Constructs a new <code>CardDetail</code>.
     * @alias module:model/CardDetail
     * @param loyaltyCardNum {String} MaxLength:25
     * @param pointsBalance {Number} 
     * @param pointsValue {Number} 
     * @param prepaidBalance {Number} 
     * @param isVirtualCard {Boolean} 
     */
    constructor(loyaltyCardNum, pointsBalance, pointsValue, prepaidBalance, isVirtualCard) { 
        
        CardDetail.initialize(this, loyaltyCardNum, pointsBalance, pointsValue, prepaidBalance, isVirtualCard);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, loyaltyCardNum, pointsBalance, pointsValue, prepaidBalance, isVirtualCard) { 
        obj['LoyaltyCardNum'] = loyaltyCardNum;
        obj['PointsBalance'] = pointsBalance;
        obj['PointsValue'] = pointsValue;
        obj['PrepaidBalance'] = prepaidBalance;
        obj['IsVirtualCard'] = isVirtualCard;
    }

    /**
     * Constructs a <code>CardDetail</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CardDetail} obj Optional instance to populate.
     * @return {module:model/CardDetail} The populated <code>CardDetail</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CardDetail();

            if (data.hasOwnProperty('LoyaltyCardNum')) {
                obj['LoyaltyCardNum'] = ApiClient.convertToType(data['LoyaltyCardNum'], 'String');
            }
            if (data.hasOwnProperty('PointsBalance')) {
                obj['PointsBalance'] = ApiClient.convertToType(data['PointsBalance'], 'Number');
            }
            if (data.hasOwnProperty('PointsValue')) {
                obj['PointsValue'] = ApiClient.convertToType(data['PointsValue'], 'Number');
            }
            if (data.hasOwnProperty('PrepaidBalance')) {
                obj['PrepaidBalance'] = ApiClient.convertToType(data['PrepaidBalance'], 'Number');
            }
            if (data.hasOwnProperty('CardType')) {
                obj['CardType'] = ApiClient.convertToType(data['CardType'], 'String');
            }
            if (data.hasOwnProperty('CardStatus')) {
                obj['CardStatus'] = ApiClient.convertToType(data['CardStatus'], 'String');
            }
            if (data.hasOwnProperty('IsVirtualCard')) {
                obj['IsVirtualCard'] = ApiClient.convertToType(data['IsVirtualCard'], 'Boolean');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>CardDetail</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CardDetail</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of CardDetail.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['LoyaltyCardNum'] && !(typeof data['LoyaltyCardNum'] === 'string' || data['LoyaltyCardNum'] instanceof String)) {
            throw new Error("Expected the field `LoyaltyCardNum` to be a primitive type in the JSON string but got " + data['LoyaltyCardNum']);
        }
        // ensure the json data is a string
        if (data['CardType'] && !(typeof data['CardType'] === 'string' || data['CardType'] instanceof String)) {
            throw new Error("Expected the field `CardType` to be a primitive type in the JSON string but got " + data['CardType']);
        }
        // ensure the json data is a string
        if (data['CardStatus'] && !(typeof data['CardStatus'] === 'string' || data['CardStatus'] instanceof String)) {
            throw new Error("Expected the field `CardStatus` to be a primitive type in the JSON string but got " + data['CardStatus']);
        }

        return true;
    }


}

CardDetail.RequiredProperties = ["LoyaltyCardNum", "PointsBalance", "PointsValue", "PrepaidBalance", "IsVirtualCard"];

/**
 * MaxLength:25
 * @member {String} LoyaltyCardNum
 */
CardDetail.prototype['LoyaltyCardNum'] = undefined;

/**
 * @member {Number} PointsBalance
 */
CardDetail.prototype['PointsBalance'] = undefined;

/**
 * @member {Number} PointsValue
 */
CardDetail.prototype['PointsValue'] = undefined;

/**
 * @member {Number} PrepaidBalance
 */
CardDetail.prototype['PrepaidBalance'] = undefined;

/**
 * MaxLength:100
 * @member {String} CardType
 */
CardDetail.prototype['CardType'] = undefined;

/**
 * MaxLength:50
 * @member {String} CardStatus
 */
CardDetail.prototype['CardStatus'] = undefined;

/**
 * @member {Boolean} IsVirtualCard
 */
CardDetail.prototype['IsVirtualCard'] = undefined;






export default CardDetail;

