/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The Reward model module.
 * @module model/Reward
 * @version v1
 */
class Reward {
    /**
     * Constructs a new <code>Reward</code>.
     * @alias module:model/Reward
     * @param rewardId {Number} 
     * @param startDate {String} Format [yyyy-MM-dd HH:mm:ss] UTC
     * @param endDate {String} Format [yyyy-MM-dd HH:mm:ss] UTC
     * @param rewardCategory {String} MaxLength:100
     * @param categoryAppImageLocation {String} MaxLength:1000
     * @param categoryWebImageLocation {String} MaxLength:1000
     * @param categoryThumbnailLocation {String} MaxLength:1000
     * @param rewardName {String} MaxLength:500
     * @param rewardDetail {String} MaxLength:8000
     * @param providerName {String} MaxLength:500
     * @param pointsCost {Number} 
     * @param tandC {String} MaxLength:8000
     * @param appImageLocation {String} MaxLength:1000
     * @param webImageLocation {String} MaxLength:1000
     * @param thumbnailImageLocation {String} MaxLength:1000
     * @param rewardValue {Number} 
     * @param maxUnits {Number} 
     */
    constructor(rewardId, startDate, endDate, rewardCategory, categoryAppImageLocation, categoryWebImageLocation, categoryThumbnailLocation, rewardName, rewardDetail, providerName, pointsCost, tandC, appImageLocation, webImageLocation, thumbnailImageLocation, rewardValue, maxUnits) { 
        
        Reward.initialize(this, rewardId, startDate, endDate, rewardCategory, categoryAppImageLocation, categoryWebImageLocation, categoryThumbnailLocation, rewardName, rewardDetail, providerName, pointsCost, tandC, appImageLocation, webImageLocation, thumbnailImageLocation, rewardValue, maxUnits);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, rewardId, startDate, endDate, rewardCategory, categoryAppImageLocation, categoryWebImageLocation, categoryThumbnailLocation, rewardName, rewardDetail, providerName, pointsCost, tandC, appImageLocation, webImageLocation, thumbnailImageLocation, rewardValue, maxUnits) { 
        obj['rewardId'] = rewardId;
        obj['StartDate'] = startDate;
        obj['EndDate'] = endDate;
        obj['RewardCategory'] = rewardCategory;
        obj['CategoryAppImageLocation'] = categoryAppImageLocation;
        obj['CategoryWebImageLocation'] = categoryWebImageLocation;
        obj['CategoryThumbnailLocation'] = categoryThumbnailLocation;
        obj['RewardName'] = rewardName;
        obj['RewardDetail'] = rewardDetail;
        obj['ProviderName'] = providerName;
        obj['PointsCost'] = pointsCost;
        obj['TandC'] = tandC;
        obj['AppImageLocation'] = appImageLocation;
        obj['WebImageLocation'] = webImageLocation;
        obj['ThumbnailImageLocation'] = thumbnailImageLocation;
        obj['RewardValue'] = rewardValue;
        obj['MaxUnits'] = maxUnits;
    }

    /**
     * Constructs a <code>Reward</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Reward} obj Optional instance to populate.
     * @return {module:model/Reward} The populated <code>Reward</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Reward();

            if (data.hasOwnProperty('rewardId')) {
                obj['rewardId'] = ApiClient.convertToType(data['rewardId'], 'Number');
            }
            if (data.hasOwnProperty('StartDate')) {
                obj['StartDate'] = ApiClient.convertToType(data['StartDate'], 'String');
            }
            if (data.hasOwnProperty('EndDate')) {
                obj['EndDate'] = ApiClient.convertToType(data['EndDate'], 'String');
            }
            if (data.hasOwnProperty('RewardCategory')) {
                obj['RewardCategory'] = ApiClient.convertToType(data['RewardCategory'], 'String');
            }
            if (data.hasOwnProperty('CategoryAppImageLocation')) {
                obj['CategoryAppImageLocation'] = ApiClient.convertToType(data['CategoryAppImageLocation'], 'String');
            }
            if (data.hasOwnProperty('CategoryWebImageLocation')) {
                obj['CategoryWebImageLocation'] = ApiClient.convertToType(data['CategoryWebImageLocation'], 'String');
            }
            if (data.hasOwnProperty('CategoryThumbnailLocation')) {
                obj['CategoryThumbnailLocation'] = ApiClient.convertToType(data['CategoryThumbnailLocation'], 'String');
            }
            if (data.hasOwnProperty('RewardName')) {
                obj['RewardName'] = ApiClient.convertToType(data['RewardName'], 'String');
            }
            if (data.hasOwnProperty('RewardDetail')) {
                obj['RewardDetail'] = ApiClient.convertToType(data['RewardDetail'], 'String');
            }
            if (data.hasOwnProperty('ProviderName')) {
                obj['ProviderName'] = ApiClient.convertToType(data['ProviderName'], 'String');
            }
            if (data.hasOwnProperty('PointsCost')) {
                obj['PointsCost'] = ApiClient.convertToType(data['PointsCost'], 'Number');
            }
            if (data.hasOwnProperty('TandC')) {
                obj['TandC'] = ApiClient.convertToType(data['TandC'], 'String');
            }
            if (data.hasOwnProperty('AppImageLocation')) {
                obj['AppImageLocation'] = ApiClient.convertToType(data['AppImageLocation'], 'String');
            }
            if (data.hasOwnProperty('WebImageLocation')) {
                obj['WebImageLocation'] = ApiClient.convertToType(data['WebImageLocation'], 'String');
            }
            if (data.hasOwnProperty('ThumbnailImageLocation')) {
                obj['ThumbnailImageLocation'] = ApiClient.convertToType(data['ThumbnailImageLocation'], 'String');
            }
            if (data.hasOwnProperty('RewardValue')) {
                obj['RewardValue'] = ApiClient.convertToType(data['RewardValue'], 'Number');
            }
            if (data.hasOwnProperty('MaxUnits')) {
                obj['MaxUnits'] = ApiClient.convertToType(data['MaxUnits'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Reward</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Reward</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of Reward.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['StartDate'] && !(typeof data['StartDate'] === 'string' || data['StartDate'] instanceof String)) {
            throw new Error("Expected the field `StartDate` to be a primitive type in the JSON string but got " + data['StartDate']);
        }
        // ensure the json data is a string
        if (data['EndDate'] && !(typeof data['EndDate'] === 'string' || data['EndDate'] instanceof String)) {
            throw new Error("Expected the field `EndDate` to be a primitive type in the JSON string but got " + data['EndDate']);
        }
        // ensure the json data is a string
        if (data['RewardCategory'] && !(typeof data['RewardCategory'] === 'string' || data['RewardCategory'] instanceof String)) {
            throw new Error("Expected the field `RewardCategory` to be a primitive type in the JSON string but got " + data['RewardCategory']);
        }
        // ensure the json data is a string
        if (data['CategoryAppImageLocation'] && !(typeof data['CategoryAppImageLocation'] === 'string' || data['CategoryAppImageLocation'] instanceof String)) {
            throw new Error("Expected the field `CategoryAppImageLocation` to be a primitive type in the JSON string but got " + data['CategoryAppImageLocation']);
        }
        // ensure the json data is a string
        if (data['CategoryWebImageLocation'] && !(typeof data['CategoryWebImageLocation'] === 'string' || data['CategoryWebImageLocation'] instanceof String)) {
            throw new Error("Expected the field `CategoryWebImageLocation` to be a primitive type in the JSON string but got " + data['CategoryWebImageLocation']);
        }
        // ensure the json data is a string
        if (data['CategoryThumbnailLocation'] && !(typeof data['CategoryThumbnailLocation'] === 'string' || data['CategoryThumbnailLocation'] instanceof String)) {
            throw new Error("Expected the field `CategoryThumbnailLocation` to be a primitive type in the JSON string but got " + data['CategoryThumbnailLocation']);
        }
        // ensure the json data is a string
        if (data['RewardName'] && !(typeof data['RewardName'] === 'string' || data['RewardName'] instanceof String)) {
            throw new Error("Expected the field `RewardName` to be a primitive type in the JSON string but got " + data['RewardName']);
        }
        // ensure the json data is a string
        if (data['RewardDetail'] && !(typeof data['RewardDetail'] === 'string' || data['RewardDetail'] instanceof String)) {
            throw new Error("Expected the field `RewardDetail` to be a primitive type in the JSON string but got " + data['RewardDetail']);
        }
        // ensure the json data is a string
        if (data['ProviderName'] && !(typeof data['ProviderName'] === 'string' || data['ProviderName'] instanceof String)) {
            throw new Error("Expected the field `ProviderName` to be a primitive type in the JSON string but got " + data['ProviderName']);
        }
        // ensure the json data is a string
        if (data['TandC'] && !(typeof data['TandC'] === 'string' || data['TandC'] instanceof String)) {
            throw new Error("Expected the field `TandC` to be a primitive type in the JSON string but got " + data['TandC']);
        }
        // ensure the json data is a string
        if (data['AppImageLocation'] && !(typeof data['AppImageLocation'] === 'string' || data['AppImageLocation'] instanceof String)) {
            throw new Error("Expected the field `AppImageLocation` to be a primitive type in the JSON string but got " + data['AppImageLocation']);
        }
        // ensure the json data is a string
        if (data['WebImageLocation'] && !(typeof data['WebImageLocation'] === 'string' || data['WebImageLocation'] instanceof String)) {
            throw new Error("Expected the field `WebImageLocation` to be a primitive type in the JSON string but got " + data['WebImageLocation']);
        }
        // ensure the json data is a string
        if (data['ThumbnailImageLocation'] && !(typeof data['ThumbnailImageLocation'] === 'string' || data['ThumbnailImageLocation'] instanceof String)) {
            throw new Error("Expected the field `ThumbnailImageLocation` to be a primitive type in the JSON string but got " + data['ThumbnailImageLocation']);
        }

        return true;
    }


}

Reward.RequiredProperties = ["rewardId", "StartDate", "EndDate", "RewardCategory", "CategoryAppImageLocation", "CategoryWebImageLocation", "CategoryThumbnailLocation", "RewardName", "RewardDetail", "ProviderName", "PointsCost", "TandC", "AppImageLocation", "WebImageLocation", "ThumbnailImageLocation", "RewardValue", "MaxUnits"];

/**
 * @member {Number} rewardId
 */
Reward.prototype['rewardId'] = undefined;

/**
 * Format [yyyy-MM-dd HH:mm:ss] UTC
 * @member {String} StartDate
 */
Reward.prototype['StartDate'] = undefined;

/**
 * Format [yyyy-MM-dd HH:mm:ss] UTC
 * @member {String} EndDate
 */
Reward.prototype['EndDate'] = undefined;

/**
 * MaxLength:100
 * @member {String} RewardCategory
 */
Reward.prototype['RewardCategory'] = undefined;

/**
 * MaxLength:1000
 * @member {String} CategoryAppImageLocation
 */
Reward.prototype['CategoryAppImageLocation'] = undefined;

/**
 * MaxLength:1000
 * @member {String} CategoryWebImageLocation
 */
Reward.prototype['CategoryWebImageLocation'] = undefined;

/**
 * MaxLength:1000
 * @member {String} CategoryThumbnailLocation
 */
Reward.prototype['CategoryThumbnailLocation'] = undefined;

/**
 * MaxLength:500
 * @member {String} RewardName
 */
Reward.prototype['RewardName'] = undefined;

/**
 * MaxLength:8000
 * @member {String} RewardDetail
 */
Reward.prototype['RewardDetail'] = undefined;

/**
 * MaxLength:500
 * @member {String} ProviderName
 */
Reward.prototype['ProviderName'] = undefined;

/**
 * @member {Number} PointsCost
 */
Reward.prototype['PointsCost'] = undefined;

/**
 * MaxLength:8000
 * @member {String} TandC
 */
Reward.prototype['TandC'] = undefined;

/**
 * MaxLength:1000
 * @member {String} AppImageLocation
 */
Reward.prototype['AppImageLocation'] = undefined;

/**
 * MaxLength:1000
 * @member {String} WebImageLocation
 */
Reward.prototype['WebImageLocation'] = undefined;

/**
 * MaxLength:1000
 * @member {String} ThumbnailImageLocation
 */
Reward.prototype['ThumbnailImageLocation'] = undefined;

/**
 * @member {Number} RewardValue
 */
Reward.prototype['RewardValue'] = undefined;

/**
 * @member {Number} MaxUnits
 */
Reward.prototype['MaxUnits'] = undefined;






export default Reward;

