import React from 'react';
import { CKColors, CKPadding } from '../Styles';
import CKIcon, { CKIconType } from './CKIcon';
import CKText from './CKText';
import CKButton from './CKButton';
import View from './View';

const CKMenuItem = (props) => {
    return <CKButton 
        custom
        className={"ck-menu-item-container " + (props.className ?? '')}
        customChildren={(isPress) => {
            return <View className="inner" style={{
                backgroundColor: isPress && props.onPress ? CKColors.borderColor : (props.backgroundColor ?? CKColors.white),
                border: (props.full_border_off ? "0" : "1") + "px solid " + CKColors.borderColor,
                borderTop: (props.border ?? 0) + "px solid " + CKColors.borderColor
            }}>
               {props.icon}
    
                <View className={'ck-menu-item-inner-container ' + (props.icon ? 'has-icon' : '')} style={{
                    paddingRight: props.onPress && !props.skipArrow ? CKPadding.default : 0
                }}>
                    <CKText className={'first-line' + ' ' + (props.bold ? 'bold' : '') + ' ' + (props.medium ? 'medium' : '')} style={{
                        ...props.titleStyle
                    }}>{props.title}</CKText>
                    {
                        props.description ? <CKText className={'second-line' + ' ' + (props.descriptionMedium ? 'medium' : '')} style={{
                            ...props.descriptionStyle
                        }}>{props.description}</CKText>
                        :
                        null
                    }
                </View>
    
               {props.onPress && !props.skipArrow ? <CKIcon width={9} height={15} icon={props.arrowIconType ? props.arrowIconType : CKIconType.arrow_right} /> : null}
    
               {props.right ?? null}
            </View>
        }}
        onPress={props.onPress} />
}

export default CKMenuItem;