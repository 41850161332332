/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The MemberClub model module.
 * @module model/MemberClub
 * @version v1
 */
class MemberClub {
    /**
     * Constructs a new <code>MemberClub</code>.
     * @alias module:model/MemberClub
     * @param clubId {Number} 
     * @param clubName {String} MaxLength:500
     * @param clubType {String} MaxLength:1
     * @param dateCreated {String} Format [yyyy-MM-dd HH:mm:ss] UTC
     * @param dateJoined {String} Format [yyyy-MM-dd HH:mm:ss] UTC
     */
    constructor(clubId, clubName, clubType, dateCreated, dateJoined) { 
        
        MemberClub.initialize(this, clubId, clubName, clubType, dateCreated, dateJoined);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, clubId, clubName, clubType, dateCreated, dateJoined) { 
        obj['ClubId'] = clubId;
        obj['ClubName'] = clubName;
        obj['ClubType'] = clubType;
        obj['DateCreated'] = dateCreated;
        obj['DateJoined'] = dateJoined;
    }

    /**
     * Constructs a <code>MemberClub</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/MemberClub} obj Optional instance to populate.
     * @return {module:model/MemberClub} The populated <code>MemberClub</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MemberClub();

            if (data.hasOwnProperty('ClubId')) {
                obj['ClubId'] = ApiClient.convertToType(data['ClubId'], 'Number');
            }
            if (data.hasOwnProperty('ClubName')) {
                obj['ClubName'] = ApiClient.convertToType(data['ClubName'], 'String');
            }
            if (data.hasOwnProperty('ClubType')) {
                obj['ClubType'] = ApiClient.convertToType(data['ClubType'], 'String');
            }
            if (data.hasOwnProperty('DateCreated')) {
                obj['DateCreated'] = ApiClient.convertToType(data['DateCreated'], 'String');
            }
            if (data.hasOwnProperty('DateJoined')) {
                obj['DateJoined'] = ApiClient.convertToType(data['DateJoined'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>MemberClub</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>MemberClub</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of MemberClub.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['ClubName'] && !(typeof data['ClubName'] === 'string' || data['ClubName'] instanceof String)) {
            throw new Error("Expected the field `ClubName` to be a primitive type in the JSON string but got " + data['ClubName']);
        }
        // ensure the json data is a string
        if (data['ClubType'] && !(typeof data['ClubType'] === 'string' || data['ClubType'] instanceof String)) {
            throw new Error("Expected the field `ClubType` to be a primitive type in the JSON string but got " + data['ClubType']);
        }
        // ensure the json data is a string
        if (data['DateCreated'] && !(typeof data['DateCreated'] === 'string' || data['DateCreated'] instanceof String)) {
            throw new Error("Expected the field `DateCreated` to be a primitive type in the JSON string but got " + data['DateCreated']);
        }
        // ensure the json data is a string
        if (data['DateJoined'] && !(typeof data['DateJoined'] === 'string' || data['DateJoined'] instanceof String)) {
            throw new Error("Expected the field `DateJoined` to be a primitive type in the JSON string but got " + data['DateJoined']);
        }

        return true;
    }


}

MemberClub.RequiredProperties = ["ClubId", "ClubName", "ClubType", "DateCreated", "DateJoined"];

/**
 * @member {Number} ClubId
 */
MemberClub.prototype['ClubId'] = undefined;

/**
 * MaxLength:500
 * @member {String} ClubName
 */
MemberClub.prototype['ClubName'] = undefined;

/**
 * MaxLength:1
 * @member {String} ClubType
 */
MemberClub.prototype['ClubType'] = undefined;

/**
 * Format [yyyy-MM-dd HH:mm:ss] UTC
 * @member {String} DateCreated
 */
MemberClub.prototype['DateCreated'] = undefined;

/**
 * Format [yyyy-MM-dd HH:mm:ss] UTC
 * @member {String} DateJoined
 */
MemberClub.prototype['DateJoined'] = undefined;






export default MemberClub;

