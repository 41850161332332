/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The Donate model module.
 * @module model/Donate
 * @version v1
 */
class Donate {
    /**
     * Constructs a new <code>Donate</code>.
     * @alias module:model/Donate
     * @param donateSubject {String} MaxLength:500
     * @param donateMessage {String} MaxLength:8000
     * @param displayQRCode {Boolean} 
     */
    constructor(donateSubject, donateMessage, displayQRCode) { 
        
        Donate.initialize(this, donateSubject, donateMessage, displayQRCode);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, donateSubject, donateMessage, displayQRCode) { 
        obj['DonateSubject'] = donateSubject;
        obj['DonateMessage'] = donateMessage;
        obj['DisplayQRCode'] = displayQRCode;
    }

    /**
     * Constructs a <code>Donate</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Donate} obj Optional instance to populate.
     * @return {module:model/Donate} The populated <code>Donate</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Donate();

            if (data.hasOwnProperty('DonateSubject')) {
                obj['DonateSubject'] = ApiClient.convertToType(data['DonateSubject'], 'String');
            }
            if (data.hasOwnProperty('DonateMessage')) {
                obj['DonateMessage'] = ApiClient.convertToType(data['DonateMessage'], 'String');
            }
            if (data.hasOwnProperty('DisplayQRCode')) {
                obj['DisplayQRCode'] = ApiClient.convertToType(data['DisplayQRCode'], 'Boolean');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Donate</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Donate</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of Donate.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['DonateSubject'] && !(typeof data['DonateSubject'] === 'string' || data['DonateSubject'] instanceof String)) {
            throw new Error("Expected the field `DonateSubject` to be a primitive type in the JSON string but got " + data['DonateSubject']);
        }
        // ensure the json data is a string
        if (data['DonateMessage'] && !(typeof data['DonateMessage'] === 'string' || data['DonateMessage'] instanceof String)) {
            throw new Error("Expected the field `DonateMessage` to be a primitive type in the JSON string but got " + data['DonateMessage']);
        }

        return true;
    }


}

Donate.RequiredProperties = ["DonateSubject", "DonateMessage", "DisplayQRCode"];

/**
 * MaxLength:500
 * @member {String} DonateSubject
 */
Donate.prototype['DonateSubject'] = undefined;

/**
 * MaxLength:8000
 * @member {String} DonateMessage
 */
Donate.prototype['DonateMessage'] = undefined;

/**
 * @member {Boolean} DisplayQRCode
 */
Donate.prototype['DisplayQRCode'] = undefined;






export default Donate;

