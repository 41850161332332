import React, { Component } from 'react';
import CKButton from './CKButton';

import { ReactComponent as IconHomeOn } from '../../images/icon_home_on.svg';
import { ReactComponent as IconHome } from '../../images/icon_home.svg';
import { ReactComponent as IconInboxOn } from '../../images/icon_inbox_on.svg';
import { ReactComponent as IconInbox } from '../../images/icon_inbox.svg';
import { ReactComponent as IconMoreOn } from '../../images/icon_more_on.svg';
import { ReactComponent as IconMore } from '../../images/icon_more.svg';

export default class CKButtonSide extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return <CKButton className={"ck-side-buton-special-main " + (this.props.selected ? 'selected' : '')} onPress={this.props.onPress} custom customChildren={(isHover) => {
            var iconStyle = {
                width: 36,
                height: 36,
                marginTop: 7
            };

            return <div className={'side-button-custom'}>
                <div className={"ck-side-button-special "}>
                    {this.props.selected ? <div className="ck-side-button-deco"></div> : null}
                    {this.props.icon ==='home' ? (this.props.selected ? <IconHomeOn style={iconStyle} /> : <IconHome style={iconStyle} />) : null}
                    {this.props.icon ==='inbox' ? (this.props.selected ? <IconInboxOn style={iconStyle} /> : <IconInbox style={iconStyle} />) : null}
                    {this.props.icon ==='more' ? (this.props.selected ? <IconMoreOn style={iconStyle} /> : <IconMore style={iconStyle} />) : null}
                </div>

                <div className="ck-side-button-special-label">
                    {this.props.label}
                </div>
            </div>
        }} />
    }
}