/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The UpdatePassword model module.
 * @module model/UpdatePassword
 * @version v1
 */
class UpdatePassword {
    /**
     * Constructs a new <code>UpdatePassword</code>.
     * @alias module:model/UpdatePassword
     * @param oldPassword {String} MaxLength:64
     * @param newPassword {String} MaxLength:64
     * @param confirmNewPassword {String} MaxLength:64
     */
    constructor(oldPassword, newPassword, confirmNewPassword) { 
        
        UpdatePassword.initialize(this, oldPassword, newPassword, confirmNewPassword);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, oldPassword, newPassword, confirmNewPassword) { 
        obj['OldPassword'] = oldPassword;
        obj['NewPassword'] = newPassword;
        obj['ConfirmNewPassword'] = confirmNewPassword;
    }

    /**
     * Constructs a <code>UpdatePassword</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/UpdatePassword} obj Optional instance to populate.
     * @return {module:model/UpdatePassword} The populated <code>UpdatePassword</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new UpdatePassword();

            if (data.hasOwnProperty('OldPassword')) {
                obj['OldPassword'] = ApiClient.convertToType(data['OldPassword'], 'String');
            }
            if (data.hasOwnProperty('NewPassword')) {
                obj['NewPassword'] = ApiClient.convertToType(data['NewPassword'], 'String');
            }
            if (data.hasOwnProperty('ConfirmNewPassword')) {
                obj['ConfirmNewPassword'] = ApiClient.convertToType(data['ConfirmNewPassword'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>UpdatePassword</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>UpdatePassword</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of UpdatePassword.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['OldPassword'] && !(typeof data['OldPassword'] === 'string' || data['OldPassword'] instanceof String)) {
            throw new Error("Expected the field `OldPassword` to be a primitive type in the JSON string but got " + data['OldPassword']);
        }
        // ensure the json data is a string
        if (data['NewPassword'] && !(typeof data['NewPassword'] === 'string' || data['NewPassword'] instanceof String)) {
            throw new Error("Expected the field `NewPassword` to be a primitive type in the JSON string but got " + data['NewPassword']);
        }
        // ensure the json data is a string
        if (data['ConfirmNewPassword'] && !(typeof data['ConfirmNewPassword'] === 'string' || data['ConfirmNewPassword'] instanceof String)) {
            throw new Error("Expected the field `ConfirmNewPassword` to be a primitive type in the JSON string but got " + data['ConfirmNewPassword']);
        }

        return true;
    }


}

UpdatePassword.RequiredProperties = ["OldPassword", "NewPassword", "ConfirmNewPassword"];

/**
 * MaxLength:64
 * @member {String} OldPassword
 */
UpdatePassword.prototype['OldPassword'] = undefined;

/**
 * MaxLength:64
 * @member {String} NewPassword
 */
UpdatePassword.prototype['NewPassword'] = undefined;

/**
 * MaxLength:64
 * @member {String} ConfirmNewPassword
 */
UpdatePassword.prototype['ConfirmNewPassword'] = undefined;






export default UpdatePassword;

