import CKNavigator from "../lib/CKNavigator";
import CKMenuItem from "../lib/components/CKMenuItem";
import CKMenuPanel from "../lib/components/CKMenuPanel";
import CKSpacer from "../lib/components/CKSpacer";
import View from "../lib/components/View";
import LoggedInBase from "./LoggedInBase";

export default class HelpAndSupport extends LoggedInBase {
    screenName = 'more'
    title = 'Help & Support'
    subLevelPage = true

    constructor(props) {
        super(props);

        this.state = {
            ...this.state
        }
    }

    onBack() {
        this.navigateTo(CKNavigator.more);
    }

    loadData() {
        this.hideLoading();
    }

    componentDidMount() {
        super.componentDidMount();
    }

    render() {
        return super.render(
            <View className="main-container">
                <CKMenuPanel>
                    <CKMenuItem titleStyle={{
                        
                     }} full_border_off onPress={() => {
                        this.navigateTo(CKNavigator.howItWorks);
                    }} title='How Extra works' />
                    <CKMenuItem titleStyle={{
                        
                     }} onPress={() => {
                        window.open("https://www.circlek.ie/faq", "_blank");
                    }} full_border_off border={1} title="FAQ's" />
                </CKMenuPanel>

                <CKSpacer />
            </View>
        )
    }
}