import CKNavigator from "../lib/CKNavigator";
import View from "../lib/components/View";
import LoggedInBase from "./LoggedInBase";

export default class FAQs extends LoggedInBase {
    screenName = 'more'
    title = 'FAQ\'s'
    subLevelPage = true

    constructor(props) {
        super(props);

        this.state = {
            ...this.state
        }
    }

    onBack() {
        this.navigateTo(CKNavigator.helpAndSupport);
    }

    loadData() {
        this.hideLoading();
    }

    componentDidMount() {
        super.componentDidMount();
    }

    render() {
        return super.render(
            <View className="main-container">
                <iframe src="https://www.circlek.ie/faq?simplified" style={{border: 'none', height: 600}}></iframe>
            </View>
        )
    }
}