/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The DetailLine model module.
 * @module model/DetailLine
 * @version v1
 */
class DetailLine {
    /**
     * Constructs a new <code>DetailLine</code>.
     * @alias module:model/DetailLine
     * @param lineNumber {Number} 
     * @param points {Number} 
     * @param offerDiscount {Number} 
     * @param offerDesc {String} MaxLength:250
     */
    constructor(lineNumber, points, offerDiscount, offerDesc) { 
        
        DetailLine.initialize(this, lineNumber, points, offerDiscount, offerDesc);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, lineNumber, points, offerDiscount, offerDesc) { 
        obj['LineNumber'] = lineNumber;
        obj['Points'] = points;
        obj['OfferDiscount'] = offerDiscount;
        obj['OfferDesc'] = offerDesc;
    }

    /**
     * Constructs a <code>DetailLine</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/DetailLine} obj Optional instance to populate.
     * @return {module:model/DetailLine} The populated <code>DetailLine</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new DetailLine();

            if (data.hasOwnProperty('LineNumber')) {
                obj['LineNumber'] = ApiClient.convertToType(data['LineNumber'], 'Number');
            }
            if (data.hasOwnProperty('Points')) {
                obj['Points'] = ApiClient.convertToType(data['Points'], 'Number');
            }
            if (data.hasOwnProperty('OfferDiscount')) {
                obj['OfferDiscount'] = ApiClient.convertToType(data['OfferDiscount'], 'Number');
            }
            if (data.hasOwnProperty('OfferDesc')) {
                obj['OfferDesc'] = ApiClient.convertToType(data['OfferDesc'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>DetailLine</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>DetailLine</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of DetailLine.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['OfferDesc'] && !(typeof data['OfferDesc'] === 'string' || data['OfferDesc'] instanceof String)) {
            throw new Error("Expected the field `OfferDesc` to be a primitive type in the JSON string but got " + data['OfferDesc']);
        }

        return true;
    }


}

DetailLine.RequiredProperties = ["LineNumber", "Points", "OfferDiscount", "OfferDesc"];

/**
 * @member {Number} LineNumber
 */
DetailLine.prototype['LineNumber'] = undefined;

/**
 * @member {Number} Points
 */
DetailLine.prototype['Points'] = undefined;

/**
 * @member {Number} OfferDiscount
 */
DetailLine.prototype['OfferDiscount'] = undefined;

/**
 * MaxLength:250
 * @member {String} OfferDesc
 */
DetailLine.prototype['OfferDesc'] = undefined;






export default DetailLine;

