import axios from 'axios';

export default class FuelCard {
    endpoint = "";
    key = "";
    isProxy = false;

    constructor(endpoint, key) {
        this.endpoint = endpoint;
        this.key = key;
    }

    getBasic = () => {
        return "Basic " + this.key;
    }
    _makeRequest = (method, params) => {
        // 
        return axios.post(this.endpoint + method, params, {
            headers: {
                "Content-Type" : this.isProxy ? "application/json" : "application/x-www-form-urlencoded",
                "Authorization" : this.getBasic()
            },
            timeout: 15000
        }).then(resp => {
            return resp.data;
        })
    }

    checkFuelCard = (fuelCard) => {
        let method = '/check'
        return new Promise(async (resolve, reject) => {
            try {
                let response = await this._makeRequest(method, {
                    fuel_card : fuelCard
                });

                resolve(response)

            } catch(err) {
                reject(err);
            }
        });
    }

    assignFuelCard = (fuelCard, username, password) => {
        let method = '/assign'
        return new Promise(async (resolve, reject) => {
            try {
                let response = await this._makeRequest(method, {
                    fuel_card : fuelCard,
                    username: username,
                    password: password
                });

                resolve(response)

            } catch(err) {
                reject(err);
            }
        });
    }
    
}
