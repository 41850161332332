/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The MemberInfo model module.
 * @module model/MemberInfo
 * @version v1
 */
class MemberInfo {
    /**
     * Constructs a new <code>MemberInfo</code>.
     * @alias module:model/MemberInfo
     * @param firstName {String} MaxLength:64
     * @param lastName {String} MaxLength:64
     * @param isRegistered {Boolean} 
     * @param dateOfBirth {String} Format [yyyy-MM-dd]
     * @param gender {Number} 
     * @param title {Number} 
     * @param houseName {String} MaxLength:100
     * @param addressLine1 {String} MaxLength:100
     * @param addressLine2 {String} MaxLength:100
     * @param town {String} MaxLength:100
     * @param postCode {String} MaxLength:100
     * @param storeId {Number} 
     * @param isMemberLoginCreated {Boolean} 
     * @param country {Number} 
     * @param county {Number} 
     * @param homeHeatOil {Boolean} 
     * @param isEmployee {Boolean} 
     * @param partnersOptIn {Boolean} 
     * @param sMSOptIn {Boolean} 
     * @param emailOptIn {Boolean} 
     * @param postOptIn {Boolean} 
     * @param inAppOptIn {Boolean} 
     * @param researchOptIn {Boolean} 
     * @param tandCAgreed {Boolean} 
     * @param mobileNumCountryId {Number} 
     * @param mobileNum {String} MaxLength:20
     * @param homeNum {String} MaxLength:20
     * @param workNum {String} MaxLength:20
     * @param email {String} MaxLength:64
     * @param loyaltyBalance {Number} 
     * @param linkedPointsBalance {Number} 
     * @param spendBalance {Number} 
     */
    constructor(firstName, lastName, isRegistered, dateOfBirth, gender, title, houseName, addressLine1, addressLine2, town, postCode, storeId, isMemberLoginCreated, country, county, homeHeatOil, isEmployee, partnersOptIn, sMSOptIn, emailOptIn, postOptIn, inAppOptIn, researchOptIn, tandCAgreed, mobileNumCountryId, mobileNum, homeNum, workNum, email, loyaltyBalance, linkedPointsBalance, spendBalance) { 
        
        MemberInfo.initialize(this, firstName, lastName, isRegistered, dateOfBirth, gender, title, houseName, addressLine1, addressLine2, town, postCode, storeId, isMemberLoginCreated, country, county, homeHeatOil, isEmployee, partnersOptIn, sMSOptIn, emailOptIn, postOptIn, inAppOptIn, researchOptIn, tandCAgreed, mobileNumCountryId, mobileNum, homeNum, workNum, email, loyaltyBalance, linkedPointsBalance, spendBalance);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, firstName, lastName, isRegistered, dateOfBirth, gender, title, houseName, addressLine1, addressLine2, town, postCode, storeId, isMemberLoginCreated, country, county, homeHeatOil, isEmployee, partnersOptIn, sMSOptIn, emailOptIn, postOptIn, inAppOptIn, researchOptIn, tandCAgreed, mobileNumCountryId, mobileNum, homeNum, workNum, email, loyaltyBalance, linkedPointsBalance, spendBalance) { 
        obj['FirstName'] = firstName;
        obj['LastName'] = lastName;
        obj['IsRegistered'] = isRegistered;
        obj['DateOfBirth'] = dateOfBirth;
        obj['Gender'] = gender;
        obj['Title'] = title;
        obj['HouseName'] = houseName;
        obj['AddressLine1'] = addressLine1;
        obj['AddressLine2'] = addressLine2;
        obj['Town'] = town;
        obj['PostCode'] = postCode;
        obj['StoreId'] = storeId;
        obj['IsMemberLoginCreated'] = isMemberLoginCreated;
        obj['Country'] = country;
        obj['County'] = county;
        obj['HomeHeatOil'] = homeHeatOil;
        obj['IsEmployee'] = isEmployee;
        obj['PartnersOptIn'] = partnersOptIn;
        obj['SMSOptIn'] = sMSOptIn;
        obj['EmailOptIn'] = emailOptIn;
        obj['PostOptIn'] = postOptIn;
        obj['InAppOptIn'] = inAppOptIn;
        obj['ResearchOptIn'] = researchOptIn;
        obj['TandCAgreed'] = tandCAgreed;
        obj['MobileNumCountryId'] = mobileNumCountryId;
        obj['MobileNum'] = mobileNum;
        obj['HomeNum'] = homeNum;
        obj['WorkNum'] = workNum;
        obj['Email'] = email;
        obj['LoyaltyBalance'] = loyaltyBalance;
        obj['LinkedPointsBalance'] = linkedPointsBalance;
        obj['SpendBalance'] = spendBalance;
    }

    /**
     * Constructs a <code>MemberInfo</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/MemberInfo} obj Optional instance to populate.
     * @return {module:model/MemberInfo} The populated <code>MemberInfo</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MemberInfo();

            if (data.hasOwnProperty('FirstName')) {
                obj['FirstName'] = ApiClient.convertToType(data['FirstName'], 'String');
            }
            if (data.hasOwnProperty('LastName')) {
                obj['LastName'] = ApiClient.convertToType(data['LastName'], 'String');
            }
            if (data.hasOwnProperty('IsRegistered')) {
                obj['IsRegistered'] = ApiClient.convertToType(data['IsRegistered'], 'Boolean');
            }
            if (data.hasOwnProperty('DateOfBirth')) {
                obj['DateOfBirth'] = ApiClient.convertToType(data['DateOfBirth'], 'String');
            }
            if (data.hasOwnProperty('Gender')) {
                obj['Gender'] = ApiClient.convertToType(data['Gender'], 'Number');
            }
            if (data.hasOwnProperty('Title')) {
                obj['Title'] = ApiClient.convertToType(data['Title'], 'Number');
            }
            if (data.hasOwnProperty('HouseName')) {
                obj['HouseName'] = ApiClient.convertToType(data['HouseName'], 'String');
            }
            if (data.hasOwnProperty('AddressLine1')) {
                obj['AddressLine1'] = ApiClient.convertToType(data['AddressLine1'], 'String');
            }
            if (data.hasOwnProperty('AddressLine2')) {
                obj['AddressLine2'] = ApiClient.convertToType(data['AddressLine2'], 'String');
            }
            if (data.hasOwnProperty('Town')) {
                obj['Town'] = ApiClient.convertToType(data['Town'], 'String');
            }
            if (data.hasOwnProperty('PostCode')) {
                obj['PostCode'] = ApiClient.convertToType(data['PostCode'], 'String');
            }
            if (data.hasOwnProperty('StoreId')) {
                obj['StoreId'] = ApiClient.convertToType(data['StoreId'], 'Number');
            }
            if (data.hasOwnProperty('IsMemberLoginCreated')) {
                obj['IsMemberLoginCreated'] = ApiClient.convertToType(data['IsMemberLoginCreated'], 'Boolean');
            }
            if (data.hasOwnProperty('Country')) {
                obj['Country'] = ApiClient.convertToType(data['Country'], 'Number');
            }
            if (data.hasOwnProperty('County')) {
                obj['County'] = ApiClient.convertToType(data['County'], 'Number');
            }
            if (data.hasOwnProperty('HomeHeatOil')) {
                obj['HomeHeatOil'] = ApiClient.convertToType(data['HomeHeatOil'], 'Boolean');
            }
            if (data.hasOwnProperty('IsEmployee')) {
                obj['IsEmployee'] = ApiClient.convertToType(data['IsEmployee'], 'Boolean');
            }
            if (data.hasOwnProperty('PartnersOptIn')) {
                obj['PartnersOptIn'] = ApiClient.convertToType(data['PartnersOptIn'], 'Boolean');
            }
            if (data.hasOwnProperty('SMSOptIn')) {
                obj['SMSOptIn'] = ApiClient.convertToType(data['SMSOptIn'], 'Boolean');
            }
            if (data.hasOwnProperty('EmailOptIn')) {
                obj['EmailOptIn'] = ApiClient.convertToType(data['EmailOptIn'], 'Boolean');
            }
            if (data.hasOwnProperty('PostOptIn')) {
                obj['PostOptIn'] = ApiClient.convertToType(data['PostOptIn'], 'Boolean');
            }
            if (data.hasOwnProperty('InAppOptIn')) {
                obj['InAppOptIn'] = ApiClient.convertToType(data['InAppOptIn'], 'Boolean');
            }
            if (data.hasOwnProperty('ResearchOptIn')) {
                obj['ResearchOptIn'] = ApiClient.convertToType(data['ResearchOptIn'], 'Boolean');
            }
            if (data.hasOwnProperty('TandCAgreed')) {
                obj['TandCAgreed'] = ApiClient.convertToType(data['TandCAgreed'], 'Boolean');
            }
            if (data.hasOwnProperty('MobileNumCountryId')) {
                obj['MobileNumCountryId'] = ApiClient.convertToType(data['MobileNumCountryId'], 'Number');
            }
            if (data.hasOwnProperty('MobileNum')) {
                obj['MobileNum'] = ApiClient.convertToType(data['MobileNum'], 'String');
            }
            if (data.hasOwnProperty('HomeNum')) {
                obj['HomeNum'] = ApiClient.convertToType(data['HomeNum'], 'String');
            }
            if (data.hasOwnProperty('WorkNum')) {
                obj['WorkNum'] = ApiClient.convertToType(data['WorkNum'], 'String');
            }
            if (data.hasOwnProperty('Email')) {
                obj['Email'] = ApiClient.convertToType(data['Email'], 'String');
            }
            if (data.hasOwnProperty('LoyaltyBalance')) {
                obj['LoyaltyBalance'] = ApiClient.convertToType(data['LoyaltyBalance'], 'Number');
            }
            if (data.hasOwnProperty('LinkedPointsBalance')) {
                obj['LinkedPointsBalance'] = ApiClient.convertToType(data['LinkedPointsBalance'], 'Number');
            }
            if (data.hasOwnProperty('SpendBalance')) {
                obj['SpendBalance'] = ApiClient.convertToType(data['SpendBalance'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>MemberInfo</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>MemberInfo</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of MemberInfo.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['FirstName'] && !(typeof data['FirstName'] === 'string' || data['FirstName'] instanceof String)) {
            throw new Error("Expected the field `FirstName` to be a primitive type in the JSON string but got " + data['FirstName']);
        }
        // ensure the json data is a string
        if (data['LastName'] && !(typeof data['LastName'] === 'string' || data['LastName'] instanceof String)) {
            throw new Error("Expected the field `LastName` to be a primitive type in the JSON string but got " + data['LastName']);
        }
        // ensure the json data is a string
        if (data['DateOfBirth'] && !(typeof data['DateOfBirth'] === 'string' || data['DateOfBirth'] instanceof String)) {
            throw new Error("Expected the field `DateOfBirth` to be a primitive type in the JSON string but got " + data['DateOfBirth']);
        }
        // ensure the json data is a string
        if (data['HouseName'] && !(typeof data['HouseName'] === 'string' || data['HouseName'] instanceof String)) {
            throw new Error("Expected the field `HouseName` to be a primitive type in the JSON string but got " + data['HouseName']);
        }
        // ensure the json data is a string
        if (data['AddressLine1'] && !(typeof data['AddressLine1'] === 'string' || data['AddressLine1'] instanceof String)) {
            throw new Error("Expected the field `AddressLine1` to be a primitive type in the JSON string but got " + data['AddressLine1']);
        }
        // ensure the json data is a string
        if (data['AddressLine2'] && !(typeof data['AddressLine2'] === 'string' || data['AddressLine2'] instanceof String)) {
            throw new Error("Expected the field `AddressLine2` to be a primitive type in the JSON string but got " + data['AddressLine2']);
        }
        // ensure the json data is a string
        if (data['Town'] && !(typeof data['Town'] === 'string' || data['Town'] instanceof String)) {
            throw new Error("Expected the field `Town` to be a primitive type in the JSON string but got " + data['Town']);
        }
        // ensure the json data is a string
        if (data['PostCode'] && !(typeof data['PostCode'] === 'string' || data['PostCode'] instanceof String)) {
            throw new Error("Expected the field `PostCode` to be a primitive type in the JSON string but got " + data['PostCode']);
        }
        // ensure the json data is a string
        if (data['MobileNum'] && !(typeof data['MobileNum'] === 'string' || data['MobileNum'] instanceof String)) {
            throw new Error("Expected the field `MobileNum` to be a primitive type in the JSON string but got " + data['MobileNum']);
        }
        // ensure the json data is a string
        if (data['HomeNum'] && !(typeof data['HomeNum'] === 'string' || data['HomeNum'] instanceof String)) {
            throw new Error("Expected the field `HomeNum` to be a primitive type in the JSON string but got " + data['HomeNum']);
        }
        // ensure the json data is a string
        if (data['WorkNum'] && !(typeof data['WorkNum'] === 'string' || data['WorkNum'] instanceof String)) {
            throw new Error("Expected the field `WorkNum` to be a primitive type in the JSON string but got " + data['WorkNum']);
        }
        // ensure the json data is a string
        if (data['Email'] && !(typeof data['Email'] === 'string' || data['Email'] instanceof String)) {
            throw new Error("Expected the field `Email` to be a primitive type in the JSON string but got " + data['Email']);
        }

        return true;
    }


}

MemberInfo.RequiredProperties = ["FirstName", "LastName", "IsRegistered", "DateOfBirth", "Gender", "Title", "HouseName", "AddressLine1", "AddressLine2", "Town", "PostCode", "StoreId", "IsMemberLoginCreated", "Country", "County", "HomeHeatOil", "IsEmployee", "PartnersOptIn", "SMSOptIn", "EmailOptIn", "PostOptIn", "InAppOptIn", "ResearchOptIn", "TandCAgreed", "MobileNumCountryId", "MobileNum", "HomeNum", "WorkNum", "Email", "LoyaltyBalance", "LinkedPointsBalance", "SpendBalance"];

/**
 * MaxLength:64
 * @member {String} FirstName
 */
MemberInfo.prototype['FirstName'] = undefined;

/**
 * MaxLength:64
 * @member {String} LastName
 */
MemberInfo.prototype['LastName'] = undefined;

/**
 * @member {Boolean} IsRegistered
 */
MemberInfo.prototype['IsRegistered'] = undefined;

/**
 * Format [yyyy-MM-dd]
 * @member {String} DateOfBirth
 */
MemberInfo.prototype['DateOfBirth'] = undefined;

/**
 * @member {Number} Gender
 */
MemberInfo.prototype['Gender'] = undefined;

/**
 * @member {Number} Title
 */
MemberInfo.prototype['Title'] = undefined;

/**
 * MaxLength:100
 * @member {String} HouseName
 */
MemberInfo.prototype['HouseName'] = undefined;

/**
 * MaxLength:100
 * @member {String} AddressLine1
 */
MemberInfo.prototype['AddressLine1'] = undefined;

/**
 * MaxLength:100
 * @member {String} AddressLine2
 */
MemberInfo.prototype['AddressLine2'] = undefined;

/**
 * MaxLength:100
 * @member {String} Town
 */
MemberInfo.prototype['Town'] = undefined;

/**
 * MaxLength:100
 * @member {String} PostCode
 */
MemberInfo.prototype['PostCode'] = undefined;

/**
 * @member {Number} StoreId
 */
MemberInfo.prototype['StoreId'] = undefined;

/**
 * @member {Boolean} IsMemberLoginCreated
 */
MemberInfo.prototype['IsMemberLoginCreated'] = undefined;

/**
 * @member {Number} Country
 */
MemberInfo.prototype['Country'] = undefined;

/**
 * @member {Number} County
 */
MemberInfo.prototype['County'] = undefined;

/**
 * @member {Boolean} HomeHeatOil
 */
MemberInfo.prototype['HomeHeatOil'] = undefined;

/**
 * @member {Boolean} IsEmployee
 */
MemberInfo.prototype['IsEmployee'] = undefined;

/**
 * @member {Boolean} PartnersOptIn
 */
MemberInfo.prototype['PartnersOptIn'] = undefined;

/**
 * @member {Boolean} SMSOptIn
 */
MemberInfo.prototype['SMSOptIn'] = undefined;

/**
 * @member {Boolean} EmailOptIn
 */
MemberInfo.prototype['EmailOptIn'] = undefined;

/**
 * @member {Boolean} PostOptIn
 */
MemberInfo.prototype['PostOptIn'] = undefined;

/**
 * @member {Boolean} InAppOptIn
 */
MemberInfo.prototype['InAppOptIn'] = undefined;

/**
 * @member {Boolean} ResearchOptIn
 */
MemberInfo.prototype['ResearchOptIn'] = undefined;

/**
 * @member {Boolean} TandCAgreed
 */
MemberInfo.prototype['TandCAgreed'] = undefined;

/**
 * @member {Number} MobileNumCountryId
 */
MemberInfo.prototype['MobileNumCountryId'] = undefined;

/**
 * MaxLength:20
 * @member {String} MobileNum
 */
MemberInfo.prototype['MobileNum'] = undefined;

/**
 * MaxLength:20
 * @member {String} HomeNum
 */
MemberInfo.prototype['HomeNum'] = undefined;

/**
 * MaxLength:20
 * @member {String} WorkNum
 */
MemberInfo.prototype['WorkNum'] = undefined;

/**
 * MaxLength:64
 * @member {String} Email
 */
MemberInfo.prototype['Email'] = undefined;

/**
 * @member {Number} LoyaltyBalance
 */
MemberInfo.prototype['LoyaltyBalance'] = undefined;

/**
 * @member {Number} LinkedPointsBalance
 */
MemberInfo.prototype['LinkedPointsBalance'] = undefined;

/**
 * @member {Number} SpendBalance
 */
MemberInfo.prototype['SpendBalance'] = undefined;






export default MemberInfo;

