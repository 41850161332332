/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The Offer model module.
 * @module model/Offer
 * @version v1
 */
class Offer {
    /**
     * Constructs a new <code>Offer</code>.
     * @alias module:model/Offer
     * @param offerId {Number} 
     * @param offerTypeId {Number} 
     * @param pointsCost {Number} 
     */
    constructor(offerId, offerTypeId, pointsCost) { 
        
        Offer.initialize(this, offerId, offerTypeId, pointsCost);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, offerId, offerTypeId, pointsCost) { 
        obj['OfferId'] = offerId;
        obj['OfferTypeId'] = offerTypeId;
        obj['PointsCost'] = pointsCost;
    }

    /**
     * Constructs a <code>Offer</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Offer} obj Optional instance to populate.
     * @return {module:model/Offer} The populated <code>Offer</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Offer();

            if (data.hasOwnProperty('OfferId')) {
                obj['OfferId'] = ApiClient.convertToType(data['OfferId'], 'Number');
            }
            if (data.hasOwnProperty('OfferTypeId')) {
                obj['OfferTypeId'] = ApiClient.convertToType(data['OfferTypeId'], 'Number');
            }
            if (data.hasOwnProperty('OfferText')) {
                obj['OfferText'] = ApiClient.convertToType(data['OfferText'], 'String');
            }
            if (data.hasOwnProperty('StartDate')) {
                obj['StartDate'] = ApiClient.convertToType(data['StartDate'], 'String');
            }
            if (data.hasOwnProperty('EndDate')) {
                obj['EndDate'] = ApiClient.convertToType(data['EndDate'], 'String');
            }
            if (data.hasOwnProperty('ImageLocation')) {
                obj['ImageLocation'] = ApiClient.convertToType(data['ImageLocation'], 'String');
            }
            if (data.hasOwnProperty('FindOutMore')) {
                obj['FindOutMore'] = ApiClient.convertToType(data['FindOutMore'], 'String');
            }
            if (data.hasOwnProperty('PointsCost')) {
                obj['PointsCost'] = ApiClient.convertToType(data['PointsCost'], 'Number');
            }
            if (data.hasOwnProperty('NboText')) {
                obj['NboText'] = ApiClient.convertToType(data['NboText'], 'String');
            }
            if (data.hasOwnProperty('OfferClass')) {
                obj['OfferClass'] = ApiClient.convertToType(data['OfferClass'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Offer</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Offer</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of Offer.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['OfferText'] && !(typeof data['OfferText'] === 'string' || data['OfferText'] instanceof String)) {
            throw new Error("Expected the field `OfferText` to be a primitive type in the JSON string but got " + data['OfferText']);
        }
        // ensure the json data is a string
        if (data['StartDate'] && !(typeof data['StartDate'] === 'string' || data['StartDate'] instanceof String)) {
            throw new Error("Expected the field `StartDate` to be a primitive type in the JSON string but got " + data['StartDate']);
        }
        // ensure the json data is a string
        if (data['EndDate'] && !(typeof data['EndDate'] === 'string' || data['EndDate'] instanceof String)) {
            throw new Error("Expected the field `EndDate` to be a primitive type in the JSON string but got " + data['EndDate']);
        }
        // ensure the json data is a string
        if (data['ImageLocation'] && !(typeof data['ImageLocation'] === 'string' || data['ImageLocation'] instanceof String)) {
            throw new Error("Expected the field `ImageLocation` to be a primitive type in the JSON string but got " + data['ImageLocation']);
        }
        // ensure the json data is a string
        if (data['FindOutMore'] && !(typeof data['FindOutMore'] === 'string' || data['FindOutMore'] instanceof String)) {
            throw new Error("Expected the field `FindOutMore` to be a primitive type in the JSON string but got " + data['FindOutMore']);
        }
        // ensure the json data is a string
        if (data['NboText'] && !(typeof data['NboText'] === 'string' || data['NboText'] instanceof String)) {
            throw new Error("Expected the field `NboText` to be a primitive type in the JSON string but got " + data['NboText']);
        }
        // ensure the json data is a string
        if (data['OfferClass'] && !(typeof data['OfferClass'] === 'string' || data['OfferClass'] instanceof String)) {
            throw new Error("Expected the field `OfferClass` to be a primitive type in the JSON string but got " + data['OfferClass']);
        }

        return true;
    }


}

Offer.RequiredProperties = ["OfferId", "OfferTypeId", "PointsCost"];

/**
 * @member {Number} OfferId
 */
Offer.prototype['OfferId'] = undefined;

/**
 * @member {Number} OfferTypeId
 */
Offer.prototype['OfferTypeId'] = undefined;

/**
 * MaxLength:250
 * @member {String} OfferText
 */
Offer.prototype['OfferText'] = undefined;

/**
 * Format [yyyy-MM-dd HH:mm:ss] UTC
 * @member {String} StartDate
 */
Offer.prototype['StartDate'] = undefined;

/**
 * Format [yyyy-MM-dd HH:mm:ss] UTC
 * @member {String} EndDate
 */
Offer.prototype['EndDate'] = undefined;

/**
 * MaxLength:500
 * @member {String} ImageLocation
 */
Offer.prototype['ImageLocation'] = undefined;

/**
 * MaxLength:500
 * @member {String} FindOutMore
 */
Offer.prototype['FindOutMore'] = undefined;

/**
 * @member {Number} PointsCost
 */
Offer.prototype['PointsCost'] = undefined;

/**
 * MaxLength:50
 * @member {String} NboText
 */
Offer.prototype['NboText'] = undefined;

/**
 * MaxLength:10
 * @member {String} OfferClass
 */
Offer.prototype['OfferClass'] = undefined;






export default Offer;

