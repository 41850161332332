/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The TransactionalEmail model module.
 * @module model/TransactionalEmail
 * @version v1
 */
class TransactionalEmail {
    /**
     * Constructs a new <code>TransactionalEmail</code>.
     * @alias module:model/TransactionalEmail
     * @param emailAddressTo {String} MaxLength:64
     * @param bodyMessage {String} MaxLength:500
     * @param transTypeId {Number} 
     */
    constructor(emailAddressTo, bodyMessage, transTypeId) { 
        
        TransactionalEmail.initialize(this, emailAddressTo, bodyMessage, transTypeId);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, emailAddressTo, bodyMessage, transTypeId) { 
        obj['EmailAddressTo'] = emailAddressTo;
        obj['BodyMessage'] = bodyMessage;
        obj['TransTypeId'] = transTypeId;
    }

    /**
     * Constructs a <code>TransactionalEmail</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/TransactionalEmail} obj Optional instance to populate.
     * @return {module:model/TransactionalEmail} The populated <code>TransactionalEmail</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new TransactionalEmail();

            if (data.hasOwnProperty('EmailAddressTo')) {
                obj['EmailAddressTo'] = ApiClient.convertToType(data['EmailAddressTo'], 'String');
            }
            if (data.hasOwnProperty('BodyMessage')) {
                obj['BodyMessage'] = ApiClient.convertToType(data['BodyMessage'], 'String');
            }
            if (data.hasOwnProperty('TransTypeId')) {
                obj['TransTypeId'] = ApiClient.convertToType(data['TransTypeId'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>TransactionalEmail</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>TransactionalEmail</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of TransactionalEmail.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['EmailAddressTo'] && !(typeof data['EmailAddressTo'] === 'string' || data['EmailAddressTo'] instanceof String)) {
            throw new Error("Expected the field `EmailAddressTo` to be a primitive type in the JSON string but got " + data['EmailAddressTo']);
        }
        // ensure the json data is a string
        if (data['BodyMessage'] && !(typeof data['BodyMessage'] === 'string' || data['BodyMessage'] instanceof String)) {
            throw new Error("Expected the field `BodyMessage` to be a primitive type in the JSON string but got " + data['BodyMessage']);
        }

        return true;
    }


}

TransactionalEmail.RequiredProperties = ["EmailAddressTo", "BodyMessage", "TransTypeId"];

/**
 * MaxLength:64
 * @member {String} EmailAddressTo
 */
TransactionalEmail.prototype['EmailAddressTo'] = undefined;

/**
 * MaxLength:500
 * @member {String} BodyMessage
 */
TransactionalEmail.prototype['BodyMessage'] = undefined;

/**
 * @member {Number} TransTypeId
 */
TransactionalEmail.prototype['TransTypeId'] = undefined;






export default TransactionalEmail;

