/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import APIOKResponse from '../model/APIOKResponse';
import APIResponse from '../model/APIResponse';
import SurveyAnswer from '../model/SurveyAnswer';
import SurveyInfo from '../model/SurveyInfo';

/**
* Surveys service.
* @module api/SurveysApi
* @version v1
*/
export default class SurveysApi {

    /**
    * Constructs a new SurveysApi. 
    * @alias module:api/SurveysApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the surveysV1AddMemberSurveyEntry operation.
     * @callback module:api/SurveysApi~surveysV1AddMemberSurveyEntryCallback
     * @param {String} error Error message, if any.
     * @param {module:model/APIOKResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Add Survey Entry
     * Adds a survey entry for a Member   
     * @param {String} memberUId Member Unique Identifier
     * @param {Number} surveyId SurveyId
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {Array.<module:model/SurveyAnswer>} surveyAnswers List of SurveyAnswer Objects
     * @param {module:api/SurveysApi~surveysV1AddMemberSurveyEntryCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/APIOKResponse}
     */
    surveysV1AddMemberSurveyEntry(memberUId, surveyId, clientId, authorization, surveyAnswers, callback) {
      let postBody = surveyAnswers;
      // verify the required parameter 'memberUId' is set
      if (memberUId === undefined || memberUId === null) {
        throw new Error("Missing the required parameter 'memberUId' when calling surveysV1AddMemberSurveyEntry");
      }
      // verify the required parameter 'surveyId' is set
      if (surveyId === undefined || surveyId === null) {
        throw new Error("Missing the required parameter 'surveyId' when calling surveysV1AddMemberSurveyEntry");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling surveysV1AddMemberSurveyEntry");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling surveysV1AddMemberSurveyEntry");
      }
      // verify the required parameter 'surveyAnswers' is set
      if (surveyAnswers === undefined || surveyAnswers === null) {
        throw new Error("Missing the required parameter 'surveyAnswers' when calling surveysV1AddMemberSurveyEntry");
      }

      let pathParams = {
        'memberUId': memberUId,
        'surveyId': surveyId
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json', 'text/json', 'application/xml', 'text/xml', 'application/x-www-form-urlencoded'];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = APIOKResponse;
      return this.apiClient.callApi(
        '/v1/surveys/{surveyId}/Members/{memberUId}/Entry', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the surveysV1GetSurvey operation.
     * @callback module:api/SurveysApi~surveysV1GetSurveyCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SurveyInfo} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get Survey
     * Get a specific survey   
     * @param {Number} surveyId ID of Survey
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:api/SurveysApi~surveysV1GetSurveyCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SurveyInfo}
     */
    surveysV1GetSurvey(surveyId, clientId, authorization, callback) {
      let postBody = null;
      // verify the required parameter 'surveyId' is set
      if (surveyId === undefined || surveyId === null) {
        throw new Error("Missing the required parameter 'surveyId' when calling surveysV1GetSurvey");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling surveysV1GetSurvey");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling surveysV1GetSurvey");
      }

      let pathParams = {
        'surveyId': surveyId
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = SurveyInfo;
      return this.apiClient.callApi(
        '/v1/surveys/{surveyId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the surveysV1GetSurveys operation.
     * @callback module:api/SurveysApi~surveysV1GetSurveysCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/SurveyInfo>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * List Surveys
     * List all surveys   
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:api/SurveysApi~surveysV1GetSurveysCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/SurveyInfo>}
     */
    surveysV1GetSurveys(clientId, authorization, callback) {
      let postBody = null;
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling surveysV1GetSurveys");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling surveysV1GetSurveys");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = [SurveyInfo];
      return this.apiClient.callApi(
        '/v1/surveys', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
