import React from 'react';
import CommsLayer from '../api/CommsLayer';

class _CKStrings {
    constructor() {
        // initialising localisation
        this.strings = require('../assets/locale/en.json')
    }

    convertToUserCurrency = (key) => {
        let userCurrency = "€";
        let userCurrencyPens = "1c";
        let userCurrencyPensFull_1 = "1 Cent";
        let userCurrencyPensFull_2 = "2 Cent";
        let userCurrencyPensFull_3 = "3 Cent";

        if(CommsLayer.memberData.Country == 229) {
            userCurrency = "£";
            userCurrencyPens = "1p";
            userCurrencyPensFull_1 = "1 Penny";
            userCurrencyPensFull_2 = "2 Penny";
            userCurrencyPensFull_3 = "3 Penny";
        }
        

        return typeof(key) == 'string' ? key.replace(new RegExp("€", "g"), userCurrency)
            .replace(new RegExp("1c ", "g"), userCurrencyPens + ' ')
            .replace(new RegExp("1 Cent", "g"), userCurrencyPensFull_1)
            .replace(new RegExp("2 Cent", "g"), userCurrencyPensFull_2)
            .replace(new RegExp("3 Cent", "g"), userCurrencyPensFull_3)
            :
            key
        ;
    }

    _ = (key) => {
        if(this.strings[key]) {
            return this.strings[key];
        }

        console.log('"' + key + '" : "' + key + '",');

        return key;
    }
}

export const CKStrings = new _CKStrings();
