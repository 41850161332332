/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The OrderDetailOffer model module.
 * @module model/OrderDetailOffer
 * @version v1
 */
class OrderDetailOffer {
    /**
     * Constructs a new <code>OrderDetailOffer</code>.
     * @alias module:model/OrderDetailOffer
     * @param offerId {Number} 
     * @param unitsEarned {Number} 
     * @param itemsRedeemed {Number} 
     */
    constructor(offerId, unitsEarned, itemsRedeemed) { 
        
        OrderDetailOffer.initialize(this, offerId, unitsEarned, itemsRedeemed);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, offerId, unitsEarned, itemsRedeemed) { 
        obj['OfferId'] = offerId;
        obj['UnitsEarned'] = unitsEarned;
        obj['ItemsRedeemed'] = itemsRedeemed;
    }

    /**
     * Constructs a <code>OrderDetailOffer</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/OrderDetailOffer} obj Optional instance to populate.
     * @return {module:model/OrderDetailOffer} The populated <code>OrderDetailOffer</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new OrderDetailOffer();

            if (data.hasOwnProperty('OfferId')) {
                obj['OfferId'] = ApiClient.convertToType(data['OfferId'], 'Number');
            }
            if (data.hasOwnProperty('OfferName')) {
                obj['OfferName'] = ApiClient.convertToType(data['OfferName'], 'String');
            }
            if (data.hasOwnProperty('UnitsEarned')) {
                obj['UnitsEarned'] = ApiClient.convertToType(data['UnitsEarned'], 'Number');
            }
            if (data.hasOwnProperty('ItemsRedeemed')) {
                obj['ItemsRedeemed'] = ApiClient.convertToType(data['ItemsRedeemed'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>OrderDetailOffer</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>OrderDetailOffer</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of OrderDetailOffer.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['OfferName'] && !(typeof data['OfferName'] === 'string' || data['OfferName'] instanceof String)) {
            throw new Error("Expected the field `OfferName` to be a primitive type in the JSON string but got " + data['OfferName']);
        }

        return true;
    }


}

OrderDetailOffer.RequiredProperties = ["OfferId", "UnitsEarned", "ItemsRedeemed"];

/**
 * @member {Number} OfferId
 */
OrderDetailOffer.prototype['OfferId'] = undefined;

/**
 * @member {String} OfferName
 */
OrderDetailOffer.prototype['OfferName'] = undefined;

/**
 * @member {Number} UnitsEarned
 */
OrderDetailOffer.prototype['UnitsEarned'] = undefined;

/**
 * @member {Number} ItemsRedeemed
 */
OrderDetailOffer.prototype['ItemsRedeemed'] = undefined;






export default OrderDetailOffer;

