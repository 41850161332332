import moment from "moment";
import AsyncStorage from "./AsyncStorage";

export const CKCacheItem = {
    communicationsV1GetMemberMessages: "communicationsV1GetMemberMessages",
    membersV1GetMemberSummary: "membersV1GetMemberSummary"
}

const CKCache = {
    _cache: {},
    getItem: (item) => {
        return AsyncStorage.getItem(item).then((data) => {
            if(data) {
                let cache = JSON.parse(data);

                if(cache.expires_at > moment().valueOf()) {
                    return cache.data;
                }
            }

            return false;
        });
    },
    setItem: (item, data, cacheExpiryInSeconds = 60) => {
        if(!data) {
            return CKCache.clear(item);
        }

        let cache = {
            expires_at: moment().add(cacheExpiryInSeconds, 'seconds').valueOf(),
            data: data
        }
        return AsyncStorage.setItem(item, JSON.stringify(cache));
    },
    clear: (item) => {
        return AsyncStorage.removeItem(item);
    },
    clearAll: () => {
        return new Promise(async (resolve) => {
            for(var i = 0; i < Object.values(CKCacheItem).length; i++) {
                try {
                    await CKCache.clear(Object.values(CKCacheItem)[i]);
                } catch {
                    // nothing to do here, just failed to clear the cache
                }
            }

            resolve(true);
        });
    }
}

export default CKCache;