import Base from "./Base";

export default class AuthBase extends Base {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state
        }
    }

    componentDidMount() {
        
    }

    render(children) {
        return super.render(children);
    }
}