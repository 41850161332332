import axios from 'axios';

export default class Identity {
    endpoint = "";
    key = "";
    isProxy = false;

    constructor(endpoint, key) {
        this.endpoint = endpoint;
        this.key = key;
    }

    getBasic = () => {
        return "Basic " + this.key;
    }
    _makeRequest = (params) => {
        // 
        return axios.post(this.endpoint, params, {
            headers: {
                "Content-Type" : this.isProxy ? "application/json" : "application/x-www-form-urlencoded",
                "Authorization" : this.getBasic()
            },
            timeout: 15000
        }).then(resp => {
            return resp.data;
        })
    }

    appLogin = () => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await this._makeRequest({
                    grant_type : "client_credentials"
                });

                let token = new TokenResponse(response);

                if(!token.isInvalid) resolve(token);
                else reject(token.errorMessage);
            } catch(err) {
                reject(err);
            }
        });
    }

    memberLogin = (username, password) => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await this._makeRequest({
                    grant_type : "password",
                    username: username,
                    password: password 
                });

                let token = new TokenResponse(response);

                if(!token.isInvalid) resolve(token);
                else reject(token.errorMessage);
            } catch(err) {
                reject(err);
            }
        });
    }

    refreshToken = (refresh_token) => {
        return new Promise(async (resolve, reject) => {
            try {
                let response = await this._makeRequest({
                    grant_type : "refresh_token",
                    refresh_token : refresh_token
                });

                let token = new TokenResponse(response);

                if(!token.isInvalid) resolve(token);
                else reject(token.errorMessage);
            } catch(err) {
                reject(err);
            }
        });
    }
}

export class TokenResponse {
    _expires_in_tolerance = 20 // seconds to allow before token is expired

    access_token = "" 
    token_type = ""
    expires_in = 0
    refresh_token = ""
    ID = ""
    memberUID = ""
    issued = ""
    expires = ""
    isInvalid = false
    errorMessage = ""

    constructor(data) {
        if(!data) return;

        if(data['access_token']) this.access_token = data['access_token'];
        if(data['token_type']) this.token_type = data['token_type'];
        if(data['expires_in']) this.expires_in = data['expires_in'];
        if(data['refresh_token']) this.refresh_token = data['refresh_token'];
        if(data['ID']) this.ID = data['ID'];
        if(data['memberUID']) this.memberUID = data['memberUID'];
        if(data['.issued']) this.issued = data['.issued'];
        if(data['.expires']) {
            this.expires = data['.expires'];
            this.expires = new Date(this.expires);
        }

        if(data['error']) {
            this.isInvalid = true;
            this.errorMessage = data['error_description'];
        }
    }

    getBearer = () => {
        return 'Bearer ' + this.access_token;
    }

    isExpired = () => {
        return this.expires ? new Date(Date.now() - (this._expires_in_tolerance * 1000)) > this.expires : true;
    }
}