/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import APIOKResponse from '../model/APIOKResponse';
import APIResponse from '../model/APIResponse';
import ActivateGiftRequest from '../model/ActivateGiftRequest';
import GiftCardBalRequest from '../model/GiftCardBalRequest';
import GiftCardBalResponse from '../model/GiftCardBalResponse';
import GiftCardRequest from '../model/GiftCardRequest';
import GiftCardResponse from '../model/GiftCardResponse';
import GiftCardValueResponse from '../model/GiftCardValueResponse';
import OrderStatus from '../model/OrderStatus';
import RedeemGiftResponse from '../model/RedeemGiftResponse';
import RedeemRequest from '../model/RedeemRequest';
import RevokeGiftCardRequest from '../model/RevokeGiftCardRequest';

/**
* Gifts service.
* @module api/GiftsApi
* @version v1
*/
export default class GiftsApi {

    /**
    * Constructs a new GiftsApi. 
    * @alias module:api/GiftsApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the giftsV1Activate operation.
     * @callback module:api/GiftsApi~giftsV1ActivateCallback
     * @param {String} error Error message, if any.
     * @param {module:model/GiftCardValueResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Activate Gift Card
     * Activate an E-gift card for use at the POS   
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:model/ActivateGiftRequest} activateRequest ActivateRequest Object
     * @param {module:api/GiftsApi~giftsV1ActivateCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/GiftCardValueResponse}
     */
    giftsV1Activate(clientId, authorization, activateRequest, callback) {
      let postBody = activateRequest;
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling giftsV1Activate");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling giftsV1Activate");
      }
      // verify the required parameter 'activateRequest' is set
      if (activateRequest === undefined || activateRequest === null) {
        throw new Error("Missing the required parameter 'activateRequest' when calling giftsV1Activate");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json', 'text/json', 'application/xml', 'text/xml', 'application/x-www-form-urlencoded'];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = GiftCardValueResponse;
      return this.apiClient.callApi(
        '/v1/gifts/Activate', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the giftsV1AddGift operation.
     * @callback module:api/GiftsApi~giftsV1AddGiftCallback
     * @param {String} error Error message, if any.
     * @param {module:model/GiftCardResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Add Gift Card
     * Add a gift card of a specified value. This allocates a Gift Card that has already been paid for by a customer.<br /> This call supports idempotency for safely retrying gift card requests without accidentally performing the same operation twice.<br /> If a request to create a new gift card does not respond e.g. due to a network connection error, you can retry the request with the same idempotency key to guarantee that only one gift card will be created. <br />An Idempotency key is a unique GUID generated by the client.   
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:model/GiftCardRequest} cardRequest GiftCardRequest object, Idempotency key must be a valid GUID (xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx)
     * @param {module:api/GiftsApi~giftsV1AddGiftCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/GiftCardResponse}
     */
    giftsV1AddGift(clientId, authorization, cardRequest, callback) {
      let postBody = cardRequest;
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling giftsV1AddGift");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling giftsV1AddGift");
      }
      // verify the required parameter 'cardRequest' is set
      if (cardRequest === undefined || cardRequest === null) {
        throw new Error("Missing the required parameter 'cardRequest' when calling giftsV1AddGift");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json', 'text/json', 'application/xml', 'text/xml', 'application/x-www-form-urlencoded'];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = GiftCardResponse;
      return this.apiClient.callApi(
        '/v1/gifts/Add', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the giftsV1Balance operation.
     * @callback module:api/GiftsApi~giftsV1BalanceCallback
     * @param {String} error Error message, if any.
     * @param {module:model/GiftCardBalResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Request a Gift Card Balance
     * Request a Gift Card Balance for a Specified Gift Card   
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:model/GiftCardBalRequest} balanceRequest GiftCardBalRequest Object
     * @param {module:api/GiftsApi~giftsV1BalanceCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/GiftCardBalResponse}
     */
    giftsV1Balance(clientId, authorization, balanceRequest, callback) {
      let postBody = balanceRequest;
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling giftsV1Balance");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling giftsV1Balance");
      }
      // verify the required parameter 'balanceRequest' is set
      if (balanceRequest === undefined || balanceRequest === null) {
        throw new Error("Missing the required parameter 'balanceRequest' when calling giftsV1Balance");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json', 'text/json', 'application/xml', 'text/xml', 'application/x-www-form-urlencoded'];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = GiftCardBalResponse;
      return this.apiClient.callApi(
        '/v1/gifts/Balance', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the giftsV1CancelRedeem operation.
     * @callback module:api/GiftsApi~giftsV1CancelRedeemCallback
     * @param {String} error Error message, if any.
     * @param {module:model/OrderStatus} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Cancel Gift Card Redeem
     * Cancel Gift Card redemption(s) for a specified orderId. This represents gift card redemption(s) that have been cancelled by the customer.   
     * @param {Number} orderId ID of Order
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:api/GiftsApi~giftsV1CancelRedeemCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/OrderStatus}
     */
    giftsV1CancelRedeem(orderId, clientId, authorization, callback) {
      let postBody = null;
      // verify the required parameter 'orderId' is set
      if (orderId === undefined || orderId === null) {
        throw new Error("Missing the required parameter 'orderId' when calling giftsV1CancelRedeem");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling giftsV1CancelRedeem");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling giftsV1CancelRedeem");
      }

      let pathParams = {
        'orderId': orderId
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = OrderStatus;
      return this.apiClient.callApi(
        '/v1/gifts/{orderId}/CancelRedeem', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the giftsV1CompleteRedeem operation.
     * @callback module:api/GiftsApi~giftsV1CompleteRedeemCallback
     * @param {String} error Error message, if any.
     * @param {module:model/OrderStatus} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Complete Gift Card Redeem
     * Complete Gift Card redemption(s) for a specified orderId. This represents gift card redemption(s) that have been paid for and completed by a customer.   
     * @param {Number} orderId ID of Order
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:api/GiftsApi~giftsV1CompleteRedeemCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/OrderStatus}
     */
    giftsV1CompleteRedeem(orderId, clientId, authorization, callback) {
      let postBody = null;
      // verify the required parameter 'orderId' is set
      if (orderId === undefined || orderId === null) {
        throw new Error("Missing the required parameter 'orderId' when calling giftsV1CompleteRedeem");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling giftsV1CompleteRedeem");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling giftsV1CompleteRedeem");
      }

      let pathParams = {
        'orderId': orderId
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = OrderStatus;
      return this.apiClient.callApi(
        '/v1/gifts/{orderId}/CompleteRedeem', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the giftsV1Redeem operation.
     * @callback module:api/GiftsApi~giftsV1RedeemCallback
     * @param {String} error Error message, if any.
     * @param {module:model/RedeemGiftResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Redeem Gift Card
     * Redeem a specified value from a gift card without sending basket details   
     * @param {Number} storeId ID of Store
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:model/RedeemRequest} redeemRequest RedeemRequest Object
     * @param {module:api/GiftsApi~giftsV1RedeemCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/RedeemGiftResponse}
     */
    giftsV1Redeem(storeId, clientId, authorization, redeemRequest, callback) {
      let postBody = redeemRequest;
      // verify the required parameter 'storeId' is set
      if (storeId === undefined || storeId === null) {
        throw new Error("Missing the required parameter 'storeId' when calling giftsV1Redeem");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling giftsV1Redeem");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling giftsV1Redeem");
      }
      // verify the required parameter 'redeemRequest' is set
      if (redeemRequest === undefined || redeemRequest === null) {
        throw new Error("Missing the required parameter 'redeemRequest' when calling giftsV1Redeem");
      }

      let pathParams = {
        'storeId': storeId
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json', 'text/json', 'application/xml', 'text/xml', 'application/x-www-form-urlencoded'];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = RedeemGiftResponse;
      return this.apiClient.callApi(
        '/v1/gifts/stores/{storeId}/Redeem', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the giftsV1RevokeGift operation.
     * @callback module:api/GiftsApi~giftsV1RevokeGiftCallback
     * @param {String} error Error message, if any.
     * @param {module:model/APIOKResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Revoke Gift Card
     * Revoke a gift card that has been added in error. The call must provide the IdempotencyKey used in the POST gifts\\Add call. It can be revoked within 30 minutes of adding a gift card and must not be redeemed.<br />   
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:model/RevokeGiftCardRequest} revokeRequest RevokeGiftCardRequest object
     * @param {module:api/GiftsApi~giftsV1RevokeGiftCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/APIOKResponse}
     */
    giftsV1RevokeGift(clientId, authorization, revokeRequest, callback) {
      let postBody = revokeRequest;
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling giftsV1RevokeGift");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling giftsV1RevokeGift");
      }
      // verify the required parameter 'revokeRequest' is set
      if (revokeRequest === undefined || revokeRequest === null) {
        throw new Error("Missing the required parameter 'revokeRequest' when calling giftsV1RevokeGift");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json', 'text/json', 'application/xml', 'text/xml', 'application/x-www-form-urlencoded'];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = APIOKResponse;
      return this.apiClient.callApi(
        '/v1/gifts/Revoke', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
