import { Component } from "react";

export default class View extends Component {

    getWidth = () => {
        return this.ref ? this.ref.offsetWidth : 0;
    }

    render() {
        let props = {};

        Object.keys(this.props).forEach(p => {
            if(p !=='children' || p !=='ref' || p !=='style' || p !=='flex') {
                props[p] = this.props[p];
            }
        })

        return <div {...props} ref={ref => this.ref = ref} {...({
            style: {
                ...(this.props.flex ? {display: 'flex'}:{}),
                ...this.props.style, 
                ...(
                    this.props.style && this.props.style.paddingHorizontal ? {
                        paddingLeft: this.props.style.paddingHorizontal,
                        paddingRight: this.props.style.paddingHorizontal
                    } : {}
                    ),
                ...(
                    this.props.style && this.props.style.paddingVertical ? {
                        paddingTop: this.props.style.paddingVertical,
                        paddingBottom: this.props.style.paddingVertical
                    } : {}
                    )
                },
                ...(
                    this.props.style && this.props.style.marginHorizontal ? {
                        marginLeft: this.props.style.marginHorizontal,
                        marginRight: this.props.style.marginHorizontal
                    } : {}
                    ),
                ...(
                    this.props.style && this.props.style.marginVertical ? {
                        marginTop: this.props.style.marginVertical,
                        marginBottom: this.props.style.marginVertical
                    } : {}
                )
        })}>{this.props.children}</div>
    }
}