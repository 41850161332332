const AsyncStorage = {
    _data: {},

    getItem: (item) => {
        return new Promise((resolve) => {
            if(AsyncStorage._data[item] !== undefined) {
                resolve(AsyncStorage._data[item]);
            } else {
                resolve(undefined);
            }
        })
    },

    setItem: (item, data) => {
        return new Promise((resolve) => {
            AsyncStorage._data[item] = data;
            resolve();
        })
    },

    removeItem: (item) => {
        return new Promise((resolve) => {
            delete AsyncStorage._data[item];

            resolve();
        })
    }
}

export default AsyncStorage;