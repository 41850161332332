/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import APIOKResponse from '../model/APIOKResponse';
import APIResponse from '../model/APIResponse';
import CustomField from '../model/CustomField';

/**
* CustomFields service.
* @module api/CustomFieldsApi
* @version v1
*/
export default class CustomFieldsApi {

    /**
    * Constructs a new CustomFieldsApi. 
    * @alias module:api/CustomFieldsApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the customFieldsV1GetMemberCustomFields operation.
     * @callback module:api/CustomFieldsApi~customFieldsV1GetMemberCustomFieldsCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/CustomField>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * List Member Custom Fields
     * List custom fields for a member   
     * @param {String} memberUId Member Unique Identifier
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:api/CustomFieldsApi~customFieldsV1GetMemberCustomFieldsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/CustomField>}
     */
    customFieldsV1GetMemberCustomFields(memberUId, clientId, authorization, callback) {
      let postBody = null;
      // verify the required parameter 'memberUId' is set
      if (memberUId === undefined || memberUId === null) {
        throw new Error("Missing the required parameter 'memberUId' when calling customFieldsV1GetMemberCustomFields");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling customFieldsV1GetMemberCustomFields");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling customFieldsV1GetMemberCustomFields");
      }

      let pathParams = {
        'memberUId': memberUId
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = [CustomField];
      return this.apiClient.callApi(
        '/v1/customfields/Members/{memberUId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the customFieldsV1GetStoreCustomFields operation.
     * @callback module:api/CustomFieldsApi~customFieldsV1GetStoreCustomFieldsCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/CustomField>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * List Store Custom Fields
     * List custom fields for a store   
     * @param {Number} storeId ID of Store
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:api/CustomFieldsApi~customFieldsV1GetStoreCustomFieldsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/CustomField>}
     */
    customFieldsV1GetStoreCustomFields(storeId, clientId, authorization, callback) {
      let postBody = null;
      // verify the required parameter 'storeId' is set
      if (storeId === undefined || storeId === null) {
        throw new Error("Missing the required parameter 'storeId' when calling customFieldsV1GetStoreCustomFields");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling customFieldsV1GetStoreCustomFields");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling customFieldsV1GetStoreCustomFields");
      }

      let pathParams = {
        'storeId': storeId
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = [CustomField];
      return this.apiClient.callApi(
        '/v1/customfields/Stores/{storeId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the customFieldsV1SaveCustomFieldAnswers operation.
     * @callback module:api/CustomFieldsApi~customFieldsV1SaveCustomFieldAnswersCallback
     * @param {String} error Error message, if any.
     * @param {module:model/APIOKResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Add or Update Member Custom Field Answers
     * Add or Update custom field answers for a member. See Response from GET /v1/customfields/members/{memberUId}.      <br /><span class=\"green\">Request CustomFieldAnswer Object:</span>            {         \"CustomFieldId\": 0,         \"CustomFieldType\": See Lookup Table below,         \"CustomFieldAnswer\": \"Answer\" - MaxLength:500       }  <br /><span class=\"green\">Request CustomFieldAnswers Object:</span>            {         \"CustomFieldId\": 0,         \"CustomFieldType\": See Lookup Table below,         \"CustomFieldAnswers\":          {           \"OptionId\": 0,           \"OptionValue\": \"Answer\" - MaxLength:20          }      }  <br /><span class=\"green\">Lookup Tables for the following are listed here:</span><br /><a href=\"../../v1/Helpers/Lookups/CustomFieldType/\">CustomFieldType</a>   
     * @param {String} memberUId Member Unique Identifier
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {Array.<Object>} answers See CustomField Objects and Lookup Tables above
     * @param {module:api/CustomFieldsApi~customFieldsV1SaveCustomFieldAnswersCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/APIOKResponse}
     */
    customFieldsV1SaveCustomFieldAnswers(memberUId, clientId, authorization, answers, callback) {
      let postBody = answers;
      // verify the required parameter 'memberUId' is set
      if (memberUId === undefined || memberUId === null) {
        throw new Error("Missing the required parameter 'memberUId' when calling customFieldsV1SaveCustomFieldAnswers");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling customFieldsV1SaveCustomFieldAnswers");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling customFieldsV1SaveCustomFieldAnswers");
      }
      // verify the required parameter 'answers' is set
      if (answers === undefined || answers === null) {
        throw new Error("Missing the required parameter 'answers' when calling customFieldsV1SaveCustomFieldAnswers");
      }

      let pathParams = {
        'memberUId': memberUId
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json', 'text/json', 'application/xml', 'text/xml', 'application/x-www-form-urlencoded'];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = APIOKResponse;
      return this.apiClient.callApi(
        '/v1/customfields/Members/{memberUId}/Answer', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
