/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The PartnerStore model module.
 * @module model/PartnerStore
 * @version v1
 */
class PartnerStore {
    /**
     * Constructs a new <code>PartnerStore</code>.
     * @alias module:model/PartnerStore
     * @param storeId {Number} 
     * @param storeName {String} MaxLength:500
     * @param storeDisplayName {String} MaxLength:50
     * @param addressLine1 {String} MaxLength:100
     * @param addressLine2 {String} MaxLength:100
     * @param postCode {String} MaxLength:25
     * @param countyId {Number} 
     * @param countyName {String} MaxLength:64
     * @param countryId {Number} 
     * @param countryName {String} MaxLength:100
     * @param openingHours {String} MaxLength:30
     * @param storeComments {String} MaxLength:750
     * @param latitude {String} MaxLength:20
     * @param longitude {String} MaxLength:20
     */
    constructor(storeId, storeName, storeDisplayName, addressLine1, addressLine2, postCode, countyId, countyName, countryId, countryName, openingHours, storeComments, latitude, longitude) { 
        
        PartnerStore.initialize(this, storeId, storeName, storeDisplayName, addressLine1, addressLine2, postCode, countyId, countyName, countryId, countryName, openingHours, storeComments, latitude, longitude);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, storeId, storeName, storeDisplayName, addressLine1, addressLine2, postCode, countyId, countyName, countryId, countryName, openingHours, storeComments, latitude, longitude) { 
        obj['StoreId'] = storeId;
        obj['StoreName'] = storeName;
        obj['StoreDisplayName'] = storeDisplayName;
        obj['AddressLine1'] = addressLine1;
        obj['AddressLine2'] = addressLine2;
        obj['PostCode'] = postCode;
        obj['CountyId'] = countyId;
        obj['CountyName'] = countyName;
        obj['CountryId'] = countryId;
        obj['CountryName'] = countryName;
        obj['OpeningHours'] = openingHours;
        obj['StoreComments'] = storeComments;
        obj['Latitude'] = latitude;
        obj['Longitude'] = longitude;
    }

    /**
     * Constructs a <code>PartnerStore</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/PartnerStore} obj Optional instance to populate.
     * @return {module:model/PartnerStore} The populated <code>PartnerStore</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PartnerStore();

            if (data.hasOwnProperty('StoreId')) {
                obj['StoreId'] = ApiClient.convertToType(data['StoreId'], 'Number');
            }
            if (data.hasOwnProperty('StoreName')) {
                obj['StoreName'] = ApiClient.convertToType(data['StoreName'], 'String');
            }
            if (data.hasOwnProperty('StoreDisplayName')) {
                obj['StoreDisplayName'] = ApiClient.convertToType(data['StoreDisplayName'], 'String');
            }
            if (data.hasOwnProperty('AddressLine1')) {
                obj['AddressLine1'] = ApiClient.convertToType(data['AddressLine1'], 'String');
            }
            if (data.hasOwnProperty('AddressLine2')) {
                obj['AddressLine2'] = ApiClient.convertToType(data['AddressLine2'], 'String');
            }
            if (data.hasOwnProperty('PostCode')) {
                obj['PostCode'] = ApiClient.convertToType(data['PostCode'], 'String');
            }
            if (data.hasOwnProperty('CountyId')) {
                obj['CountyId'] = ApiClient.convertToType(data['CountyId'], 'Number');
            }
            if (data.hasOwnProperty('CountyName')) {
                obj['CountyName'] = ApiClient.convertToType(data['CountyName'], 'String');
            }
            if (data.hasOwnProperty('CountryId')) {
                obj['CountryId'] = ApiClient.convertToType(data['CountryId'], 'Number');
            }
            if (data.hasOwnProperty('CountryName')) {
                obj['CountryName'] = ApiClient.convertToType(data['CountryName'], 'String');
            }
            if (data.hasOwnProperty('OpeningHours')) {
                obj['OpeningHours'] = ApiClient.convertToType(data['OpeningHours'], 'String');
            }
            if (data.hasOwnProperty('StoreComments')) {
                obj['StoreComments'] = ApiClient.convertToType(data['StoreComments'], 'String');
            }
            if (data.hasOwnProperty('Latitude')) {
                obj['Latitude'] = ApiClient.convertToType(data['Latitude'], 'String');
            }
            if (data.hasOwnProperty('Longitude')) {
                obj['Longitude'] = ApiClient.convertToType(data['Longitude'], 'String');
            }
            if (data.hasOwnProperty('ContactNumber')) {
                obj['ContactNumber'] = ApiClient.convertToType(data['ContactNumber'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>PartnerStore</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>PartnerStore</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of PartnerStore.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['StoreName'] && !(typeof data['StoreName'] === 'string' || data['StoreName'] instanceof String)) {
            throw new Error("Expected the field `StoreName` to be a primitive type in the JSON string but got " + data['StoreName']);
        }
        // ensure the json data is a string
        if (data['StoreDisplayName'] && !(typeof data['StoreDisplayName'] === 'string' || data['StoreDisplayName'] instanceof String)) {
            throw new Error("Expected the field `StoreDisplayName` to be a primitive type in the JSON string but got " + data['StoreDisplayName']);
        }
        // ensure the json data is a string
        if (data['AddressLine1'] && !(typeof data['AddressLine1'] === 'string' || data['AddressLine1'] instanceof String)) {
            throw new Error("Expected the field `AddressLine1` to be a primitive type in the JSON string but got " + data['AddressLine1']);
        }
        // ensure the json data is a string
        if (data['AddressLine2'] && !(typeof data['AddressLine2'] === 'string' || data['AddressLine2'] instanceof String)) {
            throw new Error("Expected the field `AddressLine2` to be a primitive type in the JSON string but got " + data['AddressLine2']);
        }
        // ensure the json data is a string
        if (data['PostCode'] && !(typeof data['PostCode'] === 'string' || data['PostCode'] instanceof String)) {
            throw new Error("Expected the field `PostCode` to be a primitive type in the JSON string but got " + data['PostCode']);
        }
        // ensure the json data is a string
        if (data['CountyName'] && !(typeof data['CountyName'] === 'string' || data['CountyName'] instanceof String)) {
            throw new Error("Expected the field `CountyName` to be a primitive type in the JSON string but got " + data['CountyName']);
        }
        // ensure the json data is a string
        if (data['CountryName'] && !(typeof data['CountryName'] === 'string' || data['CountryName'] instanceof String)) {
            throw new Error("Expected the field `CountryName` to be a primitive type in the JSON string but got " + data['CountryName']);
        }
        // ensure the json data is a string
        if (data['OpeningHours'] && !(typeof data['OpeningHours'] === 'string' || data['OpeningHours'] instanceof String)) {
            throw new Error("Expected the field `OpeningHours` to be a primitive type in the JSON string but got " + data['OpeningHours']);
        }
        // ensure the json data is a string
        if (data['StoreComments'] && !(typeof data['StoreComments'] === 'string' || data['StoreComments'] instanceof String)) {
            throw new Error("Expected the field `StoreComments` to be a primitive type in the JSON string but got " + data['StoreComments']);
        }
        // ensure the json data is a string
        if (data['Latitude'] && !(typeof data['Latitude'] === 'string' || data['Latitude'] instanceof String)) {
            throw new Error("Expected the field `Latitude` to be a primitive type in the JSON string but got " + data['Latitude']);
        }
        // ensure the json data is a string
        if (data['Longitude'] && !(typeof data['Longitude'] === 'string' || data['Longitude'] instanceof String)) {
            throw new Error("Expected the field `Longitude` to be a primitive type in the JSON string but got " + data['Longitude']);
        }
        // ensure the json data is a string
        if (data['ContactNumber'] && !(typeof data['ContactNumber'] === 'string' || data['ContactNumber'] instanceof String)) {
            throw new Error("Expected the field `ContactNumber` to be a primitive type in the JSON string but got " + data['ContactNumber']);
        }

        return true;
    }


}

PartnerStore.RequiredProperties = ["StoreId", "StoreName", "StoreDisplayName", "AddressLine1", "AddressLine2", "PostCode", "CountyId", "CountyName", "CountryId", "CountryName", "OpeningHours", "StoreComments", "Latitude", "Longitude"];

/**
 * @member {Number} StoreId
 */
PartnerStore.prototype['StoreId'] = undefined;

/**
 * MaxLength:500
 * @member {String} StoreName
 */
PartnerStore.prototype['StoreName'] = undefined;

/**
 * MaxLength:50
 * @member {String} StoreDisplayName
 */
PartnerStore.prototype['StoreDisplayName'] = undefined;

/**
 * MaxLength:100
 * @member {String} AddressLine1
 */
PartnerStore.prototype['AddressLine1'] = undefined;

/**
 * MaxLength:100
 * @member {String} AddressLine2
 */
PartnerStore.prototype['AddressLine2'] = undefined;

/**
 * MaxLength:25
 * @member {String} PostCode
 */
PartnerStore.prototype['PostCode'] = undefined;

/**
 * @member {Number} CountyId
 */
PartnerStore.prototype['CountyId'] = undefined;

/**
 * MaxLength:64
 * @member {String} CountyName
 */
PartnerStore.prototype['CountyName'] = undefined;

/**
 * @member {Number} CountryId
 */
PartnerStore.prototype['CountryId'] = undefined;

/**
 * MaxLength:100
 * @member {String} CountryName
 */
PartnerStore.prototype['CountryName'] = undefined;

/**
 * MaxLength:30
 * @member {String} OpeningHours
 */
PartnerStore.prototype['OpeningHours'] = undefined;

/**
 * MaxLength:750
 * @member {String} StoreComments
 */
PartnerStore.prototype['StoreComments'] = undefined;

/**
 * MaxLength:20
 * @member {String} Latitude
 */
PartnerStore.prototype['Latitude'] = undefined;

/**
 * MaxLength:20
 * @member {String} Longitude
 */
PartnerStore.prototype['Longitude'] = undefined;

/**
 * MaxLength:100
 * @member {String} ContactNumber
 */
PartnerStore.prototype['ContactNumber'] = undefined;






export default PartnerStore;

