import { Component } from "react";

export default class CKSeparator extends Component {
    render() {
        let isSmaller = this.props.smaller ? true : false;
        const className = 'ck-separator' + ' ' + 
            (this.props.full ? 'full' : '') + 
            ' ' + 
            (isSmaller ? 'smaller' : '') +
            ' ' + 
            (this.props.nospace ? 'nospace' : '') + ' ' + (this.props.className ?? '');

        return <div className={className} style={{
            ...this.props.style
        }} />
    }
}