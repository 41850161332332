import Base from "./Base";
import CommsLayer from "./api/CommsLayer";

export default class PublicBase extends Base {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state
        }
    }

    componentDidMount() {
        let refresh_token = this.getRefreshToken();

        if(refresh_token) {
            this.showLoading();

            // lets check if access token acquired or not
            if(CommsLayer.getToken() && !CommsLayer.getToken().access_token) {
                // lets refresh token
                CommsLayer.refreshToken(refresh_token).then(() => {
                    this.navigateTo(this.redirectTo ? this.redirectTo : '/');
                }).catch(() => {
                    this.hideLoading();
                })
            } else {
                this.hideLoading();
            }
        }
    }

    render (children) {
        return super.render(children);
    }
}