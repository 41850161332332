/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The OrdersSummary model module.
 * @module model/OrdersSummary
 * @version v1
 */
class OrdersSummary {
    /**
     * Constructs a new <code>OrdersSummary</code>.
     * @alias module:model/OrdersSummary
     * @param orderId {Number} int64
     * @param storeName {String} MaxLength:500
     * @param transactionRef {String} MaxLength:35
     * @param transDate {String} Format [yyyy-MM-dd HH:mm:ss] UTC
     * @param orderPoints {Number} 
     * @param prepaid {Number} 
     * @param totalValue {Number} 
     * @param numBasketItems {Number} 
     */
    constructor(orderId, storeName, transactionRef, transDate, orderPoints, prepaid, totalValue, numBasketItems) { 
        
        OrdersSummary.initialize(this, orderId, storeName, transactionRef, transDate, orderPoints, prepaid, totalValue, numBasketItems);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, orderId, storeName, transactionRef, transDate, orderPoints, prepaid, totalValue, numBasketItems) { 
        obj['OrderId'] = orderId;
        obj['StoreName'] = storeName;
        obj['TransactionRef'] = transactionRef;
        obj['TransDate'] = transDate;
        obj['OrderPoints'] = orderPoints;
        obj['Prepaid'] = prepaid;
        obj['TotalValue'] = totalValue;
        obj['NumBasketItems'] = numBasketItems;
    }

    /**
     * Constructs a <code>OrdersSummary</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/OrdersSummary} obj Optional instance to populate.
     * @return {module:model/OrdersSummary} The populated <code>OrdersSummary</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new OrdersSummary();

            if (data.hasOwnProperty('OrderId')) {
                obj['OrderId'] = ApiClient.convertToType(data['OrderId'], 'Number');
            }
            if (data.hasOwnProperty('StoreName')) {
                obj['StoreName'] = ApiClient.convertToType(data['StoreName'], 'String');
            }
            if (data.hasOwnProperty('TransactionRef')) {
                obj['TransactionRef'] = ApiClient.convertToType(data['TransactionRef'], 'String');
            }
            if (data.hasOwnProperty('TransDate')) {
                obj['TransDate'] = ApiClient.convertToType(data['TransDate'], 'String');
            }
            if (data.hasOwnProperty('OrderPoints')) {
                obj['OrderPoints'] = ApiClient.convertToType(data['OrderPoints'], 'Number');
            }
            if (data.hasOwnProperty('Prepaid')) {
                obj['Prepaid'] = ApiClient.convertToType(data['Prepaid'], 'Number');
            }
            if (data.hasOwnProperty('TotalValue')) {
                obj['TotalValue'] = ApiClient.convertToType(data['TotalValue'], 'Number');
            }
            if (data.hasOwnProperty('NumBasketItems')) {
                obj['NumBasketItems'] = ApiClient.convertToType(data['NumBasketItems'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>OrdersSummary</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>OrdersSummary</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of OrdersSummary.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['StoreName'] && !(typeof data['StoreName'] === 'string' || data['StoreName'] instanceof String)) {
            throw new Error("Expected the field `StoreName` to be a primitive type in the JSON string but got " + data['StoreName']);
        }
        // ensure the json data is a string
        if (data['TransactionRef'] && !(typeof data['TransactionRef'] === 'string' || data['TransactionRef'] instanceof String)) {
            throw new Error("Expected the field `TransactionRef` to be a primitive type in the JSON string but got " + data['TransactionRef']);
        }
        // ensure the json data is a string
        if (data['TransDate'] && !(typeof data['TransDate'] === 'string' || data['TransDate'] instanceof String)) {
            throw new Error("Expected the field `TransDate` to be a primitive type in the JSON string but got " + data['TransDate']);
        }

        return true;
    }


}

OrdersSummary.RequiredProperties = ["OrderId", "StoreName", "TransactionRef", "TransDate", "OrderPoints", "Prepaid", "TotalValue", "NumBasketItems"];

/**
 * int64
 * @member {Number} OrderId
 */
OrdersSummary.prototype['OrderId'] = undefined;

/**
 * MaxLength:500
 * @member {String} StoreName
 */
OrdersSummary.prototype['StoreName'] = undefined;

/**
 * MaxLength:35
 * @member {String} TransactionRef
 */
OrdersSummary.prototype['TransactionRef'] = undefined;

/**
 * Format [yyyy-MM-dd HH:mm:ss] UTC
 * @member {String} TransDate
 */
OrdersSummary.prototype['TransDate'] = undefined;

/**
 * @member {Number} OrderPoints
 */
OrdersSummary.prototype['OrderPoints'] = undefined;

/**
 * @member {Number} Prepaid
 */
OrdersSummary.prototype['Prepaid'] = undefined;

/**
 * @member {Number} TotalValue
 */
OrdersSummary.prototype['TotalValue'] = undefined;

/**
 * @member {Number} NumBasketItems
 */
OrdersSummary.prototype['NumBasketItems'] = undefined;






export default OrdersSummary;

