import PublicBase from "../lib/PublicBase";
import Styles from "../lib/Styles";
import CommsLayer from "../lib/api/CommsLayer";
import Header from "../lib/components/Header";
import CKButton from "../lib/components/CKButton";
import ShadowPanel from "../lib/components/ShadowPanel";
import View from "../lib/components/View";
import CKNavigator from "../lib/CKNavigator";
import {ReactComponent as LogoPlay} from '../images/ck_playorpark_logo.svg';
import {ReactComponent as LogoExtra} from '../images/ck_extra_logo.svg';

export default class FuelCard extends PublicBase {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            isLoginInProgress: false,
            txtEmail: '',
            txtPassword: ''
        }
    }

    componentDidMount() {
        super.componentDidMount();

        this.hideLoading();
    }

    render() {
        return super.render(            
            <ShadowPanel style={{
                width: '100%',
                maxWidth:'540'
            }}>
                <Header style={{
                        marginBottom: 30,
                        fontSize: 28,
                        ...Styles.gothamNormal,
                    }}>Fuel Card Customer Sign Up</Header>
                <View style={{
                    marginTop: 20,
                    padding: 10,
                    borderRadius: 8,
                    backgroundColor: '#f7f5f2',
                    textAlign: 'center'
                }}>
                    <LogoPlay style={{
                        float: 'left',
                        width: 128,
                        marginLeft: 80,
                        marginTop: 18
                    }}/>
                    <LogoExtra style={{
                        float: 'left',
                        width: 128,
                        marginLeft: 40
                    }} />
                    <div 
                        style={{
                            clear: 'both'
                    }}></div>
                </View>
                <p style={{
                    fontSize: 16,
                    color: '#101820',
                    ...Styles.gothamNormal,
                }}>
                    Do you have a Play or Park or a Circle K Extra account?
                </p>
                <View style={{
                    paddingTop: 20,
                    paddingLeft: 60,
                    paddingRight: 60
                }}>
                    <div style={{}}>
                        <CKButton style={{float:'left', width: '47%'}} loading={this.state.isLoginInProgress} onClick={() => {this.navigateTo(CKNavigator.fuelCardTransfer);}}>Yes</CKButton>
                        <CKButton style={{float:'right', width: '47%'}} loading={this.state.isLoginInProgress} onClick={() => {this.navigateTo(CKNavigator.fuelCardSignup);}}>No</CKButton>
                        <div style={{ clear:'both'}}></div>
                    </div>
                    <CKButton style={{
                        marginTop: 12,
                        backgroundColor: 'transparent'
                    }} secondary onClick={() => {
                    this.navigateTo(CKNavigator.login);
                }}>Cancel</CKButton>
               </View>
            </ShadowPanel>
        )
    }
}