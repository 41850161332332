import LoggedInBase from "./LoggedInBase";
import Styles from "../lib/Styles";
import CKButton from "../lib/components/CKButton";
import ShadowPanel from "../lib/components/ShadowPanel";
import CKNavigator from "../lib/CKNavigator";
import {ReactComponent as Congratulation} from '../images/ck_congratulation.svg';


export default class FuelCardSuccess extends LoggedInBase {
    constructor(props) {
        super(props);

        this.state = {
            ...this.state
        }
    }

    componentDidMount() {
        super.componentDidMount();

        this.hideLoading();
    }

    render() {
        return super.render(            
            <ShadowPanel style={{
                width: '100%',
                maxWidth:'540',
                textAlign: 'center'
            }}>
                <Congratulation style={{
                    width: 102,
                    height: 102
                }}/>
                <div style={{
                    maxWidth: 384,
                    textAlign: 'center',
                    margin: '0 auto'
                }}>
                <p style={{
                    fontSize: 20,
                    color: '#101820',
                    fontWeight: 'bold',                    
                    ...Styles.gothamBold,
                }}>
                    Congratulations
                </p>

                <p style={{
                    fontSize: 15,
                    color: '#101820',
                    ...Styles.gothamNormal,                    
                }}>
                    Your Circle K Extra account has been created and your Fuel Card benefits have been set up.
                </p>
                </div>
                <CKButton style={{
                        maxWidth: 328,
                        marginTop: 10
                    }} onClick={() => {
                        this.navigateTo(CKNavigator.home);
                    }}>Start Circle K Extra</CKButton>

            </ShadowPanel>
        )
    }
}