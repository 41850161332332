/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The MemberCompetitionPlays model module.
 * @module model/MemberCompetitionPlays
 * @version v1
 */
class MemberCompetitionPlays {
    /**
     * Constructs a new <code>MemberCompetitionPlays</code>.
     * @alias module:model/MemberCompetitionPlays
     * @param competitionId {Number} 
     * @param totalPlays {Number} 
     */
    constructor(competitionId, totalPlays) { 
        
        MemberCompetitionPlays.initialize(this, competitionId, totalPlays);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, competitionId, totalPlays) { 
        obj['CompetitionId'] = competitionId;
        obj['TotalPlays'] = totalPlays;
    }

    /**
     * Constructs a <code>MemberCompetitionPlays</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/MemberCompetitionPlays} obj Optional instance to populate.
     * @return {module:model/MemberCompetitionPlays} The populated <code>MemberCompetitionPlays</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MemberCompetitionPlays();

            if (data.hasOwnProperty('CompetitionId')) {
                obj['CompetitionId'] = ApiClient.convertToType(data['CompetitionId'], 'Number');
            }
            if (data.hasOwnProperty('TotalPlays')) {
                obj['TotalPlays'] = ApiClient.convertToType(data['TotalPlays'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>MemberCompetitionPlays</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>MemberCompetitionPlays</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of MemberCompetitionPlays.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }

        return true;
    }


}

MemberCompetitionPlays.RequiredProperties = ["CompetitionId", "TotalPlays"];

/**
 * @member {Number} CompetitionId
 */
MemberCompetitionPlays.prototype['CompetitionId'] = undefined;

/**
 * @member {Number} TotalPlays
 */
MemberCompetitionPlays.prototype['TotalPlays'] = undefined;






export default MemberCompetitionPlays;

