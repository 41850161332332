/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import FreeItemsAvailable from './FreeItemsAvailable';

/**
 * The OfferDetails model module.
 * @module model/OfferDetails
 * @version v1
 */
class OfferDetails {
    /**
     * Constructs a new <code>OfferDetails</code>.
     * @alias module:model/OfferDetails
     * @param offerDiscount {Number} 
     * @param unitsEarned {Number} 
     */
    constructor(offerDiscount, unitsEarned) { 
        
        OfferDetails.initialize(this, offerDiscount, unitsEarned);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, offerDiscount, unitsEarned) { 
        obj['OfferDiscount'] = offerDiscount;
        obj['UnitsEarned'] = unitsEarned;
    }

    /**
     * Constructs a <code>OfferDetails</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/OfferDetails} obj Optional instance to populate.
     * @return {module:model/OfferDetails} The populated <code>OfferDetails</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new OfferDetails();

            if (data.hasOwnProperty('Multiple')) {
                obj['Multiple'] = ApiClient.convertToType(data['Multiple'], 'Number');
            }
            if (data.hasOwnProperty('OfferDiscount')) {
                obj['OfferDiscount'] = ApiClient.convertToType(data['OfferDiscount'], 'Number');
            }
            if (data.hasOwnProperty('MaxUnits')) {
                obj['MaxUnits'] = ApiClient.convertToType(data['MaxUnits'], 'Number');
            }
            if (data.hasOwnProperty('SkusTypeId')) {
                obj['SkusTypeId'] = ApiClient.convertToType(data['SkusTypeId'], 'Number');
            }
            if (data.hasOwnProperty('DiscountTypeId')) {
                obj['DiscountTypeId'] = ApiClient.convertToType(data['DiscountTypeId'], 'Number');
            }
            if (data.hasOwnProperty('MaxDayTakeup')) {
                obj['MaxDayTakeup'] = ApiClient.convertToType(data['MaxDayTakeup'], 'Number');
            }
            if (data.hasOwnProperty('OfferMsg')) {
                obj['OfferMsg'] = ApiClient.convertToType(data['OfferMsg'], 'String');
            }
            if (data.hasOwnProperty('UnitsEarned')) {
                obj['UnitsEarned'] = ApiClient.convertToType(data['UnitsEarned'], 'Number');
            }
            if (data.hasOwnProperty('FreeItemsAvailable')) {
                obj['FreeItemsAvailable'] = FreeItemsAvailable.constructFromObject(data['FreeItemsAvailable']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>OfferDetails</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>OfferDetails</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of OfferDetails.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['OfferMsg'] && !(typeof data['OfferMsg'] === 'string' || data['OfferMsg'] instanceof String)) {
            throw new Error("Expected the field `OfferMsg` to be a primitive type in the JSON string but got " + data['OfferMsg']);
        }
        // validate the optional field `FreeItemsAvailable`
        if (data['FreeItemsAvailable']) { // data not null
          FreeItemsAvailable.validateJSON(data['FreeItemsAvailable']);
        }

        return true;
    }


}

OfferDetails.RequiredProperties = ["OfferDiscount", "UnitsEarned"];

/**
 * @member {Number} Multiple
 */
OfferDetails.prototype['Multiple'] = undefined;

/**
 * @member {Number} OfferDiscount
 */
OfferDetails.prototype['OfferDiscount'] = undefined;

/**
 * @member {Number} MaxUnits
 */
OfferDetails.prototype['MaxUnits'] = undefined;

/**
 * @member {Number} SkusTypeId
 */
OfferDetails.prototype['SkusTypeId'] = undefined;

/**
 * @member {Number} DiscountTypeId
 */
OfferDetails.prototype['DiscountTypeId'] = undefined;

/**
 * @member {Number} MaxDayTakeup
 */
OfferDetails.prototype['MaxDayTakeup'] = undefined;

/**
 * MaxLength:100
 * @member {String} OfferMsg
 */
OfferDetails.prototype['OfferMsg'] = undefined;

/**
 * @member {Number} UnitsEarned
 */
OfferDetails.prototype['UnitsEarned'] = undefined;

/**
 * @member {module:model/FreeItemsAvailable} FreeItemsAvailable
 */
OfferDetails.prototype['FreeItemsAvailable'] = undefined;






export default OfferDetails;

