/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import OrderDetails from './OrderDetails';

/**
 * The OrderDetail model module.
 * @module model/OrderDetail
 * @version v1
 */
class OrderDetail {
    /**
     * Constructs a new <code>OrderDetail</code>.
     * @alias module:model/OrderDetail
     * @param orderId {Number} int64
     * @param storeName {String} MaxLength:500
     * @param transactionRef {String} MaxLength:35
     * @param transDate {String} Format [yyyy-MM-dd HH:mm:ss] UTC
     * @param orderPoints {Number} 
     * @param orderDetails {Array.<module:model/OrderDetails>} 
     */
    constructor(orderId, storeName, transactionRef, transDate, orderPoints, orderDetails) { 
        
        OrderDetail.initialize(this, orderId, storeName, transactionRef, transDate, orderPoints, orderDetails);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, orderId, storeName, transactionRef, transDate, orderPoints, orderDetails) { 
        obj['OrderId'] = orderId;
        obj['StoreName'] = storeName;
        obj['TransactionRef'] = transactionRef;
        obj['TransDate'] = transDate;
        obj['OrderPoints'] = orderPoints;
        obj['OrderDetails'] = orderDetails;
    }

    /**
     * Constructs a <code>OrderDetail</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/OrderDetail} obj Optional instance to populate.
     * @return {module:model/OrderDetail} The populated <code>OrderDetail</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new OrderDetail();

            if (data.hasOwnProperty('OrderId')) {
                obj['OrderId'] = ApiClient.convertToType(data['OrderId'], 'Number');
            }
            if (data.hasOwnProperty('StoreName')) {
                obj['StoreName'] = ApiClient.convertToType(data['StoreName'], 'String');
            }
            if (data.hasOwnProperty('TransactionRef')) {
                obj['TransactionRef'] = ApiClient.convertToType(data['TransactionRef'], 'String');
            }
            if (data.hasOwnProperty('TransDate')) {
                obj['TransDate'] = ApiClient.convertToType(data['TransDate'], 'String');
            }
            if (data.hasOwnProperty('OrderPoints')) {
                obj['OrderPoints'] = ApiClient.convertToType(data['OrderPoints'], 'Number');
            }
            if (data.hasOwnProperty('OrderDetails')) {
                obj['OrderDetails'] = ApiClient.convertToType(data['OrderDetails'], [OrderDetails]);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>OrderDetail</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>OrderDetail</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of OrderDetail.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['StoreName'] && !(typeof data['StoreName'] === 'string' || data['StoreName'] instanceof String)) {
            throw new Error("Expected the field `StoreName` to be a primitive type in the JSON string but got " + data['StoreName']);
        }
        // ensure the json data is a string
        if (data['TransactionRef'] && !(typeof data['TransactionRef'] === 'string' || data['TransactionRef'] instanceof String)) {
            throw new Error("Expected the field `TransactionRef` to be a primitive type in the JSON string but got " + data['TransactionRef']);
        }
        // ensure the json data is a string
        if (data['TransDate'] && !(typeof data['TransDate'] === 'string' || data['TransDate'] instanceof String)) {
            throw new Error("Expected the field `TransDate` to be a primitive type in the JSON string but got " + data['TransDate']);
        }
        if (data['OrderDetails']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['OrderDetails'])) {
                throw new Error("Expected the field `OrderDetails` to be an array in the JSON data but got " + data['OrderDetails']);
            }
            // validate the optional field `OrderDetails` (array)
            for (const item of data['OrderDetails']) {
                OrderDetails.validateJSON(item);
            };
        }

        return true;
    }


}

OrderDetail.RequiredProperties = ["OrderId", "StoreName", "TransactionRef", "TransDate", "OrderPoints", "OrderDetails"];

/**
 * int64
 * @member {Number} OrderId
 */
OrderDetail.prototype['OrderId'] = undefined;

/**
 * MaxLength:500
 * @member {String} StoreName
 */
OrderDetail.prototype['StoreName'] = undefined;

/**
 * MaxLength:35
 * @member {String} TransactionRef
 */
OrderDetail.prototype['TransactionRef'] = undefined;

/**
 * Format [yyyy-MM-dd HH:mm:ss] UTC
 * @member {String} TransDate
 */
OrderDetail.prototype['TransDate'] = undefined;

/**
 * @member {Number} OrderPoints
 */
OrderDetail.prototype['OrderPoints'] = undefined;

/**
 * @member {Array.<module:model/OrderDetails>} OrderDetails
 */
OrderDetail.prototype['OrderDetails'] = undefined;






export default OrderDetail;

