/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import APIResponse from '../model/APIResponse';
import Donate from '../model/Donate';
import Donation from '../model/Donation';

/**
* Donations service.
* @module api/DonationsApi
* @version v1
*/
export default class DonationsApi {

    /**
    * Constructs a new DonationsApi. 
    * @alias module:api/DonationsApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the donationsV1Donate operation.
     * @callback module:api/DonationsApi~donationsV1DonateCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Donate} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Add Donation Entry
     * Add a donation for a member   
     * @param {String} memberUId Member Unique Identifier
     * @param {Number} donationId ID of Donation
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:api/DonationsApi~donationsV1DonateCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Donate}
     */
    donationsV1Donate(memberUId, donationId, clientId, authorization, callback) {
      let postBody = null;
      // verify the required parameter 'memberUId' is set
      if (memberUId === undefined || memberUId === null) {
        throw new Error("Missing the required parameter 'memberUId' when calling donationsV1Donate");
      }
      // verify the required parameter 'donationId' is set
      if (donationId === undefined || donationId === null) {
        throw new Error("Missing the required parameter 'donationId' when calling donationsV1Donate");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling donationsV1Donate");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling donationsV1Donate");
      }

      let pathParams = {
        'memberUId': memberUId,
        'donationId': donationId
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = Donate;
      return this.apiClient.callApi(
        '/v1/donations/{donationId}/Members/{memberUId}/Donate', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the donationsV1GetDonation operation.
     * @callback module:api/DonationsApi~donationsV1GetDonationCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Donation} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get Donation
     * Get details of a specific donation   
     * @param {Number} donationId ID of Donation
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:api/DonationsApi~donationsV1GetDonationCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Donation}
     */
    donationsV1GetDonation(donationId, clientId, authorization, callback) {
      let postBody = null;
      // verify the required parameter 'donationId' is set
      if (donationId === undefined || donationId === null) {
        throw new Error("Missing the required parameter 'donationId' when calling donationsV1GetDonation");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling donationsV1GetDonation");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling donationsV1GetDonation");
      }

      let pathParams = {
        'donationId': donationId
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = Donation;
      return this.apiClient.callApi(
        '/v1/donations/{donationId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the donationsV1GetDonations operation.
     * @callback module:api/DonationsApi~donationsV1GetDonationsCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/Donation>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * List Donations
     * List details of all donations   
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {Object} opts Optional parameters
     * @param {String} [sortOrder] 1 - Points Cost descending    2 - Points Cost ascending    3 - Reward Name descending    4 - Reward Name ascending
     * @param {Number} [maxNo] Set to 0 for ALL
     * @param {module:api/DonationsApi~donationsV1GetDonationsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/Donation>}
     */
    donationsV1GetDonations(clientId, authorization, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling donationsV1GetDonations");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling donationsV1GetDonations");
      }

      let pathParams = {
      };
      let queryParams = {
        'sortOrder': opts['sortOrder'],
        'maxNo': opts['maxNo']
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = [Donation];
      return this.apiClient.callApi(
        '/v1/donations', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the donationsV1GetMemberDonation operation.
     * @callback module:api/DonationsApi~donationsV1GetMemberDonationCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Donation} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get Member Donation
     * Get details of a specific donation for a member   
     * @param {String} memberUId Member Unique Identifier
     * @param {Number} donationId ID of Donation
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:api/DonationsApi~donationsV1GetMemberDonationCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Donation}
     */
    donationsV1GetMemberDonation(memberUId, donationId, clientId, authorization, callback) {
      let postBody = null;
      // verify the required parameter 'memberUId' is set
      if (memberUId === undefined || memberUId === null) {
        throw new Error("Missing the required parameter 'memberUId' when calling donationsV1GetMemberDonation");
      }
      // verify the required parameter 'donationId' is set
      if (donationId === undefined || donationId === null) {
        throw new Error("Missing the required parameter 'donationId' when calling donationsV1GetMemberDonation");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling donationsV1GetMemberDonation");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling donationsV1GetMemberDonation");
      }

      let pathParams = {
        'memberUId': memberUId,
        'donationId': donationId
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = Donation;
      return this.apiClient.callApi(
        '/v1/donations/{donationId}/Members/{memberUId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the donationsV1GetMemberDonations operation.
     * @callback module:api/DonationsApi~donationsV1GetMemberDonationsCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/Donation>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * List Member Donations
     * List details of all donations for a member   
     * @param {String} memberUId Member Unique Identifier
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {Object} opts Optional parameters
     * @param {String} [sortOrder] 1 - Points Cost descending    2 - Points Cost ascending    3 - Reward Name descending    4 - Reward Name ascending
     * @param {Number} [maxNo] Set to 0 for ALL
     * @param {module:api/DonationsApi~donationsV1GetMemberDonationsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/Donation>}
     */
    donationsV1GetMemberDonations(memberUId, clientId, authorization, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'memberUId' is set
      if (memberUId === undefined || memberUId === null) {
        throw new Error("Missing the required parameter 'memberUId' when calling donationsV1GetMemberDonations");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling donationsV1GetMemberDonations");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling donationsV1GetMemberDonations");
      }

      let pathParams = {
        'memberUId': memberUId
      };
      let queryParams = {
        'sortOrder': opts['sortOrder'],
        'maxNo': opts['maxNo']
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = [Donation];
      return this.apiClient.callApi(
        '/v1/donations/Members/{memberUId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
