/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import APIResponse from '../model/APIResponse';
import Offer from '../model/Offer';
import OfferDetail from '../model/OfferDetail';
import OfferDetails from '../model/OfferDetails';
import OfferReserveResponse from '../model/OfferReserveResponse';

/**
* Offers service.
* @module api/OffersApi
* @version v1
*/
export default class OffersApi {

    /**
    * Constructs a new OffersApi. 
    * @alias module:api/OffersApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the offersV1GetCurrentOffersByMember operation.
     * @callback module:api/OffersApi~offersV1GetCurrentOffersByMemberCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/Offer>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * List Store Offers for Member
     * List all store offers for a member   
     * @param {Number} storeId ID of Store (0 for homestore)
     * @param {String} memberUId Member Unique Identifier
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {Object} opts Optional parameters
     * @param {Number} [maxNo] Set to 0 for ALL
     * @param {String} [offerClass] PH, PA, Blank for all
     * @param {module:api/OffersApi~offersV1GetCurrentOffersByMemberCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/Offer>}
     */
    offersV1GetCurrentOffersByMember(storeId, memberUId, clientId, authorization, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'storeId' is set
      if (storeId === undefined || storeId === null) {
        throw new Error("Missing the required parameter 'storeId' when calling offersV1GetCurrentOffersByMember");
      }
      // verify the required parameter 'memberUId' is set
      if (memberUId === undefined || memberUId === null) {
        throw new Error("Missing the required parameter 'memberUId' when calling offersV1GetCurrentOffersByMember");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling offersV1GetCurrentOffersByMember");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling offersV1GetCurrentOffersByMember");
      }

      let pathParams = {
        'storeId': storeId,
        'memberUId': memberUId
      };
      let queryParams = {
        'maxNo': opts['maxNo'],
        'offerClass': opts['offerClass']
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = [Offer];
      return this.apiClient.callApi(
        '/v1/offers/Stores/{storeId}/Members/{memberUId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the offersV1GetCurrentOffersByStore operation.
     * @callback module:api/OffersApi~offersV1GetCurrentOffersByStoreCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/Offer>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * List Store Offers
     * List all store offers   
     * @param {Number} storeId ID of Store (0 for homestore)
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {Object} opts Optional parameters
     * @param {Number} [maxNo] Set to 0 for ALL
     * @param {String} [offerClass] PH, PA, Blank for all
     * @param {module:api/OffersApi~offersV1GetCurrentOffersByStoreCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/Offer>}
     */
    offersV1GetCurrentOffersByStore(storeId, clientId, authorization, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'storeId' is set
      if (storeId === undefined || storeId === null) {
        throw new Error("Missing the required parameter 'storeId' when calling offersV1GetCurrentOffersByStore");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling offersV1GetCurrentOffersByStore");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling offersV1GetCurrentOffersByStore");
      }

      let pathParams = {
        'storeId': storeId
      };
      let queryParams = {
        'maxNo': opts['maxNo'],
        'offerClass': opts['offerClass']
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = [Offer];
      return this.apiClient.callApi(
        '/v1/offers/Stores/{storeId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the offersV1GetCurrentOffersWithDetails operation.
     * @callback module:api/OffersApi~offersV1GetCurrentOffersWithDetailsCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/OfferDetail>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * List Store Offer Details for Member
     * List all store offers and details for a member   
     * @param {Number} storeId ID of Store (0 for homestore)
     * @param {String} memberUId Member Unique Identifier
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {Object} opts Optional parameters
     * @param {Number} [maxNo] Set to 0 for ALL
     * @param {String} [offerClass] PH, PA, Blank for all
     * @param {module:api/OffersApi~offersV1GetCurrentOffersWithDetailsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/OfferDetail>}
     */
    offersV1GetCurrentOffersWithDetails(storeId, memberUId, clientId, authorization, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'storeId' is set
      if (storeId === undefined || storeId === null) {
        throw new Error("Missing the required parameter 'storeId' when calling offersV1GetCurrentOffersWithDetails");
      }
      // verify the required parameter 'memberUId' is set
      if (memberUId === undefined || memberUId === null) {
        throw new Error("Missing the required parameter 'memberUId' when calling offersV1GetCurrentOffersWithDetails");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling offersV1GetCurrentOffersWithDetails");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling offersV1GetCurrentOffersWithDetails");
      }

      let pathParams = {
        'storeId': storeId,
        'memberUId': memberUId
      };
      let queryParams = {
        'maxNo': opts['maxNo'],
        'offerClass': opts['offerClass']
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = [OfferDetail];
      return this.apiClient.callApi(
        '/v1/offers/Stores/{storeId}/Members/{memberUId}/Details', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the offersV1GetOfferDetails operation.
     * @callback module:api/OffersApi~offersV1GetOfferDetailsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/OfferDetails} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get Offer Details for Member
     * Get a specific offer details for a member   
     * @param {String} memberUId Member Unique Identifier
     * @param {Number} offerId ID of Offer
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {module:api/OffersApi~offersV1GetOfferDetailsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/OfferDetails}
     */
    offersV1GetOfferDetails(memberUId, offerId, clientId, authorization, callback) {
      let postBody = null;
      // verify the required parameter 'memberUId' is set
      if (memberUId === undefined || memberUId === null) {
        throw new Error("Missing the required parameter 'memberUId' when calling offersV1GetOfferDetails");
      }
      // verify the required parameter 'offerId' is set
      if (offerId === undefined || offerId === null) {
        throw new Error("Missing the required parameter 'offerId' when calling offersV1GetOfferDetails");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling offersV1GetOfferDetails");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling offersV1GetOfferDetails");
      }

      let pathParams = {
        'memberUId': memberUId,
        'offerId': offerId
      };
      let queryParams = {
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = OfferDetails;
      return this.apiClient.callApi(
        '/v1/offers/{offerId}/Members/{memberUId}/Details', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the offersV1ReserveOffer operation.
     * @callback module:api/OffersApi~offersV1ReserveOfferCallback
     * @param {String} error Error message, if any.
     * @param {module:model/OfferReserveResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Add Offer Reservation
     * Add an offer reservation for a member   
     * @param {String} memberUId Member Unique Identifier
     * @param {Number} offerId ID of Offer
     * @param {String} clientId Provided by PDI Technologies
     * @param {String} authorization Bearer {AccessToken}
     * @param {Object} opts Optional parameters
     * @param {Number} [quantity] No of items to reserve
     * @param {module:api/OffersApi~offersV1ReserveOfferCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/OfferReserveResponse}
     */
    offersV1ReserveOffer(memberUId, offerId, clientId, authorization, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'memberUId' is set
      if (memberUId === undefined || memberUId === null) {
        throw new Error("Missing the required parameter 'memberUId' when calling offersV1ReserveOffer");
      }
      // verify the required parameter 'offerId' is set
      if (offerId === undefined || offerId === null) {
        throw new Error("Missing the required parameter 'offerId' when calling offersV1ReserveOffer");
      }
      // verify the required parameter 'clientId' is set
      if (clientId === undefined || clientId === null) {
        throw new Error("Missing the required parameter 'clientId' when calling offersV1ReserveOffer");
      }
      // verify the required parameter 'authorization' is set
      if (authorization === undefined || authorization === null) {
        throw new Error("Missing the required parameter 'authorization' when calling offersV1ReserveOffer");
      }

      let pathParams = {
        'memberUId': memberUId,
        'offerId': offerId
      };
      let queryParams = {
        'quantity': opts['quantity']
      };
      let headerParams = {
        'clientId': clientId,
        'authorization': authorization
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json', 'text/json', 'application/xml', 'text/xml'];
      let returnType = OfferReserveResponse;
      return this.apiClient.callApi(
        '/v1/offers/{offerId}/Members/{memberUId}/Reserve', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
