/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The APIResponse model module.
 * @module model/APIResponse
 * @version v1
 */
class APIResponse {
    /**
     * Constructs a new <code>APIResponse</code>.
     * @alias module:model/APIResponse
     * @param success {Boolean} 
     * @param errorCode {String} MaxLength:10
     */
    constructor(success, errorCode) { 
        
        APIResponse.initialize(this, success, errorCode);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, success, errorCode) { 
        obj['Success'] = success;
        obj['ErrorCode'] = errorCode;
    }

    /**
     * Constructs a <code>APIResponse</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/APIResponse} obj Optional instance to populate.
     * @return {module:model/APIResponse} The populated <code>APIResponse</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new APIResponse();

            if (data.hasOwnProperty('Success')) {
                obj['Success'] = ApiClient.convertToType(data['Success'], 'Boolean');
            }
            if (data.hasOwnProperty('ErrorCode')) {
                obj['ErrorCode'] = ApiClient.convertToType(data['ErrorCode'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>APIResponse</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>APIResponse</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of APIResponse.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['ErrorCode'] && !(typeof data['ErrorCode'] === 'string' || data['ErrorCode'] instanceof String)) {
            throw new Error("Expected the field `ErrorCode` to be a primitive type in the JSON string but got " + data['ErrorCode']);
        }

        return true;
    }


}

APIResponse.RequiredProperties = ["Success", "ErrorCode"];

/**
 * @member {Boolean} Success
 */
APIResponse.prototype['Success'] = undefined;

/**
 * MaxLength:10
 * @member {String} ErrorCode
 */
APIResponse.prototype['ErrorCode'] = undefined;






export default APIResponse;

