/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The RedeemGiftResponse model module.
 * @module model/RedeemGiftResponse
 * @version v1
 */
class RedeemGiftResponse {
    /**
     * Constructs a new <code>RedeemGiftResponse</code>.
     * @alias module:model/RedeemGiftResponse
     * @param orderId {Number} int64
     * @param giftBalanceBefore {Number} 
     * @param giftBalanceNow {Number} 
     * @param giftAmountRedeemed {Number} 
     * @param giftAmountOutstanding {Number} 
     */
    constructor(orderId, giftBalanceBefore, giftBalanceNow, giftAmountRedeemed, giftAmountOutstanding) { 
        
        RedeemGiftResponse.initialize(this, orderId, giftBalanceBefore, giftBalanceNow, giftAmountRedeemed, giftAmountOutstanding);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, orderId, giftBalanceBefore, giftBalanceNow, giftAmountRedeemed, giftAmountOutstanding) { 
        obj['OrderId'] = orderId;
        obj['GiftBalanceBefore'] = giftBalanceBefore;
        obj['GiftBalanceNow'] = giftBalanceNow;
        obj['GiftAmountRedeemed'] = giftAmountRedeemed;
        obj['GiftAmountOutstanding'] = giftAmountOutstanding;
    }

    /**
     * Constructs a <code>RedeemGiftResponse</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/RedeemGiftResponse} obj Optional instance to populate.
     * @return {module:model/RedeemGiftResponse} The populated <code>RedeemGiftResponse</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new RedeemGiftResponse();

            if (data.hasOwnProperty('OrderId')) {
                obj['OrderId'] = ApiClient.convertToType(data['OrderId'], 'Number');
            }
            if (data.hasOwnProperty('GiftBalanceBefore')) {
                obj['GiftBalanceBefore'] = ApiClient.convertToType(data['GiftBalanceBefore'], 'Number');
            }
            if (data.hasOwnProperty('GiftBalanceNow')) {
                obj['GiftBalanceNow'] = ApiClient.convertToType(data['GiftBalanceNow'], 'Number');
            }
            if (data.hasOwnProperty('GiftAmountRedeemed')) {
                obj['GiftAmountRedeemed'] = ApiClient.convertToType(data['GiftAmountRedeemed'], 'Number');
            }
            if (data.hasOwnProperty('GiftAmountOutstanding')) {
                obj['GiftAmountOutstanding'] = ApiClient.convertToType(data['GiftAmountOutstanding'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>RedeemGiftResponse</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>RedeemGiftResponse</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of RedeemGiftResponse.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }

        return true;
    }


}

RedeemGiftResponse.RequiredProperties = ["OrderId", "GiftBalanceBefore", "GiftBalanceNow", "GiftAmountRedeemed", "GiftAmountOutstanding"];

/**
 * int64
 * @member {Number} OrderId
 */
RedeemGiftResponse.prototype['OrderId'] = undefined;

/**
 * @member {Number} GiftBalanceBefore
 */
RedeemGiftResponse.prototype['GiftBalanceBefore'] = undefined;

/**
 * @member {Number} GiftBalanceNow
 */
RedeemGiftResponse.prototype['GiftBalanceNow'] = undefined;

/**
 * @member {Number} GiftAmountRedeemed
 */
RedeemGiftResponse.prototype['GiftAmountRedeemed'] = undefined;

/**
 * @member {Number} GiftAmountOutstanding
 */
RedeemGiftResponse.prototype['GiftAmountOutstanding'] = undefined;






export default RedeemGiftResponse;

