import { useParams } from "react-router-dom";
import LoggedInBase from "./LoggedInBase";
import CKNavigator from "../lib/CKNavigator";
import CommsLayer from "../lib/api/CommsLayer";
import InboxBanner from '../images/inbox_banner.png';
import View from "../lib/components/View";
import moment from "moment";
import CKText from "../lib/components/CKText";
import CKSpacer from "../lib/components/CKSpacer";

class InboxSingle extends LoggedInBase {
    screenName = 'inbox'
    title = 'Circle K'
    subLevelPage = true

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            commsId: this.props.params && this.props.params.id ? this.props.params.id : false,
            item: false,
            screenLoading: true
        }
    }

    onBack () {
        this.navigateTo(CKNavigator.inbox)  
    }

    loadData() {
        // lets check if comms ID exists
        if(!this.state.commsId) return this.onBack();

        // lets verify if comms ID found on the API
        CommsLayer.communications().communicationsV1GetMemberMessages().then(data => {
            // lets verify that commsID belongs to the member
            let comm = data.find(f => {
                return f.CommunicationId ===this.state.commsId;
            })

            if(comm) {
                // lets check if comm is Read
                if(!comm.Read) {
                    CommsLayer.communications().communicationsV1MarkAsRead(this.state.commsId).then(() => {
                        comm.Read = true;

                        CommsLayer.communications().communicationsV1GetMemberMessages(true);
                    });
                }

                this.setState({
                    item: comm
                }, this.hideScreenLoading)
            } else {
                return this.onBack();
            }
        }).catch(() => {
            return this.onBack();
        })
    }

    componentDidMount() {
        super.componentDidMount();

        this.showScreenLoading();
    }

    render() {
        return super.render(
            !this.state.item ? null
            :
            <View>
                <img style={{
                    width: '100%'
                }} src={InboxBanner} />

                <View>
                    <CKText className="single-inbox-text-holder">
                        {moment(this.state.item.DateCreated).format('DD/MM/YYYY')}
                        &nbsp;&nbsp;&nbsp;
                        <CKText className="single-inbox-text-holder-from">From Circle K</CKText>
                    </CKText>

                    <CKText className="single-inbox-subject-holder">
                        {this.state.item.Subject}
                    </CKText>

                    <CKText className="single-inbox-subject-body">
                        {this.state.item.Message}
                    </CKText>

                    <CKSpacer />
                </View>
            </View>
        );
    }
}

export default (props) => (
    <InboxSingle
        {...props}
        params={useParams()}
    />
);