/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import QuestionAnswer from './QuestionAnswer';

/**
 * The CompetitionQuestion model module.
 * @module model/CompetitionQuestion
 * @version v1
 */
class CompetitionQuestion {
    /**
     * Constructs a new <code>CompetitionQuestion</code>.
     * @alias module:model/CompetitionQuestion
     * @param questionDescription {String} MaxLength:8000
     * @param questionAnswers {Array.<module:model/QuestionAnswer>} 
     */
    constructor(questionDescription, questionAnswers) { 
        
        CompetitionQuestion.initialize(this, questionDescription, questionAnswers);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, questionDescription, questionAnswers) { 
        obj['QuestionDescription'] = questionDescription;
        obj['QuestionAnswers'] = questionAnswers;
    }

    /**
     * Constructs a <code>CompetitionQuestion</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CompetitionQuestion} obj Optional instance to populate.
     * @return {module:model/CompetitionQuestion} The populated <code>CompetitionQuestion</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CompetitionQuestion();

            if (data.hasOwnProperty('QuestionDescription')) {
                obj['QuestionDescription'] = ApiClient.convertToType(data['QuestionDescription'], 'String');
            }
            if (data.hasOwnProperty('QuestionAnswers')) {
                obj['QuestionAnswers'] = ApiClient.convertToType(data['QuestionAnswers'], [QuestionAnswer]);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>CompetitionQuestion</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CompetitionQuestion</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of CompetitionQuestion.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['QuestionDescription'] && !(typeof data['QuestionDescription'] === 'string' || data['QuestionDescription'] instanceof String)) {
            throw new Error("Expected the field `QuestionDescription` to be a primitive type in the JSON string but got " + data['QuestionDescription']);
        }
        if (data['QuestionAnswers']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['QuestionAnswers'])) {
                throw new Error("Expected the field `QuestionAnswers` to be an array in the JSON data but got " + data['QuestionAnswers']);
            }
            // validate the optional field `QuestionAnswers` (array)
            for (const item of data['QuestionAnswers']) {
                QuestionAnswer.validateJSON(item);
            };
        }

        return true;
    }


}

CompetitionQuestion.RequiredProperties = ["QuestionDescription", "QuestionAnswers"];

/**
 * MaxLength:8000
 * @member {String} QuestionDescription
 */
CompetitionQuestion.prototype['QuestionDescription'] = undefined;

/**
 * @member {Array.<module:model/QuestionAnswer>} QuestionAnswers
 */
CompetitionQuestion.prototype['QuestionAnswers'] = undefined;






export default CompetitionQuestion;

