/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The MemberRegister model module.
 * @module model/MemberRegister
 * @version v1
 */
class MemberRegister {
    /**
     * Constructs a new <code>MemberRegister</code>.
     * @alias module:model/MemberRegister
     * @param email {String} MaxLength:64
     * @param registrationType {Number} 
     * @param tandCAgreed {Boolean} 
     * @param storeId {Number} 
     * @param language {Number} 
     * @param gender {Number} 
     * @param title {Number} 
     * @param nationality {Number} 
     * @param homeHeatOil {Boolean} 
     * @param isEmployee {Boolean} 
     * @param mobileNumCountryId {Number} 
     * @param sMSOptIn {Boolean} 
     * @param emailOptIn {Boolean} 
     * @param postOptIn {Boolean} 
     * @param inAppOptIn {Boolean} 
     * @param verificationCode {Number} 
     * @param isPaperRegistration {Boolean} 
     * @param countryCode {Number} 
     */
    constructor(email, registrationType, tandCAgreed, storeId, language, gender, title, nationality, homeHeatOil, isEmployee, mobileNumCountryId, sMSOptIn, emailOptIn, postOptIn, inAppOptIn, verificationCode, isPaperRegistration, countryCode) { 
        
        MemberRegister.initialize(this, email, registrationType, tandCAgreed, storeId, language, gender, title, nationality, homeHeatOil, isEmployee, mobileNumCountryId, sMSOptIn, emailOptIn, postOptIn, inAppOptIn, verificationCode, isPaperRegistration, countryCode);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, email, registrationType, tandCAgreed, storeId, language, gender, title, nationality, homeHeatOil, isEmployee, mobileNumCountryId, sMSOptIn, emailOptIn, postOptIn, inAppOptIn, verificationCode, isPaperRegistration, countryCode) { 
        obj['Email'] = email;
        obj['RegistrationType'] = registrationType;
        obj['TandCAgreed'] = tandCAgreed;
        obj['StoreId'] = storeId;
        obj['Language'] = language;
        obj['Gender'] = gender;
        obj['Title'] = title;
        obj['Nationality'] = nationality;
        obj['HomeHeatOil'] = homeHeatOil;
        obj['IsEmployee'] = isEmployee;
        obj['MobileNumCountryId'] = mobileNumCountryId;
        obj['SMSOptIn'] = sMSOptIn;
        obj['EmailOptIn'] = emailOptIn;
        obj['PostOptIn'] = postOptIn;
        obj['InAppOptIn'] = inAppOptIn;
        obj['VerificationCode'] = verificationCode;
        obj['IsPaperRegistration'] = isPaperRegistration;
        obj['CountryCode'] = countryCode;
    }

    /**
     * Constructs a <code>MemberRegister</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/MemberRegister} obj Optional instance to populate.
     * @return {module:model/MemberRegister} The populated <code>MemberRegister</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MemberRegister();

            if (data.hasOwnProperty('FirstName')) {
                obj['FirstName'] = ApiClient.convertToType(data['FirstName'], 'String');
            }
            if (data.hasOwnProperty('LastName')) {
                obj['LastName'] = ApiClient.convertToType(data['LastName'], 'String');
            }
            if (data.hasOwnProperty('Email')) {
                obj['Email'] = ApiClient.convertToType(data['Email'], 'String');
            }
            if (data.hasOwnProperty('HouseName')) {
                obj['HouseName'] = ApiClient.convertToType(data['HouseName'], 'String');
            }
            if (data.hasOwnProperty('AddressLine1')) {
                obj['AddressLine1'] = ApiClient.convertToType(data['AddressLine1'], 'String');
            }
            if (data.hasOwnProperty('AddressLine2')) {
                obj['AddressLine2'] = ApiClient.convertToType(data['AddressLine2'], 'String');
            }
            if (data.hasOwnProperty('Town')) {
                obj['Town'] = ApiClient.convertToType(data['Town'], 'String');
            }
            if (data.hasOwnProperty('PostCode')) {
                obj['PostCode'] = ApiClient.convertToType(data['PostCode'], 'String');
            }
            if (data.hasOwnProperty('County')) {
                obj['County'] = ApiClient.convertToType(data['County'], 'Number');
            }
            if (data.hasOwnProperty('Country')) {
                obj['Country'] = ApiClient.convertToType(data['Country'], 'Number');
            }
            if (data.hasOwnProperty('RegistrationType')) {
                obj['RegistrationType'] = ApiClient.convertToType(data['RegistrationType'], 'Number');
            }
            if (data.hasOwnProperty('TandCAgreed')) {
                obj['TandCAgreed'] = ApiClient.convertToType(data['TandCAgreed'], 'Boolean');
            }
            if (data.hasOwnProperty('Password')) {
                obj['Password'] = ApiClient.convertToType(data['Password'], 'String');
            }
            if (data.hasOwnProperty('LoyaltyCardNum')) {
                obj['LoyaltyCardNum'] = ApiClient.convertToType(data['LoyaltyCardNum'], 'String');
            }
            if (data.hasOwnProperty('StoreId')) {
                obj['StoreId'] = ApiClient.convertToType(data['StoreId'], 'Number');
            }
            if (data.hasOwnProperty('Language')) {
                obj['Language'] = ApiClient.convertToType(data['Language'], 'Number');
            }
            if (data.hasOwnProperty('Gender')) {
                obj['Gender'] = ApiClient.convertToType(data['Gender'], 'Number');
            }
            if (data.hasOwnProperty('Title')) {
                obj['Title'] = ApiClient.convertToType(data['Title'], 'Number');
            }
            if (data.hasOwnProperty('DateOfBirth')) {
                obj['DateOfBirth'] = ApiClient.convertToType(data['DateOfBirth'], 'String');
            }
            if (data.hasOwnProperty('Nationality')) {
                obj['Nationality'] = ApiClient.convertToType(data['Nationality'], 'Number');
            }
            if (data.hasOwnProperty('HomeHeatOil')) {
                obj['HomeHeatOil'] = ApiClient.convertToType(data['HomeHeatOil'], 'Boolean');
            }
            if (data.hasOwnProperty('IsEmployee')) {
                obj['IsEmployee'] = ApiClient.convertToType(data['IsEmployee'], 'Boolean');
            }
            if (data.hasOwnProperty('FuelCardNum')) {
                obj['FuelCardNum'] = ApiClient.convertToType(data['FuelCardNum'], 'String');
            }
            if (data.hasOwnProperty('MobileNum')) {
                obj['MobileNum'] = ApiClient.convertToType(data['MobileNum'], 'String');
            }
            if (data.hasOwnProperty('MobileNumCountryId')) {
                obj['MobileNumCountryId'] = ApiClient.convertToType(data['MobileNumCountryId'], 'Number');
            }
            if (data.hasOwnProperty('SMSOptIn')) {
                obj['SMSOptIn'] = ApiClient.convertToType(data['SMSOptIn'], 'Boolean');
            }
            if (data.hasOwnProperty('EmailOptIn')) {
                obj['EmailOptIn'] = ApiClient.convertToType(data['EmailOptIn'], 'Boolean');
            }
            if (data.hasOwnProperty('PostOptIn')) {
                obj['PostOptIn'] = ApiClient.convertToType(data['PostOptIn'], 'Boolean');
            }
            if (data.hasOwnProperty('InAppOptIn')) {
                obj['InAppOptIn'] = ApiClient.convertToType(data['InAppOptIn'], 'Boolean');
            }
            if (data.hasOwnProperty('VerificationCode')) {
                obj['VerificationCode'] = ApiClient.convertToType(data['VerificationCode'], 'Number');
            }
            if (data.hasOwnProperty('IsPaperRegistration')) {
                obj['IsPaperRegistration'] = ApiClient.convertToType(data['IsPaperRegistration'], 'Boolean');
            }
            if (data.hasOwnProperty('CountryCode')) {
                obj['CountryCode'] = ApiClient.convertToType(data['CountryCode'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>MemberRegister</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>MemberRegister</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of MemberRegister.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['FirstName'] && !(typeof data['FirstName'] === 'string' || data['FirstName'] instanceof String)) {
            throw new Error("Expected the field `FirstName` to be a primitive type in the JSON string but got " + data['FirstName']);
        }
        // ensure the json data is a string
        if (data['LastName'] && !(typeof data['LastName'] === 'string' || data['LastName'] instanceof String)) {
            throw new Error("Expected the field `LastName` to be a primitive type in the JSON string but got " + data['LastName']);
        }
        // ensure the json data is a string
        if (data['Email'] && !(typeof data['Email'] === 'string' || data['Email'] instanceof String)) {
            throw new Error("Expected the field `Email` to be a primitive type in the JSON string but got " + data['Email']);
        }
        // ensure the json data is a string
        if (data['HouseName'] && !(typeof data['HouseName'] === 'string' || data['HouseName'] instanceof String)) {
            throw new Error("Expected the field `HouseName` to be a primitive type in the JSON string but got " + data['HouseName']);
        }
        // ensure the json data is a string
        if (data['AddressLine1'] && !(typeof data['AddressLine1'] === 'string' || data['AddressLine1'] instanceof String)) {
            throw new Error("Expected the field `AddressLine1` to be a primitive type in the JSON string but got " + data['AddressLine1']);
        }
        // ensure the json data is a string
        if (data['AddressLine2'] && !(typeof data['AddressLine2'] === 'string' || data['AddressLine2'] instanceof String)) {
            throw new Error("Expected the field `AddressLine2` to be a primitive type in the JSON string but got " + data['AddressLine2']);
        }
        // ensure the json data is a string
        if (data['Town'] && !(typeof data['Town'] === 'string' || data['Town'] instanceof String)) {
            throw new Error("Expected the field `Town` to be a primitive type in the JSON string but got " + data['Town']);
        }
        // ensure the json data is a string
        if (data['PostCode'] && !(typeof data['PostCode'] === 'string' || data['PostCode'] instanceof String)) {
            throw new Error("Expected the field `PostCode` to be a primitive type in the JSON string but got " + data['PostCode']);
        }
        // ensure the json data is a string
        if (data['Password'] && !(typeof data['Password'] === 'string' || data['Password'] instanceof String)) {
            throw new Error("Expected the field `Password` to be a primitive type in the JSON string but got " + data['Password']);
        }
        // ensure the json data is a string
        if (data['LoyaltyCardNum'] && !(typeof data['LoyaltyCardNum'] === 'string' || data['LoyaltyCardNum'] instanceof String)) {
            throw new Error("Expected the field `LoyaltyCardNum` to be a primitive type in the JSON string but got " + data['LoyaltyCardNum']);
        }
        // ensure the json data is a string
        if (data['DateOfBirth'] && !(typeof data['DateOfBirth'] === 'string' || data['DateOfBirth'] instanceof String)) {
            throw new Error("Expected the field `DateOfBirth` to be a primitive type in the JSON string but got " + data['DateOfBirth']);
        }
        // ensure the json data is a string
        if (data['FuelCardNum'] && !(typeof data['FuelCardNum'] === 'string' || data['FuelCardNum'] instanceof String)) {
            throw new Error("Expected the field `FuelCardNum` to be a primitive type in the JSON string but got " + data['FuelCardNum']);
        }
        // ensure the json data is a string
        if (data['MobileNum'] && !(typeof data['MobileNum'] === 'string' || data['MobileNum'] instanceof String)) {
            throw new Error("Expected the field `MobileNum` to be a primitive type in the JSON string but got " + data['MobileNum']);
        }

        return true;
    }


}

MemberRegister.RequiredProperties = ["Email", "RegistrationType", "TandCAgreed", "StoreId", "Language", "Gender", "Title", "Nationality", "HomeHeatOil", "IsEmployee", "MobileNumCountryId", "SMSOptIn", "EmailOptIn", "PostOptIn", "InAppOptIn", "VerificationCode", "IsPaperRegistration", "CountryCode"];

/**
 * MaxLength:64
 * @member {String} FirstName
 */
MemberRegister.prototype['FirstName'] = undefined;

/**
 * MaxLength:64
 * @member {String} LastName
 */
MemberRegister.prototype['LastName'] = undefined;

/**
 * MaxLength:64
 * @member {String} Email
 */
MemberRegister.prototype['Email'] = undefined;

/**
 * MaxLength:100
 * @member {String} HouseName
 */
MemberRegister.prototype['HouseName'] = undefined;

/**
 * MaxLength:100
 * @member {String} AddressLine1
 */
MemberRegister.prototype['AddressLine1'] = undefined;

/**
 * MaxLength:100
 * @member {String} AddressLine2
 */
MemberRegister.prototype['AddressLine2'] = undefined;

/**
 * MaxLength:100
 * @member {String} Town
 */
MemberRegister.prototype['Town'] = undefined;

/**
 * MaxLength:100
 * @member {String} PostCode
 */
MemberRegister.prototype['PostCode'] = undefined;

/**
 * @member {Number} County
 */
MemberRegister.prototype['County'] = undefined;

/**
 * @member {Number} Country
 */
MemberRegister.prototype['Country'] = undefined;

/**
 * @member {Number} RegistrationType
 */
MemberRegister.prototype['RegistrationType'] = undefined;

/**
 * @member {Boolean} TandCAgreed
 */
MemberRegister.prototype['TandCAgreed'] = undefined;

/**
 * MaxLength:64
 * @member {String} Password
 */
MemberRegister.prototype['Password'] = undefined;

/**
 * MaxLength:25
 * @member {String} LoyaltyCardNum
 */
MemberRegister.prototype['LoyaltyCardNum'] = undefined;

/**
 * @member {Number} StoreId
 */
MemberRegister.prototype['StoreId'] = undefined;

/**
 * @member {Number} Language
 */
MemberRegister.prototype['Language'] = undefined;

/**
 * @member {Number} Gender
 */
MemberRegister.prototype['Gender'] = undefined;

/**
 * @member {Number} Title
 */
MemberRegister.prototype['Title'] = undefined;

/**
 * Format [yyyy-MM-dd]
 * @member {String} DateOfBirth
 */
MemberRegister.prototype['DateOfBirth'] = undefined;

/**
 * @member {Number} Nationality
 */
MemberRegister.prototype['Nationality'] = undefined;

/**
 * @member {Boolean} HomeHeatOil
 */
MemberRegister.prototype['HomeHeatOil'] = undefined;

/**
 * @member {Boolean} IsEmployee
 */
MemberRegister.prototype['IsEmployee'] = undefined;

/**
 * MaxLength:25
 * @member {String} FuelCardNum
 */
MemberRegister.prototype['FuelCardNum'] = undefined;

/**
 * MaxLength:20
 * @member {String} MobileNum
 */
MemberRegister.prototype['MobileNum'] = undefined;

/**
 * @member {Number} MobileNumCountryId
 */
MemberRegister.prototype['MobileNumCountryId'] = undefined;

/**
 * @member {Boolean} SMSOptIn
 */
MemberRegister.prototype['SMSOptIn'] = undefined;

/**
 * @member {Boolean} EmailOptIn
 */
MemberRegister.prototype['EmailOptIn'] = undefined;

/**
 * @member {Boolean} PostOptIn
 */
MemberRegister.prototype['PostOptIn'] = undefined;

/**
 * @member {Boolean} InAppOptIn
 */
MemberRegister.prototype['InAppOptIn'] = undefined;

/**
 * @member {Number} VerificationCode
 */
MemberRegister.prototype['VerificationCode'] = undefined;

/**
 * @member {Boolean} IsPaperRegistration
 */
MemberRegister.prototype['IsPaperRegistration'] = undefined;

/**
 * @member {Number} CountryCode
 */
MemberRegister.prototype['CountryCode'] = undefined;






export default MemberRegister;

