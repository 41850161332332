/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The MemberSummary model module.
 * @module model/MemberSummary
 * @version v1
 */
class MemberSummary {
    /**
     * Constructs a new <code>MemberSummary</code>.
     * @alias module:model/MemberSummary
     * @param firstName {String} MaxLength:64
     * @param lastName {String} MaxLength:64
     * @param storeId {Number} 
     * @param isMemberLoginCreated {Boolean} 
     * @param homeStore {String} MaxLength:50
     * @param loyaltyBalance {Number} 
     * @param linkedPointsBalance {Number} 
     * @param spendBalance {Number} 
     * @param cardNum {String} MaxLength:25
     * @param cardStatus {String} MaxLength:10
     * @param isVirtualCard {Boolean} 
     */
    constructor(firstName, lastName, storeId, isMemberLoginCreated, homeStore, loyaltyBalance, linkedPointsBalance, spendBalance, cardNum, cardStatus, isVirtualCard) { 
        
        MemberSummary.initialize(this, firstName, lastName, storeId, isMemberLoginCreated, homeStore, loyaltyBalance, linkedPointsBalance, spendBalance, cardNum, cardStatus, isVirtualCard);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, firstName, lastName, storeId, isMemberLoginCreated, homeStore, loyaltyBalance, linkedPointsBalance, spendBalance, cardNum, cardStatus, isVirtualCard) { 
        obj['FirstName'] = firstName;
        obj['LastName'] = lastName;
        obj['StoreId'] = storeId;
        obj['IsMemberLoginCreated'] = isMemberLoginCreated;
        obj['HomeStore'] = homeStore;
        obj['LoyaltyBalance'] = loyaltyBalance;
        obj['LinkedPointsBalance'] = linkedPointsBalance;
        obj['SpendBalance'] = spendBalance;
        obj['CardNum'] = cardNum;
        obj['CardStatus'] = cardStatus;
        obj['IsVirtualCard'] = isVirtualCard;
    }

    /**
     * Constructs a <code>MemberSummary</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/MemberSummary} obj Optional instance to populate.
     * @return {module:model/MemberSummary} The populated <code>MemberSummary</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MemberSummary();

            if (data.hasOwnProperty('FirstName')) {
                obj['FirstName'] = ApiClient.convertToType(data['FirstName'], 'String');
            }
            if (data.hasOwnProperty('LastName')) {
                obj['LastName'] = ApiClient.convertToType(data['LastName'], 'String');
            }
            if (data.hasOwnProperty('StoreId')) {
                obj['StoreId'] = ApiClient.convertToType(data['StoreId'], 'Number');
            }
            if (data.hasOwnProperty('IsMemberLoginCreated')) {
                obj['IsMemberLoginCreated'] = ApiClient.convertToType(data['IsMemberLoginCreated'], 'Boolean');
            }
            if (data.hasOwnProperty('HomeStore')) {
                obj['HomeStore'] = ApiClient.convertToType(data['HomeStore'], 'String');
            }
            if (data.hasOwnProperty('LoyaltyBalance')) {
                obj['LoyaltyBalance'] = ApiClient.convertToType(data['LoyaltyBalance'], 'Number');
            }
            if (data.hasOwnProperty('LinkedPointsBalance')) {
                obj['LinkedPointsBalance'] = ApiClient.convertToType(data['LinkedPointsBalance'], 'Number');
            }
            if (data.hasOwnProperty('SpendBalance')) {
                obj['SpendBalance'] = ApiClient.convertToType(data['SpendBalance'], 'Number');
            }
            if (data.hasOwnProperty('CardNum')) {
                obj['CardNum'] = ApiClient.convertToType(data['CardNum'], 'String');
            }
            if (data.hasOwnProperty('CardStatus')) {
                obj['CardStatus'] = ApiClient.convertToType(data['CardStatus'], 'String');
            }
            if (data.hasOwnProperty('IsVirtualCard')) {
                obj['IsVirtualCard'] = ApiClient.convertToType(data['IsVirtualCard'], 'Boolean');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>MemberSummary</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>MemberSummary</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of MemberSummary.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['FirstName'] && !(typeof data['FirstName'] === 'string' || data['FirstName'] instanceof String)) {
            throw new Error("Expected the field `FirstName` to be a primitive type in the JSON string but got " + data['FirstName']);
        }
        // ensure the json data is a string
        if (data['LastName'] && !(typeof data['LastName'] === 'string' || data['LastName'] instanceof String)) {
            throw new Error("Expected the field `LastName` to be a primitive type in the JSON string but got " + data['LastName']);
        }
        // ensure the json data is a string
        if (data['HomeStore'] && !(typeof data['HomeStore'] === 'string' || data['HomeStore'] instanceof String)) {
            throw new Error("Expected the field `HomeStore` to be a primitive type in the JSON string but got " + data['HomeStore']);
        }
        // ensure the json data is a string
        if (data['CardNum'] && !(typeof data['CardNum'] === 'string' || data['CardNum'] instanceof String)) {
            throw new Error("Expected the field `CardNum` to be a primitive type in the JSON string but got " + data['CardNum']);
        }
        // ensure the json data is a string
        if (data['CardStatus'] && !(typeof data['CardStatus'] === 'string' || data['CardStatus'] instanceof String)) {
            throw new Error("Expected the field `CardStatus` to be a primitive type in the JSON string but got " + data['CardStatus']);
        }

        return true;
    }


}

MemberSummary.RequiredProperties = ["FirstName", "LastName", "StoreId", "IsMemberLoginCreated", "HomeStore", "LoyaltyBalance", "LinkedPointsBalance", "SpendBalance", "CardNum", "CardStatus", "IsVirtualCard"];

/**
 * MaxLength:64
 * @member {String} FirstName
 */
MemberSummary.prototype['FirstName'] = undefined;

/**
 * MaxLength:64
 * @member {String} LastName
 */
MemberSummary.prototype['LastName'] = undefined;

/**
 * @member {Number} StoreId
 */
MemberSummary.prototype['StoreId'] = undefined;

/**
 * @member {Boolean} IsMemberLoginCreated
 */
MemberSummary.prototype['IsMemberLoginCreated'] = undefined;

/**
 * MaxLength:50
 * @member {String} HomeStore
 */
MemberSummary.prototype['HomeStore'] = undefined;

/**
 * @member {Number} LoyaltyBalance
 */
MemberSummary.prototype['LoyaltyBalance'] = undefined;

/**
 * @member {Number} LinkedPointsBalance
 */
MemberSummary.prototype['LinkedPointsBalance'] = undefined;

/**
 * @member {Number} SpendBalance
 */
MemberSummary.prototype['SpendBalance'] = undefined;

/**
 * MaxLength:25
 * @member {String} CardNum
 */
MemberSummary.prototype['CardNum'] = undefined;

/**
 * MaxLength:10
 * @member {String} CardStatus
 */
MemberSummary.prototype['CardStatus'] = undefined;

/**
 * @member {Boolean} IsVirtualCard
 */
MemberSummary.prototype['IsVirtualCard'] = undefined;






export default MemberSummary;

