import CKNavigator from "../lib/CKNavigator";
import Styles, { CKColors, CKPadding, CKSizes } from "../lib/Styles";
import CommsLayer from "../lib/api/CommsLayer";
import ErrorCodes from "../lib/api/ErrorCodes";
import Alert from "../lib/components/Alert";
import CKButton from "../lib/components/CKButton";
import CKInput from "../lib/components/CKInput";
import CKMenuItem from "../lib/components/CKMenuItem";
import CKMenuPanel from "../lib/components/CKMenuPanel";
import CKSpacer from "../lib/components/CKSpacer";
import CKText from "../lib/components/CKText";
import View from "../lib/components/View";
import LoggedInBase from "./LoggedInBase";

export default class ChangePassword extends LoggedInBase {
    screenName = 'more'
    title = 'Change Password'
    subLevelPage = true

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,

            txtPassword: '',
            txtPasswordError: '',

            txtNewPassword: '',
            txtNewPasswordError: '',

            txtConfirmNewPassword: '',
            txtConfirmNewPasswordError: '',

            waitingForChangePassword: false,
            isValid: false
        }
    }

    onBack() {
        this.navigateTo(CKNavigator.moreProfile);
    }

    loadData() {
        this.hideLoading();
    }

    componentDidMount() {
        super.componentDidMount();
    }

    changePassword = () => {
        if(this.validate()) {
            this.setState({
                waitingForChangePassword: true
            });

            CommsLayer.members().membersV1UpdatePassword(
                this.state.txtPassword,
                this.state.txtNewPassword,
                this.state.txtConfirmNewPassword
            ).then(() => {
                this.onBack();
            }).catch((err) => {
                if(err.code && err.message) {
                    Alert.alert("Error", err.message);
                } else {
                    Alert.alert("Error", ErrorCodes.generalAPIError());
                }

                this.setState({
                    waitingForChangePassword: false
                })
            })
        }
    }

    validate = (showErrors = true, field = '') => {
        var isValid = true;

        let newState = {
            txtConfirmNewPasswordError: '',
            txtNewPasswordError: '',
            txtPasswordError: ''
        };

        if(field) {
            newState = {};
            newState[field + 'Error'] = '';
        }

        if(this.state.txtPassword.trim() ==='') {
            isValid = false;
            if(!field || field ==='txtPassword') newState.txtPasswordError = 'Please enter your current password.';
        }

        if(this.state.txtNewPassword ==='') {
            isValid = false;
            if(!field || field ==='txtNewPassword') newState.txtNewPasswordError = 'Password is required';
        } else if(this.state.txtNewPassword.length < 8) {
            isValid = false;
            if(!field || field ==='txtNewPassword') newState.txtNewPasswordError = 'Password must be at least 8 characters.';
        } else if(!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d#$@!%&*?.*\W_]{8,}$/.test(this.state.txtNewPassword)) {
            isValid = false;
            if(!field || field ==='txtNewPassword') newState.txtNewPasswordError = 'Your password must consist of at least one UPPERCASE letter, lowercase letter, 1 number, and must be eight characters or more in length.';
        }

        if(this.state.txtConfirmNewPassword ==='') {
            isValid = false;
            if(!field || field ==='txtConfirmNewPassword') newState.txtConfirmNewPasswordError = 'Please confirm your password';
        } else if(this.state.txtNewPassword !==this.state.txtConfirmNewPassword) {
            isValid = false;
            if(!field || field ==='txtConfirmNewPassword') newState.txtConfirmNewPasswordError = 'Both passwords must match.';
        }

        if(showErrors) {
            this.setState(newState);
        }

        return isValid;
    }


    render() {
        return super.render(
            <View style={{
                display: 'flex',
                justifyContent: 'center'
            }}>
                <View className="main-container maxed" style={{
                    textAlign: 'left'
                }}>
                    <CKText className="change-pass-title">Change password</CKText>

                    <CKText className="change-pass-subtitle">
                        Choose a new password to keep your account safe.
                    </CKText>

                    <View style={{
                        marginBottom: CKPadding.default * 2.5
                    }}>
                        <CKInput
                            autoCapitalize='none'
                            label='Current password'
                            placeholder={'Please enter your current password'}
                            error={this.state.txtPasswordError}
                            type="password"
                            value={this.state.txtPassword}
                            onChange={(evt) => {
                                this.setState({
                                    txtPassword: evt.target.value,
                                    txtPasswordError: ''
                                }, () => {
                                    this.setState({
                                        isValid: this.validate(true, 'txtPassword')
                                    });
                                });
                            }}
                        />
                    </View>


                    <View style={{
                        marginBottom: CKPadding.default * 2.5
                    }}>
                        <CKInput
                            ref={ref => this.txtNewPassword = ref}
                            type="password"
                            placeholder={'Please enter password'}
                            value={this.state.txtNewPassword}
                            onChange={(evt) => {
                                this.setState({txtNewPassword: evt.target.value}, () => {
                                    this.setState({
                                        isValid: this.validate(true, 'txtNewPassword')
                                    });
                                    this.validate(true, 'txtConfirmNewPassword');
                                });
                            }}
                            label='New Password'
                            error={this.state.txtNewPasswordError}
                            sublabel='Your password must consist of at least one UPPERCASE letter, lowercase letter, 1 number, and must be eight characters or more in length.'
                        />
                    </View>



                    <View style={{
                        marginBottom: CKPadding.default * 2.5
                    }}>
                        <CKInput
                            ref={ref => this.txtRepeatPassword = ref}
                            type="password"
                            placeholder={'Please confirm new password'}
                            value={this.state.txtConfirmNewPassword}
                            onChange={(evt) => {
                                this.setState({txtConfirmNewPassword: evt.target.value}, () => {
                                    this.setState({
                                        isValid: this.validate(true, 'txtConfirmNewPassword')
                                    });
                                });
                            }}
                            label='Confirm New Password'
                            error={this.state.txtConfirmNewPasswordError}
                            sublabel='Both passwords must match.'
                        />
                    </View>

                    <CKButton disabled={!this.state.isValid} onWhite onPress={() => {
                        this.changePassword();
                    }} loading={this.state.waitingForChangePassword}
                    >Reset Password</CKButton>

                    <CKButton secondary className="add-top-space hide-on-desktop" onPress={() => {
                        this.onBack();
                    }}
                    >Back to Profile</CKButton>

                    <CKSpacer />
                </View>
            </View>
        )
    }
}