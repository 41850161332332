import { Component } from "react";
import Styles, { Colors } from "../Styles";
import {ReactComponent as Logo} from '../../images/ck_extra_logo.svg';

export default class ShadowPanel extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const className = 'shadow-panel ' + (this.props.className ?? '');

        return <div className={className}>
            <div className="shadow-panel-container" style={{
                ...this.props.style
            }}>
                <div className="logo-container">
                    <Logo />
                </div>

                <div className="children">
                    {this.props.children}
                </div>
            </div>
        </div>
    }
}