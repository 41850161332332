/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The IdentifyResponse model module.
 * @module model/IdentifyResponse
 * @version v1
 */
class IdentifyResponse {
    /**
     * Constructs a new <code>IdentifyResponse</code>.
     * @alias module:model/IdentifyResponse
     * @param memberUId {String} MaxLength:100
     */
    constructor(memberUId) { 
        
        IdentifyResponse.initialize(this, memberUId);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, memberUId) { 
        obj['MemberUId'] = memberUId;
    }

    /**
     * Constructs a <code>IdentifyResponse</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/IdentifyResponse} obj Optional instance to populate.
     * @return {module:model/IdentifyResponse} The populated <code>IdentifyResponse</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new IdentifyResponse();

            if (data.hasOwnProperty('MemberUId')) {
                obj['MemberUId'] = ApiClient.convertToType(data['MemberUId'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>IdentifyResponse</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>IdentifyResponse</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of IdentifyResponse.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['MemberUId'] && !(typeof data['MemberUId'] === 'string' || data['MemberUId'] instanceof String)) {
            throw new Error("Expected the field `MemberUId` to be a primitive type in the JSON string but got " + data['MemberUId']);
        }

        return true;
    }


}

IdentifyResponse.RequiredProperties = ["MemberUId"];

/**
 * MaxLength:100
 * @member {String} MemberUId
 */
IdentifyResponse.prototype['MemberUId'] = undefined;






export default IdentifyResponse;

