/**
 * PDI Loyalty International API v1.5.20
 * Note: All dates/times returned via API call will be UTC / Accept-Encoding: gzip, deflate<br><br><a href='https://pditechnologies.com/engage-customers/loyalty-marketing-solutions/'>PDI Technologies website</a><br><a href='mailto:azpiral-info@pditechnologies.com?subject=AzpiralAPI'>Send an email to PDI Technologies</a><br><a href='../../Contents/FlowDiagrams/Index.htm'>Flow Diagrams</a><br><a href='../../v1/Helpers/ApiErrors/'>Error Codes</a>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The SocialProfileUpdate model module.
 * @module model/SocialProfileUpdate
 * @version v1
 */
class SocialProfileUpdate {
    /**
     * Constructs a new <code>SocialProfileUpdate</code>.
     * @alias module:model/SocialProfileUpdate
     * @param socialProfileTypeId {Number} 
     */
    constructor(socialProfileTypeId) { 
        
        SocialProfileUpdate.initialize(this, socialProfileTypeId);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, socialProfileTypeId) { 
        obj['SocialProfileTypeId'] = socialProfileTypeId;
    }

    /**
     * Constructs a <code>SocialProfileUpdate</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/SocialProfileUpdate} obj Optional instance to populate.
     * @return {module:model/SocialProfileUpdate} The populated <code>SocialProfileUpdate</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new SocialProfileUpdate();

            if (data.hasOwnProperty('Name')) {
                obj['Name'] = ApiClient.convertToType(data['Name'], 'String');
            }
            if (data.hasOwnProperty('AccessToken')) {
                obj['AccessToken'] = ApiClient.convertToType(data['AccessToken'], 'String');
            }
            if (data.hasOwnProperty('SocialMediaId')) {
                obj['SocialMediaId'] = ApiClient.convertToType(data['SocialMediaId'], 'String');
            }
            if (data.hasOwnProperty('SocialProfileTypeId')) {
                obj['SocialProfileTypeId'] = ApiClient.convertToType(data['SocialProfileTypeId'], 'Number');
            }
            if (data.hasOwnProperty('PersonGender')) {
                obj['PersonGender'] = ApiClient.convertToType(data['PersonGender'], 'String');
            }
            if (data.hasOwnProperty('FirstName')) {
                obj['FirstName'] = ApiClient.convertToType(data['FirstName'], 'String');
            }
            if (data.hasOwnProperty('LastName')) {
                obj['LastName'] = ApiClient.convertToType(data['LastName'], 'String');
            }
            if (data.hasOwnProperty('Email')) {
                obj['Email'] = ApiClient.convertToType(data['Email'], 'String');
            }
            if (data.hasOwnProperty('Link')) {
                obj['Link'] = ApiClient.convertToType(data['Link'], 'String');
            }
            if (data.hasOwnProperty('Locale')) {
                obj['Locale'] = ApiClient.convertToType(data['Locale'], 'String');
            }
            if (data.hasOwnProperty('TimeZone')) {
                obj['TimeZone'] = ApiClient.convertToType(data['TimeZone'], 'Number');
            }
            if (data.hasOwnProperty('Verified')) {
                obj['Verified'] = ApiClient.convertToType(data['Verified'], 'Boolean');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>SocialProfileUpdate</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>SocialProfileUpdate</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of SocialProfileUpdate.RequiredProperties) {
            if (!data[property]) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['Name'] && !(typeof data['Name'] === 'string' || data['Name'] instanceof String)) {
            throw new Error("Expected the field `Name` to be a primitive type in the JSON string but got " + data['Name']);
        }
        // ensure the json data is a string
        if (data['AccessToken'] && !(typeof data['AccessToken'] === 'string' || data['AccessToken'] instanceof String)) {
            throw new Error("Expected the field `AccessToken` to be a primitive type in the JSON string but got " + data['AccessToken']);
        }
        // ensure the json data is a string
        if (data['SocialMediaId'] && !(typeof data['SocialMediaId'] === 'string' || data['SocialMediaId'] instanceof String)) {
            throw new Error("Expected the field `SocialMediaId` to be a primitive type in the JSON string but got " + data['SocialMediaId']);
        }
        // ensure the json data is a string
        if (data['PersonGender'] && !(typeof data['PersonGender'] === 'string' || data['PersonGender'] instanceof String)) {
            throw new Error("Expected the field `PersonGender` to be a primitive type in the JSON string but got " + data['PersonGender']);
        }
        // ensure the json data is a string
        if (data['FirstName'] && !(typeof data['FirstName'] === 'string' || data['FirstName'] instanceof String)) {
            throw new Error("Expected the field `FirstName` to be a primitive type in the JSON string but got " + data['FirstName']);
        }
        // ensure the json data is a string
        if (data['LastName'] && !(typeof data['LastName'] === 'string' || data['LastName'] instanceof String)) {
            throw new Error("Expected the field `LastName` to be a primitive type in the JSON string but got " + data['LastName']);
        }
        // ensure the json data is a string
        if (data['Email'] && !(typeof data['Email'] === 'string' || data['Email'] instanceof String)) {
            throw new Error("Expected the field `Email` to be a primitive type in the JSON string but got " + data['Email']);
        }
        // ensure the json data is a string
        if (data['Link'] && !(typeof data['Link'] === 'string' || data['Link'] instanceof String)) {
            throw new Error("Expected the field `Link` to be a primitive type in the JSON string but got " + data['Link']);
        }
        // ensure the json data is a string
        if (data['Locale'] && !(typeof data['Locale'] === 'string' || data['Locale'] instanceof String)) {
            throw new Error("Expected the field `Locale` to be a primitive type in the JSON string but got " + data['Locale']);
        }

        return true;
    }


}

SocialProfileUpdate.RequiredProperties = ["SocialProfileTypeId"];

/**
 * MaxLength:128
 * @member {String} Name
 */
SocialProfileUpdate.prototype['Name'] = undefined;

/**
 * MaxLength:500
 * @member {String} AccessToken
 */
SocialProfileUpdate.prototype['AccessToken'] = undefined;

/**
 * MaxLength:200
 * @member {String} SocialMediaId
 */
SocialProfileUpdate.prototype['SocialMediaId'] = undefined;

/**
 * @member {Number} SocialProfileTypeId
 */
SocialProfileUpdate.prototype['SocialProfileTypeId'] = undefined;

/**
 * MaxLength:15
 * @member {String} PersonGender
 */
SocialProfileUpdate.prototype['PersonGender'] = undefined;

/**
 * MaxLength:64
 * @member {String} FirstName
 */
SocialProfileUpdate.prototype['FirstName'] = undefined;

/**
 * MaxLength:64
 * @member {String} LastName
 */
SocialProfileUpdate.prototype['LastName'] = undefined;

/**
 * MaxLength:64
 * @member {String} Email
 */
SocialProfileUpdate.prototype['Email'] = undefined;

/**
 * MaxLength:500
 * @member {String} Link
 */
SocialProfileUpdate.prototype['Link'] = undefined;

/**
 * MaxLength:50
 * @member {String} Locale
 */
SocialProfileUpdate.prototype['Locale'] = undefined;

/**
 * @member {Number} TimeZone
 */
SocialProfileUpdate.prototype['TimeZone'] = undefined;

/**
 * @member {Boolean} Verified
 */
SocialProfileUpdate.prototype['Verified'] = undefined;






export default SocialProfileUpdate;

