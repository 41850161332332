export const Colors = {
    primaryRed: "#da291c",
    darkRed: "#C42115",
    lightGray: "#f7f5f2",
    darkGray: "#D3D3D3",
    pink: "#EA766D",
    white: "#FFFFFF",
    primaryGray: "#C4C4C4",
    primaryDarkGray: "#7B7B7B",
    borderColor: "#E3E3E3",
    orange: "#ED8B00",
    primaryText: "#101820",
    placeholderColor: "#65676A",
    menuGray: "#ECEFF2"
}

const Styles = {
    gothamBold: {
        fontFamily: 'Gotham Bold',
        lineHeight: 1.25,
        fontWeight: 'normal'
    },
    gothamMedium : {
        fontFamily: 'Gotham Medium',
        lineHeight: 1.25,
        fontWeight: 'normal'
    },
    gothamBook: {
        fontFamily: 'Gotham Book',
        lineHeight: 1.25,
        fontWeight: 'normal'
    },
    gotham: {
        fontFamily: 'Gotham Book',
        lineHeight: 1.25,
        fontWeight: 'normal'
    },
    gothamBlack: {
        fontFamily: 'Gotham Black',
        lineHeight: 1.25,
        fontWeight: 'normal'
    },
    shadowPanel: {
        background: '#FFFFFF',
        boxShadow: "0px 3px 30px " + Colors.primaryText + "26"
    },
    shadow: {
        boxShadow: "0px 5px 8px #10182026"
    }
}

export default Styles;

export const CKColors = {
    verRedDark: '#F59911',
    redDark: '#a61315',
    red: "#DA291C",
    red2: "#C42115",
    red3: "#B92318",
    red4: "#A43339",
    red5: "#A8353A",
    redLight: "#e25f5a",
    redLight2: "#EA766D",
    orange: "#ED8B00",
    darkerOrange: "#d57d00",
    lightOrange: "#FEDBB7",
    offWhite: "#F7F5F2",
    white: "#FFFFFF",
    whiteTransparent: "#FFFFFFAA",
    offBlack: "#101820",
    offerNot: "#757575",
    disabledBG: "#b7b7b7",
    disabledText: "#686868",
    lightGray: "#D3D3D3",
    borderColor: "#E3E3E3",
    borderColorLight: "#EDEDED",
    focusedBorderColor: "#ED8B00",
    placeholderColor: "#65676A",
    readOnlyColor: "#65676A",
    anotherGrayColor: "#65676A",
    playOrParkQRBG: "#f9f7f3",
    playOrParkDarkBlack: "#231F20",
    someLightGray: "#ccccca"
}

export const CKPadding = {
    default: 16,
    minimal: 10
}

export const CKSizes = {
    borderWidth: 1,
    sliderIndicator: 8,
    font: {
        px9: 9,
        px10: 10,
        px11: 11,
        tiny: 12,
        px13: 13,
        default: 14,
        larger: 15,
        px16: 16,
        px18: 18,
        px20: 20,
        px22: 22,
        px24: 24,
        px30: 30,
        px32: 32,
        px36: 36,
        px40: 40
    },
    defaultIconWidth: 40,
    defaultIconHeight: 40,
    inputHeight: 45,
    readonlyInputHeight: 35,
    smallIconHeight: 24,
    smallIconWidth: 24,
    headerLogo: 30,
    radius: 8
}